import { FC, ReactElement, useState } from 'react';

import {
    CheckboxList,
    DateInput,
    NumberInput,
    NumberInputWithControls,
    Pagination,
    PasswordInput,
    RadioList,
    RangeInput,
    SelectInput,
    Textarea,
    TextInput,
} from '../../../../../compositions';
import { MinMaxValue } from '../../../../../types';

import './InputExamples.scss';

interface InputExamplesProps {
    className?: string;
}

export const InputExamples: FC<InputExamplesProps> = ({
    className = '',
}): ReactElement => {
    const formOptions = [
        { label: 'Optie 1', value: '1' },
        { label: 'Optie 2', value: '2' },
        { label: 'Optie 3', value: '3' },
    ];

    const [selectedRadio, setSelectedRadio] = useState<string>('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];

    const [date, setDate] = useState<string>(formattedDate);
    const [range, setRange] = useState<MinMaxValue>({ min: 0, max: 100 });

    const [paginationPage, setPaginationPage] = useState<number>(1);

    return (
        <div className={`input-examples ${className}`}>
            <RadioList
                label="Enkele selectie"
                name="radio-list"
                value={selectedRadio}
                options={formOptions}
                onChange={setSelectedRadio}
                className="input-examples__input"
            />

            <RangeInput
                id="range-input"
                minHandleLabel="min"
                maxHandleLabel="max"
                label="Range invoer"
                value={range}
                min={0}
                max={100}
                onChange={setRange}
                className="input-examples__input"
            />

            <CheckboxList
                label="Meerdere selectie"
                name="checkbox-list"
                value={selectedCheckboxes}
                options={formOptions}
                onChange={setSelectedCheckboxes}
                className="input-examples__input"
            />

            <CheckboxList
                isSmall
                label="Meerdere selectie (compact)"
                name="checkbox-list-small"
                value={selectedCheckboxes}
                options={formOptions}
                onChange={setSelectedCheckboxes}
                className="input-examples__input"
            />

            <TextInput
                label="Tekst invoer"
                placeholder="Voer een tekst in"
                onChange={console.log}
                className="input-examples__input"
            />

            <TextInput
                required
                label="Tekst invoer (required)"
                placeholder="Voer een tekst in"
                onChange={console.log}
                className="input-examples__input"
            />

            <TextInput
                label="Tekst invoer (disabled)"
                placeholder="Voer een tekst in"
                disabled
                onChange={console.log}
                className="input-examples__input"
            />

            <TextInput
                label="Tekst invoer (error)"
                placeholder="Voer een tekst in"
                error="Dit is een foutmelding"
                onChange={console.log}
                className="input-examples__input"
            />

            <TextInput
                hasUnderline
                label="Tekst invoer (underline)"
                placeholder="Voer een tekst in"
                onChange={console.log}
                className="input-examples__input"
            />

            <TextInput
                hasUnderline
                type="search"
                icon="search"
                label="Zoekveld"
                onChange={console.log}
                className="input-examples__input"
            />

            <PasswordInput
                required
                label="Wachtwoord invoer"
                onChange={console.log}
                className="input-examples__input"
            />

            <NumberInput
                label="Nummer invoer"
                value={1}
                onChange={console.log}
                className="input-examples__input"
            />

            <DateInput
                label="Datum invoer"
                value={date}
                onChange={setDate}
                className="input-examples__input"
            />

            <NumberInputWithControls
                label="Nummer invoer met knoppen"
                value={1}
                min={1}
                max={99}
                onChange={console.log}
                className="input-examples__input"
            />

            <SelectInput
                name="select"
                label="Selectie"
                options={formOptions}
                onChange={console.log}
                className="input-examples__input input-examples__select-input"
            />

            <Textarea
                label="Tekstveld"
                rows={8}
                onChange={console.log}
                className="input-examples__input"
            />

            <Pagination
                numberOfPages={30}
                currentPage={paginationPage}
                onChange={setPaginationPage}
                className="input-examples__input"
            />
        </div>
    );
};
