import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './SearchResultsSkeletons.scss';

interface SearchResultsSkeletonsProps {
    className?: string;
}

export const SearchResultsSkeletons: FC<SearchResultsSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const numberOfProductResults = 5;
    const productResultSkeletons = generateIdArray(numberOfProductResults);

    const numberOfPageResults = 5;
    const pageResultSkeletons = generateIdArray(numberOfPageResults);

    return (
        <div className={`search-results-skeletons ${className}`}>
            <div className="search-results-skeletons__product-results">
                <Skeleton className="search-results-skeletons__label" />

                <div className="search-results-skeletons__list">
                    {productResultSkeletons.map(skeleton => (
                        <div key={skeleton} className="search-results-skeletons__item">
                            <Skeleton className="search-results-skeletons__skeleton" />
                        </div>
                    ))}
                </div>
            </div>

            <div className="search-results-skeletons__page-results">
                <Skeleton className="search-results-skeletons__label" />

                <div className="search-results-skeletons__list">
                    {pageResultSkeletons.map(skeleton => (
                        <div key={skeleton} className="search-results-skeletons__item">
                            <Skeleton className="search-results-skeletons__skeleton" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
