export interface TokenResource {
    readonly customer: string;
    readonly refresh_token: string;
    readonly refresh_token_expiration: number;
    readonly token: string;
}

export interface RefreshTokenBodyResource {
    refresh_token: string;
}

export interface UnauthorizedTokenResource {
    readonly code: number;
    readonly message: string;
}

export const isUnauthorizedResponse = (response: TokenResource | UnauthorizedTokenResource): response is UnauthorizedTokenResource => (
    (<UnauthorizedTokenResource>response).code !== undefined
);

export const tokenKey = 'syliusToken';

export interface Token {
    customerId: string;
    customerLoods5Id?: string;
    token: string;
    refreshToken: string;
}
