import { FC, PropsWithChildren, ReactElement } from 'react';

import { DefaultHelmet } from '../../../../../compositions';
import { Brand } from '../../../../../entities/Brand/Brand';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { generateBreadcrumbStructuredData } from '../../../../../helpers/structuredData';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useTrans } from '../../../../../hooks';

interface BrandDetailHelmetProps {
    brand: Brand;
}

export const BrandDetailHelmet: FC<PropsWithChildren<BrandDetailHelmetProps>> = ({
    brand,
    children,
}): ReactElement => {
    const trans = useTrans();

    const siteUrl = trans('company.url');

    const metaTitle = brand.seo
        ? brand.seo.title
        : brand.name;

    const metaDescription = brand.seo
        ? brand.seo.description
        : metaTitle;

    const metaKeywords = brand.seo
        ? brand.seo.keywords
        : undefined;

    const metaImage = brand.seo
        ? brand.seo.image
        : brand.logo;

    const brandPath = trans(appRoutes[AppRoute.brandDetail].path);
    const brandUrl = replaceUrlParamKeysWithValues(brandPath, {
        slug: brand.slug,
    });

    const breadcrumbs = generateBreadcrumbStructuredData(siteUrl, [
        {
            label: trans(appRoutes[AppRoute.storeOverview].label),
            href: trans(appRoutes[AppRoute.storeOverview].path),
        },
        {
            label: trans(appRoutes[AppRoute.departmentOverview].label),
            href: trans(appRoutes[AppRoute.departmentOverview].path),
        },
        {
            label: brand.name,
            href: brandUrl,
        },
    ]);

    return (
        <DefaultHelmet
            title={metaTitle}
            description={metaDescription}
            keywords={metaKeywords}
            image={metaImage}
            canonical={brand.seo?.canonicalURL}
        >
            <script type="application/ld+json">
                {breadcrumbs}
            </script>

            {children}
        </DefaultHelmet>
    );
};
