import { RouteData } from '../../LocalizedRoutes';

export enum AppRoute {
    // Account
    account = 'routes.account',
    accountChangePassword = 'routes.accountChangePassword',
    accountDashboard = 'routes.accountDashboard',
    accountData = 'routes.accountData',
    accountOrderDetail = 'routes.accountOrderDetail',
    accountOrderOverview = 'routes.accountOrderOverview',
    accountWishlist = 'routes.accountWishlist',
    login = 'routes.login',
    registration = 'routes.registration',
    resetPassword = 'routes.resetPassword',
    // Blog
    blogOverview = 'routes.blogOverview',
    blogOverviewCategory = 'routes.blogOverviewCategory',
    blogPost = 'routes.blogPost',
    // Brands
    brandDetail = 'routes.brandDetail',
    brandOverview = 'routes.brandOverview',
    // Content
    companyJoinUs = 'routes.companyJoinUs',
    companyMedia = 'routes.companyMedia',
    companyStory = 'routes.companyStory',
    companySustainability = 'routes.companySustainability',
    companyWorkAt = 'routes.companyWorkAt',
    customerService = 'routes.customerService',
    customerServiceAccount = 'routes.customerServiceAccount',
    customerServiceDelivery = 'routes.customerServiceDelivery',
    customerServiceFaq = 'routes.customerServiceFaq',
    customerServiceOrdering = 'routes.customerServiceOrdering',
    customerServiceReturns = 'routes.customerServiceReturns',
    customerServiceTerms = 'routes.customerServiceTerms',
    legalCookies = 'routes.legalCookies',
    legalDisclaimer = 'routes.legalDisclaimer',
    legalPrivacy = 'routes.legalPrivacy',
    newsletter = 'routes.newsletter',
    ownCollection = 'routes.ownCollection',
    ownCollectionCategory = 'routes.ownCollectionCategory',
    departmentOverview = 'routes.departmentOverview',
    storeOpeningHours = 'routes.storeOpeningHours',
    storeOverview = 'routes.storeOverview',
    restaurant = 'routes.restaurant',
    // Departments
    departmentDetail = 'routes.departmentDetail',
    // Products
    productCategory = 'routes.productCategory',
    productDetail = 'routes.productDetail',
    products = 'routes.products',
    sale = 'routes.sale',
    // Shop
    checkout = 'routes.checkout',
    checkoutOrder = 'routes.checkoutOrder',
    checkoutDetails = 'routes.checkoutDetails',
    checkoutPayment = 'routes.checkoutPayment',
    checkoutThankYou = 'routes.checkoutThankYou',
    wishlist = 'routes.wishlist',
    // Spaces
    spaceDetail = 'routes.spaceDetail',
    spacesOverview = 'routes.spacesOverview',
    // Stores
    storeDetail = 'routes.storeDetail',
    // Other
    custom = 'routes.custom',
    giftcardBalance = 'routes.giftcardBalance',
    home = 'routes.home',
    notFound = 'routes.notFound',
    search = 'routes.search',
    // Development
    contentBlocks = 'routes.contentBlocks',
    index = 'routes.index',
    styleguide = 'routes.styleguide',
}

export const appRoutes = Object.fromEntries<RouteData<AppRoute>>(
    Object.values(AppRoute).map(value => ([value, {
        key: value as AppRoute,
        label: `${value}.label`,
        path: `${value}.path`,
    }])),
);
