import { FC, ReactElement } from 'react';

import { LinkButton } from '../../../../compositions';
import { Product } from '../../../../entities/@products/Product/Product';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { convertNumberToCurrency } from '../../../../helpers/number';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import { useTrans } from '../../../../hooks';

import './ProductSuggestions.scss';

interface ProductSuggestionsProps {
    products: Product[];
    onProductClick: (product: Product) => void;
    className?: string;
}

export const ProductSuggestions: FC<ProductSuggestionsProps> = ({
    products,
    onProductClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`product-suggestions ${className}`}>
            <p className="product-suggestions__label">
                {trans('common.products')}
            </p>

            {products.length === 0 && (
                <p className="product-suggestions__no-results">
                    {trans('containers.searchDrawer.noProductResults')}
                </p>
            )}

            {products.length > 0 && (
                <ul className="product-suggestions__list">
                    {products.map(product => {
                        const productDetailPath = trans(appRoutes[AppRoute.productDetail].path);
                        const productDetailUrl = replaceUrlParamKeysWithValues(productDetailPath, {
                            slug: product.variantSlug,
                        });

                        const price = convertNumberToCurrency(product.price.current, { includeCurrencySymbol: true });
                        const buttonLabel = [product.name, product.brand?.label, price]
                            .filter(Boolean)
                            .join(' ⋅ ');

                        const handleLinkClick = (): void => {
                            onProductClick(product);
                        };

                        return (
                            <li key={product.id}>
                                <LinkButton
                                    to={productDetailUrl}
                                    text={buttonLabel}
                                    onClick={handleLinkClick}
                                    className="product-suggestions__list-item"
                                />
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};
