import { strapiFetch } from '../../../entities/@api/Strapi';
import { GetTaxonResponse, syliusFetch } from '../../../entities/@api/Sylius';
import { ProductCategoryParams, ProductCategoryResponse } from '../../../entities/@products/ProductCategory/ProductCategory';
import { generateProductCategoryQuery } from '../../../entities/@products/ProductCategory/ProductCategoryRequests';
import {
    generateProductCategoryBreadcrumbs,
    getProductCategoryReplacementUrl,
} from '../../../entities/@products/ProductCategory/ProductCategoryService';
import {
    transformTaxonToProductCategory,
    transformToProductCategoryPageData,
} from '../../../entities/@products/ProductCategory/ProductCategoryTransformers';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { ReduxFetchAction } from '../../defaults';
import {
    setActiveProductCategory,
    setError,
    setHasFetched,
    setIsLoading,
    setProductCategoryBreadcrumbs,
    setProductCategoryPageData,
    setProductCategoryReplacementUrl,
} from './productCategorySlice';

export const fetchProductCategoryPageData: ReduxFetchAction<ProductCategoryParams> = categoryParams => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setError(''));
    dispatch(setProductCategoryPageData(undefined));

    const {
        slug: mainCategorySlug = '',
        subCategory: subCategorySlug,
        subSubCategory: subSubCategorySlug,
    } = categoryParams;

    try {
        const mainCategoryTaxonResponse = await syliusFetch<GetTaxonResponse>(`/shop/taxons/${mainCategorySlug}`);

        if (!isFetchResultSuccessful(mainCategoryTaxonResponse)) {
            dispatch(setError(mainCategoryTaxonResponse.error));
            return;
        }

        const mainCategoryTaxonResource = mainCategoryTaxonResponse.data;
        const mainCategory = mainCategoryTaxonResource
            ? transformTaxonToProductCategory(mainCategoryTaxonResource)
            : undefined;

        const subCategory = mainCategory && subCategorySlug
            ? mainCategory.children?.find(child => child.slug === subCategorySlug)
            : undefined;

        const subSubCategory = subSubCategorySlug
            ? subCategory?.children?.find(child => child.slug === subSubCategorySlug)
            : undefined;

        dispatch(setActiveProductCategory(subSubCategory || subCategory || mainCategory));

        const strapiQuery = generateProductCategoryQuery(subSubCategorySlug || subCategorySlug || mainCategorySlug);
        const productCategoryResponse = await strapiFetch<ProductCategoryResponse>(`/categories?${strapiQuery}`);

        if (!isFetchResultSuccessful(productCategoryResponse)) {
            dispatch(setError(productCategoryResponse.error));
            return;
        }

        const { data: productCategoryData } = productCategoryResponse.data;

        const productCategoryResource = productCategoryData
            ? productCategoryData[0]
            : undefined;

        const productCategoryPageData = productCategoryResource
            ? transformToProductCategoryPageData(productCategoryResource)
            : undefined;

        const productCategories = { mainCategory, subCategory, subSubCategory };
        const replacementUrl = mainCategory
            ? getProductCategoryReplacementUrl(productCategories, categoryParams)
            : undefined;

        const breadcrumbs = generateProductCategoryBreadcrumbs(productCategories);

        dispatch(setProductCategoryReplacementUrl(replacementUrl));
        dispatch(setProductCategoryBreadcrumbs(breadcrumbs));
        dispatch(setProductCategoryPageData(productCategoryPageData));
    } catch (error) {
        console.error('[fetchProductCategoryPageData]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};
