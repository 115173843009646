import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../entities/@forms/FormValidation/FormValidationService';
import { Address } from '../../../../entities/Address/Address';

export type AddressFormErrors = FormErrors<Address>;

export const validateAddressFormData = (formData: Address): FormValidation<AddressFormErrors> => {
    const errors: AddressFormErrors = {
        label: validateRequiredString('addressLabel', formData.label),
        street: validateRequiredString('street', formData.street),
        houseNumber: validateRequiredString('houseNumber', formData.houseNumber),
        city: validateRequiredString('city', formData.city),
        postalCode: validateRequiredString('postalCode', formData.postalCode),
        country: validateRequiredString('country', formData.country),
    };

    return validateForm<AddressFormErrors>(errors);
};
