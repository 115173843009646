import { BaseResource } from './BaseResource';
import { Locales } from './Locale';
import { DiscountResource } from './Prices';

export type ProductAttributeChoice = Record<Locales, string>;
export type ProductAttributeChoices = Record<string, ProductAttributeChoice>;

export interface ProductAttributeConfigurationResource extends BaseResource {
    choices: ProductAttributeChoices;
}

export interface ProductAttributeContentsResource extends BaseResource {
    name: string;
    code: string;
    configuration: ProductAttributeConfigurationResource;
}

export enum ProductAttributeType {
    text = 'text',
    number = 'float',
    boolean = 'checkbox',
    option = 'select',
}

export enum ProductAttributeKey {
    sku = 'sku',
    brand = 'v_brand',
    origin = 'v_origin',
    materials = 'v_material',
    colors = 'v_color',
    isNew = 'v_isNew',
    ranking = 'v_ranking',
    deliveryMin = 'v_deliveryMin',
    deliveryMax = 'v_deliveryMax',
}

export enum ProductDimensionAttributeKey {
    width = 'width',
    height = 'height',
    depth = 'depth',
    diameter = 'diameter',
    weight = 'weight',
}

type ProductAttributeValue = string | string[] | number | boolean;

export interface ProductAttributeResource extends BaseResource {
    type: ProductAttributeType;
    name: string;
    code: ProductAttributeKey | ProductDimensionAttributeKey;
    attribute: ProductAttributeContentsResource;
    value: ProductAttributeValue;
    localeCode: string;
}

export interface ProductVariantBaseResource extends BaseResource {
    inStock: boolean;
    lowestPriceBeforeDiscount?: number;
    originalPrice: number;
    price: number;
}

export interface ProductVariantResource extends ProductVariantBaseResource {
    code: string;
    product: string;
    variantId: string;
    variantCode: string;
    optionValues: string[];
    attributes: ProductAttributeResource[];
    name: string;
    inStock: boolean;
    price: number;
    originalPrice: number;
    discount: DiscountResource | null;
}
