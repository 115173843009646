import {
    ChangeEvent,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { Input, InputProps } from '../../../components';
import { InputLabelProps, InputLabelWrapper } from '../..';

import './DateInput.scss';

export interface DateInputProps extends InputLabelProps, Omit<InputProps, 'onChange'> {
    onChange: (value: string) => void;
    fieldClassName?: string;
    labelClassName?: string;
}

export const DateInput: ForwardRefExoticComponent<DateInputProps> = forwardRef(({
    label,
    hideLabel,
    required,
    tooltip,
    error,
    onChange,
    className = '',
    fieldClassName = '',
    labelClassName = '',
    ...inputProps
}, ref: Ref<HTMLInputElement>): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <label aria-label={label} className={`date-input ${className}`}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                required={required}
                tooltip={tooltip}
                error={error}
                labelClassName={labelClassName}
            >
                <Input
                    {...inputProps}
                    ref={ref}
                    type="date"
                    required={required}
                    error={error}
                    onChange={handleChange}
                    fieldClassName={fieldClassName}
                />
            </InputLabelWrapper>
        </label>
    );
});
