import { FC, ReactElement } from 'react';

import { useLocation } from 'react-router-dom';

import {
    ConnectedAppWrapper,
    ConnectedCacheBuster,
    ConnectedCookieSettings,
    ConnectedFooter,
    ConnectedMenu,
    ConnectedToastList,
    ConnectedTopNavbar,
    ConnectedUrgentBanner,
} from './connectors';
import { initializeTagManager } from './entities/GTM/GTMService';
import { AppRoute, appRoutes } from './entities/Routing/Routing';
import { isSSR } from './helpers';
import { useTrans } from './hooks';
import { LocalizedRoutes } from './LocalizedRoutes';

// TODO: if (!isSSR && isProduction) {
if (!isSSR) {
    initializeTagManager({
        originalLocation: window?.location?.href,
    });
}

export const App: FC = (): ReactElement => {
    const trans = useTrans();
    const { pathname } = useLocation();

    const checkoutDetailsPath = trans(appRoutes[AppRoute.checkoutDetails].path);
    const checkoutPaymentPath = trans(appRoutes[AppRoute.checkoutPayment].path);

    const isCheckoutPage = pathname.includes(checkoutDetailsPath) || pathname.includes(checkoutPaymentPath);

    return (
        <ConnectedAppWrapper>
            {!isCheckoutPage && (
                <>
                    <ConnectedTopNavbar />
                    <ConnectedMenu />
                    <ConnectedUrgentBanner />
                </>
            )}

            <LocalizedRoutes />

            <ConnectedFooter showTopFooter={!isCheckoutPage} />

            <ConnectedToastList />
            <ConnectedCookieSettings />
            <ConnectedCacheBuster />
        </ConnectedAppWrapper>
    );
};
