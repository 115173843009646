export enum GTMEvent {
    addToCart = 'add_to_cart',
    addToWishlist = 'add_to_wishlist',
    checkoutHasBegun = 'begin_checkout',
    consent = 'consent',
    login = 'login',
    purchase = 'purchase',
    removeItemFromCart = 'remove_from_cart',
    search = 'search',
    signUp = 'sign_up',
    viewCart = 'view_cart',
    viewItemList = 'view_item_list',
    viewItem = 'view_item',
}
