import { FC, ReactElement } from 'react';

import { ConnectedProductList } from '../../connectors';
import { MeilisearchStoreKey } from '../../entities/@search/Meilisearch/Meilisearch';
import { useTrans } from '../../hooks';

import './WishlistItems.scss';

interface WishlistItemsProps {
    productsFilterQuery?: string;
    resultsCount: number;
    className?: string;
}

export const WishlistItems: FC<WishlistItemsProps> = ({
    productsFilterQuery,
    resultsCount,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`wishlist-items ${className}`}>
            {resultsCount === 0 && (
                <p className="wishlist-items__no-items-label">
                    {trans('containers.wishlistItems.noWishlistItem')}
                </p>
            )}

            {productsFilterQuery && resultsCount > 0 && (
                <div className="wishlist-items__items">
                    <ConnectedProductList
                        storeKey={MeilisearchStoreKey.wishlist}
                        baseFilter={productsFilterQuery}
                    />
                </div>
            )}
        </div>
    );
};
