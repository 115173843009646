import { RequestCustomerResetPasswordResponse, syliusFetch } from '../../../entities/@api/Sylius';
import { ResetPasswordFormData } from '../../../entities/@forms/ResetPasswordForm/ResetPasswordForm';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { TypedDispatch } from '../../store';
import { setError, setIsLoading, setIsSuccessful } from './resetPasswordSlice';

export const resetPassword = (formData: ResetPasswordFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsSuccessful(false));

    try {
        const requestBody = {
            email: formData.username,
        };

        const resetPasswordResponse = await syliusFetch<RequestCustomerResetPasswordResponse>('/shop/customers/reset-password', {
            method: 'POST',
            body: JSON.stringify(requestBody),
        });

        if (!isFetchResultSuccessful(resetPasswordResponse)) {
            dispatch(setError(resetPasswordResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[resetPassword]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
