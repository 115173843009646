import { generateUuid } from '../../../helpers/string';
import {
    CheckoutStateResource,
    OrderBaseResource,
    OrderItemResource,
    OrderPaymentMethodResource,
    OrderPaymentProviderResource,
    OrderPaymentResource,
    OrderResource,
    OrderShipmentMethodResource,
    OrderShipmentResource,
    OrderStateResource,
    PaymentStateResource,
    ShippingStateResource,
} from '../../@api/Sylius';
import { transformToVariantCode } from '../../@products/Product/ProductTransformers';
import { AddressType } from '../../Address/Address';
import { transformToCustomerAddress } from '../../Address/AddressTransformers';
import {
    CheckoutState,
    OrderBase,
    OrderDetailInterface,
    OrderPayment,
    OrderPaymentMethod,
    OrderPaymentProvider,
    OrderProductVariant,
    OrderShipment,
    OrderShipmentMethod,
    OrderState,
    PaymentState,
    ShippingState,
} from './OrderDetail';

const transformToOrderState = (resource: OrderStateResource): OrderState => {
    if (resource === OrderStateResource.cancelled) {
        return OrderState.cancelled;
    }

    if (resource === OrderStateResource.fulfilled) {
        return OrderState.fulfilled;
    }

    if (resource === OrderStateResource.new) {
        return OrderState.new;
    }

    return OrderState.cart;
};

const transformToCheckoutState = (resource: CheckoutStateResource): CheckoutState => {
    if (resource === CheckoutStateResource.addressed) {
        return CheckoutState.addressed;
    }

    if (resource === CheckoutStateResource.completed) {
        return CheckoutState.completed;
    }

    if (resource === CheckoutStateResource.paymentSelected) {
        return CheckoutState.paymentSelected;
    }

    if (resource === CheckoutStateResource.paymentSkipped) {
        return CheckoutState.paymentSkipped;
    }

    if (resource === CheckoutStateResource.shippingSelected) {
        return CheckoutState.shippingSelected;
    }

    if (resource === CheckoutStateResource.shippingSkipped) {
        return CheckoutState.shippingSkipped;
    }

    return CheckoutState.cart;
};

const transformToPaymentState = (resource: PaymentStateResource): PaymentState => {
    if (resource === PaymentStateResource.authorized) {
        return PaymentState.authorized;
    }

    if (resource === PaymentStateResource.awaitingPayment) {
        return PaymentState.awaitingPayment;
    }

    if (resource === PaymentStateResource.cancelled) {
        return PaymentState.cancelled;
    }

    if (resource === PaymentStateResource.paid) {
        return PaymentState.paid;
    }

    if (resource === PaymentStateResource.partiallyAuthorized) {
        return PaymentState.partiallyAuthorized;
    }

    if (resource === PaymentStateResource.partiallyPaid) {
        return PaymentState.partiallyPaid;
    }

    if (resource === PaymentStateResource.partiallyRefunded) {
        return PaymentState.partiallyRefunded;
    }

    if (resource === PaymentStateResource.refunded) {
        return PaymentState.refunded;
    }

    return PaymentState.cart;
};

const transformToShippingState = (resource: ShippingStateResource): ShippingState => {
    if (resource === ShippingStateResource.ready) {
        return ShippingState.ready;
    }

    // TODO: Add other ShippingState enum values and return a default, once available
    return ShippingState.ready;
};

const transformToOrderPaymentProvider = (resource: OrderPaymentProviderResource): OrderPaymentProvider => {
    if (resource === OrderPaymentProviderResource.paynl) {
        return OrderPaymentProvider.paynl;
    }

    return OrderPaymentProvider.paynl;
};

const transformToOrderPaymentMethod = (resource?: OrderPaymentMethodResource): OrderPaymentMethod => {
    if (!resource) {
        return OrderPaymentMethod.unknown;
    }

    const paymentMethods = {
        [OrderPaymentMethodResource.bancontact]: OrderPaymentMethod.bancontact,
        [OrderPaymentMethodResource.bankTransfer]: OrderPaymentMethod.bankTransfer,
        [OrderPaymentMethodResource.creditCard]: OrderPaymentMethod.creditCard,
        [OrderPaymentMethodResource.giftCard]: OrderPaymentMethod.giftCard,
        [OrderPaymentMethodResource.ideal]: OrderPaymentMethod.ideal,
        [OrderPaymentMethodResource.in3]: OrderPaymentMethod.in3,
        [OrderPaymentMethodResource.klarna]: OrderPaymentMethod.klarna,
        [OrderPaymentMethodResource.paypal]: OrderPaymentMethod.paypal,
        [OrderPaymentMethodResource.pin]: OrderPaymentMethod.pin,
        [OrderPaymentMethodResource.riverty]: OrderPaymentMethod.riverty,
        [OrderPaymentMethodResource.sofort]: OrderPaymentMethod.sofort,
        [OrderPaymentMethodResource.voucher]: OrderPaymentMethod.voucher,
        [OrderPaymentMethodResource.unknown]: OrderPaymentMethod.unknown,
    };

    return paymentMethods[resource];
};

export const transformToOrderBase = (resource: OrderBaseResource): OrderBase => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const orderDate = resource.orderedAt
        ? new Date(resource.orderedAt).toISOString()
        : undefined;

    const state = resource.state
        ? transformToOrderState(resource.state)
        : OrderState.cart;

    return {
        id,
        tokenValue: resource.tokenValue || '',
        orderNumber: resource.number || '',
        state,
        priceTotal: resource.itemsTotal,
        priceSubTotal: resource.itemsSubtotal,
        channel: resource.channel,
        orderDate,
    };
};

export const transformToOrderProductVariant = (resource: OrderItemResource): OrderProductVariant => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const variantId = transformToVariantCode(resource.variant);

    return {
        id,
        variantId,
        productName: resource.productName || '',
        quantity: resource.quantity,
        priceUnit: resource.unitPrice,
        priceUnitOriginal: resource.originalUnitPrice || 0,
        priceUnitDiscounted: resource.discountedUnitPrice,
        priceSubTotal: resource.subtotal,
        priceTotal: resource.total,
    };
};

const transformToOrderPayment = (resource: OrderPaymentResource): OrderPayment => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const provider = transformToOrderPaymentProvider(resource.method);

    return { id, provider };
};

const transformToOrderShipmentMethod = (resource: OrderShipmentMethodResource): OrderShipmentMethod => {
    if (resource === OrderShipmentMethodResource.ups) {
        return OrderShipmentMethod.ups;
    }

    return OrderShipmentMethod.ups;
};

const transformToOrderShipment = (resource: OrderShipmentResource): OrderShipment => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const method = transformToOrderShipmentMethod(resource.method);

    return { id, method };
};

export const transformToOrderDetail = (resource: OrderResource): OrderDetailInterface => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const productVariants = resource.items.map(transformToOrderProductVariant);

    const checkoutState = transformToCheckoutState(resource.checkoutState);
    const paymentState = transformToPaymentState(resource.paymentState);
    const shippingState = transformToShippingState(resource.shippingState);
    const state = resource.state
        ? transformToOrderState(resource.state)
        : OrderState.cart;

    const shippingAddress = resource.shippingAddress
        ? transformToCustomerAddress(resource.shippingAddress, AddressType.shipping)
        : undefined;

    const billingAddress = resource.billingAddress
        ? transformToCustomerAddress(resource.billingAddress, AddressType.billing)
        : undefined;

    const payments = resource.payments.map(transformToOrderPayment);
    const shipments = resource.shipments.map(transformToOrderShipment);

    const orderDate = resource.orderedAt
        ? resource.orderedAt
        : undefined;

    const paymentMethod = transformToOrderPaymentMethod(resource.paymentMethod);

    return {
        id,
        tokenValue: resource.tokenValue || '',
        orderNumber: resource.number || '',
        productVariants,
        currencyCode: resource.currencyCode,
        localeCode: resource.localeCode,
        checkoutState,
        paymentState,
        shippingState,
        state,
        paymentMethod,
        priceItemsSubTotal: resource.itemsSubtotal,
        priceItemsTotal: resource.itemsTotal,
        priceTaxTotal: resource.taxTotal,
        priceShippingTotal: resource.shippingTotal,
        priceShippingTaxTotal: resource.shippingTaxTotal,
        priceTaxExcludedTotal: resource.taxExcludedTotal,
        priceTaxIncludedTotal: resource.taxIncludedTotal,
        priceOrderPromotionTotal: resource.orderPromotionTotal,
        priceShippingPromotionTotal: resource.shippingPromotionTotal,
        priceTotal: resource.total,
        shippingAddress,
        billingAddress,
        payments,
        shipments,
        orderDate,
    };
};
