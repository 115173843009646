import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '../../../../components';
import { PageSearchResult } from '../../../../entities/@search/PageSearchResult/PageSearchResult';
import { trimToMaxLength } from '../../../../helpers/string';

import './PageSearchResultItem.scss';

interface PageSearchResultItemProps extends PageSearchResult {
    className?: string;
}

export const PageSearchResultItem: FC<PageSearchResultItemProps> = ({
    type,
    link,
    title,
    shortDescription = '',
    className = '',
}): ReactElement => {
    const description = shortDescription
        ? trimToMaxLength(shortDescription)
        : undefined;

    return (
        <li className={`page-search-result-item ${className}`}>
            <div className="page-search-result-item__text-wrapper">
                {type && (
                    <p className="page-search-result-item__type">
                        {type}
                    </p>
                )}

                <Link to={link.href} className="page-search-result-item__title">
                    {title}
                </Link>

                {description && (
                    <p className="page-search-result-item__description">
                        {description}
                    </p>
                )}
            </div>

            <Icon
                name="arrow-short-right"
                className="page-search-result-item__icon"
            />
        </li>
    );
};
