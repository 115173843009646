import { generatePaginatedDataQuery, strapiFetch } from '../../entities/@api/Strapi';
import { FaqItemInterface, FaqItemsResponse } from '../../entities/@blocks/Faq/Faq';
import { sortFaqItemsByCategory } from '../../entities/@blocks/Faq/FaqSorters';
import { transformToFaqCategories, transformToFaqItem } from '../../entities/@blocks/Faq/FaqTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { generateIdArray } from '../../helpers/array';
import { ReduxFetchAction } from '../defaults';
import {
    setCategories,
    setError,
    setFaqItems,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './faqSlice';

export const fetchFaqCategories: ReduxFetchAction = () => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));
    dispatch(setFaqItems([]));

    try {
        const query = generatePaginatedDataQuery(1);

        const faqsResponse = await strapiFetch<FaqItemsResponse>(`/faqs?${query}`);

        if (!isFetchResultSuccessful(faqsResponse)) {
            dispatch(setError(faqsResponse.error));
            return;
        }

        const { data: faqsData, meta } = faqsResponse.data;
        const { pagination } = meta;

        const extraPages = faqsData
            ? Math.ceil(pagination.total / faqsData.length) - 1
            : 0;

        const pagesToFetch = generateIdArray(extraPages).map(page => page + 2);

        let allFaqItems: FaqItemInterface[] = faqsData
            ? faqsData.map(transformToFaqItem)
            : [];

        const pageFetchPromises = pagesToFetch.map(async page => {
            const pageQuery = generatePaginatedDataQuery(page);
            const pageResponse = await strapiFetch<FaqItemsResponse>(`/faqs?${pageQuery}`);

            if (!isFetchResultSuccessful(pageResponse)) {
                dispatch(setError(pageResponse.error));
                return;
            }

            const { data: pageData } = pageResponse.data;

            const faqs = pageData
                ? pageData.map(transformToFaqItem)
                : [];

            allFaqItems = [...allFaqItems, ...faqs];
        });

        await Promise.allSettled(pageFetchPromises);

        const sortedFaqItems = allFaqItems.sort(sortFaqItemsByCategory);
        const categories = transformToFaqCategories(sortedFaqItems);

        dispatch(setFaqItems(sortedFaqItems));
        dispatch(setCategories(categories));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchFaqCategories]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};
