// Source: https://developers.google.com/analytics/devguides/collection/ga4/reference/events

export interface GTMProductItem {
    item_id: string;
    item_name: string;
    affiliation?: string;
    coupon?: string;
    discount?: number;
    index?: number;
    item_brand?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_list_id?: string;
    item_list_name?: string;
    item_variant?: string;
    location_id?: string;
    price?: number;
    quantity?: number;
}

export interface GTMProduct {
    currency: string;
    value: number;
    items: GTMProductItem[];
}

export interface GTMCompletedOrder extends GTMProduct {
    transaction_id: string;
    coupon?: string;
    shipping?: number; // Shipping cost associated with a transaction
    tax?: number; // Tax cost associated with a transaction
    new_customer?: boolean; // Source: https://support.google.com/google-ads/answer/12077475#zippy=%2Cinstall-with-google-tag-manager
}

export interface GTMProductList {
    currency: string;
    item_list_id?: string; // Category ID for example
    item_list_name?: string; // Category name for example
    items: GTMProductItem[];
}

// This is a default value from Google to determine wether a customer is a "new" or existing customer.
// Source: https://support.google.com/google-ads/answer/12077475#zippy=%2Cinstall-with-google-tag-manager
export const defaultGTMDaysBetweenTodayAndLastPurchase = 540;
