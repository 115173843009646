import { FC, ReactElement } from 'react';

import { AccountTabHeader } from '../../../../../compositions';
import { ConnectedOrderOverview } from '../../../../../connectors';
import { OrderOverviewType } from '../../../../../entities/@account/OrderOverview/OrderOverview';
import { useTrans } from '../../../../../hooks';

import './AccountOrderOverviewTab.scss';

export const AccountOrderOverviewTab: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <div className="account-order-overview-tab">
            <AccountTabHeader
                title={trans('pages.account.tabs.orderOverview.title')}
                description={trans('pages.account.tabs.orderOverview.description')}
            />

            <div className="account-order-overview-tab__order-section">
                <h2 className="account-order-overview-tab__order-overview-title">
                    {trans('pages.account.tabs.orderOverview.yourLatestOrders')}
                </h2>

                <ConnectedOrderOverview
                    showPagination
                    overviewType={OrderOverviewType.online}
                    className="account-order-overview-tab__order-overview"
                />
            </div>
        </div>
    );
};
