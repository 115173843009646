import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { NotFound, StoreDetailRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchStoreDetail } from '../../../redux/@stores/store/storeActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedStoreDetailRendererProps {
    className?: string;
}

export const ConnectedStoreDetailRenderer: FC<ConnectedStoreDetailRendererProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.storeSlice.isLoading);
    const hasFetched = useTypedSelector(state => state.storeSlice.hasFetched);
    const store = useTypedSelector(state => state.storeSlice.store);

    useClientEffect((): void => {
        dispatch(fetchStoreDetail(slug));
    }, [slug]);

    if (hasFetched && !store) {
        return (
            <NotFound />
        );
    }

    return (
        <StoreDetailRenderer
            isLoading={isLoading}
            store={store}
            className={className}
        />
    );
};
