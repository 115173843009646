export enum MeilisearchFilterRangeValue {
    min = 'min',
    max = 'max',
}

export enum MeilisearchFilterOperator {
    and = 'AND',
    or = 'OR',
    not = 'NOT',
}

export type MeilisearchFilterOptions = Record<string, number>;
export type MeilisearchFilterEntry = [string, MeilisearchFilterOptions];
export type MeilisearchFacetDistribution = Record<string, MeilisearchFilterOptions>;

export type MeilisearchRangeFilterOptions = Record<MeilisearchFilterRangeValue, number>;
export type MeilisearchFacetStats = Record<string, MeilisearchRangeFilterOptions>;
