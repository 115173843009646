import { getImageType } from '../../../helpers/image';
import trans from '../../../helpers/trans';
import { Link } from '../../Link/Link';
import { Image } from '../../Media/Media';
import { AppRoute, appRoutes } from '../../Routing/Routing';
import {
    Navigation,
    NavigationGroup,
    NavigationItem,
    NavigationItemImage,
    NavigationItemResource,
} from './Navigation';

const transformNavigationItemImageToImage = (resource: NavigationItemImage): Image => ({
    src: resource.url,
    alt: resource.name,
    formats: {
        original: {
            srcSet: resource.url,
            type: resource.mime || getImageType(resource.url),
            media: '(min-width: 320px)',
        },
    },
});

const transformNavigationItemToParentLink = (resource: NavigationItemResource): Link => {
    const href = resource.title === trans(appRoutes[AppRoute.products].label)
        ? trans(appRoutes[AppRoute.products].path)
        : resource.path;

    return {
        href,
        label: resource.title,
    };
};

const transformNavigationItemChildrenToNavigation = (resource: NavigationItemResource): Navigation => {
    const parent = {
        ...transformNavigationItemToParentLink(resource),
        id: resource.id.toString(),
    };

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const groups = transformToNavigationGroups(resource.items);

    const image = resource.additionalFields.image
        ? transformNavigationItemImageToImage(resource.additionalFields.image)
        : undefined;

    return {
        id: resource.id.toString(),
        title: resource.title,
        parent,
        groups,
        image,
    };
};

const transformToNavigationItem = (resource: NavigationItemResource): NavigationItem => {
    const subNavigation = resource.items.length > 0
        ? transformNavigationItemChildrenToNavigation(resource)
        : undefined;

    return {
        id: resource.id.toString(),
        to: resource.path,
        label: resource.title,
        subNavigation,
    };
};

const transformToNavigationGroups = (resourceItems: NavigationItemResource[]): NavigationGroup[] => {
    const splitIndexes = resourceItems.reduce<number[]>((accumulator, item, index) => {
        if (item.additionalFields.section_line) {
            accumulator.push(index);
        }

        return accumulator;
    }, []);

    splitIndexes.push(resourceItems.length);

    let previousIndex = 0;

    return splitIndexes.map((endIndex, index) => {
        const groupItems = resourceItems.slice(previousIndex, endIndex + 1).map(transformToNavigationItem);

        previousIndex = endIndex + 1;

        return {
            id: index.toString(),
            items: groupItems,
        };
    });
};

export const transformToNavigation = (resource: NavigationItemResource): Navigation => {
    const groups = transformToNavigationGroups(resource.items);

    return {
        id: resource.id.toString(),
        title: resource.title,
        groups,
    };
};

