import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Customer } from '../../../entities/@account/Customer/Customer';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type CustomerState = AsyncReduxState<{
    customer?: Customer;
}>;

const initialState: CustomerState = {
    ...initialAsyncReduxState,
};

const customerSlice = createSlice({
    name: 'customerSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CustomerState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): CustomerState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CustomerState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CustomerState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCustomer(state, action: PayloadAction<Customer | undefined>): CustomerState {
            return {
                ...state,
                customer: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setCustomer,
} = customerSlice.actions;

export default customerSlice;
