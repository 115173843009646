import {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { ErrorLabel } from '../../../components';
import { SearchableOption } from '../../../entities/@forms/Form/Form';
import { clamp } from '../../../helpers/number';
import { useHandleClickOutside, useKeyPress } from '../../../hooks';
import { SearchInput, SearchInputProps } from '../SearchInput/SearchInput';
import { createCustomOption, searchOptionsOnQuery } from './helpers';
import { SearchableValueInputOption } from './subcomponents';

import './SearchableValueInput.scss';

export interface SearchableValueInputProps extends Omit<SearchInputProps, 'onChange'> {
    isSearchable?: boolean;
    showResultsOnClick?: boolean;
    hasMultipleValues?: boolean;
    options: SearchableOption[];
    resultLimit?: number;
    onChange: (option: SearchableOption) => void;
    onOptionClick: (option: SearchableOption) => void;
    inputClassName?: string;
    inputWrapperClassName?: string;
    listClassName?: string;
}

export const SearchableValueInput: FC<SearchableValueInputProps> = ({
    isSearchable,
    showResultsOnClick,
    hasMultipleValues,
    hideIcon,
    options,
    resultLimit = 10,
    error,
    onChange,
    onOptionClick,
    inputClassName = '',
    inputWrapperClassName = '',
    iconWrapperClassName = '',
    listClassName = '',
    className = '',
    ...inputProps
}): ReactElement => {
    const defaultFocusIndex = -1;
    const noInputResultLimit = 100;

    const [query, setQuery] = useState<string>('');
    const [searchResults, setSearchResults] = useState<SearchableOption[]>([]);
    const [focusIndex, setFocusIndex] = useState<number>(defaultFocusIndex);
    const pressedUp = useKeyPress('ArrowUp');
    const pressedDown = useKeyPress('ArrowDown');

    const searchableValueInputRef = useRef<HTMLDivElement>(null);
    const resultListRef = useRef<HTMLUListElement>(null);

    const updateSelectedIndex = useCallback((index: number): void => {
        const newIndex = clamp(index, 0, searchResults.length - 1);
        setFocusIndex(newIndex);
    }, [setFocusIndex, searchResults]);

    const clearResults = (): void => {
        setSearchResults([]);
        setFocusIndex(defaultFocusIndex);
    };

    useHandleClickOutside([searchableValueInputRef], clearResults);

    useEffect((): void => {
        if (searchResults.length > 0) {
            if (pressedUp) updateSelectedIndex(focusIndex - 1);
            if (pressedDown) updateSelectedIndex(focusIndex + 1);
        }
    }, [pressedUp, pressedDown]);

    useEffect((): void => {
        if (resultListRef.current && focusIndex >= 0) {
            const buttons = resultListRef.current.querySelectorAll('button');
            buttons[focusIndex].focus();
        }
    }, [focusIndex, resultListRef]);

    useEffect((): void => {
        setSearchResults(options);
    }, [options]);

    const handleSearchChange = (value: string): void => {
        onChange(createCustomOption(value));

        if (isSearchable) {
            setQuery(value);
            setSearchResults(searchOptionsOnQuery(options, value, resultLimit));
            setFocusIndex(defaultFocusIndex);
        }
    };

    const handleSearchClick = (): void => {
        if (showResultsOnClick) {
            setSearchResults(options.slice(0, noInputResultLimit));
        }
    };

    const handleOptionClick = (option: SearchableOption): void => {
        onChange(option);
        onOptionClick(option);
        clearResults();
    };

    return (
        <div ref={searchableValueInputRef} className={`searchable-value-input ${className}`}>
            <SearchInput
                {...inputProps}
                hasSearchResults={searchResults.length > 0}
                hasMultipleValues={hasMultipleValues}
                hasError={!!error}
                hideIcon={hideIcon || !isSearchable}
                onChange={handleSearchChange}
                onClick={handleSearchClick}
                className={inputClassName}
                inputWrapperClassName={inputWrapperClassName}
                iconWrapperClassName={iconWrapperClassName}
            />

            {searchResults.length > 0 && (
                <ul ref={resultListRef} className={`searchable-value-input__result-list ${listClassName}`}>
                    {searchResults.map(option => (
                        <SearchableValueInputOption
                            key={option.value}
                            option={option}
                            query={query}
                            onSelect={handleOptionClick}
                            className="searchable-value-input__result-option"
                        />
                    ))}
                </ul>
            )}

            {error && (
                <ErrorLabel
                    text={error}
                    className="searchable-value-input__error-label"
                />
            )}
        </div>
    );
};
