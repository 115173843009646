import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { CategoryCard } from '../../../compositions';
import { ProductCategory } from '../../../entities/@products/ProductCategory/ProductCategory';
import { Link } from '../../../entities/Link/Link';
import { useTrans } from '../../../hooks';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import { SliderSection } from '../../SliderSection/SliderSection';

import './SpaceDetailRenderer.scss';

interface SpaceDetailRendererProps {
    isLoading: boolean;
    showSlider: boolean;
    categories: ProductCategory[];
    breadcrumbs: Link[];
    title: string;
    className?: string;
}

export const SpaceDetailRenderer: FC<SpaceDetailRendererProps> = ({
    isLoading,
    showSlider,
    categories,
    breadcrumbs,
    title,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const categorySlider = {
        id: `category-slider-${title}`,
        title: trans('containers.spaceDetailRenderer.allForSpace', { space: title }),
        categories,
    };

    return (
        <div className={`space-detail-renderer ${className}`}>
            <Wrapper>
                <Breadcrumbs
                    isLoading={isLoading}
                    breadcrumbs={breadcrumbs}
                    className="space-detail-renderer__breadcrumbs"
                />
            </Wrapper>

            {!showSlider && (
                <Wrapper className={`space-detail-renderer ${className}`}>
                    <h1 className="space-detail-renderer__heading">{title}</h1>
                </Wrapper>
            )}

            {showSlider && (
                <SliderSection
                    {...categorySlider}
                    isLoading={isLoading}
                    key={categorySlider.id}
                    items={categorySlider.categories.map(category => (
                        <CategoryCard
                            {...category}
                        />
                    ))}
                    className="space-detail-renderer__category-slider"
                />
            )}
        </div>
    );
};
