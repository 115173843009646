export const generateUuid = (): string => crypto.randomUUID();

// eslint-disable-next-line no-self-compare
export const stringIsNumber = (n: string): boolean => (n ? +n === +n : false);

export const normalizeString = (input = ''): string => input
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9 ]/g, '');

export const generateQueryHighlight = (label: string, query?: string): [string, string, string] => {
    if (!query) {
        return [label, '', ''];
    }

    const cleanLabel = label.toLowerCase();
    const cleanQuery = query.toLowerCase();

    const [start = ''] = cleanLabel.split(cleanQuery);
    const startIndex = start.length;
    const endIndex = start.length + query.length;

    return [
        label.slice(0, startIndex),
        label.slice(startIndex, endIndex),
        label.slice(endIndex),
    ];
};

export const trimToMaxLength = (value: string, maxLength = 130): string => {
    if (value.length <= maxLength) {
        return value;
    }

    const words = value.substring(0, maxLength).split(' ');
    words.pop();

    const output = words.join(' ').trim();

    return `${output}…`;
};

// TODO: Better to retrieve slugs from back-end, since there's no guarantee for a match now
export const transformToSlug = (value: string): string => value.replaceAll(' ', '-').toLowerCase();

export const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export const alphabetNumbers = '0-9';

export const alphabetWithNumbers = [
    ...alphabet,
    alphabetNumbers,
];
