import { FC, ReactElement } from 'react';

import { LoginForm } from '../../../containers';
import { LoginFormData } from '../../../entities/@forms/LoginForm/LoginForm';
import { GTMEvent } from '../../../entities/GTM/GTM';
import { gtmEvent } from '../../../entities/GTM/GTMService';
import { authenticateUser } from '../../../redux/@account/authentication/authenticationActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedLoginFormProps {
    heading?: string;
    onLoggedIn: () => void;
    className?: string;
}

export const ConnectedLoginForm: FC<ConnectedLoginFormProps> = ({
    heading,
    onLoggedIn,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.authenticationSlice.isLoading);
    const error = useTypedSelector(state => state.authenticationSlice.error);

    const handleSubmit = async (formData: LoginFormData): Promise<void> => {
        await dispatch(authenticateUser(formData));

        gtmEvent({
            event: GTMEvent.login,
        });

        onLoggedIn();
    };

    return (
        <LoginForm
            isLoading={isLoading}
            error={error}
            onSubmit={handleSubmit}
            heading={heading}
            className={className}
        />
    );
};
