import { FC, ReactElement } from 'react';

import { Product } from '../../entities/@products/Product/Product';
import { useTrans } from '../../hooks';

import './ProductLabels.scss';

interface ProductLabels extends Product {
    className?: string;
}

export const ProductLabels: FC<ProductLabels> = ({
    price,
    stock,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`product-labels ${className}`}>
            {/* @TODO: Check if product is a bestseller (L5W-389) */}
            <p className="product-labels__label product-labels__label--is-outlined product-labels__label--is-bestseller">
                {trans('compositions.productLabels.bestseller')}
            </p>

            {/* @TODO: Check if product is only online available (L5W-389) */}
            <p className="product-labels__label product-labels__label--is-online-only">
                {trans('compositions.productLabels.onlyOnline')}
            </p>

            {!stock && (
                <p className="product-labels__label product-labels__label--is-outlined">
                    {trans('compositions.productLabels.soldOut')}
                </p>
            )}

            {price.discountInPercentage && (
                <p className="product-labels__label product-labels__label--has-discount">
                    {price.discountInPercentage}
                </p>
            )}
        </div>
    );
};
