import { strapiFetch } from '../../../entities/@api/Strapi';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { OpenStreetMapResource } from '../../../entities/OpenStreetMap/OpenStreetMap';
import { getOpenStreetMapApiCall } from '../../../entities/OpenStreetMap/OpenStreetMapService';
import { transformOpenStreetMapFeatureToSearchableOption } from '../../../entities/OpenStreetMap/OpenStreetMapTransformers';
import { StoreResponse } from '../../../entities/Store/Store';
import { generateStoresQuery } from '../../../entities/Store/StoreRequest';
import { transformToStore, transformToStoreLocation } from '../../../entities/Store/StoreTransformers';
import { retrieveUniqueValues } from '../../../helpers/array';
import { ReduxFetchAction } from '../../defaults';
import { TypedDispatch } from '../../store';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setSearchResults,
    setStoreLocations,
    setStores,
} from './storesSlice';

export const fetchStoreLocationSearchResults = (query: string, limit: number = 5) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const openStreetMapResponse = await getOpenStreetMapApiCall<OpenStreetMapResource>(query, limit);

        if (!isFetchResultSuccessful(openStreetMapResponse)) {
            dispatch(setError(openStreetMapResponse.error));
            return;
        }

        const { features } = openStreetMapResponse.data;

        const results = features.length > 0
            ? features.map(transformOpenStreetMapFeatureToSearchableOption)
            : [];

        const uniqueResults = retrieveUniqueValues(results);

        dispatch(setSearchResults(uniqueResults));
    } catch (error) {
        console.error('[fetchStoreLocationSearchResults]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchStores: ReduxFetchAction = () => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));
    dispatch(setStores([]));

    try {
        const queryString = generateStoresQuery();
        const storesResponse = await strapiFetch<StoreResponse>(`/shops?${queryString}`);

        if (!isFetchResultSuccessful(storesResponse)) {
            dispatch(setError(storesResponse.error));
            return;
        }

        const { data } = storesResponse.data;

        const stores = data
            ? data.map(transformToStore)
            : [];

        const storeLocations = data
            ? data.map(transformToStoreLocation)
            : [];

        dispatch(setStores(stores));
        dispatch(setStoreLocations(storeLocations));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchStores]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};
