import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { UspItem } from '../../entities/@blocks/UspSection/UspSection';
import { AddCouponCodeToOrderFormData } from '../../entities/@forms/CouponCodeForm/CouponCodeForm';
import { CreateAccountDuringCheckoutFormData, UpdateCartFormData } from '../../entities/@forms/GuestForm/GuestForm';
import { PaymentStepFormData } from '../../entities/@forms/PaymentForm/PaymentForm';
import { CheckoutTab } from '../../entities/@shop/Checkout/Checkout';
import { ExtendedOrderInterface } from '../../entities/@shop/Order/Order';
import { PaymentMethod } from '../../entities/@shop/PaymentMethod/PaymentMethod';
import { Address } from '../../entities/Address/Address';
import {
    CartSection,
    DetailsStep,
    PaymentStep,
    StartStep,
} from './subcomponents';

import './Checkout.scss';

interface CheckoutProps extends ExtendedOrderInterface {
    isLoading: boolean;
    tab?: CheckoutTab;
    addresses: Address[];
    paymentMethods?: PaymentMethod[];
    uspItems: UspItem[];
    onAddCouponCode: (data: AddCouponCodeToOrderFormData) => void;
    onRemoveCouponCode: () => void;
    onLoggedIn: () => void;
    onGoToCheckout: () => void;
    onContinueAsGuest: (data: UpdateCartFormData) => void;
    onCreateAccount: (data: CreateAccountDuringCheckoutFormData) => void;
    setPaymentData: (data: PaymentStepFormData) => void;
    onPayOrder: () => void;
    onUpdateCartItem: (cartItemId: string, quantity: number) => void;
    onRemoveItemFromCart: (cartItemId: string) => void;
    onClearCart: () => void;
    className?: string;
}

export const Checkout: FC<CheckoutProps> = ({
    isLoading,
    tab,
    addresses,
    paymentMethods,
    uspItems,
    id,
    items,
    subTotalPrice,
    totalPrice,
    discounts,
    onAddCouponCode,
    onRemoveCouponCode,
    onLoggedIn,
    onGoToCheckout,
    onContinueAsGuest,
    onCreateAccount,
    setPaymentData,
    onUpdateCartItem,
    onRemoveItemFromCart,
    onClearCart,
    onPayOrder,
    className = '',
}): ReactElement => {
    const activeCheckout = tab !== CheckoutTab.order;
    const isPaymentStep = tab === CheckoutTab.payment;

    const checkoutClassNames = classNames('checkout', {
        'checkout-layout': activeCheckout,
    }, className);

    const wrapperClassNames = classNames('checkout__wrapper', {
        'checkout__wrapper--has-grid-layout': activeCheckout,
    });

    return (
        <div className={checkoutClassNames}>
            <div className={wrapperClassNames}>
                {tab === CheckoutTab.order && (
                    <StartStep
                        isLoading={isLoading}
                        id={id}
                        paymentMethods={paymentMethods}
                        uspItems={uspItems}
                        items={items}
                        subTotalPrice={subTotalPrice}
                        totalPrice={totalPrice}
                        discounts={discounts}
                        onGoToCheckout={onGoToCheckout}
                        onRemoveItemFromCart={onRemoveItemFromCart}
                        onUpdateCartItem={onUpdateCartItem}
                        onClearCart={onClearCart}
                        onSetCouponCode={onAddCouponCode}
                        onRemoveCouponCode={onRemoveCouponCode}
                    />
                )}

                {tab === CheckoutTab.details && (
                    <DetailsStep
                        onLoggedIn={onLoggedIn}
                        onContinueAsGuest={onContinueAsGuest}
                        onCreateAccount={onCreateAccount}
                    />
                )}

                {tab === CheckoutTab.payment && (
                    <PaymentStep
                        addresses={addresses}
                        paymentMethods={paymentMethods}
                        setPaymentData={setPaymentData}
                    />
                )}

                {activeCheckout && (
                    <CartSection
                        isLoading={isLoading}
                        id={id}
                        paymentMethods={paymentMethods}
                        items={items}
                        subTotalPrice={subTotalPrice}
                        totalPrice={totalPrice}
                        discounts={discounts}
                        showPayButton={isPaymentStep}
                        onSetCouponCode={onAddCouponCode}
                        onRemoveCouponCode={onRemoveCouponCode}
                        onPayOrder={onPayOrder}
                    />
                )}
            </div>
        </div>
    );
};
