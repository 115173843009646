import { CurrencyConfig } from '../entities/Currency/Currency';

export const clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);

export const randomInBetweenValue = (min: number, max: number): number => (
    Math.floor(Math.random() * (max - min + 1) + min)
);

export const convertPriceInCentsToEuros = (price: number): number => price / 100;

export const convertNumberToCurrency = (number: number, config: CurrencyConfig): string => {
    const {
        includeCurrencySymbol,
        isCents = false,
        trimDecimals = true,
        locale = 'nl-NL',
    } = config;

    const amount = isCents
        ? convertPriceInCentsToEuros(number)
        : number;

    let price = amount.toLocaleString(locale, {
        style: includeCurrencySymbol ? 'currency' : 'decimal',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    if (trimDecimals) {
        price = price.replace(',00', ',-');
    }

    return price;
};

export const getClosestNumber = (value: number, options: number[]): number => options.sort((current, comparison): number => {
    const currentOption = Math.abs(current - value);
    const comparisonOption = Math.abs(comparison - value);

    return currentOption - comparisonOption;
})[0];
