import qs from 'qs';

import { strapiBlockPopulates } from '../../@api/Strapi';
import { PageDraftConfig } from '../../Page/Page';

export const generateProductCategoryQuery = (slug: string, draftParams?: PageDraftConfig): string => {
    const filters = {
        slug: {
            $eq: slug,
        },
    };

    const populate = {
        image: { populate: '*' },
        callToAction: { populate: '*' },
        elements: strapiBlockPopulates,
        seo: { populate: '*' },
    };

    return qs.stringify({
        ...draftParams,
        filters,
        populate,
    }, {
        encodeValuesOnly: true,
    });
};
