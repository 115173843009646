import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { ProductCard } from '../../containers';
import { Product } from '../../entities/@products/Product/Product';
import { GTMEvent } from '../../entities/GTM/GTM';
import { generateGTMProduct } from '../../entities/GTM/GTMRequests';
import { gtmEvent } from '../../entities/GTM/GTMService';
import { addProductVariantToCart } from '../../redux/@shop/cart/cartActions';
import { addProductVariantToWishlist, removeProductVariantFromWishlist } from '../../redux/@shop/wishlist/wishlistActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedProductCardProps {
    isGridView?: boolean;
    product: Product;
    className?: string;
    pictureWrapperClassName?: string;
    pictureClassName?: string;
}

export const ConnectedProductCard: FC<ConnectedProductCardProps> = ({
    isGridView,
    product,
    className = '',
    pictureWrapperClassName = '',
    pictureClassName = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const cartIsLoading = useTypedSelector(state => state.cartSlice.isLoading);

    const wishlistIsLoading = useTypedSelector(state => state.wishlistSlice.isLoading);
    const wishlist = useTypedSelector(state => state.wishlistSlice.wishlist);

    const [isInWishlist, setIsInWishlist] = useState<boolean>(false);

    const gtmProduct = generateGTMProduct(product);

    useEffect((): void => {
        const isProductVariantInWishlist = wishlist.productVariantIds.includes(product.id);

        setIsInWishlist(isProductVariantInWishlist);
    }, [wishlist]);

    const handleAddToCart = async (): Promise<void> => {
        await dispatch(addProductVariantToCart(product.variantCode));

        gtmEvent({
            event: GTMEvent.addToCart,
            ...gtmProduct,
        });
    };

    const handleWishlistButtonClick = async (): Promise<void> => {
        if (isInWishlist) {
            await dispatch(removeProductVariantFromWishlist(product.variantId));
            return;
        }

        await dispatch(addProductVariantToWishlist(product.variantId));

        gtmEvent({
            event: GTMEvent.addToWishlist,
            ...gtmProduct,
        });
    };

    return (
        <ProductCard
            cartIsLoading={cartIsLoading}
            wishlistIsLoading={wishlistIsLoading}
            isGridView={isGridView}
            isInWishlist={isInWishlist}
            product={product}
            onAddToCart={handleAddToCart}
            onClickWishlistButton={handleWishlistButtonClick}
            className={className}
            pictureWrapperClassName={pictureWrapperClassName}
            pictureClassName={pictureClassName}
        />
    );
};
