import { BaseErrorResponse } from '../@api/Sylius';
import {
    FetchResultClientError,
    FetchResultError,
    FetchResultGetPricesError,
    FetchResultStartPaymentError,
} from '../FetchResult/FetchResult';

export const isBaseErrorResponse = (error: unknown): error is BaseErrorResponse => (
    !!(error as BaseErrorResponse)['hydra:title']
);

export const isFetchResultErrorResponse = (error: unknown): error is FetchResultError => (
    !!(error as FetchResultError).detail
);

export const isFetchResultClientErrorResponse = (error: unknown): error is FetchResultClientError => (
    !!(error as FetchResultClientError).message
);

export const isFetchResultStartPaymentErrorResponse = (error: unknown): error is FetchResultStartPaymentError => (
    !!(error as FetchResultStartPaymentError).error.message
);

export const isFetchResultGetPricesErrorResponse = (error: unknown): error is FetchResultGetPricesError => (
    !!(error as FetchResultGetPricesError).error.message
);
