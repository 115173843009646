import { RefObject } from 'react';

import { isSSR } from './index';

export const scrollToTop = (behavior: ScrollBehavior = 'smooth'): void => {
    if (typeof window !== 'undefined') {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior,
        });
    }
};

export const scrollIntoView = <T extends HTMLElement>(ref?: RefObject<T | null>, scrollOptions: ScrollIntoViewOptions = {}): void => {
    if (!ref?.current) return;

    ref.current.scrollIntoView({
        behavior: 'smooth',
        ...scrollOptions,
    });
};

export const scrollIntoViewIncludingNavigation = <T extends HTMLElement>(ref?: RefObject<T | null>): void => {
    if (isSSR) {
        return;
    }

    const menu = document.querySelector('.menu');

    if (!menu || !ref?.current) {
        return;
    }

    const { height: menuHeight } = menu.getBoundingClientRect();

    const top = ref.current.getBoundingClientRect().top + window.scrollY - menuHeight;

    window.scrollTo({
        top,
        behavior: 'smooth',
    });
};

export const scrollQuerySelectorIntoViewIncludingNavigation = (selector: string): void => {
    if (isSSR) {
        return;
    }

    const menu = document.querySelector('.menu');
    const element = document.querySelector(selector);

    if (!menu || !element) {
        return;
    }

    const { height: menuHeight } = menu.getBoundingClientRect();

    const top = element.getBoundingClientRect().top + window.scrollY - menuHeight;

    window.scrollTo({
        top,
        behavior: 'smooth',
    });
};

export const resetElementScroll = <T extends HTMLElement>(element?: T): void => {
    if (element) {
        element.scroll(0, 0);
    }
};

export const elementHasScroll = <T extends HTMLElement>(ref?: RefObject<T | null>): boolean => {
    if (!ref?.current) return false;

    return ref.current.scrollWidth > ref.current.clientWidth;
};
