import { transformToProductCategory } from '../@products/ProductCategory/ProductCategoryTransformers';
import { transformToImage } from '../Media/MediaTransformers';
import { transformToSeo } from '../Seo/SeoTransformers';
import { Space, SpaceItemResource } from './Space';

export const transformToSpace = (resource: SpaceItemResource): Space => {
    const image = resource.image
        ? transformToImage(resource.image)
        : undefined;

    const categories = resource.productCategories
        ? resource.productCategories.map(transformToProductCategory)
        : undefined;

    const meilisearchProductListName = resource.productSelections
        ? resource.productSelections.name
        : '';

    const seo = resource.seo
        ? transformToSeo(resource.seo)
        : undefined;

    return {
        id: resource.documentId,
        title: resource.name,
        meilisearchProductListName,
        slug: resource.slug,
        image,
        categories,
        seo,
    };
};
