import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { BrandItemResource } from '../../Brand/Brand';
import { transformButtonToLink } from '../../Link/LinkTransformers';
import { defaultImage, transformImagePathToImage } from '../../Media/MediaTransformers';
import { BrandCarouselInterface, BrandCarouselResource, BrandLogo } from './BrandCarousel';

export const transformToBrandCarouselItem = (resource: BrandItemResource): BrandLogo => {
    const logo: BrandLogo = {
        id: resource.id.toString(),
        href: resource.slug || '',
        label: resource.name || '',
        image: defaultImage(),
    };

    if (resource.logo) {
        const altText = resource.logo.alternativeText || resource.name || '';

        logo.image = transformImagePathToImage(resource.logo.url, altText);
    }

    return logo;
};

export const transformToBrandCarousel = (resource: BrandCarouselResource): BrandCarouselInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const brandLogos = resource.brands
        ? resource.brands.map(transformToBrandCarouselItem)
        : [];

    const link = resource.button
        ? transformButtonToLink(resource.button)
        : undefined;

    return {
        ...defaultBlock,
        type: BlockType.brandCarousel,
        title: resource.title || undefined,
        logos: brandLogos,
        link,
    };
};
