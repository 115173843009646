import { useState } from 'react';

import { useDebounce } from 'react-use';

export const useDebounceAfterInitialRender = (callback: () => void, debounceDelay: number, deps: unknown[] = []): void => {
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);

    useDebounce((): void => {
        if (isInitialRender) {
            setIsInitialRender(false);
            return;
        }

        callback();
    }, debounceDelay, deps);
};
