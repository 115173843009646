import { useEffect, useState } from 'react';

export const useEffectAfterInitialRender = (callback: () => void, deps: unknown[] = []): void => {
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);

    useEffect((): void => {
        if (isInitialRender) {
            setIsInitialRender(false);
            return;
        }

        callback();
    }, deps);
};
