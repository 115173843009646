import TagManager from 'react-gtm-module';

import { getCustomerLoods5Id } from '../@account/Customer/CustomerService';

export const initializeTagManager = (dataLayer: Record<string, string>): void => {
    const gtmId = process.env.REACT_APP_GTM_ID;

    if (gtmId) {
        TagManager.initialize({
            gtmId,
            dataLayer,
        });
    }
};

export const gtmEvent = (dataLayer: Record<string, unknown>): void => {
    const userId = getCustomerLoods5Id();

    TagManager.dataLayer({
        dataLayer: {
            ...dataLayer,
            user_id: userId,
        },
    });
};
