import trans from '../../../helpers/trans';
import { removeTrailingSlash, replaceUrlParamKeysWithValues } from '../../../helpers/url';
import { Link } from '../../Link/Link';
import { AppRoute, appRoutes } from '../../Routing/Routing';
import { ProductCategory, ProductCategoryParams } from './ProductCategory';

interface ProductCategories {
    mainCategory?: ProductCategory;
    subCategory?: ProductCategory;
    subSubCategory?: ProductCategory;
}

export const generateProductCategoryBreadcrumbs = (productCategories: ProductCategories): Link[] => {
    const productCategoryPath = trans(appRoutes[AppRoute.productCategory].path);
    const allProducts = {
        label: trans(appRoutes[AppRoute.products].label),
        href: trans(appRoutes[AppRoute.products].path),
    };

    const breadcrumbs: Link[] = [allProducts];

    const { mainCategory, subCategory, subSubCategory } = productCategories;

    if (mainCategory) {
        breadcrumbs.push({
            label: mainCategory.name,
            href: replaceUrlParamKeysWithValues(productCategoryPath, {
                slug: mainCategory.slug,
                subCategory: '',
                subSubCategory: '',
            }),
        });
    }

    if (mainCategory && subCategory) {
        breadcrumbs.push({
            label: subCategory.name,
            href: replaceUrlParamKeysWithValues(productCategoryPath, {
                slug: mainCategory.slug,
                subCategory: subCategory.slug,
                subSubCategory: '',
            }),
        });
    }

    if (mainCategory && subCategory && subSubCategory) {
        breadcrumbs.push({
            label: subSubCategory.name,
            href: replaceUrlParamKeysWithValues(productCategoryPath, {
                slug: mainCategory.slug,
                subCategory: subCategory.slug,
                subSubCategory: subSubCategory.slug,
            }),
        });
    }

    return breadcrumbs;
};

export const getProductCategoryReplacementUrl = (
    productCategories: ProductCategories,
    categoryParams: ProductCategoryParams,
): string => {
    const { subCategory, subSubCategory } = productCategories;

    const {
        slug: mainCategorySlug = '',
        subCategory: subCategorySlug = '',
        subSubCategory: subSubCategorySlug = '',
    } = categoryParams;

    const subCategoryDoesNotExist = subCategorySlug && !subCategory;
    const subSubCategoryDoesNotExist = subSubCategorySlug && !subSubCategory;

    if (subCategoryDoesNotExist || subSubCategoryDoesNotExist) {
        const productCategoryPath = trans(appRoutes[AppRoute.productCategory].path);

        const replacementUrl = replaceUrlParamKeysWithValues(productCategoryPath, {
            slug: mainCategorySlug,
            subCategory: subCategory ? subCategorySlug : undefined,
            subSubCategory: undefined,
        });

        return removeTrailingSlash(replacementUrl);
    }

    return '';
};
