import {
    CreateOrderItemResponse,
    CreateOrderResponse,
    DeleteOrderItemResponse,
    DeleteOrderResponse,
    DuplicateOrderResponse,
    GetOrderPricesResponse,
    GetOrderResponse,
    GetProductResponse,
    GetProductVariantResponse,
    PricesResource,
    ProductAttributeKey,
    SetOrderItemResponse,
    syliusCustomerOrGuestFetch,
    syliusFetch,
} from '../../../entities/@api/Sylius';
import { transformToProductCode } from '../../../entities/@products/Product/ProductTransformers';
import { transformToProductAttribute } from '../../../entities/@products/ProductAttribute/ProductAttributeTransformers';
import { locale } from '../../../entities/@products/ProductVariant/ProductVariantTransformers';
import { CartInterface, CartItem, cartToken } from '../../../entities/@shop/Cart/Cart';
import { transformToCart, transformToCartItem } from '../../../entities/@shop/Cart/CartTransformers';
import { fetchTaxonWithParents } from '../../../entities/@shop/Taxon/TaxonService';
import { transformBrandNameToLink } from '../../../entities/Brand/BrandTransformers';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { Image } from '../../../entities/Media/Media';
import { defaultImage, transformMeilisearchImageToImage } from '../../../entities/Media/MediaTransformers';
import { ReduxFetchAction } from '../../defaults';
import { SliceGetter, TypedDispatch } from '../../store';
import { setOrder } from '../order/orderSlice';
import {
    defaultCart,
    setCart,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessfullyAdded,
    setIsSuccessfullyRemoved,
} from './cartSlice';

const getCartItem = async (cart: CartInterface, dispatch: TypedDispatch): Promise<CartItem[]> => Promise.all(cart.items.map(async item => {
    const productVariantResponse = await syliusFetch<GetProductVariantResponse>(`/shop/product-variants/${item.variantId}`);

    if (!isFetchResultSuccessful(productVariantResponse)) {
        dispatch(setError(productVariantResponse.error));
        return item;
    }

    const productVariantResource = productVariantResponse.data;
    const { product, attributes } = productVariantResource;

    const translatedProductAttributes = attributes
        ? attributes.filter(attribute => attribute.localeCode === locale)
        : [];

    const productVariantAttributes = translatedProductAttributes
        ? translatedProductAttributes.map(transformToProductAttribute)
        : [];

    const productVariantAttributesWithData = productVariantAttributes.filter(productVariantAttribute => productVariantAttribute.value);

    const brandAttribute = productVariantAttributesWithData.find(productAttribute => productAttribute.key === ProductAttributeKey.brand);
    const brand = brandAttribute
        ? transformBrandNameToLink(brandAttribute.value)
        : undefined;

    const productCode = transformToProductCode(product);

    const productResponse = await syliusFetch<GetProductResponse>(`/shop/products/${productCode}`);

    if (!isFetchResultSuccessful(productResponse)) {
        dispatch(setError(productResponse.error));
        return item;
    }

    const productDefaultResource = productResponse.data;

    const taxon = await fetchTaxonWithParents(productDefaultResource.mainTaxon || '');
    const parentCategories = taxon
        ? taxon.parents.map(parent => parent.name)
        : [];

    const categories = taxon
        ? [...parentCategories, taxon.name]
        : parentCategories;

    const productResource = productResponse.data;
    let image: Image = defaultImage();

    if (productResource.images.length) {
        const replaceImage = {
            url: productResource.images[0].path,
            alt: productVariantResource.name || productResource.name || '',
        };

        image = transformMeilisearchImageToImage(replaceImage);
    }

    return {
        ...item,
        productCode,
        categories,
        image,
        brand,
    };
}));

export const duplicateCart: ReduxFetchAction<string> = oldCartToken => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const duplicateCartResponse = await syliusCustomerOrGuestFetch<DuplicateOrderResponse>(
            customer?.id,
            `/shop/orders/${oldCartToken}/duplicate`,
            {
                method: 'POST',
            },
        );

        if (!isFetchResultSuccessful(duplicateCartResponse)) {
            dispatch(setError(duplicateCartResponse.error));
            return;
        }

        const { token } = duplicateCartResponse.data.order;

        const getCartResponse = await syliusCustomerOrGuestFetch<GetOrderResponse>(customer?.id, `/shop/orders/${token}`);

        if (!isFetchResultSuccessful(getCartResponse)) {
            dispatch(setError(getCartResponse.error));
            return;
        }

        const cartTokenValue = getCartResponse.data.tokenValue;

        if (!cartTokenValue) {
            console.error('Token value of cart is missing.');
            return;
        }

        localStorage.setItem(cartToken, JSON.stringify(cartTokenValue));

        const cartResource = getCartResponse.data;

        const hasItems = cartResource.items.length > 0;

        let pricing: PricesResource | undefined;

        if (hasItems) {
            const pricesResponse = await syliusFetch<GetOrderPricesResponse>(`/shop/orders/${token}/prices`);

            if (!isFetchResultSuccessful(pricesResponse)) {
                dispatch(setError(pricesResponse.error));
                return;
            }

            pricing = pricesResponse.data;
        }

        const cart = transformToCart(cartResource, pricing);

        const cartItems = await getCartItem(cart, dispatch);

        const newCart: CartInterface = {
            ...cart,
            items: cartItems,
        };

        dispatch(setCart(newCart));
        dispatch(setOrder(newCart));
    } catch (error) {
        console.error('[duplicateCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

const createCart = async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const body = { localeCode: 'nl' };

        const createCartResponse = await syliusCustomerOrGuestFetch<CreateOrderResponse>(customer?.id, '/shop/orders', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(createCartResponse)) {
            dispatch(setError(createCartResponse.error));
            return;
        }

        const { tokenValue } = createCartResponse.data;

        if (!tokenValue) {
            console.error('Token value is missing after creating a cart.');
            return;
        }

        localStorage.setItem(cartToken, JSON.stringify(tokenValue));

        const cart = transformToCart(createCartResponse.data);

        dispatch(setCart(cart));
        dispatch(setOrder(cart));
    } catch (error) {
        console.error('[createCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

const getCartByToken = async (
    dispatch: TypedDispatch,
    getState: SliceGetter,
    storedCartToken: string,
    isCheckoutThankYouPage: boolean,
): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setHasFetched(false));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const cartTokenValue = JSON.parse(storedCartToken);

        const cartResponse = await syliusCustomerOrGuestFetch<GetOrderResponse>(customer?.id, `/shop/orders/${cartTokenValue}`);

        if (!isFetchResultSuccessful(cartResponse)) {
            dispatch(setError(cartResponse.error));
            return;
        }

        const cartResource = cartResponse.data;
        const { tokenValue } = cartResource;

        const hasItems = cartResource.items.length > 0;

        let pricing: PricesResource | undefined;

        if (hasItems && !isCheckoutThankYouPage) {
            const pricesResponse = await syliusFetch<GetOrderPricesResponse>(`/shop/orders/${tokenValue}/prices`);

            if (!isFetchResultSuccessful(pricesResponse)) {
                await dispatch(duplicateCart(cartTokenValue));

                dispatch(setError(pricesResponse.error));
                return;
            }

            pricing = pricesResponse.data;
        }

        const cart = transformToCart(cartResource, pricing);

        const cartItems = await getCartItem(cart, dispatch);

        const newCart: CartInterface = {
            ...cart,
            items: cartItems,
        };

        // For GTM purposes
        if (isCheckoutThankYouPage) {
            newCart.totalPrice = cartResource.total;
            newCart.items = cartResource.items.map(item => transformToCartItem(item));
        }

        dispatch(setCart(newCart));
        dispatch(setOrder(newCart));
    } catch (error) {
        console.error('[getCartByToken]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const getCart: ReduxFetchAction = (isCheckoutThankYouPage: boolean = false) => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const storedCartToken = localStorage.getItem(cartToken);

        if (!storedCartToken) {
            await createCart(dispatch, getState);
        } else {
            await getCartByToken(dispatch, getState, storedCartToken, isCheckoutThankYouPage);
        }
    } catch (error) {
        console.error('[getCart]', error);
    }
};

export const addProductVariantToCart: ReduxFetchAction<string> = productVariantCode => async (dispatch, getState) => {
    dispatch(setIsSuccessfullyAdded(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const body = {
            productVariant: productVariantCode,
            quantity: 1,
        };

        if (!cart.id) {
            return;
        }

        const addProductVariantToCartResponse = await syliusCustomerOrGuestFetch<CreateOrderItemResponse>(
            customer?.id,
            `/shop/orders/${cart.id}/items`,
            {
                method: 'POST',
                body: JSON.stringify(body),
            },
        );

        if (!isFetchResultSuccessful(addProductVariantToCartResponse)) {
            dispatch(setError(addProductVariantToCartResponse.error));
            return;
        }

        const pricesResponse = await syliusFetch<GetOrderPricesResponse>(`/shop/orders/${addProductVariantToCartResponse.data.tokenValue}/prices`);

        if (!isFetchResultSuccessful(pricesResponse)) {
            dispatch(setError(pricesResponse.error));
            return;
        }

        const updatedCart = transformToCart(addProductVariantToCartResponse.data, pricesResponse.data);

        const cartItems = await getCartItem(updatedCart, dispatch);

        const newCart: CartInterface = {
            ...updatedCart,
            items: cartItems,
        };

        dispatch(setCart(newCart));
        dispatch(setOrder(newCart));

        dispatch(setIsSuccessfullyAdded(true));
    } catch (error) {
        console.error('[addProductVariantToCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateProductVariantInCart = (
    cartItemId: string,
    newQuantity: number,
) => async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const body = {
            quantity: newQuantity,
        };

        const updateProductVariantInCartResponse = await syliusCustomerOrGuestFetch<SetOrderItemResponse>(
            customer?.id,
            `/shop/orders/${cart.id}/items/${cartItemId}`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/merge-patch+json' },
                body: JSON.stringify(body),
            },
        );

        if (!isFetchResultSuccessful(updateProductVariantInCartResponse)) {
            dispatch(setError(updateProductVariantInCartResponse.error));
            return;
        }

        const pricesResponse = await syliusFetch<GetOrderPricesResponse>(`/shop/orders/${updateProductVariantInCartResponse.data.tokenValue}/prices`);

        if (!isFetchResultSuccessful(pricesResponse)) {
            dispatch(setError(pricesResponse.error));
            return;
        }

        const transformedCart = transformToCart(updateProductVariantInCartResponse.data, pricesResponse.data);

        const cartItems = await getCartItem(transformedCart, dispatch);

        const newCart: CartInterface = {
            ...transformedCart,
            items: cartItems,
        };

        dispatch(setCart(newCart));
        dispatch(setOrder(newCart));
    } catch (error) {
        console.error('[updateProductVariantInCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const removeProductVariantFromCart: ReduxFetchAction<string> = cartItemId => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const removeVariantResponse = await syliusCustomerOrGuestFetch<DeleteOrderItemResponse>(
            customer?.id,
            `/shop/orders/${cart.id}/items/${cartItemId}`,
            {
                method: 'DELETE',
            },
        );

        if (!isFetchResultSuccessful(removeVariantResponse)) {
            dispatch(setError(removeVariantResponse.error));
            return;
        }

        const cartResponse = await syliusCustomerOrGuestFetch<GetOrderResponse>(customer?.id, `/shop/orders/${cart.id}`);

        if (!isFetchResultSuccessful(cartResponse)) {
            dispatch(setError(cartResponse.error));
            return;
        }

        const cartResource = cartResponse.data;
        const { tokenValue } = cartResource;

        const pricesResponse = await syliusFetch<GetOrderPricesResponse>(`/shop/orders/${tokenValue}/prices`);

        if (!isFetchResultSuccessful(pricesResponse)) {
            dispatch(setError(pricesResponse.error));
            return;
        }

        const updatedCart = transformToCart(cartResource, pricesResponse.data);

        const cartItems = await getCartItem(updatedCart, dispatch);

        const newCart: CartInterface = {
            ...updatedCart,
            items: cartItems,
        };

        dispatch(setCart(newCart));
        dispatch(setOrder(newCart));
        dispatch(setIsSuccessfullyRemoved(true));
    } catch (error) {
        console.error('[removeProductVariantFromCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const clearCart: ReduxFetchAction = () => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const clearCartResponse = await syliusCustomerOrGuestFetch<DeleteOrderResponse>(customer?.id, `/shop/orders/${cart.id}`, {
            method: 'DELETE',
        });

        if (!isFetchResultSuccessful(clearCartResponse)) {
            dispatch(setError(clearCartResponse.error));
        }

        const storedCartToken = localStorage.getItem(cartToken);

        if (storedCartToken) {
            localStorage.removeItem(cartToken);
        }

        dispatch(getCart());
    } catch (error) {
        console.error('[clearCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const removeCartLocally: ReduxFetchAction = () => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice } = getState();
        const { cart } = cartSlice;

        if (cart.id.length) {
            dispatch(setCart(defaultCart));
            dispatch(setOrder(defaultCart));
        }

        const storedCartToken = localStorage.getItem(cartToken);

        if (storedCartToken) {
            localStorage.removeItem(cartToken);
        }

        dispatch(getCart(true));
    } catch (error) {
        console.error('[removeCartLocally]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
