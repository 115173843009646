import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { FeaturedProductsInterface, FeaturedProductsType } from '../../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { FeaturedProductsAlternatingSizes, FeaturedProductsVerticalTitle } from './subcomponents';

import './FeaturedProducts.scss';

export interface FeaturedProductsProps extends FeaturedProductsInterface {
    className?: string;
}

export const FeaturedProducts: FC<FeaturedProductsProps> = ({
    layoutType,
    products,
    className = '',
    ...featuredProductsProps
}): ReactElement => (
    <section className={`featured-products ${className}`}>
        <Wrapper className="featured-products__wrapper">
            {products.length > 0 && (
                <div className="featured-products__layout-wrapper">
                    {layoutType === FeaturedProductsType.alternatingSizes && (
                        <FeaturedProductsAlternatingSizes
                            {...featuredProductsProps}
                            products={products}
                        />
                    )}

                    {layoutType === FeaturedProductsType.verticalTitle && (
                        <FeaturedProductsVerticalTitle
                            {...featuredProductsProps}
                            products={products}
                        />
                    )}
                </div>
            )}
        </Wrapper>
    </section>
);
