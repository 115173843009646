import { FC, ReactElement, useMemo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { useLocale } from './context';
import { AppRoute } from './entities/Routing/Routing';
import { isProduction } from './helpers';
import { useTrans } from './hooks';
import {
    AccountPage,
    BlogOverviewPage,
    BlogPostPage,
    BrandDetailPage,
    BrandOverviewPage,
    CheckoutPage,
    CheckoutThankYouPage,
    CompanyJoinUsPage,
    CompanyMediaPage,
    CompanyStoryPage,
    CompanySustainabilityPage,
    ContentBlocksPage,
    CustomerServiceAccountPage,
    CustomerServiceDeliveryPage,
    CustomerServiceFaqPage,
    CustomerServiceOrderingPage,
    CustomerServicePage,
    CustomerServiceReturnsPage,
    CustomerServiceTermsPage,
    DepartmentDetailPage,
    DepartmentOverviewPage,
    GiftcardBalancePage,
    GuestWishlistPage,
    HomePage,
    IndexPage,
    LegalCookiesPage,
    LegalDisclaimerPage,
    LegalPrivacyPage,
    LoginPage,
    NewsletterPage,
    NotFoundPage,
    OtherPage,
    OwnCollectionPage,
    ProductsPage,
    RegistrationPage,
    ResetPasswordPage,
    RestaurantPage,
    SalePage,
    SearchPage,
    SpaceDetailPage,
    SpaceOverviewPage,
    StoreDetailPage,
    StoreOverviewPage,
    StyleguidePage,
} from './pages';

export interface RouteData<T = string> {
    key: T;
    label: string;
    path: string;
}

export type RouteParams = Record<string, string | undefined>;

const routes = [
    // Account
    {
        key: AppRoute.account,
        element: <AccountPage />,
        children: (
            <Route path=":tab" element={<AccountPage />}>
                <Route path=":id" element={<AccountPage />} />
            </Route>
        ),
    },
    { key: AppRoute.login, element: <LoginPage /> },
    { key: AppRoute.registration, element: <RegistrationPage /> },
    { key: AppRoute.resetPassword, element: <ResetPasswordPage /> },
    // Blog
    { key: AppRoute.blogOverview, element: <BlogOverviewPage /> },
    { key: AppRoute.blogOverviewCategory, element: <BlogOverviewPage /> },
    { key: AppRoute.blogPost, element: <BlogPostPage /> },
    // Brands
    { key: AppRoute.brandDetail, element: <BrandDetailPage /> },
    { key: AppRoute.brandOverview, element: <BrandOverviewPage /> },
    // Content
    { key: AppRoute.companyJoinUs, element: <CompanyJoinUsPage /> },
    { key: AppRoute.companyMedia, element: <CompanyMediaPage /> },
    { key: AppRoute.companyStory, element: <CompanyStoryPage /> },
    { key: AppRoute.companySustainability, element: <CompanySustainabilityPage /> },
    { key: AppRoute.customerService, element: <CustomerServicePage /> },
    { key: AppRoute.customerServiceAccount, element: <CustomerServiceAccountPage /> },
    { key: AppRoute.customerServiceDelivery, element: <CustomerServiceDeliveryPage /> },
    { key: AppRoute.customerServiceFaq, element: <CustomerServiceFaqPage /> },
    { key: AppRoute.customerServiceOrdering, element: <CustomerServiceOrderingPage /> },
    { key: AppRoute.customerServiceReturns, element: <CustomerServiceReturnsPage /> },
    { key: AppRoute.customerServiceTerms, element: <CustomerServiceTermsPage /> },
    { key: AppRoute.legalCookies, element: <LegalCookiesPage /> },
    { key: AppRoute.legalDisclaimer, element: <LegalDisclaimerPage /> },
    { key: AppRoute.legalPrivacy, element: <LegalPrivacyPage /> },
    { key: AppRoute.newsletter, element: <NewsletterPage /> },
    { key: AppRoute.restaurant, element: <RestaurantPage /> },
    // Departments
    { key: AppRoute.departmentDetail, element: <DepartmentDetailPage /> },
    { key: AppRoute.departmentOverview, element: <DepartmentOverviewPage /> },
    // Products
    { key: AppRoute.ownCollection, element: <OwnCollectionPage /> },
    { key: AppRoute.ownCollectionCategory, element: <OwnCollectionPage /> },
    { key: AppRoute.productCategory, element: <OtherPage /> },
    { key: AppRoute.productDetail, element: <OtherPage /> },
    { key: AppRoute.products, element: <ProductsPage /> },
    { key: AppRoute.sale, element: <SalePage /> },
    // Shop
    {
        key: AppRoute.checkout,
        element: <CheckoutPage />,
        children: (
            <Route path=":tab" element={<CheckoutPage />}>
                <Route path=":id" element={<CheckoutPage />} />
            </Route>
        ),
    },
    { key: AppRoute.checkoutThankYou, element: <CheckoutThankYouPage /> },
    { key: AppRoute.wishlist, element: <GuestWishlistPage /> },
    // Spaces
    { key: AppRoute.spaceDetail, element: <SpaceDetailPage /> },
    { key: AppRoute.spacesOverview, element: <SpaceOverviewPage /> },
    // Stores
    { key: AppRoute.storeDetail, element: <StoreDetailPage /> },
    { key: AppRoute.storeOverview, element: <StoreOverviewPage /> },
    // Other
    { key: AppRoute.custom, element: <OtherPage /> },
    { key: AppRoute.giftcardBalance, element: <GiftcardBalancePage /> },
    { key: AppRoute.home, element: <HomePage /> },
    { key: AppRoute.notFound, element: <NotFoundPage /> },
    { key: AppRoute.search, element: <SearchPage /> },
    // Development
    { key: AppRoute.contentBlocks, element: <ContentBlocksPage /> },
    { key: AppRoute.index, element: <IndexPage /> },
    { key: AppRoute.styleguide, element: <StyleguidePage /> },
];

export const LocalizedRoutes: FC = (): ReactElement => {
    const { language } = useLocale();
    const trans = useTrans();

    // Get route paths and labels from translations, based on active language
    const localizedRoutes = useMemo(() => routes.map(route => {
        const developmentRoutes = [
            AppRoute.contentBlocks,
            AppRoute.index,
            AppRoute.styleguide,
        ];

        if (isProduction && developmentRoutes.includes(route.key)) {
            return route;
        }

        const routeData = {
            path: trans(`${route.key}.path`),
            label: trans(`${route.key}.label`),
        };

        return {
            ...route,
            ...routeData,
        };
    }), [language]);

    return (
        <Routes>
            {localizedRoutes.map(route => <Route {...route} key={route.key} />)}
        </Routes>
    );
};
