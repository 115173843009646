import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import './CheckoutNavigationItem.scss';

interface CheckoutNavigationItemProps {
    to: string;
    label: string;
    onClick?: () => void;
    className?: string;
    navLinkClassName?: string;
    activeIndex: number;
    stepIndex: number;
}

export const CheckoutNavigationItem: FC<CheckoutNavigationItemProps> = ({
    to = '',
    label,
    onClick,
    className = '',
    navLinkClassName = '',
    activeIndex,
    stepIndex,
}): ReactElement => {
    const currentStep = stepIndex === activeIndex;
    const stepIsDone = stepIndex < activeIndex;

    const itemClassNames = ({ isActive }: { isActive: boolean }): string => classNames('checkout-navigation-item__item', {
        'checkout-navigation-item__item--is-active': isActive || currentStep,
        'checkout-navigation-item__item--is-done': stepIsDone,
    }, navLinkClassName);

    return (
        <li className={`checkout-navigation-item ${className}`}>
            {stepIsDone ? (
                <NavLink
                    to={to}
                    onClick={onClick}
                    className={itemClassNames}
                >
                    <span className="checkout-navigation-item__label">
                        {label}
                    </span>
                </NavLink>
            ) : (
                <div className={itemClassNames({ isActive: false })}>
                    <span className="checkout-navigation-item__label">
                        {label}
                    </span>
                </div>
            )}
        </li>
    );
};
