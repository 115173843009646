import {
    FC,
    lazy,
    ReactElement,
    Suspense,
} from 'react';

import { MapLocation } from '../../../../../entities/Map/Map';
import { Store } from '../../../../../entities/Store/Store';
import { isSSR } from '../../../../../helpers';

import './StoreDetailMap.scss';

const Map = lazy(() => import('../../../../../compositions/Map/Map')
    .then(module => ({ default: module.Map })));

interface StoreDetailMapProps {
    store: Store;
    className?: string;
}

export const StoreDetailMap: FC<StoreDetailMapProps> = ({
    store,
    className = '',
}): ReactElement => {
    const handleMarkerClick = (location: MapLocation): void => {
        if (!isSSR) {
            window.open(`https://www.google.com/maps/search/${encodeURIComponent(location.label)}`);
        }
    };

    return (
        <div className={`store-detail-map ${className}`}>
            <Suspense>
                <Map
                    center={{
                        latitude: store.address.geoCoords?.latitude || 0,
                        longitude: store.address.geoCoords?.longitude || 0,
                    }}
                    zoom={7}
                    markers={[{
                        label: store.title || '',
                        location: {
                            latitude: store.address.geoCoords?.latitude || 0,
                            longitude: store.address.geoCoords?.longitude || 0,
                        },
                    }]}
                    onMarkerClick={handleMarkerClick}
                    className="store-detail-map__map"
                />
            </Suspense>
        </div>
    );
};
