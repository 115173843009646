import { FC, ReactElement } from 'react';

import { LinkIconButton } from '../../../../compositions';
import { Navigation, NavigationItem } from '../../../../entities/@navigation/Navigation/Navigation';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';
import { useTrans } from '../../../../hooks';
import { NavigationLink } from '..';

import './DesktopMainNavigationColumn.scss';

interface DesktopMainNavigationColumnProps {
    hasLowerCaseTypeface?: boolean;
    navigation: Navigation;
    activeItemId?: string;
    onItemClick?: (navigationItem: NavigationItem) => void;
    onLinkClick: () => void;
    className?: string;
}

export const DesktopMainNavigationColumn: FC<DesktopMainNavigationColumnProps> = ({
    hasLowerCaseTypeface,
    navigation,
    activeItemId,
    onItemClick,
    onLinkClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`desktop-main-navigation-column ${className}`}>
            <ul className="desktop-main-navigation-column__group-list">
                {navigation.groups.map(group => (
                    <li key={group.id} className="desktop-main-navigation-column__group">
                        <ul className="desktop-main-navigation-column__item-list">
                            {group.items.map(item => {
                                const handleItemClick = (): void => {
                                    if (onItemClick) {
                                        onItemClick(item);
                                    }
                                };

                                return (
                                    <NavigationLink
                                        key={item.id}
                                        showActiveState
                                        hasLowerCaseTypeface={hasLowerCaseTypeface}
                                        isExpanded={activeItemId === item.id}
                                        item={item}
                                        onButtonClick={handleItemClick}
                                        onLinkClick={onLinkClick}
                                    />
                                );
                            })}
                        </ul>
                    </li>
                ))}
            </ul>

            {navigation.parent && (
                <LinkIconButton
                    to={navigation.parent.href}
                    icon="arrow-short-right"
                    iconPos={HorizontalAlignment.right}
                    text={trans('containers.menu.visitParent')}
                    onClick={onLinkClick}
                    className="desktop-main-navigation-column__parent-link-button"
                    iconClassName="desktop-main-navigation-column__parent-link-icon"
                />
            )}
        </div>
    );
};
