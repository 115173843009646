import { convertNumberToCurrency, convertPriceInCentsToEuros } from '../../helpers/number';
import trans from '../../helpers/trans';
import { DiscountCodeWarnings, DiscountResource, PricesResource } from '../@api/Sylius';
import { Discount } from './Price';

export const transformToDiscount = (resource: DiscountResource, warnings: DiscountCodeWarnings): Discount => {
    const id = resource.erpId.toString();

    const discountValue = resource.isPercentage
        ? `${convertPriceInCentsToEuros(resource.value)}%`
        : convertNumberToCurrency(resource.value, {
            includeCurrencySymbol: true,
            isCents: true,
        });

    const value = Math.abs(resource.actualEffect);

    const invalid = resource.isOrderLevel
        ? warnings.discountCodeHasNoEffect || warnings.discountValueNotFullyUsed
        : false;

    return {
        id,
        label: trans('entities.price.discount', { name: `${resource.label} (${discountValue})` }),
        value,
        articleDiscount: !resource.isOrderLevel,
        invalid,
    };
};

export const calculateDiscounts = (resource: PricesResource): Discount[] => {
    const discounts: Discount[] = [];

    let articleDiscounts: DiscountResource[] = [];
    let orderLevelDiscounts: DiscountResource[] = [];

    if (resource.discounts) {
        articleDiscounts = resource.discounts.filter(discount => !discount.isOrderLevel);
        orderLevelDiscounts = resource.discounts.filter(discount => discount.isOrderLevel);
    }

    const totalArticlesDiscount = articleDiscounts
        .reduce((total, discount) => total + discount.actualEffect, 0);

    discounts.push({
        id: 'articles-discount',
        label: trans('entities.price.totalArticlesDiscount'),
        value: Math.abs(totalArticlesDiscount),
        articleDiscount: true,
    });

    orderLevelDiscounts.forEach(discount => {
        const item = transformToDiscount(discount, resource?.warnings || {});

        discounts.push(item);
    });

    return discounts;
};
