import { Navigation } from '../entities/@navigation/Navigation/Navigation';

export const getParentNavigation = (navigation: Navigation, childNavigation: Navigation): Navigation | undefined => {
    const searchNavigation = (nav: Navigation, targetId: string): Navigation | undefined => {
        for (let i = 0; i < nav.groups.length; i += 1) {
            const group = nav.groups[i];

            for (let j = 0; j < group.items.length; j += 1) {
                const item = group.items[j];

                if (item.subNavigation) {
                    if (item.subNavigation.id === targetId) {
                        return nav;
                    }

                    const parent = searchNavigation(item.subNavigation, targetId);

                    if (parent) {
                        return parent;
                    }
                }
            }
        }

        return undefined;
    };

    return searchNavigation(navigation, childNavigation.id);
};
