import {
    isUnauthorizedResponse,
    RefreshTokenBodyResource,
    syliusFetch,
    Token,
    tokenKey,
    TokenResource,
    transformToToken,
    UnauthorizedTokenResource,
} from '../../../entities/@api/Sylius';
import { LoginFormData } from '../../../entities/@forms/LoginForm/LoginForm';
import { cartToken } from '../../../entities/@shop/Cart/Cart';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { getCart } from '../../@shop/cart/cartActions';
import { getWishList } from '../../@shop/wishlist/wishlistActions';
import { ReduxFetchAction } from '../../defaults';
import { TypedDispatch } from '../../store';
import { setCustomer } from '../customer/customerSlice';
import {
    setError,
    setHasFetched,
    setIsAuthenticated,
    setIsLoading,
} from './authenticationSlice';

export const authenticateUser: ReduxFetchAction<LoginFormData> = formData => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setError(''));
    dispatch(setIsAuthenticated(false));

    try {
        const requestBody = {
            email: formData.username,
            password: formData.password,
        };

        const tokenResponse = await syliusFetch<TokenResource | UnauthorizedTokenResource>('/shop/customers/token', {
            method: 'POST',
            body: JSON.stringify(requestBody),
        });

        if (!isFetchResultSuccessful(tokenResponse)) {
            dispatch(setError(tokenResponse.error));
            return;
        }

        if (isUnauthorizedResponse(tokenResponse.data)) {
            dispatch(setError(tokenResponse.data.message));
            return;
        }

        const token = transformToToken(tokenResponse.data);

        // Store Sylius JWT token based on preference
        if (formData.rememberMe) {
            localStorage.setItem(tokenKey, JSON.stringify(token));
        } else {
            sessionStorage.setItem(tokenKey, JSON.stringify(token));
        }

        dispatch(setIsAuthenticated(true));
    } catch (error) {
        console.error('[authenticateUser]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};

export const authenticateUserViaRefreshToken: ReduxFetchAction<string> = refreshToken => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setError(''));
    dispatch(setIsAuthenticated(false));

    try {
        const requestBody: RefreshTokenBodyResource = {
            refresh_token: refreshToken,
        };

        const authenticateUserViaRefreshTokenResponse = await syliusFetch<TokenResource>('/shop/customers/refresh_token', {
            method: 'POST',
            body: JSON.stringify(requestBody),
        });

        if (!isFetchResultSuccessful(authenticateUserViaRefreshTokenResponse)) {
            dispatch(setError(authenticateUserViaRefreshTokenResponse.error));
            return;
        }

        const storedToken = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
        const storedTokenData = storedToken
            ? JSON.parse(storedToken) as Token
            : undefined;

        const newToken = transformToToken(authenticateUserViaRefreshTokenResponse.data);

        const token: Token = {
            ...storedTokenData,
            ...newToken,
        };

        localStorage.setItem(tokenKey, JSON.stringify(token));

        dispatch(setIsAuthenticated(true));
    } catch (error) {
        console.error('[authenticateUserViaRefreshToken]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};

export const logout = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setError(''));

    try {
        localStorage.removeItem(tokenKey);
        sessionStorage.removeItem(tokenKey);

        dispatch(setCustomer(undefined));

        localStorage.removeItem(cartToken);

        await dispatch(getWishList());
        await dispatch(getCart());
    } catch (error) {
        console.error('[logout]', error);
    } finally {
        dispatch(setIsAuthenticated(false));
    }
};
