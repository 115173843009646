import { FC, PropsWithChildren, ReactElement } from 'react';

import { DefaultHelmet } from '../../../../../compositions';
import { ProductCategoryPageData } from '../../../../../entities/@products/ProductCategory/ProductCategory';

interface ProductCategoryHelmetProps {
    pageData: ProductCategoryPageData;
}

export const ProductCategoryHelmet: FC<PropsWithChildren<ProductCategoryHelmetProps>> = ({
    pageData,
    children,
}): ReactElement => {
    const metaTitle = pageData.seo
        ? pageData.seo.title
        : pageData.title;

    const metaDescription = pageData.seo
        ? pageData.seo.description
        : metaTitle;

    const metaKeywords = pageData.seo
        ? pageData.seo.keywords
        : undefined;

    const metaImage = pageData.seo
        ? pageData.seo.image
        : undefined;

    return (
        <DefaultHelmet
            title={metaTitle}
            description={metaDescription}
            keywords={metaKeywords}
            image={metaImage}
            canonical={pageData.seo?.canonicalURL}
        >
            {children}
        </DefaultHelmet>
    );
};
