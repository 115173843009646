import { FC, ReactElement } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { BlogCategories } from '../../containers';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import { useClientEffect, useTrans } from '../../hooks';
import { fetchBlogCategories } from '../../redux/@blog/blogCategories/blogCategoriesActions';
import { setAllowScrollReset } from '../../redux/app/appSlice';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedBlogCategoriesProps {
    className?: string;
}

export const ConnectedBlogCategories: FC<ConnectedBlogCategoriesProps> = ({
    className = '',
}): ReactElement => {
    const { category = '' } = useParams();

    const trans = useTrans();
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();

    const isLoading = useTypedSelector(state => state.blogCategoriesSlice.isLoading);
    const categoryTabs = useTypedSelector(state => state.blogCategoriesSlice.categoryTabs);

    useClientEffect((): void => {
        dispatch(fetchBlogCategories());
    }, []);

    const handleCategorySelect = (selectedCategory: string): void => {
        const blogCategoryPath = trans(appRoutes[AppRoute.blogOverviewCategory].path);
        const blogCategoryUrl = replaceUrlParamKeysWithValues(blogCategoryPath, {
            category: selectedCategory,
        });

        // Prevent next page from auto scrolling to top
        dispatch(setAllowScrollReset(false));

        navigate(blogCategoryUrl, { replace: true });
    };

    return (
        <BlogCategories
            isLoading={isLoading}
            activeCategory={category}
            categories={categoryTabs}
            onCategorySelect={handleCategorySelect}
            className={className}
        />
    );
};
