import { FC, ReactElement, useState } from 'react';

import { TabList } from '../../../../compositions';
import { ConnectedLoginForm } from '../../../../connectors';
import { CreateAccountDuringCheckoutFormData, UpdateCartFormData } from '../../../../entities/@forms/GuestForm/GuestForm';
import { CheckoutDetailsTab } from '../../../../entities/@shop/Checkout/Checkout';
import { useTrans } from '../../../../hooks';
import { GuestForm } from '../GuestForm/GuestForm';

import './DetailsStep.scss';

interface DetailsStepProps {
    onLoggedIn: () => void;
    onCreateAccount: (data: CreateAccountDuringCheckoutFormData) => void;
    onContinueAsGuest: (data: UpdateCartFormData) => void;
    className?: string;
}

export const DetailsStep: FC<DetailsStepProps> = ({
    onLoggedIn,
    onCreateAccount,
    onContinueAsGuest,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const tabs = [
        { label: trans('containers.checkout.detailsStep.existingCustomer'), value: CheckoutDetailsTab.existingCustomer },
        { label: trans('containers.checkout.detailsStep.guest'), value: CheckoutDetailsTab.guest },
    ];

    const [activeTab, setActiveTab] = useState<CheckoutDetailsTab>(CheckoutDetailsTab.existingCustomer);

    const handleOrdersTabSelect = (value: string): void => {
        const selectedTab = value as CheckoutDetailsTab;

        setActiveTab(selectedTab);
    };

    return (
        <div className={`details-step ${className}`}>
            <h2 className="details-step__heading">
                {trans('containers.checkout.detailsStep.heading')}
            </h2>

            <TabList
                tabs={tabs}
                activeTab={activeTab}
                onTabSelect={handleOrdersTabSelect}
                className="details-step__tabs"
                tabItemLabelClassName="details-step__tabs-item-label"
            />

            {activeTab === CheckoutDetailsTab.existingCustomer && (
                <ConnectedLoginForm onLoggedIn={onLoggedIn} />
            )}

            {activeTab === CheckoutDetailsTab.guest && (
                <GuestForm
                    onSubmit={onCreateAccount}
                    onContinueAsGuest={onContinueAsGuest}
                />
            )}
        </div>
    );
};
