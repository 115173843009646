import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type CustomPagesState = AsyncReduxState<{
    slugs: string[];
}>;

const initialState: CustomPagesState = {
    ...initialAsyncReduxState,
    slugs: [],
};

const customPagesSlice = createSlice({
    name: 'customPagesSlice',
    initialState,
    reducers: {
        setData(state, action: PayloadAction<CustomPagesState>): CustomPagesState {
            return {
                ...action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): CustomPagesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): CustomPagesState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CustomPagesState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CustomPagesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSlugs(state, action: PayloadAction<string[]>): CustomPagesState {
            return {
                ...state,
                slugs: action.payload,
            };
        },
    },
});

export const {
    setData,
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setSlugs,
} = customPagesSlice.actions;

export default customPagesSlice;
