import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ServiceContactDetails } from '../../../entities/@blocks/ServiceSection/ServiceSection';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type ServiceSectionState = AsyncReduxState<{
    serviceSectionItems?: ServiceContactDetails;
}>;

const initialState: ServiceSectionState = {
    ...initialAsyncReduxState,
};

const serviceSectionSlice = createSlice({
    name: 'serviceSectionSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ServiceSectionState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): ServiceSectionState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ServiceSectionState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ServiceSectionState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setServiceSectionItems(state, action: PayloadAction<ServiceContactDetails | undefined>): ServiceSectionState {
            return {
                ...state,
                serviceSectionItems: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setServiceSectionItems,
} = serviceSectionSlice.actions;

export default serviceSectionSlice;
