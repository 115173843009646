import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Link } from 'react-router-dom';

import {
    Icon,
    Page,
    Picture,
    Wrapper,
} from '../../../components';
import { ConnectedBlockRenderer } from '../../../connectors';
import { Token, tokenKey } from '../../../entities/@api/Sylius';
import { GTMEvent } from '../../../entities/GTM/GTM';
import { checkIfCustomerIsNew, generateGTMCompletedOrder } from '../../../entities/GTM/GTMRequests';
import { gtmEvent } from '../../../entities/GTM/GTMService';
import { LinkTarget } from '../../../entities/Link/Link';
import { AppRoute } from '../../../entities/Routing/Routing';
import { useTrans } from '../../../hooks';
import { MOCK_IMAGE_1, MOCK_SOCIAL_MEDIA_NAVIGATION } from '../../../mock/mock-data';
import { fetchOnlineOrderOverview } from '../../../redux/@account/orderOverview/orderOverviewActions';
import { removeCartLocally } from '../../../redux/@shop/cart/cartActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

import './CheckoutThankYouPage.scss';

interface CheckoutThankYouPageProps {
    page?: string;
}

export const CheckoutThankYouPage: FC<CheckoutThankYouPageProps> = (): ReactElement => {
    const trans = useTrans();
    const dispatch = useTypedDispatch();

    const cart = useTypedSelector(state => state.cartSlice.cart);

    const isLoading = useTypedSelector(state => state.orderOverviewSlice.isLoading);
    const onlineOrderOverview = useTypedSelector(state => state.orderOverviewSlice.onlineOrderOverview);

    const [gtmEventHasSend, setGtmEventHasSend] = useState<boolean>(false);

    useEffect((): void => {
        const storedToken = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
        const tokenData = storedToken
            ? JSON.parse(storedToken) as Token
            : undefined;

        const customerId = tokenData?.customerId;

        if (customerId) {
            dispatch(fetchOnlineOrderOverview({
                page: 1,
                itemsPerPage: 1,
            }));
        }
    }, []);

    useEffect((): void => {
        if (gtmEventHasSend || !cart.items.length || !onlineOrderOverview) {
            return;
        }

        const orders = onlineOrderOverview?.orders
            ? onlineOrderOverview.orders
            : undefined;

        const isNewCustomer = orders
            ? checkIfCustomerIsNew(orders)
            : false;

        const gtmCompletedOrder = generateGTMCompletedOrder(cart, isNewCustomer);

        gtmEvent({
            event: GTMEvent.purchase,
            ...gtmCompletedOrder,
        });

        setGtmEventHasSend(true);
    }, [cart, isLoading, onlineOrderOverview, gtmEventHasSend]);

    useEffect((): void => {
        if (gtmEventHasSend) {
            dispatch(removeCartLocally());
        }
    }, [gtmEventHasSend]);

    return (
        <Page className="checkout-thank-you-page">
            <Wrapper className="checkout-thank-you-page__wrapper">
                <div className="checkout-thank-you-page__inner-wrapper">
                    <div className="checkout-thank-you-page__title-wrapper">
                        <h1 className="checkout-thank-you-page__heading">
                            {trans('pages.checkoutThankYouPage.heading')}
                        </h1>

                        <p className="checkout-thank-you-page__text checkout-thank-you-page__intro">
                            {trans('pages.checkoutThankYouPage.intro')}
                        </p>

                        <p className="checkout-thank-you-page__text checkout-thank-you-page__body">
                            {trans('pages.checkoutThankYouPage.body')}
                        </p>

                        <p className="checkout-thank-you-page__text checkout-thank-you-page__postscript">
                            {trans('pages.checkoutThankYouPage.postscript')}
                        </p>

                        <div className="checkout-thank-you-page__social-wrapper">
                            {MOCK_SOCIAL_MEDIA_NAVIGATION.groups.map(group => (
                                <ul key={group.id} className="checkout-thank-you-page__social-media-group">
                                    {group.items.map(item => (
                                        <li key={item.id}>
                                            <Link
                                                aria-label={item.label}
                                                to={item.to}
                                                target={LinkTarget.blank}
                                                className="checkout-thank-you-page__social-media-link"
                                            >
                                                {item.icon && (
                                                    <Icon
                                                        name={item.icon}
                                                        className="checkout-thank-you-page__social-media-link-icon"
                                                    />
                                                )}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            ))}
                        </div>
                    </div>

                    <div className="checkout-thank-you-page__image-wrapper">
                        <Picture
                            {...MOCK_IMAGE_1}
                            className="checkout-thank-you-page__picture"
                            imageClassName="checkout-thank-you-page__image"
                        />
                    </div>
                </div>
            </Wrapper>

            <ConnectedBlockRenderer
                appRoute={AppRoute.checkoutThankYou}
                className="checkout-thank-you-page__block-renderer"
            />
        </Page>
    );
};
