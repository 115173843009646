import { strapiFetch } from '../../../entities/@api/Strapi';
import { BrandItemResponse } from '../../../entities/Brand/Brand';
import { generateBrandQuery } from '../../../entities/Brand/BrandRequests';
import { transformToBrand } from '../../../entities/Brand/BrandTransformers';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { ReduxFetchAction } from '../../defaults';
import {
    setBrand,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './brandSlice';

export const fetchBrand: ReduxFetchAction<string> = slug => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));
    dispatch(setBrand(undefined));

    try {
        const query = generateBrandQuery(slug);

        const brandResponse = await strapiFetch<BrandItemResponse>(`/brands?${query}`);

        if (!isFetchResultSuccessful(brandResponse)) {
            dispatch(setError(brandResponse.error));
            return;
        }

        const { data: brandsData } = brandResponse.data;

        const brandResource = brandsData
            ? brandsData[0]
            : undefined;

        const brand = brandResource
            ? transformToBrand(brandResource)
            : undefined;

        dispatch(setBrand(brand));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchBrand]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};
