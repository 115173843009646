import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { NotFound, ProductDetailRenderer } from '../../../containers';
import { MeilisearchAttribute, MeilisearchIndex } from '../../../entities/@api/Meilisearch';
import { MeilisearchStoreKey } from '../../../entities/@search/Meilisearch/Meilisearch';
import { generateMeilisearchExcludeFilterQuery, generateMeilisearchFilterQuery } from '../../../entities/@search/Meilisearch/MeilisearchRequests';
import { GTMEvent } from '../../../entities/GTM/GTM';
import { generateGTMProduct } from '../../../entities/GTM/GTMRequests';
import { gtmEvent } from '../../../entities/GTM/GTMService';
import { combineBaseFilterWithFilterQuery } from '../../../helpers/meilisearch';
import { useClientEffect } from '../../../hooks';
import { fetchBrand } from '../../../redux/@brands/brand/brandActions';
import { setBrand } from '../../../redux/@brands/brand/brandSlice';
import { fetchProductFromVariantSlug } from '../../../redux/@products/product/productActions';
import { fetchMeilisearchVariants } from '../../../redux/meilisearch/meilisearchActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedProductDetailRendererProps {
    className?: string;
}

export const ConnectedProductDetailRenderer: FC<ConnectedProductDetailRendererProps> = ({
    className = '',
}): ReactElement => {
    const { slug } = useParams();

    const dispatch = useTypedDispatch();

    const productIsLoading = useTypedSelector(state => state.productSlice.isLoading);
    const hasFetched = useTypedSelector(state => state.productSlice.hasFetched);
    const breadcrumbs = useTypedSelector(state => state.productSlice.breadcrumbs);
    const product = useTypedSelector(state => state.productSlice.product);

    const brand = useTypedSelector(state => state.brandSlice.brand);

    const cartIsLoading = useTypedSelector(state => state.cartSlice.isLoading);

    const wishlistIsLoading = useTypedSelector(state => state.wishlistSlice.isLoading);

    const variantResponses = useTypedSelector(state => state.meilisearchSlice.variantResponses);

    const brandStoreKey = MeilisearchStoreKey.productDetailBrand;
    const relatedStoreKey = MeilisearchStoreKey.productDetailRelated;

    useClientEffect((): void => {
        if (!slug) {
            return;
        }

        dispatch(fetchProductFromVariantSlug(slug));
    }, [slug]);

    useEffect((): void => {
        if (!product) {
            return;
        }

        const GTMProduct = generateGTMProduct(product);

        gtmEvent({
            event: GTMEvent.viewItem,
            ...GTMProduct,
        });

        const excludeFilter = generateMeilisearchExcludeFilterQuery(MeilisearchAttribute.code, product.variantCode);

        const relatedBaseFilter = generateMeilisearchFilterQuery(MeilisearchAttribute.categories, product.categories || []);
        const relatedFilter = combineBaseFilterWithFilterQuery(relatedBaseFilter, excludeFilter);

        dispatch(fetchMeilisearchVariants({
            key: relatedStoreKey,
            index: MeilisearchIndex.variantsNl,
            pageSize: 12,
            filter: relatedFilter,
        }));

        if (!product?.brand) {
            dispatch(setBrand(undefined));

            return;
        }

        const brandBaseFilter = generateMeilisearchFilterQuery(MeilisearchAttribute.brand, product.brand.label);
        const brandFilter = combineBaseFilterWithFilterQuery(brandBaseFilter, excludeFilter);

        dispatch(fetchBrand(product.brand.href));

        dispatch(fetchMeilisearchVariants({
            key: brandStoreKey,
            index: MeilisearchIndex.variantsNl,
            pageSize: 12,
            filter: brandFilter,
        }));
    }, [product]);

    if (hasFetched && !product) {
        return (
            <NotFound />
        );
    }

    return (
        <ProductDetailRenderer
            productIsLoading={productIsLoading}
            cartIsLoading={cartIsLoading}
            wishlistIsLoading={wishlistIsLoading}
            breadcrumbs={breadcrumbs}
            product={product}
            brand={brand}
            brandProducts={variantResponses?.[brandStoreKey]?.results || []}
            relatedProducts={variantResponses?.[relatedStoreKey]?.results || []}
            className={className}
        />
    );
};
