import { transformToSlug } from '../../helpers/string';
import trans from '../../helpers/trans';
import { removeTrailingSlash, replaceUrlParamKeysWithValues } from '../../helpers/url';
import {
    MeilisearchBlogPostHitResource,
    MeilisearchBrandHitResource,
    MeilisearchCategoryHitResource,
    MeilisearchDepartmentHitResource,
    MeilisearchPage,
    MeilisearchPageHitResource,
    MeilisearchPageType,
    MeilisearchPageTypeResource,
    MeilisearchStoreHitResource,
} from '../@api/Meilisearch';
import { defaultBlogCategory } from '../@blog/BlogCategory/BlogCategory';
import { transformToBlogCategory } from '../@blog/BlogCategory/BlogCategoryTransformers';
import { transformToPageSearchResultTitle } from '../@search/PageSearchResult/PageSearchResultTransformers';
import { Taxon } from '../@shop/Taxon/Taxon';
import { ButtonResource } from '../Button/Button';
import { appRoutePageTypes, PageType } from '../Page/Page';
import { AppRoute, appRoutes } from '../Routing/Routing';
import { Link, LinkTarget } from './Link';

export const transformSlugToGoogleMapsLink = (slug: string): string => `https://www.google.com/maps/search/${slug}`;

const transformPageTypeToAppRoute = (pageType: PageType): AppRoute | undefined => {
    const pageTypeRoutes = Object.entries(appRoutePageTypes);
    const route = pageTypeRoutes.find(([, value]) => value === pageType);

    if (!route) {
        console.error(`AppRoute not found for pageType: ${pageType}`);
        return undefined;
    }

    return route[0] as AppRoute;
};

const transformMeilisearchPageHitToPath = (page: MeilisearchPageHitResource): string | undefined => {
    const { fixedType } = page;

    const appRoute = transformPageTypeToAppRoute(fixedType as PageType);

    return appRoute
        ? trans(appRoutes[appRoute].path)
        : undefined;
};

const transformMeilisearchBlogPostHitToPath = (blogPost: MeilisearchBlogPostHitResource): string => {
    const category = blogPost.blogCategory
        ? transformToBlogCategory(blogPost.blogCategory)
        : defaultBlogCategory();

    const blogPostPath = trans(appRoutes[AppRoute.blogPost].path);

    return replaceUrlParamKeysWithValues(blogPostPath, {
        category: category.slug,
        slug: blogPost.slug,
    });
};

const transformMeilisearchBrandHitToPath = (brand: MeilisearchBrandHitResource): string => {
    const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);

    return replaceUrlParamKeysWithValues(brandDetailPath, {
        slug: brand.slug,
    });
};

const transformMeilisearchDepartmentHitToPath = (department: MeilisearchDepartmentHitResource): string => {
    const departmentDetailPath = trans(appRoutes[AppRoute.departmentDetail].path);

    return replaceUrlParamKeysWithValues(departmentDetailPath, {
        slug: department.slug,
    });
};

// TODO: Find a way to retrieve sub(sub) categories and fill in the link properly
const transformMeilisearchCategoryHitToPath = (category: MeilisearchCategoryHitResource): string => {
    const productCategoryPath = trans(appRoutes[AppRoute.productCategory].path);

    console.log('———');
    console.log(1, category.slug);
    console.log(2, transformToSlug(category.slug));

    const replacementUrl = replaceUrlParamKeysWithValues(productCategoryPath, {
        slug: transformToSlug(category.slug),
        subCategory: undefined,
        subSubCategory: undefined,
    });

    return removeTrailingSlash(replacementUrl);
};

const transformMeilisearchStoreHitToPath = (store: MeilisearchStoreHitResource): string => {
    const storeDetailPath = trans(appRoutes[AppRoute.storeDetail].path);

    return replaceUrlParamKeysWithValues(storeDetailPath, {
        slug: store.slug,
    });
};

export const transformMeilisearchPageLinkToLink = (resource: MeilisearchPage, type: MeilisearchPageType): Link => {
    let href = '';
    let label = resource.title;

    if (resource._meilisearch_id.includes(MeilisearchPageTypeResource.page)) {
        href = transformMeilisearchPageHitToPath(resource as MeilisearchPageHitResource) || '';
    } else if (resource._meilisearch_id.includes(MeilisearchPageTypeResource.blog)) {
        href = transformMeilisearchBlogPostHitToPath(resource as MeilisearchBlogPostHitResource);
    } else if (resource._meilisearch_id.includes(MeilisearchPageTypeResource.brand)) {
        label = transformToPageSearchResultTitle(resource as MeilisearchBrandHitResource, type);
        href = transformMeilisearchBrandHitToPath(resource as MeilisearchBrandHitResource);
    } else if (resource._meilisearch_id.includes(MeilisearchPageTypeResource.department)) {
        href = transformMeilisearchDepartmentHitToPath(resource as MeilisearchDepartmentHitResource);
    } else if (resource._meilisearch_id.includes(MeilisearchPageTypeResource.category)) {
        href = transformMeilisearchCategoryHitToPath(resource as MeilisearchCategoryHitResource);
    } else if (resource._meilisearch_id.includes(MeilisearchPageTypeResource.store)) {
        href = transformMeilisearchStoreHitToPath(resource as MeilisearchStoreHitResource);
    }

    return { label, href };
};

export const transformTaxonToLink = (taxon: Taxon): Link => ({
    href: taxon.slug,
    label: taxon.name,
});

export const transformButtonToLink = (button: ButtonResource): Link => {
    const target = button.link.target || LinkTarget.self;

    return {
        label: button.link.label,
        target,
        href: button.link.href,
    };
};
