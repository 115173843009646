import { IconName } from '../../../components';
import { WayfindingType } from './Wayfinding';

export const transformToWayfindingIcon = (resource: WayfindingType): IconName => {
    const wayfindingIcons: Record<WayfindingType, IconName> = {
        [WayfindingType.cta]: 'wayfinding-cta',
        [WayfindingType.download]: 'wayfinding-download',
        [WayfindingType.left]: 'wayfinding-left',
        [WayfindingType.right]: 'wayfinding-right',
        [WayfindingType.up]: 'wayfinding-up',
        [WayfindingType.enter]: 'wayfinding-enter',
    };

    return wayfindingIcons[resource] || 'wayfinding-enter';
};
