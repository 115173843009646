import { FC, ReactElement, useMemo } from 'react';

import { useTypedSelector } from '../../../redux/store';

interface ConnectedSearchPageWrapperChildProps {
    showNullState: boolean;
}

interface ConnectedSearchPageWrapperProps {
    children: (childProps: ConnectedSearchPageWrapperChildProps) => ReactElement;
}

export const ConnectedSearchPageWrapper: FC<ConnectedSearchPageWrapperProps> = ({
    children,
}): ReactElement => {
    const isLoadingVariants = useTypedSelector(state => state.meilisearchSlice.isLoadingVariants);
    const isLoadingPages = useTypedSelector(state => state.meilisearchSlice.isLoadingPages);
    const pageResponses = useTypedSelector(state => state.meilisearchSlice.pageResponses);
    const variantResponses = useTypedSelector(state => state.meilisearchSlice.variantResponses);

    const showNullState = useMemo((): boolean => {
        if (isLoadingVariants || isLoadingPages) {
            return false;
        }

        const hasNoPageResults = pageResponses.search?.results
            ? pageResponses.search.results.length === 0
            : true;

        const hasNoVariantResults = variantResponses.search?.results
            ? variantResponses.search.results.length === 0
            : true;

        return hasNoPageResults && hasNoVariantResults;
    }, [pageResponses, variantResponses, isLoadingVariants]);

    return (
        <div>
            {children({ showNullState })}
        </div>
    );
};
