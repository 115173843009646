import { MeilisearchAttribute, MeilisearchIndex } from '../../@api/Meilisearch';
import { FormOption } from '../../@forms/Form/Form';
import { PaginationInterface } from '../../Pagination/Pagination';

export const filterAttributePrefix = 'attributes.';

interface MeilisearchFilterBase {
    attribute: MeilisearchAttribute;
}

export interface MeilisearchFilter extends MeilisearchFilterBase {
    options: FormOption[];
}

export interface MeilisearchRangeFilter extends MeilisearchFilterBase {
    min: number;
    max: number;
    step?: number;
    valueFormat?: (value: number) => string;
}

export const forbiddenMeilisearchAttributeKeys = [MeilisearchAttribute.deliveryMin, MeilisearchAttribute.deliveryMax];

// List of all filter attributes that need a custom translation
export const customFilterAttributes = [
    MeilisearchAttribute.price,
    MeilisearchAttribute.categories,
];

// List of all filter attributes that don't have the attribute prefix, but should be displayed
export const filterAttributes = [
    MeilisearchAttribute.categories,
];

// List of all filter attributes that should display a range input
export const rangedFilterAttributes = [
    MeilisearchAttribute.width,
    MeilisearchAttribute.height,
    MeilisearchAttribute.depth,
    MeilisearchAttribute.weight,
    MeilisearchAttribute.price,
];

// List of all filter attributes that should be included in the highlighted filters
export const highlightedFilterAttributes = [
    MeilisearchAttribute.color,
    MeilisearchAttribute.brand,
    MeilisearchAttribute.material,
];

// List of all filter attributes that should not update with new filters from Meilisearch responses
export const staticFilterAttributes = [
    // We want all range attributes to be static
    ...rangedFilterAttributes,
    // We want all highlighted filter attributes to be static
    ...highlightedFilterAttributes,
];

export interface MeilisearchSearchConfig {
    query?: string;
    filter?: string;
    baseFilter?: string;
    sort?: string;
    facets?: string[];
    pageSize?: number;
    page?: number;
}

// Set 'retrieveInitialFilters' to true when you just want to fetch and store the initial/static filters for a given query
export interface MeilisearchVariantsConfig extends MeilisearchSearchConfig {
    retrieveInitialFilters?: boolean;
    key: MeilisearchStoreKey;
    index: MeilisearchIndex;
}

export interface MeilisearchPagesConfig extends MeilisearchSearchConfig {
    key: string;
}

export interface MeilisearchSearchResponseBase {
    query: string;
    pagination: PaginationInterface;
}

export type MeilisearchSearchResponse<Result> = MeilisearchSearchResponseBase & {
    results: Result[];
    filters?: MeilisearchFilter[];
    rangeFilters?: MeilisearchRangeFilter[];
    highlightedFilters?: MeilisearchFilter[];
    initialFilters?: MeilisearchFilter[];
    initialRangeFilters?: MeilisearchRangeFilter[];
    initialHighlightedFilters?: MeilisearchFilter[];
}

export enum MeilisearchStoreKey {
    blogPost = 'blogPost',
    brandDetail = 'brandDetail',
    featuredProducts = 'featuredProducts',
    giftcard = 'giftcard',
    ownCollection = 'ownCollection',
    products = 'products',
    productCategory = 'productCategory',
    productCollection = 'productCollection',
    productDetailBrand = 'productDetailBrand',
    productDetailRelated = 'productDetailRelated',
    productsSlider = 'productsSlider',
    sale = 'sale',
    search = 'search',
    searchDrawer = 'searchDrawer',
    spaceDetail = 'spaceDetail',
    wishlist = 'wishlist',
}

export const defaultMeilisearchPageSize = 32;
export const defaultMeilisearchPagination = (): PaginationInterface => ({
    totalResults: 0,
    page: 1,
    pageSize: defaultMeilisearchPageSize,
    totalPages: 1,
});

export const defaultMeilisearchSearchResponse = <Result>(): MeilisearchSearchResponse<Result> => {
    const pagination = defaultMeilisearchPagination();

    return {
        query: '',
        results: [],
        pagination,
    };
};
