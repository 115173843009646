import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { LinkIconButton } from '../../../../compositions';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';
import { LinkTarget } from '../../../../entities/Link/Link';
import { transformSlugToGoogleMapsLink } from '../../../../entities/Link/LinkTransformers';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { Store } from '../../../../entities/Store/Store';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import { getOpenStatusInfo } from '../../../../helpers/workingHours';
import { useDeviceWidth, useTrans } from '../../../../hooks';

import './StoreSuggestions.scss';

interface StoreSuggestionsProps {
    stores: Store[];
    onLinkClick: () => void;
    className?: string;
}

export const StoreSuggestions: FC<StoreSuggestionsProps> = ({
    stores,
    onLinkClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();

    const storesWithOpenStatus = stores.map(store => ({
        ...store,
        ...getOpenStatusInfo(store.openingHours, true),
    }));

    return (
        <div className={`store-suggestions ${className}`}>
            <p className="store-suggestions__label">
                {trans('containers.searchDrawer.openingHours')}
            </p>

            <ul className="store-suggestions__list">
                {storesWithOpenStatus.map(store => {
                    const linkToGoogleMapsStore = transformSlugToGoogleMapsLink(store.slug);

                    const storeDetailPath = trans(appRoutes[AppRoute.storeDetail].path);
                    const storeDetailUrl = replaceUrlParamKeysWithValues(storeDetailPath, {
                        slug: store.slug,
                    });

                    return (
                        <li key={store.id} className="store-suggestions__item">
                            <div className="store-suggestions__info-wrapper">
                                <Link
                                    to={storeDetailUrl}
                                    onClick={onLinkClick}
                                    className="store-suggestions__store-link"
                                >
                                    {store.title}
                                </Link>

                                <p className="store-suggestions__location-open-status">
                                    {isMobile ? store.shortOpenStatus : store.openStatus}
                                </p>
                            </div>

                            <LinkIconButton
                                hasAnimation
                                isSmall
                                to={linkToGoogleMapsStore}
                                target={LinkTarget.blank}
                                icon="arrow-right"
                                iconPos={HorizontalAlignment.right}
                                text={trans('containers.searchDrawer.route')}
                                className="store-suggestions__route-link"
                                iconClassName="store-suggestions__link-icon"
                            />
                        </li>
                    );
                })}
            </ul>

            <LinkIconButton
                isSmall
                icon="arrow-right"
                iconPos={HorizontalAlignment.right}
                text={trans('containers.searchDrawer.allOpeningHours')}
                to={trans(appRoutes[AppRoute.storeOverview].path)}
                onClick={onLinkClick}
                className="store-suggestions__show-all-link"
                labelClassName="store-suggestions__show-all-link-label"
                iconClassName="store-suggestions__link-icon"
            />
        </div>
    );
};

