import { ParsedQs } from 'qs';

import { MeilisearchAttribute, MeilisearchFilterOperator } from '../entities/@api/Meilisearch';
import { filterAttributePrefix, rangedFilterAttributes } from '../entities/@search/Meilisearch/Meilisearch';
import { MinMaxValue } from '../types';

interface FilterQueryConfig <T>{
    attribute: MeilisearchAttribute;
    operator: MeilisearchFilterOperator;
    value: T;
}

const minMaxStringSplitCharacter = '-';

export const transformRangeFilterValueToMinMaxString = (rangeFilterValue: MinMaxValue): string => {
    const { min, max } = rangeFilterValue;

    return [min, max].join(minMaxStringSplitCharacter);
};

export const transformMinMaxStringToRangeFilterValue = (minMaxString: string): MinMaxValue => {
    const [min, max] = minMaxString.split(minMaxStringSplitCharacter);

    return {
        min: Number(min),
        max: Number(max),
    };
};

export const transformToFilterQuery = (config: FilterQueryConfig<string[]>): string => {
    const { attribute, operator, value } = config;

    return value
        .map(option => `'${attribute}' = '${option}'`)
        .join(` ${operator} `);
};

export const transformToRangeFilterQuery = (config: FilterQueryConfig<MinMaxValue>): string => {
    const { attribute, operator, value } = config;

    return [
        [attribute, value.min].join(' >= '),
        [attribute, value.max].join(' <= '),
    ].join(` ${operator} `);
};

// Used for server-side request queries
export const retrieveFilterEntriesFromRequestQuery = (parsedQuery: ParsedQs): [string, string][] => {
    const paramEntries = Object.entries(parsedQuery) as [string, string][];

    return paramEntries.filter(([attribute]) => {
        const isAttribute = Object.values(MeilisearchAttribute).includes(attribute as MeilisearchAttribute);

        return isAttribute || attribute.startsWith(filterAttributePrefix);
    });
};

// Used for client-side URL search params
export const retrieveFilterEntriesFromSearchParams = (urlSearchParams: URLSearchParams): [string, string][] => {
    const paramEntries = urlSearchParams.entries();
    const activeFilterEntries = Object.fromEntries(paramEntries);

    return Object.entries(activeFilterEntries).filter(([attribute]) => {
        const isAttribute = Object.values(MeilisearchAttribute).includes(attribute as MeilisearchAttribute);

        return isAttribute || attribute.startsWith(filterAttributePrefix);
    });
};

export const transformFilterEntriesToFilterQuery = (
    activeFilters: [string, string][],
): string => activeFilters.reduce((accumulator, [attribute, value]) => {
    const typedAttribute = attribute as MeilisearchAttribute;
    const isRangeFilter = rangedFilterAttributes.includes(typedAttribute);
    const filterValue = isRangeFilter
        ? transformMinMaxStringToRangeFilterValue(value)
        : value.split(',');

    const filterEntryQuery = isRangeFilter
        ? transformToRangeFilterQuery({
            attribute: typedAttribute,
            value: filterValue as MinMaxValue,
            operator: MeilisearchFilterOperator.and,
        })
        : transformToFilterQuery({
            attribute: typedAttribute,
            value: filterValue as string[],
            operator: MeilisearchFilterOperator.or,
        });

    return accumulator
        ? `${accumulator} ${MeilisearchFilterOperator.and} (${filterEntryQuery})`
        : `(${filterEntryQuery})`;
}, '');

export const combineBaseFilterWithFilterQuery = (baseFilter = '', filterQuery = ''): string => (
    baseFilter && filterQuery
        ? `(${baseFilter}) ${MeilisearchFilterOperator.and} ${filterQuery}`
        : baseFilter || filterQuery
);
