import { FC, ReactElement } from 'react';

import { IFrameInterface } from '../../entities/@blocks/IFrame/IFrame';
import { Wrapper } from '../Wrapper/Wrapper';

import './IFrame.scss';

export interface IFrameProps extends IFrameInterface {
    className?: string;
}

export const IFrame: FC<IFrameProps> = ({
    id,
    title,
    src,
    className = '',
}): ReactElement => (
    <Wrapper className={`iframe ${className}`}>
        <iframe
            id={`iframe-${id}`}
            title={title}
            src={src}
            className="iframe__block"
        />
    </Wrapper>
);
