import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';

import { ErrorLabel } from '../../../components';
import {
    Button,
    Checkbox,
    PasswordInput,
    RecaptchaDisclaimer,
    TextInput,
} from '../../../compositions';
import { FormProps } from '../../../entities/@forms/Form/Form';
import { LoginFormData } from '../../../entities/@forms/LoginForm/LoginForm';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { scrollIntoViewIncludingNavigation } from '../../../helpers/scroll';
import { useTrans } from '../../../hooks';
import { LoginFormErrors, validateLoginFormData } from './validations';

import './LoginForm.scss';

interface LoginFormProps extends FormProps<LoginFormData> {
    heading?: string;
    className?: string;
}

export const LoginForm: FC<LoginFormProps> = ({
    isLoading,
    error,
    onSubmit,
    heading,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<LoginFormErrors>({});
    const [botDetected, setBotDetected] = useState<boolean>(false);

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(true);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (!executeRecaptcha) {
            setBotDetected(true);
            return;
        }

        const formData: LoginFormData = {
            username,
            password,
            rememberMe,
        };

        const [errors, hasErrors] = validateLoginFormData(formData);
        const token = await executeRecaptcha();

        setFormErrors(errors);
        setBotDetected(!token);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoViewIncludingNavigation<HTMLFormElement>(formRef);
        }
    };

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={`login-form ${className}`}
        >
            {heading && (
                <h2 className="login-form__heading">
                    {heading}
                </h2>
            )}

            <TextInput
                type="email"
                inputMode="email"
                autoComplete="email"
                label={trans('containers.loginForm.usernameLabel')}
                placeholder={trans('containers.loginForm.usernamePlaceholder')}
                value={username}
                error={formErrors.username}
                disabled={isLoading}
                onChange={setUsername}
                className="login-form__username-input"
            />

            <PasswordInput
                autoComplete="current-password"
                label={trans('containers.loginForm.passwordLabel')}
                placeholder={trans('containers.loginForm.passwordPlaceholder')}
                value={password}
                error={formErrors.password}
                disabled={isLoading}
                onChange={setPassword}
                className="login-form__password-input"
                fieldClassName="login-form__password-input-field"
            />

            <Checkbox
                label={trans('containers.loginForm.rememberMeLabel')}
                checked={rememberMe}
                disabled={isLoading}
                onChange={setRememberMe}
                className="login-form__remember-me-checkbox"
            />

            <Button
                hasAnimation
                type="submit"
                text={trans('containers.loginForm.submitButton')}
                className="login-form__submit-button"
            />

            <RecaptchaDisclaimer className="login-form__recaptcha-disclaimer" />

            {botDetected && (
                <ErrorLabel text={trans('errors.unknownError')} />
            )}

            {error && (
                <ErrorLabel
                    text={error}
                    className="login-form__error-label"
                />
            )}

            <Link
                to={trans(appRoutes[AppRoute.resetPassword].path)}
                className="login-form__reset-password-button"
            >
                {trans('containers.loginForm.resetPasswordButton')}
            </Link>
        </form>
    );
};
