import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { Page } from '../../../components';
import { NotFound } from '../../../containers';
import { MeilisearchAttribute } from '../../../entities/@api/Meilisearch';
import { generateMeilisearchFilterQuery } from '../../../entities/@search/Meilisearch/MeilisearchRequests';
import { useClientEffect } from '../../../hooks';
import { fetchSpace } from '../../../redux/space/spaceActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedSpaceDetailChildProps {
    name: string;
    baseFilter: string;
}

interface ConnectedSpaceDetailWrapperProps {
    children: (childProps: ConnectedSpaceDetailChildProps) => ReactElement;
}

export const ConnectedSpaceDetailWrapper: FC<ConnectedSpaceDetailWrapperProps> = ({
    children,
}): ReactElement => {
    const { slug = '' } = useParams();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.spaceSlice.hasFetched);
    const space = useTypedSelector(state => state.spaceSlice.space);

    useClientEffect((): void => {
        if (slug) {
            dispatch(fetchSpace(slug));
        }
    }, [slug]);

    if (hasFetched && !space) {
        return (
            <Page>
                <NotFound />
            </Page>
        );
    }

    const name = space
        ? space.title
        : '';

    const baseFilter = space?.meilisearchProductListName
        ? generateMeilisearchFilterQuery(MeilisearchAttribute.lists, space.meilisearchProductListName)
        : '';

    return (
        <div>
            {children({
                name,
                baseFilter,
            })}
        </div>
    );
};
