import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet, ConnectedStoreOverview } from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';
import trans from '../../../helpers/trans';

import './StoreOverviewPage.scss';

export const StoreOverviewPage: FC = (): ReactElement => (
    <Page className="store-overview-page">
        <ConnectedPageHelmet appRoute={AppRoute.storeOverview} />

        <Wrapper>
            <h1 className="store-overview-page__title">
                {trans('pages.storeOverview.title')}
            </h1>

            <ConnectedStoreOverview className="store-overview-page__store-overview" />
        </Wrapper>

        <ConnectedBlockRenderer
            appRoute={AppRoute.storeOverview}
            className="store-overview-page__block-renderer"
        />
    </Page>
);
