import { ProductAttributeKey } from '../../@api/Sylius';
import { Link } from '../../Link/Link';
import { ProductPriceInterface } from '../../Price/Price';
import { ProductAttribute, ProductDimensionAttribute } from '../ProductAttribute/ProductAttribute';
import { ProductVariantColor } from '../ProductVariantColor/ProductVariantColor';

export interface ProductVariant {
    id: string;
    variantId: string;
    variantCode: string;
    productCode: string;
    slug?: string;
    name?: string;
    price: ProductPriceInterface;
    availableInTime?: string;
    attributes: ProductAttribute[];
    dimensionAttributes: ProductDimensionAttribute[];
    brand?: Link;
    colors: ProductVariantColor[];
    stock: boolean;
}

export const dimensionAttributeKeys = [ProductAttributeKey.deliveryMin, ProductAttributeKey.deliveryMax];
