import { FC, ReactElement } from 'react';

import { Product } from '../../../../../entities/@products/Product/Product';
import { useTrans } from '../../../../../hooks';

import './ProductInformation.scss';

interface ProductInformationProps {
    product: Product;
    className?: string;
}

export const ProductInformation: FC<ProductInformationProps> = ({
    product,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <section className={`product-information ${className}`}>
            <div className="product-information__block product-information__main-wrapper">
                <div className="product-information__main-inner">
                    <div className="product-information__description-wrapper">
                        <h2 className="product-information__title">
                            {trans('containers.productDetailRenderer.description')}
                        </h2>

                        {product.description ? (
                            <p className="product-information__description">
                                {product.description}
                            </p>
                        ) : (
                            <p className="product-information__description">
                                {trans('containers.productDetailRenderer.noDescriptionAvailable')}
                            </p>
                        )}
                    </div>

                    <aside className="product-information__details-wrapper">
                        {product.attributes.length > 0 && (
                            <div className="product-information__specifications-wrapper">
                                <h2 className="product-information__title">
                                    {trans('containers.productDetailRenderer.specifications')}
                                </h2>

                                <table className="product-information__table">
                                    <tbody>
                                        {product.attributes.map(attribute => (
                                            <tr key={attribute.key} className="product-information__table-row">
                                                <td className="product-information__table-cell">
                                                    {attribute.label}
                                                </td>

                                                <td className="product-information__table-cell">
                                                    {attribute.value}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {product.dimensionAttributes && (
                            <div className="product-information__dimensions-wrapper">
                                <h2 className="product-information__title">
                                    {trans('containers.productDetailRenderer.dimensions')}
                                </h2>

                                <table className="product-information__table">
                                    <tbody>
                                        {product.dimensionAttributes.map(dimensionAttribute => (
                                            <tr key={dimensionAttribute.key} className="product-information__table-row">
                                                <td className="product-information__table-cell">
                                                    {dimensionAttribute.label}
                                                </td>

                                                <td className="product-information__table-cell">
                                                    {dimensionAttribute.value}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </aside>
                </div>
            </div>
        </section>
    );
};
