import { AddressResource } from './Address';
import { BaseResource } from './BaseResource';
import { OrderItemResource } from './OrderItem';
import { DiscountResource } from './Prices';

export enum OrderPaymentProviderResource {
    paynl = 'paynl',
}

export enum OrderPaymentMethodResource {
    bancontact = 'bancontact',
    bankTransfer = 'bank-transfer',
    creditCard = 'credit-card',
    giftCard = 'gift-card',
    ideal = 'ideal',
    in3 = 'in3',
    klarna = 'klarna',
    paypal = 'paypal',
    pin = 'pin',
    riverty = 'riverty',
    sofort = 'sofort',
    voucher = 'voucher',
    unknown = 'unknown',
}

export interface OrderPaymentResource extends BaseResource {
    method: OrderPaymentProviderResource;
}

export enum OrderShipmentMethodResource {
    ups = 'ups',
}

export interface OrderShipmentResource extends BaseResource {
    method: OrderShipmentMethodResource;
}

export enum CheckoutStateResource {
    addressed = 'addressed',
    cart = 'cart',
    completed = 'completed',
    paymentSelected = 'payment_selected',
    paymentSkipped = 'payment_skipped',
    shippingSelected = 'shipping_selected',
    shippingSkipped = 'shipping_skipped',
}

export enum PaymentStateResource {
    authorized = 'authorized', // Payment is fully authorized, but not yet captured (pay after delivery approach)
    awaitingPayment = 'awaiting_payment', // Ready to accept payment, but not yet received any info on it
    cancelled = 'cancelled', // Payment is cancelled (it will never be paid)
    cart = 'cart', // Not paid, not even in checkout
    paid = 'paid', // Successfully paid
    partiallyAuthorized = 'partially_authorized', // Payment is partially complete
    partiallyPaid = 'partially_paid', // Only a part of the order is paid so far
    partiallyRefunded = 'partially_refunded', // After payment: part of the payment is refunded
    refunded = 'refunded', // Fully refunded
}

export enum ShippingStateResource {
    ready = 'ready',
}

export enum OrderStateResource {
    cancelled = 'cancelled', // Cancelled permanently
    cart = 'cart', // It's a shopping cart
    fulfilled = 'fulfilled', // Fully paid for, delivered, done and done
    new = 'new', // A fresh new order, paid for or ready to be paid
}

export interface OrderBaseResource extends BaseResource {
    readonly channel: string;
    readonly itemsTotal: number;
    readonly itemsSubtotal: number;
    readonly number?: string | null;
    readonly state?: OrderStateResource | null;
    readonly tokenValue?: string | null;
    readonly orderedAt?: string | null;
}

export interface OrderResource extends OrderBaseResource {
    isWebshop: boolean;
    shippingAddress?: AddressResource | null;
    billingAddress?: AddressResource | null;
    readonly payments: OrderPaymentResource[];
    readonly shipments: OrderShipmentResource[];
    currencyCode: string;
    localeCode: string;
    readonly checkoutState: CheckoutStateResource;
    readonly paymentState: PaymentStateResource;
    readonly shippingState: ShippingStateResource; // @TODO: Determine possible values
    readonly paymentMethod?: OrderPaymentMethodResource;
    readonly discounts: DiscountResource[];
    items: OrderItemResource[];
    readonly total: number;
    readonly taxTotal: number;
    readonly shippingTaxTotal: number;
    readonly taxExcludedTotal: number;
    readonly taxIncludedTotal: number;
    readonly shippingTotal: number;
    readonly orderPromotionTotal: number;
    readonly shippingPromotionTotal: number;
}

export interface DuplicateOrderResource {
    order: {
        token: string;
    };
    success: boolean;
}
