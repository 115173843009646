import { FC, PropsWithChildren, ReactElement } from 'react';

import { Page } from '../../../components';
import { NotFound } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchBrandNavigation } from '../../../redux/@brands/brandOverview/brandOverviewActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

type ConnectedBrandOverviewWrapperProps = PropsWithChildren;

export const ConnectedBrandOverviewWrapper: FC<ConnectedBrandOverviewWrapperProps> = ({
    children,
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.brandOverviewSlice.hasFetched);
    const navigation = useTypedSelector(state => state.brandOverviewSlice.navigation);

    useClientEffect((): void => {
        dispatch(fetchBrandNavigation());
    }, []);

    if (hasFetched && !navigation) {
        return (
            <Page>
                <NotFound />
            </Page>
        );
    }

    return (
        <div>
            {children}
        </div>
    );
};
