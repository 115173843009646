import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Navigation } from '../../entities/@navigation/Navigation/Navigation';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type NavigationsState = AsyncReduxState<{
    navigations: Record<string, Navigation>;
}>;

const initialState: NavigationsState = {
    ...initialAsyncReduxState,
    navigations: {},
};

const navigationsSlice = createSlice({
    name: 'navigationsSlice',
    initialState,
    reducers: {
        setData(state, action: PayloadAction<NavigationsState>): NavigationsState {
            return {
                ...action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): NavigationsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): NavigationsState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): NavigationsState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): NavigationsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setNavigations(state, action: PayloadAction<Record<string, Navigation>>): NavigationsState {
            return {
                ...state,
                navigations: action.payload,
            };
        },
    },
});

export const {
    setData,
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setNavigations,
} = navigationsSlice.actions;

export default navigationsSlice;
