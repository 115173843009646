import { FormEvent, forwardRef, ReactElement } from 'react';

import { InputProps } from '../../../../components';
import { TextInput } from '../../../../compositions';
import { useTrans } from '../../../../hooks';

import './GlobalSearch.scss';

interface GlobalSearchProps extends Omit<InputProps, 'onInput'> {
    query: string;
    onInput: (value: string) => void;
    onSubmit: () => void;
    className?: string;
}

export const GlobalSearch = forwardRef<HTMLInputElement, GlobalSearchProps>(({
    query,
    onInput,
    onSubmit,
    className = '',
}, ref): ReactElement => {
    const trans = useTrans();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (query.length < 1) {
            return;
        }

        onSubmit();

        if (ref && 'current' in ref && ref.current) {
            ref.current.blur();
        }
    };

    return (
        <form
            enterKeyHint="search"
            onSubmit={handleSubmit}
            className={`global-search ${className}`}
        >
            <TextInput
                ref={ref}
                hideLabel
                hasUnderline
                type="search"
                inputMode="search"
                icon="search"
                label={trans('containers.searchDrawer.globalSearchLabel')}
                placeholder={trans('containers.searchDrawer.globalSearchLabel')}
                value={query}
                onChange={onInput}
                className="global-search__input"
                fieldClassName="global-search__input-field"
                iconClassName="global-search__input-icon"
            />
        </form>
    );
});
