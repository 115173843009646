import {
    FC,
    ReactElement,
    useMemo,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Button, IconButton, SortInput } from '../../compositions';
import { MeilisearchAttribute } from '../../entities/@api/Meilisearch';
import { FormOption } from '../../entities/@forms/Form/Form';
import { MeilisearchFilter, MeilisearchRangeFilter } from '../../entities/@search/Meilisearch/Meilisearch';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { useDeviceWidth, useHandleClickOutside, useTrans } from '../../hooks';
import { FilterAccordion, HighlightedFilter, RangeFilterAccordion } from './subcomponents';

import './ProductListFilters.scss';

interface ProductListFiltersProps {
    sidebarIsOpen: boolean;
    mayShowResetButton?: boolean;
    filters: MeilisearchFilter[];
    rangeFilters: MeilisearchRangeFilter[];
    highlightedFilters: MeilisearchFilter[];
    hiddenFilterAttributes?: MeilisearchAttribute[];
    resultsCount: number;
    sortOptions: FormOption[];
    onFilterSidebarToggle: (isOpen?: boolean) => void;
    onFiltersReset: () => void;
    className?: string;
}

export const ProductListFilters: FC<ProductListFiltersProps> = ({
    sidebarIsOpen,
    mayShowResetButton,
    filters,
    rangeFilters,
    highlightedFilters,
    hiddenFilterAttributes = [],
    resultsCount,
    sortOptions,
    onFilterSidebarToggle,
    onFiltersReset,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();

    const filterSearchThreshold = 36;

    const toggleButtonRef = useRef<HTMLButtonElement>(null);
    const filterSidebarRef = useRef<HTMLDivElement>(null);

    const [activeHighlightedFilterId, setActiveHighlightedFilterId] = useState<MeilisearchAttribute>();

    useHandleClickOutside([toggleButtonRef, filterSidebarRef], (): void => {
        onFilterSidebarToggle(false);
    });

    const allowedFilters = useMemo((): MeilisearchFilter[] => (
        filters.filter(filter => !hiddenFilterAttributes.includes(filter.attribute))
    ), [filters, hiddenFilterAttributes]);

    const allowedRangeFilters = useMemo((): MeilisearchRangeFilter[] => (
        rangeFilters.filter(filter => !hiddenFilterAttributes.includes(filter.attribute))
    ), [rangeFilters, hiddenFilterAttributes]);

    const allowedHighlightedFilters = useMemo((): MeilisearchFilter[] => (
        highlightedFilters.filter(filter => !hiddenFilterAttributes.includes(filter.attribute))
    ), [highlightedFilters, hiddenFilterAttributes]);

    const handleToggleHighlightedFilter = (filterAttribute: MeilisearchAttribute): void => {
        setActiveHighlightedFilterId(activeHighlightedFilterId === filterAttribute ? undefined : filterAttribute);

        if (sidebarIsOpen) {
            onFilterSidebarToggle(false);
        }
    };

    const handleToggleSidebar = (): void => {
        if (activeHighlightedFilterId) {
            setActiveHighlightedFilterId(undefined);
        }

        onFilterSidebarToggle();
    };

    const handleFiltersReset = (): void => {
        onFilterSidebarToggle(false);
        onFiltersReset();
    };

    const filterButtonClassNames = classNames('product-list-filters__filter-button', {
        'product-list-filters__filter-button--is-active': sidebarIsOpen,
    });

    const sidebarClassNames = classNames('product-list-filters__sidebar', {
        'product-list-filters__sidebar--is-active': sidebarIsOpen,
    });

    return (
        <div className={`product-list-filters ${className}`}>
            <div className="product-list-filters__wrapper">
                <div className="product-list-filters__filter-button-wrapper">
                    <IconButton
                        ref={toggleButtonRef}
                        isSmall={!isMobile}
                        icon="filter"
                        text={trans('containers.productListFilters.allFilters')}
                        iconPos={isMobile ? HorizontalAlignment.left : HorizontalAlignment.right}
                        onClick={handleToggleSidebar}
                        className={filterButtonClassNames}
                    />
                </div>

                <div className="product-list-filters__highlighted-filters-wrapper">
                    {allowedHighlightedFilters.map(highlightedFilter => {
                        const highlightedFilterIsExpanded = activeHighlightedFilterId === highlightedFilter.attribute;
                        const handleToggle = () => handleToggleHighlightedFilter(highlightedFilter.attribute);

                        return (
                            <HighlightedFilter
                                {...highlightedFilter}
                                key={highlightedFilter.attribute}
                                isExpanded={highlightedFilterIsExpanded}
                                showSearchInput={highlightedFilter.options.length > filterSearchThreshold}
                                searchInputLabel={trans('containers.productListFilters.searchPlaceholder')}
                                onToggle={handleToggle}
                            />
                        );
                    })}
                </div>

                <p className="product-list-filters__products-count">
                    {trans('containers.productListFilters.countProducts', { count: resultsCount })}
                </p>

                <SortInput
                    name="productSortingSelect"
                    options={sortOptions}
                    label={trans('containers.productListFilters.sortingLabel')}
                    placeholder={sortOptions[0].label}
                />
            </div>

            <aside
                ref={filterSidebarRef}
                inert={!sidebarIsOpen}
                className={sidebarClassNames}
            >
                <h6 className="product-list-filters__heading">
                    {trans('containers.productListFilters.allFilters')}
                </h6>

                {/* TODO: Merge rangeFilters with filters to sort all filters in sidebar based on label */}
                {allowedRangeFilters.map(rangeFilter => (
                    <RangeFilterAccordion
                        {...rangeFilter}
                        key={rangeFilter.attribute}
                    />
                ))}

                {allowedFilters.map(filter => (
                    <FilterAccordion
                        {...filter}
                        key={filter.attribute}
                    />
                ))}

                <div className="product-list-filters__button-wrapper">
                    <Button
                        isSmall
                        text={trans('containers.productListFilters.results', { count: resultsCount })}
                        onClick={handleToggleSidebar}
                        className="product-list-filters__results-button"
                    />

                    {mayShowResetButton && (
                        <Button
                            isSmall
                            text={trans('containers.productListFilters.resetFilters')}
                            onClick={handleFiltersReset}
                            className="product-list-filters__reset-button"
                        />
                    )}
                </div>
            </aside>
        </div>
    );
};
