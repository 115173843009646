import {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import {
    AccountInfoBlock,
    DateInput,
    IconButton,
    TextInput,
} from '../../../../compositions';
import { Customer } from '../../../../entities/@account/Customer/Customer';
import { FormProps } from '../../../../entities/@forms/Form/Form';
import { transformIsoStringToDate } from '../../../../helpers/date';
import { useTrans } from '../../../../hooks';

import './MyData.scss';

interface MyDataProps extends FormProps<Customer> {
    customer: Customer;
    className?: string;
}

export const MyData: FC<MyDataProps> = ({
    customer,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const [firstName, setFirstName] = useState(customer.firstName);
    const [lastName, setLastName] = useState(customer.lastName);
    const [email, setEmail] = useState(customer.email);
    const [dateOfBirth, setDateOfBirth] = useState(customer.birthday);
    const [phoneNumber, setPhoneNumber] = useState(customer.phoneNumber);

    const formattedDate = dateOfBirth
        ? transformIsoStringToDate(dateOfBirth)
        : '';

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (!isEditModeOn) {
            setIsEditModeOn(true);
            return;
        }

        const formData: Customer = {
            id: customer.id,
            firstName,
            lastName,
            email,
            birthday: formattedDate,
            phoneNumber,
            addresses: customer.addresses,
            // TODO: Add company when feature is requested (API must also be updated)
        };

        const [errors, hasErrors] = '';

        if (hasErrors) {
            console.error(errors);
            return;
        }

        onSubmit(formData);
        setIsEditModeOn(false);
    };

    return (
        <form className={`my-data ${className}`} onSubmit={handleSubmit}>
            <div className="my-data__header">
                <h6 className="my-data__heading">
                    {trans('containers.accountDashboardConfig.myData.title')}
                </h6>

                <IconButton
                    icon={isEditModeOn ? 'check' : 'edit'}
                    text={isEditModeOn ? trans('common.save') : trans('common.edit')}
                    type="submit"
                    className="my-data__edit-button"
                />
            </div>

            <div className="my-data__form">
                <section className="my-data__form-section">
                    <fieldset className="my-data__fieldset">
                        <AccountInfoBlock
                            title={trans('containers.accountDashboardConfig.myData.personalData')}
                            className="my-data__info-block"
                        >
                            <TextInput
                                label={trans('form.firstName')}
                                value={firstName}
                                disabled={!isEditModeOn}
                                onChange={setFirstName}
                                className="my-data__input"
                                labelClassName="my-data__input-label"
                                fieldClassName="my-data__input-field"
                            />

                            <TextInput
                                label={trans('form.lastName')}
                                value={lastName}
                                disabled={!isEditModeOn}
                                onChange={setLastName}
                                className="my-data__input"
                                labelClassName="my-data__input-label"
                                fieldClassName="my-data__input-field"
                            />

                            <TextInput
                                type="email"
                                inputMode="email"
                                label={trans('form.email')}
                                value={email}
                                disabled={!isEditModeOn}
                                onChange={setEmail}
                                className="my-data__input"
                                labelClassName="my-data__input-label"
                                fieldClassName="my-data__input-field"
                            />

                            <DateInput
                                label={trans('form.dateOfBirth')}
                                value={formattedDate}
                                disabled={!isEditModeOn}
                                onChange={setDateOfBirth}
                                className="my-data__input"
                                labelClassName="my-data__input-label"
                                fieldClassName="my-data__input-field"
                            />

                            <TextInput
                                type="tel"
                                inputMode="tel"
                                label={trans('form.phoneNumber')}
                                value={phoneNumber}
                                disabled={!isEditModeOn}
                                onChange={setPhoneNumber}
                                className="my-data__input"
                                labelClassName="my-data__input-label"
                                fieldClassName="my-data__input-field"
                            />
                        </AccountInfoBlock>
                    </fieldset>
                </section>

                {/* TODO: This section is now hidden. Enable this when company is available in API */}
                <section className="my-data__form-section my-data__form-section--company">
                    <fieldset className="my-data__fieldset">
                        <AccountInfoBlock
                            title={trans('form.companyName')}
                            className="my-data__info-block"
                        >
                            <TextInput
                                label={trans('form.companyName')}
                                value="TODO"
                                disabled={!isEditModeOn}
                                onChange={() => {}}
                                className="my-data__input"
                                labelClassName="my-data__input-label"
                                fieldClassName="my-data__input-field"
                            />

                            <TextInput
                                label={trans('form.vatNumber')}
                                value="TODO"
                                disabled={!isEditModeOn}
                                onChange={() => {}}
                                className="my-data__input"
                                labelClassName="my-data__input-label"
                                fieldClassName="my-data__input-field"
                            />
                        </AccountInfoBlock>
                    </fieldset>
                </section>
            </div>
        </form>
    );
};
