import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { AddressForm, Textarea } from '../../../../compositions';
import { StartPaymentFormData } from '../../../../entities/@api/Sylius';
import { PaymentStepFormData } from '../../../../entities/@forms/PaymentForm/PaymentForm';
import { PaymentMethod } from '../../../../entities/@shop/PaymentMethod/PaymentMethod';
import { Address, AddressType, CustomerAddress } from '../../../../entities/Address/Address';
import { useTrans } from '../../../../hooks';
import { PaymentMethodsList } from '../PaymentMethodsList/PaymentMethodsList';

import './PaymentStep.scss';

interface PaymentStepProps {
    addresses: CustomerAddress[];
    paymentMethods?: PaymentMethod[];
    setPaymentData: (data: PaymentStepFormData) => void;
    className?: string;
}

export const PaymentStep: FC<PaymentStepProps> = ({
    addresses,
    paymentMethods,
    setPaymentData,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
    const [commentsForDelivery, setCommentsForDelivery] = useState<string | undefined>();

    const [giftcardNumber, setGiftcardNumber] = useState<string>('');
    const [giftcardPin, setGiftcardPin] = useState<string>('');

    const [shippingAddress, setShippingAddress] = useState<Address | undefined>();
    const [billingAddress, setBillingAddress] = useState<Address | undefined>();

    useEffect((): void => {
        if (paymentMethods?.length) {
            setPaymentMethod(paymentMethods[0]);
        }
    }, [paymentMethods]);

    useEffect((): void => {
        const shippingAddressDefault = addresses.find(address => address.type === AddressType.shipping);
        const billingAddressDefault = addresses.find(address => address.type === AddressType.billing);

        if (shippingAddressDefault) {
            setShippingAddress(shippingAddressDefault);

            const address: CustomerAddress = {
                ...shippingAddressDefault,
                type: AddressType.billing,
            };

            setBillingAddress(billingAddressDefault || address);
        }
    }, [addresses]);

    const processPaymentData = (): void => {
        const giftCardNumber = paymentMethod?.isGiftCard
            ? giftcardNumber
            : undefined;

        const giftCardPin = paymentMethod?.isGiftCard
            ? giftcardPin
            : undefined;

        const payFormData: StartPaymentFormData = {
            notes: commentsForDelivery,
            paymentMethodId: Number(paymentMethod?.value),
            giftCardNumber,
            giftCardPin,
        };

        const body: PaymentStepFormData = {
            ...payFormData,
            shippingAddress: {
                number: shippingAddress?.houseNumber || '',
                postalCode: shippingAddress?.postalCode || '',
                city: shippingAddress?.city || '',
                addition: shippingAddress?.addition || '',
                street: shippingAddress?.street || '',
                country: shippingAddress?.country || '',
            },
            billingAddress: {
                number: billingAddress?.houseNumber || '',
                postalCode: billingAddress?.postalCode || '',
                city: billingAddress?.city || '',
                addition: billingAddress?.addition || '',
                street: billingAddress?.street || '',
                country: billingAddress?.country || '',
            },
        };

        setPaymentData(body);
    };

    useEffect((): void => {
        processPaymentData();
    }, [addresses, paymentMethod, commentsForDelivery, giftcardNumber, giftcardPin, shippingAddress, billingAddress]);

    return (
        <div className={`payment-step ${className}`}>
            <div>
                <h2 className="payment-step__heading">
                    {trans('containers.checkout.paymentStep.heading')}
                </h2>

                <p className="payment-step__sub-heading">
                    {trans('containers.checkout.paymentStep.subHeading')}
                </p>
            </div>

            <div className="payment-step__addresses">
                {shippingAddress && (
                    <>
                        <p className="payment-step__addresses-heading">
                            {trans(`entities.address.type.${AddressType.shipping}`)}
                        </p>

                        <AddressForm
                            hideTypeButtons
                            address={shippingAddress}
                            onSubmit={setShippingAddress}
                            className="payment-step__address"
                        />
                    </>
                )}

                {billingAddress && (
                    <>
                        <p className="payment-step__addresses-heading">
                            {trans(`entities.address.type.${AddressType.billing}`)}
                        </p>

                        <AddressForm
                            hideTypeButtons
                            address={billingAddress}
                            onSubmit={setBillingAddress}
                            className="payment-step__address"
                        />
                    </>
                )}
            </div>

            <Textarea
                label={trans('containers.checkout.paymentStep.commentsForDelivery')}
                rows={3}
                value={commentsForDelivery}
                onChange={setCommentsForDelivery}
                className="payment-step__textarea"
                fieldClassName="payment-step__textarea-field"
            />

            <div className="payment-step__payment-methods-wrapper">
                <p className="payment-step__payment-methods-heading">
                    {trans('containers.checkout.paymentStep.choosePaymentMethod')}
                </p>

                {paymentMethods?.length && paymentMethod && (
                    <PaymentMethodsList
                        paymentMethods={paymentMethods}
                        paymentMethod={paymentMethod}
                        giftcardPin={giftcardPin}
                        setPaymentMethod={setPaymentMethod}
                        setGiftcardNumber={setGiftcardNumber}
                        setGiftcardPin={setGiftcardPin}
                        className="payment-step__payment-methods"
                    />
                )}
            </div>
        </div>
    );
};
