import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { ProductCategoryRenderer } from '../../../containers';
import { ProductCategoryParams } from '../../../entities/@products/ProductCategory/ProductCategory';
import { useClientEffect } from '../../../hooks';
import { fetchProductCategoryPageData } from '../../../redux/@products/productCategory/productCategoryActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedProductCategoryRendererProps {
    className?: string;
}

export const ConnectedProductCategoryRenderer: FC<ConnectedProductCategoryRendererProps> = ({
    className = '',
}): ReactElement => {
    const categoryParams = useParams<ProductCategoryParams>();

    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.productCategorySlice.isLoading);
    const breadcrumbs = useTypedSelector(state => state.productCategorySlice.breadcrumbs);
    const activeProductCategory = useTypedSelector(state => state.productCategorySlice.activeProductCategory);
    const pageData = useTypedSelector(state => state.productCategorySlice.pageData);

    useClientEffect((): void => {
        const { slug = '', subCategory, subSubCategory } = categoryParams;

        if (!slug) {
            return;
        }

        dispatch(fetchProductCategoryPageData({ slug, subCategory, subSubCategory }));
    }, [categoryParams]);

    return (
        <ProductCategoryRenderer
            isLoading={isLoading}
            breadcrumbs={breadcrumbs}
            activeProductCategory={activeProductCategory}
            pageData={pageData}
            className={className}
        />
    );
};
