import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { ProductPriceInterface } from '../../entities/Price/Price';
import { convertNumberToCurrency } from '../../helpers/number';

import './ProductPrice.scss';

interface ProductPriceProps extends Omit<ProductPriceInterface, 'discount'> {
    isCents?: boolean;
    includeCurrencySymbol?: boolean;
    discount?: boolean;
    className?: string;
    oldPriceClassName?: string;
}

export const ProductPrice: FC<ProductPriceProps> = ({
    original,
    current,
    discount,
    isCents = false,
    includeCurrencySymbol = false,
    className = '',
    oldPriceClassName = '',
}): ReactElement => {
    const originalPriceClassNames = classNames('product-price__price', {
        'product-price__price--is-old ': discount,
    }, oldPriceClassName);

    const currentPriceClassNames = classNames('product-price__price', {
        'product-price__price--is-sale ': discount,
    });

    return (
        <p className={`product-price ${className}`}>
            {discount && (
                <span className={originalPriceClassNames}>
                    {convertNumberToCurrency(original, {
                        includeCurrencySymbol,
                        isCents,
                    })}
                </span>
            )}

            <span className={currentPriceClassNames}>
                {convertNumberToCurrency(current, {
                    includeCurrencySymbol,
                    isCents,
                })}
            </span>
        </p>
    );
};
