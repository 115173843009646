import { convertPriceInCentsToEuros } from '../../../helpers/number';
import { MeilisearchAttribute, MeilisearchProductResource } from '../../@api/Meilisearch';
import { getFilterAttributeKey } from '../../@search/Meilisearch/MeilisearchTransformers';
import { transformMeilisearchBrandNameToLink } from '../../Brand/BrandTransformers';
import { transformMeilisearchImagesToMediaItems } from '../../Media/MediaTransformers';
import { ProductPriceInterface } from '../../Price/Price';
import { ProductDefault } from '../ProductDefault/ProductDefault';
import { ProductVariant } from '../ProductVariant/ProductVariant';
import { ProductVariantColor } from '../ProductVariantColor/ProductVariantColor';
import { Product, productVariantSeparator } from './Product';

export const transformSlugToVariantId = (slug: string): string => slug.split(productVariantSeparator)[1];

export const transformToProductCode = (product: string): string => product.split('/api/v2/shop/products/')[1];

export const transformToVariantCode = (product: string): string => product.split('/api/v2/shop/product-variants/')[1];

export const transformProductHitToProduct = (resource: MeilisearchProductResource): Product => {
    const variantSlug = resource.slug + productVariantSeparator + resource.code;

    const brand = resource.brand
        ? transformMeilisearchBrandNameToLink(resource.brand)
        : undefined;

    const mediaItems = resource.main_image && resource.image
        ? transformMeilisearchImagesToMediaItems(resource)
        : [];

    const defaultPrice: ProductPriceInterface = {
        original: convertPriceInCentsToEuros(resource.price.original_price || 0),
        current: convertPriceInCentsToEuros(resource.price.original_price || 0),
        discount: false,
    };

    const discountInPercentage = resource.price.discount?.is_percentage
        ? convertPriceInCentsToEuros(resource.price.discount.value)
        : undefined;

    const price: ProductPriceInterface = resource.price.discount
        ? {
            ...defaultPrice,
            current: convertPriceInCentsToEuros(resource.price.price),
            discount: true,
            discountInPercentage,
        }
        : defaultPrice;

    const colorAttributeKey = getFilterAttributeKey(MeilisearchAttribute.color);
    const colorAttribute = resource.attributes[colorAttributeKey];
    const colors = colorAttribute
        ? Object.values(colorAttribute as ProductVariantColor[])
        : [];

    return {
        id: resource.id.toString(),
        slug: resource.slug,
        name: resource.article_title || '',
        productCode: resource.code,
        shortDescription: resource.short_description || '',
        description: resource.description || '',
        variantId: resource.id,
        variantCode: resource.code,
        brand,
        mediaItems,
        price,
        attributes: [],
        dimensionAttributes: [],
        colors,
        variants: resource.variants || 0,
        variantSlug,
        stock: resource.stock,
        categories: resource.categories,
    };
};

export const transformToProduct = (
    productDefault: ProductDefault,
    productVariant: ProductVariant,
    categories: string[],
): Product => ({
    id: productDefault.id,
    slug: productDefault.slug || '',
    name: productVariant.name || '',
    shortDescription: productDefault.shortDescription || '',
    description: productDefault.description || '',
    mediaItems: productDefault.mediaItems,
    price: productVariant.price,
    attributes: productVariant.attributes,
    dimensionAttributes: productVariant.dimensionAttributes,
    brand: productVariant.brand,
    colors: productVariant.colors,
    variantCode: productVariant.variantCode,
    variantId: productVariant.variantId,
    variantSlug: productDefault.variantSlug,
    variants: productDefault.variants,
    stock: productVariant.stock,
    categories,
    productCode: productDefault.productCode,
    availableInTime: productVariant.availableInTime,
});
