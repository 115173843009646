import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../components';
import { WayfindingType } from '../../entities/@navigation/Wayfinding/Wayfinding';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { Space } from '../../entities/Space/Space';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import { useTrans } from '../../hooks';
import { WayfindingWrapper } from '..';

import './SpaceCard.scss';

export interface SpaceCardProps extends Space {
    className?: string;
}

export const SpaceCard: FC<SpaceCardProps> = ({
    id,
    slug,
    title,
    image,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const spaceDetailPath = trans(appRoutes[AppRoute.spaceDetail].path);
    const spaceDetailUrl = replaceUrlParamKeysWithValues(spaceDetailPath, {
        slug,
    });

    return (
        <div className={`space-card ${className}`}>
            <WayfindingWrapper
                id={id}
                type={WayfindingType.enter}
                label={trans('common.view')}
                className="space-card__wayfinding-wrapper"
                iconPathClassName="space-card__wayfinding-icon-path"
            >
                <Link to={spaceDetailUrl}>
                    <div className="space-card__image-wrapper">
                        <Picture
                            {...image}
                            className="space-card__picture"
                            imageClassName="space-card__image"
                        />
                    </div>
                </Link>
            </WayfindingWrapper>

            <div className="space-card__text-wrapper">
                <h3 className="space-card__title">
                    {title}
                </h3>
            </div>
        </div>
    );
};
