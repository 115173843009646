import { FC, ReactElement, useMemo } from 'react';

import { PageSearchResults } from '../../containers';
import { MeilisearchStoreKey } from '../../entities/@search/Meilisearch/Meilisearch';
import { PageSearchResult } from '../../entities/@search/PageSearchResult/PageSearchResult';
import { useClientEffect } from '../../hooks';
import { fetchMeilisearchPages } from '../../redux/meilisearch/meilisearchActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedPageSearchResultsProps {
    storeKey: MeilisearchStoreKey;
    query: string;
    className?: string;
}

export const ConnectedPageSearchResults: FC<ConnectedPageSearchResultsProps> = ({
    storeKey,
    query,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoadingPages = useTypedSelector(state => state.meilisearchSlice.isLoadingPages);
    const pageResponses = useTypedSelector(state => state.meilisearchSlice.pageResponses);

    const pageResults = useMemo((): PageSearchResult[] => {
        const pageResponse = pageResponses[storeKey];

        return pageResponse
            ? pageResponse.results
            : [];
    }, [pageResponses]);

    useClientEffect((): void => {
        dispatch(fetchMeilisearchPages({
            key: storeKey,
            query,
        }));
    }, [query]);

    return (
        <PageSearchResults
            isLoading={isLoadingPages}
            pages={pageResults}
            className={className}
        />
    );
};
