import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../../components';
import { Button } from '../../../compositions';
import { ConnectedBlockRenderer, ConnectedPageHelmet } from '../../../connectors';
import { useCookieSettings, useCookieSettingsDispatch } from '../../../context';
import { AppRoute } from '../../../entities/Routing/Routing';
import { useTrans } from '../../../hooks';

import './LegalCookiesPage.scss';

export const LegalCookiesPage: FC = (): ReactElement => {
    const trans = useTrans();

    const dispatchCookieSettings = useCookieSettingsDispatch();
    const cookieSettings = useCookieSettings();

    const handleOpenCookieSettings = (): void => {
        dispatchCookieSettings({
            ...cookieSettings,
            cookieSettingsVisible: true,
        });
    };

    return (
        <Page className="legal-cookies-page">
            <ConnectedPageHelmet appRoute={AppRoute.legalCookies} />
            <ConnectedBlockRenderer appRoute={AppRoute.legalCookies} />

            <Wrapper className="legal-cookies-page__cookie-settings-wrapper">
                <Button
                    text={trans('pages.legalCookies.openCookieSettings')}
                    onClick={handleOpenCookieSettings}
                    className="legal-cookies-page__cookie-settings-button"
                />
            </Wrapper>
        </Page>
    );
};
