import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Icon, Wrapper } from '../../components';
import { Navigation } from '../../entities/@navigation/Navigation/Navigation';
import { LinkTarget } from '../../entities/Link/Link';
import { FooterSkeletons } from './skeletons';
import { FooterBottom } from './subcomponents';

import './Footer.scss';

interface FooterProps {
    isLoading?: boolean;
    showTopFooter?: boolean;
    navigation?: Navigation;
    socialNavigation?: Navigation;
    className?: string;
}

export const Footer: FC<FooterProps> = ({
    isLoading,
    showTopFooter,
    navigation,
    socialNavigation,
    className = '',
}): ReactElement => {
    if (isLoading || !navigation) {
        return (
            <footer className={`footer ${className}`}>
                <FooterSkeletons />

                <FooterBottom />
            </footer>
        );
    }

    const navigationGroups = navigation.groups[0]
        ? navigation.groups[0].items
        : [];

    return (
        <footer className={`footer ${className}`}>
            {showTopFooter && (
                <Wrapper className="footer__wrapper">
                    {navigationGroups.map(navigationGroup => (
                        <nav
                            key={navigationGroup.id}
                            aria-label={navigationGroup.label}
                            className="footer__nav"
                        >
                            <h2 className="footer__nav-title">
                                {navigationGroup.label}
                            </h2>

                            {navigationGroup.subNavigation?.groups.map(subNavigationGroup => (
                                <ul key={subNavigationGroup.id} className="footer__nav-list">
                                    {subNavigationGroup.items.map(item => {
                                        const navItemClassName = classNames('footer__nav-item', {
                                            'footer__nav-item--with-icon': item.icon,
                                        });

                                        return (
                                            <li key={item.id} className={navItemClassName}>
                                                {item.icon ? (
                                                    <Link to={item.to} className="footer__nav-link">
                                                        <Icon name={item.icon} className="footer__nav-link-icon" />
                                                    </Link>
                                                ) : (
                                                    <Link to={item.to} className="footer__nav-link">
                                                        {item.label}
                                                    </Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            ))}
                        </nav>
                    ))}

                    {socialNavigation && (
                        <nav
                            key={socialNavigation.id}
                            aria-label={socialNavigation.title}
                            className="footer__nav"
                        >
                            <h2 className="footer__nav-title">
                                {socialNavigation.title}
                            </h2>

                            {socialNavigation.groups.map(navigationGroup => (
                                <ul key={navigationGroup.id} className="footer__nav-list">
                                    {navigationGroup.items.map(item => {
                                        const itemClassName = classNames('footer__nav-item', {
                                            'footer__nav-item--with-icon': item.icon,
                                        });

                                        return (
                                            <li key={item.id} className={itemClassName}>
                                                {item.icon ? (
                                                    <Link
                                                        to={item.to}
                                                        aria-label={item.label}
                                                        target={LinkTarget.blank}
                                                        className="footer__nav-link"
                                                    >
                                                        <Icon name={item.icon} className="footer__nav-link-icon" />
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        to={item.to}
                                                        aria-label={item.label}
                                                        target={LinkTarget.blank}
                                                        className="footer__nav-link"
                                                    >
                                                        {item.label}
                                                    </Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            ))}
                        </nav>
                    )}
                </Wrapper>
            )}

            <FooterBottom />
        </footer>
    );
};
