import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { ProductCollection } from '../../containers';
import { MeilisearchAttribute, MeilisearchIndex } from '../../entities/@api/Meilisearch';
import { ProductCollectionInterface } from '../../entities/@blocks/ProductCollection/ProductCollection';
import { Product } from '../../entities/@products/Product/Product';
import { MeilisearchStoreKey } from '../../entities/@search/Meilisearch/Meilisearch';
import { generateMeilisearchFilterQuery } from '../../entities/@search/Meilisearch/MeilisearchRequests';
import { fetchMeilisearchVariants } from '../../redux/meilisearch/meilisearchActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export interface ConnectedProductCollectionProps extends ProductCollectionInterface {
    className?: string;
}

export const ConnectedProductCollection: FC<ConnectedProductCollectionProps> = ({
    className = '',
    ...productCollectionProps
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const searchLimit = 3;

    const storeKey = MeilisearchStoreKey.productCollection + productCollectionProps.id as MeilisearchStoreKey;

    const variantResponses = useTypedSelector(state => state.meilisearchSlice.variantResponses);

    useEffect((): void => {
        const filter = generateMeilisearchFilterQuery(MeilisearchAttribute.lists, productCollectionProps.meilisearchProductListName);

        dispatch(fetchMeilisearchVariants({
            key: storeKey,
            index: MeilisearchIndex.variantsNl,
            pageSize: searchLimit,
            filter,
        }));
    }, []);

    const products = useMemo((): Product[] => {
        const variantResponse = variantResponses[storeKey];

        return variantResponse
            ? variantResponse.results.slice(0, searchLimit)
            : [];
    }, [variantResponses]);

    return (
        <ProductCollection
            {...productCollectionProps}
            products={products.slice(0, 3)}
            className={className}
        />
    );
};
