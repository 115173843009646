import { FC, ReactElement } from 'react';

import { Icon, ProductPrice } from '../../../../components';
import { Accordion, Button, TooltipWrapper } from '../../../../compositions';
import { AddCouponCodeToOrderFormData } from '../../../../entities/@forms/CouponCodeForm/CouponCodeForm';
import { PriceBaseInterface } from '../../../../entities/Price/Price';
import { useTrans } from '../../../../hooks';
import { CouponCodeForm } from '../../../@forms/CouponForm/CouponCodeForm';

import './Pricing.scss';

interface PricingProps extends PriceBaseInterface {
    onSetCouponCode: (data: AddCouponCodeToOrderFormData) => void;
    onRemoveCouponCode: () => void;
    className?: string;
}

export const Pricing: FC<PricingProps> = ({
    onSetCouponCode,
    onRemoveCouponCode,
    subTotalPrice,
    totalPrice,
    discounts,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`pricing ${className}`}>
            <div className="pricing__price-item">
                <p>
                    {trans('containers.checkout.pricing.subTotal')}
                </p>

                <ProductPrice
                    isCents
                    includeCurrencySymbol
                    current={subTotalPrice}
                    original={subTotalPrice}
                    className="pricing__price"
                />
            </div>

            {discounts.map(discount => (
                <div key={discount.id} className="pricing__price-item">
                    <p>
                        {discount.label && (
                            <span className="pricing__discount-label">
                                {discount.label}
                            </span>
                        )}
                    </p>

                    {discount.invalid && (
                        <TooltipWrapper
                            id="incorrect-discountCode-tooltip"
                            text={trans('containers.checkout.pricing.incorrectDiscountCode')}
                            className="pricing__tooltip-wrapper"
                            tooltipClassName="pricing__tooltip"
                        >
                            <Icon name="box-warning" className="pricing__tooltip-icon" />
                        </TooltipWrapper>
                    )}

                    <ProductPrice
                        isCents
                        includeCurrencySymbol
                        current={discount.value}
                        original={discount.value}
                        className="pricing__price pricing__price--is-sale"
                    />

                    {!discount.articleDiscount && (
                        <Button
                            text={trans('common.delete')}
                            onClick={onRemoveCouponCode}
                            className="pricing__remove-discount-button"
                        />
                    )}
                </div>
            ))}

            <Accordion
                header={trans('containers.checkout.pricing.discountCode')}
                className="pricing__accordion"
                headingClassName="pricing__accordion-heading"
                contentClassName="pricing__accordion-content"
            >
                <CouponCodeForm
                    onSubmit={onSetCouponCode}
                    className="pricing__coupon-form"
                />
            </Accordion>

            <div className="pricing__price-item">
                <p>
                    {trans('containers.checkout.pricing.shippingCosts')}
                </p>

                {/* @TODO: Add real shipping costs (when available) */}
                <p className="pricing__price">
                    {trans('common.free')}
                </p>
            </div>

            <div className="pricing__price-item">
                <p className="pricing__price-label-total">
                    {trans('containers.checkout.pricing.total')}
                </p>

                <ProductPrice
                    isCents
                    includeCurrencySymbol
                    current={totalPrice}
                    original={totalPrice}
                    className="pricing__price-total"
                />
            </div>
        </div>
    );
};
