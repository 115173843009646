import { transformToBlogPost } from '../../@blog/BlogPost/BlogPostTransformers';
import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { BlogsSelectionBlockInterface, BlogsSelectionBlockResource } from './BlogsSelection';

export const transformToBlogsSelection = (resource: BlogsSelectionBlockResource): BlogsSelectionBlockInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const blogs = resource.blogs.length
        ? resource.blogs.map(transformToBlogPost)
        : [];

    return {
        ...defaultBlock,
        type: BlockType.blogsSelection,
        title: resource.title || undefined,
        blogPosts: blogs,
    };
};
