import trans from '../../../helpers/trans';
import { MeilisearchAttribute, MeilisearchSortingDirection } from '../../@api/Meilisearch';
import { FormOption } from '../../@forms/Form/Form';

export const productSortingOptions: FormOption[] = [
    {
        label: trans('entities.productSorting.recommended'),
        value: '',
    },
    {
        label: trans('entities.productSorting.new'),
        value: `${MeilisearchAttribute.isNew}:${MeilisearchSortingDirection.descending}`,
    },
    {
        label: trans('entities.productSorting.priceAsc'),
        value: `${MeilisearchAttribute.price}:${MeilisearchSortingDirection.ascending}`,
    },
    {
        label: trans('entities.productSorting.priceDesc'),
        value: `${MeilisearchAttribute.price}:${MeilisearchSortingDirection.descending}`,
    },
    {
        label: trans('entities.productSorting.highestDiscount'),
        value: `${MeilisearchAttribute.priceDiscount}:${MeilisearchSortingDirection.ascending}`,
    },
    {
        label: trans('entities.productSorting.bestRating'),
        value: `${MeilisearchAttribute.ranking}:${MeilisearchSortingDirection.ascending}`,
    },
];
