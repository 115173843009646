import { MeilisearchAttribute, MeilisearchFilterOperator } from '../@api/Meilisearch';
import { generateMeilisearchFilterQuery } from '../@search/Meilisearch/MeilisearchRequests';

const ownCollectionBrands = ['Loods 5', 'Loods 5 Kunst', 'Loods 5 Design'];

export const generateOwnCollectionMeilisearchFilterQuery = (categoryName?: string): string => {
    const brandBaseFilter = generateMeilisearchFilterQuery(MeilisearchAttribute.brand, ownCollectionBrands);

    const categoryBaseFilter = categoryName
        ? generateMeilisearchFilterQuery(MeilisearchAttribute.categories, categoryName)
        : '';

    return categoryBaseFilter
        ? `(${brandBaseFilter}) ${MeilisearchFilterOperator.and} (${categoryBaseFilter})`
        : brandBaseFilter;
};
