import { RefObject, useEffect, useState } from 'react';

interface Size {
    width: number;
    height: number;
}

export const useElementSize = (ref: RefObject<Element | null>, deps = [] as unknown[]): Size => {
    const [size, setSize] = useState<Size>({ width: 0, height: 0 });

    let resizeObserver: ResizeObserver;

    useEffect((): () => void => {
        resizeObserver = new ResizeObserver((entries): void => {
            for (let i = 0; i < entries.length; i += 1) {
                const entry = entries[i];

                const { width, height } = entry.target.getBoundingClientRect();

                setSize({ width, height });
            }
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return (): void => {
            resizeObserver.disconnect();
        };
    }, deps ? [ref, ...deps] : [ref]);

    return size;
};
