import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
    validateRequiredString,
} from '../../../../entities/@forms/FormValidation/FormValidationService';
import { NewsletterFormData } from '../../../../entities/@forms/NewsletterForm/NewsletterForm';

export type NewsletterFormErrors = FormErrors<NewsletterFormData>;

export const validateNewsletterFormData = (formData: NewsletterFormData): FormValidation<NewsletterFormErrors> => {
    const errors: NewsletterFormErrors = {
        firstName: validateRequiredString('firstName', formData.firstName),
        email: validateEmailAddress(formData.email),
    };

    return validateForm<NewsletterFormErrors>(errors);
};
