import { FC, ReactElement } from 'react';

import { Product } from '../../../../../entities/@products/Product/Product';
import { ProductMediaPresentation } from '../ProductMediaPresentation/ProductMediaPresentation';
import { ProductSidebar } from '../ProductSidebar/ProductSidebar';

import './ProductDetail.scss';

interface ProductDetailProps {
    cartIsLoading: boolean;
    wishlistIsLoading: boolean;
    product: Product;
    className?: string;
}

export const ProductDetail: FC<ProductDetailProps> = ({
    cartIsLoading,
    wishlistIsLoading,
    product,
    className = '',
}): ReactElement => (
    <section className={`product-detail ${className}`}>
        <ProductMediaPresentation
            mediaItems={product.mediaItems}
            className="product-detail__media-presentation"
        />

        <ProductSidebar
            cartIsLoading={cartIsLoading}
            wishlistIsLoading={wishlistIsLoading}
            product={product}
            className="product-detail__sidebar"
        />
    </section>
);
