import { FC, ReactElement } from 'react';

import { Wrapper } from '../../components';
import { PageSearchResult } from '../../entities/@search/PageSearchResult/PageSearchResult';
import { PageSearchResultsSkeletons } from './skeletons';
import { PageSearchResultItem } from './subcomponents';

import './PageSearchResults.scss';

interface PageSearchResultsProps {
    isLoading?: boolean;
    pages: PageSearchResult[];
    className?: string;
}

export const PageSearchResults: FC<PageSearchResultsProps> = ({
    isLoading,
    pages,
    className = '',
}): ReactElement => (
    <div className={`page-search-results ${className}`}>
        <Wrapper className="page-search-results__wrapper">
            {isLoading && (
                <PageSearchResultsSkeletons numberOfSkeletons={5} />
            )}

            {!isLoading && (
                <ul className="page-search-results__list">
                    {pages.map(page => (
                        <PageSearchResultItem
                            {...page}
                            key={page.id}
                        />
                    ))}
                </ul>
            )}
        </Wrapper>
    </div>
);
