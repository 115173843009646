import { strapiFetch } from '../../../entities/@api/Strapi';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { StoreResponse } from '../../../entities/Store/Store';
import { generateStoreQuery } from '../../../entities/Store/StoreRequest';
import { transformToStore } from '../../../entities/Store/StoreTransformers';
import { ReduxFetchAction } from '../../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setStore,
} from './storeSlice';

export const fetchStoreDetail: ReduxFetchAction<string> = slug => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));
    dispatch(setStore(undefined));

    try {
        const queryString = generateStoreQuery(slug);
        const storeResponse = await strapiFetch<StoreResponse>(`/shops?${queryString}`);

        if (!isFetchResultSuccessful(storeResponse)) {
            dispatch(setError(storeResponse.error));
            return;
        }

        const { data: storesData } = storeResponse.data;

        const storeResource = storesData
            ? storesData[0]
            : undefined;

        const store = storeResource
            ? transformToStore(storeResource)
            : undefined;

        dispatch(setStore(store));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchStoreDetail]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};
