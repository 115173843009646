import { RefObject, useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCombinedRefs = <T>(...refs: any[]): RefObject<T | null> => {
    const targetRef = useRef<T>(null);

    useEffect((): void => {
        for (let i = 0; i < refs.length; i += 1) {
            if (!refs[i]) {
                return;
            }

            if (typeof refs[i] === 'function') {
                refs[i](targetRef.current);
            } else {
                // eslint-disable-next-line no-param-reassign
                refs[i].current = targetRef.current;
            }
        }
    }, [refs]);

    return targetRef;
};
