import {
    GetProductTaxonResponse,
    GetTaxonParentsResponse,
    GetTaxonResponse,
    syliusFetch,
} from '../../@api/Sylius';
import { isFetchResultSuccessful } from '../../FetchResult/FetchResult';
import { ProductTaxonEntry, Taxon, TaxonWithParents } from './Taxon';
import { transformToTaxon, transformToTaxonParent } from './TaxonTransformers';

export const fetchTaxon = async (taxon: string): Promise<Taxon | undefined> => {
    const endpoint = taxon.replace('/api/v2', '');
    const taxonResponse = await syliusFetch<GetTaxonResponse>(endpoint);

    if (!isFetchResultSuccessful(taxonResponse)) {
        return undefined;
    }

    const taxonResource = taxonResponse.data;

    return transformToTaxon(taxonResource);
};

export const fetchTaxonWithParents = async (mainTaxon: string): Promise<TaxonWithParents | undefined> => {
    const code = mainTaxon.replace('/api/v2/shop/taxons/', '');
    const taxonParentsResponse = await syliusFetch<GetTaxonParentsResponse>(`/shop/taxon_parents/${code}`);

    if (!isFetchResultSuccessful(taxonParentsResponse)) {
        return undefined;
    }

    const taxonParentsResource = taxonParentsResponse.data;

    const taxon = transformToTaxon(taxonParentsResource);

    const parents = taxonParentsResource.parents
        ? taxonParentsResource.parents.map(transformToTaxonParent)
        : [];

    return {
        ...taxon,
        parents,
    };
};

export const fetchProductTaxonEntry = async (productTaxon: string): Promise<ProductTaxonEntry | undefined> => {
    const id = productTaxon.replace('/api/v2/shop/product-taxons/', '');
    const endpoint = productTaxon.replace('/api/v2', '');

    const productTaxonResponse = await syliusFetch<GetProductTaxonResponse>(endpoint);

    if (!isFetchResultSuccessful(productTaxonResponse)) {
        return undefined;
    }

    const productTaxonResource = productTaxonResponse.data;
    const category = productTaxonResource.taxon.replace('/api/v2/shop/taxons/', '');

    return [id, category];
};
