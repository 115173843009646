import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PageInterface } from '../../../entities/Page/Page';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type CustomPageState = AsyncReduxState<{
    pageData?: PageInterface;
}>;

const initialState: CustomPageState = {
    ...initialAsyncReduxState,
};

const customPageSlice = createSlice({
    name: 'customPageSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CustomPageState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): CustomPageState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CustomPageState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CustomPageState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setPageData(state, action: PayloadAction<PageInterface | undefined>): CustomPageState {
            return {
                ...state,
                pageData: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setPageData,
} = customPageSlice.actions;

export default customPageSlice;
