import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Product } from '../../entities/@products/Product/Product';
import { MeilisearchSearchResponse, MeilisearchStoreKey } from '../../entities/@search/Meilisearch/Meilisearch';
import { PageSearchResult } from '../../entities/@search/PageSearchResult/PageSearchResult';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

type MeilisearchVariantResponse = Partial<Record<MeilisearchStoreKey, MeilisearchSearchResponse<Product>>>;
type MeilisearchPageResponse = Partial<Record<MeilisearchStoreKey, MeilisearchSearchResponse<PageSearchResult>>>;

export type MeilisearchState = AsyncReduxState<{
    isLoadingVariants: boolean;
    isLoadingPages: boolean;
    variantResponses: MeilisearchVariantResponse;
    pageResponses: MeilisearchPageResponse;
}>;

const initialState: MeilisearchState = {
    ...initialAsyncReduxState,
    isLoadingVariants: false,
    isLoadingPages: false,
    variantResponses: {},
    pageResponses: {},
};

const meilisearchSlice = createSlice({
    name: 'meilisearchSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): MeilisearchState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsLoadingVariants(state, action: PayloadAction<boolean>): MeilisearchState {
            return {
                ...state,
                isLoadingVariants: action.payload,
            };
        },
        setIsLoadingPages(state, action: PayloadAction<boolean>): MeilisearchState {
            return {
                ...state,
                isLoadingPages: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): MeilisearchState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): MeilisearchState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): MeilisearchState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setVariantResponses(state, action: PayloadAction<MeilisearchVariantResponse>): MeilisearchState {
            return {
                ...state,
                variantResponses: action.payload,
            };
        },
        setPageResponses(state, action: PayloadAction<MeilisearchPageResponse>): MeilisearchState {
            return {
                ...state,
                pageResponses: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsLoadingVariants,
    setIsLoadingPages,
    setHasFetched,
    setIsSuccessful,
    setError,
    setVariantResponses,
    setPageResponses,
} = meilisearchSlice.actions;

export default meilisearchSlice;
