import trans from '../../../helpers/trans';
import { filterAttributePrefix, MeilisearchFilter } from './Meilisearch';

export const sortOnMeilisearchAttribute = (filter: MeilisearchFilter, comparisonFilter: MeilisearchFilter): number => {
    const label = !filter.attribute.startsWith(filterAttributePrefix)
        ? trans(`entities.meilisearch.customAttributes.${filter.attribute}`)
        : filter.attribute;

    const comparisonLabel = !comparisonFilter.attribute.startsWith(filterAttributePrefix)
        ? trans(`entities.meilisearch.customAttributes.${comparisonFilter.attribute}`)
        : filter.attribute;

    return label.localeCompare(comparisonLabel);
};
