import { convertPriceInCentsToEuros } from '../../../helpers/number';
import { generateUuid, transformToSlug } from '../../../helpers/string';
import {
    OrderItemResource,
    OrderLine,
    OrderResource,
    PricesResource,
} from '../../@api/Sylius';
import { productVariantSeparator } from '../../@products/Product/Product';
import { transformToVariantCode } from '../../@products/Product/ProductTransformers';
import { Address, AddressType } from '../../Address/Address';
import { transformToCustomerAddress } from '../../Address/AddressTransformers';
import { ProductPriceInterface } from '../../Price/Price';
import { calculateDiscounts } from '../../Price/PriceTransformers';
import { CartInterface, CartItem } from './Cart';

export const transformToCartItem = (resource: OrderItemResource, lines: OrderLine[] = []): CartItem => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const variantId = transformToVariantCode(resource.variant);

    const pricing = lines.find(line => line.variant === variantId);

    const formattedName = resource.productName
        ? transformToSlug(resource.productName)
        : '';

    const slug = formattedName + productVariantSeparator + variantId;

    const defaultPrice: ProductPriceInterface = {
        original: convertPriceInCentsToEuros(pricing?.price.original || 0),
        current: convertPriceInCentsToEuros(pricing?.price.original || 0),
        discount: false,
    };

    const price: ProductPriceInterface = pricing?.discount
        ? {
            ...defaultPrice,
            current: convertPriceInCentsToEuros(pricing.price.payable),
            discount: true,
        }
        : defaultPrice;

    return {
        id,
        variantId,
        slug,
        name: resource.productName || '',
        quantity: resource.quantity,
        price,
    };
};

export const transformToCart = (resource: OrderResource, prices?: PricesResource): CartInterface => {
    const items = resource.items && prices?.lines
        ? resource.items.map(item => transformToCartItem(item, prices.lines))
        : [];

    const addresses: Address[] = [];

    if (resource.shippingAddress) {
        const address = transformToCustomerAddress(resource.shippingAddress, AddressType.shipping);
        addresses.push(address);
    }

    if (resource.billingAddress) {
        const address = transformToCustomerAddress(resource.billingAddress, AddressType.billing);
        addresses.push(address);
    }

    const discounts = prices?.discounts
        ? calculateDiscounts(prices)
        : [];

    const totalPrice = prices?.price
        ? prices.price.stillToBePaid
        : 0;

    return {
        id: resource.tokenValue || generateUuid(),
        items,
        subTotalPrice: prices?.price?.original || 0,
        totalPrice,
        discounts,
        addresses,
    };
};
