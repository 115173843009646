import { FC, ReactElement } from 'react';

import { PageSearchResult } from '../../../../entities/@search/PageSearchResult/PageSearchResult';
import { Store } from '../../../../entities/Store/Store';
import { useTrans } from '../../../../hooks';
import { PageSuggestions, StoreSuggestions } from '..';

import './SearchSuggestions.scss';

interface SearchSuggestionsProps {
    stores: Store[];
    pageSuggestions: PageSearchResult[];
    onLinkClick: () => void;
    className?: string;
}

export const SearchSuggestions: FC<SearchSuggestionsProps> = ({
    pageSuggestions,
    stores,
    onLinkClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`search-suggestions ${className}`}>
            <PageSuggestions
                label={trans('containers.searchDrawer.pageSuggestions')}
                pages={pageSuggestions}
                onLinkClick={onLinkClick}
                className="search-suggestions__page-suggestions"
            />

            {stores.length > 0 && (
                <StoreSuggestions
                    stores={stores}
                    onLinkClick={onLinkClick}
                />
            )}
        </div>
    );
};
