interface Order {
    id: string;
    token: string;
}

export enum DiscountTypeResource {
    combination = 'combination', // Having a number of articles in the cart results in getting another article for free.
    credit = 'credit', // Raw discount value: percentage or fixed discount.
    default = 'default', // Default discount type.
    scale = 'scale', // The higher the quantity ordered, the lower the cost ('Staffelkorting').
    unknown = 'unknown', // Fall-back value when type could not be determined.
    xForY = 'x-for-y', // Buy X for the price of Y.
    xForYCombination = 'x-for-y-combination', // combination and x-for-y combined: buy X for the price of Y and get a free article as well.
}

export interface DiscountResource {
    erpId: number;
    label: string;
    type: DiscountTypeResource;
    isPercentage: boolean;
    value: number;
    actualEffect: number;
    isOrderLevel: boolean;
}

interface Price {
    discount: number;
    original: number;
    payable: number;
    payableVat: number;
    stillToBePaid: number;
}

interface Stock {
    count: number;
    isInStock: boolean;
}

interface OrderLinePrice {
    discount: number;
    original: number;
    payable: number;
}

export interface OrderLine {
    variant: string;
    price: OrderLinePrice;
    discount: DiscountResource;
    stock: Stock;
}

export interface DiscountCodeWarnings {
    discountCodeHasNoEffect?: boolean;
    discountValueNotFullyUsed?: boolean;
}

export interface PricesResource {
    order: Order;
    price: Price;
    lines: OrderLine[];
    discounts: DiscountResource[];
    warnings: DiscountCodeWarnings;
}
