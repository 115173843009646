import { PaymentMethodResource } from '../../@api/Sylius';
import { transformMeilisearchImageToImage } from '../../Media/MediaTransformers';
import { PaymentMethod } from './PaymentMethod';

export const transformToPaymentMethods = (resource: PaymentMethodResource): PaymentMethod => {
    const image = transformMeilisearchImageToImage({
        alt: resource.name,
        url: resource.imageUrl,
    });

    return ({
        value: resource.id,
        label: resource.name,
        image,
        isGiftCard: resource.isGiftCard,
    });
};
