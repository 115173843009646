import { FC, ReactElement } from 'react';

import { Logo } from '../../../../../components';

import './LogoExamples.scss';

interface LogoExamplesProps {
    className?: string;
}

export const LogoExamples: FC<LogoExamplesProps> = ({
    className = '',
}): ReactElement => (
    <div className={`logo-examples ${className}`}>
        <Logo className="logo-examples__logo" />
    </div>
);
