import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../components';
import { WayfindingType } from '../../entities/@navigation/Wayfinding/Wayfinding';
import { ProductCategory } from '../../entities/@products/ProductCategory/ProductCategory';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import { useTrans } from '../../hooks';
import { WayfindingWrapper } from '..';

import './CategoryCard.scss';

export interface CategoryCardProps extends ProductCategory {
    className?: string;
}

export const CategoryCard: FC<CategoryCardProps> = ({
    id,
    slug,
    name,
    image,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const productCategoryPath = trans(appRoutes[AppRoute.productCategory].path);
    const productCategoryUrl = replaceUrlParamKeysWithValues(productCategoryPath, {
        slug,
        subCategory: undefined,
        subSubCategory: undefined,
    });

    return (
        <div className={`category-card ${className}`}>
            <WayfindingWrapper
                id={id}
                type={WayfindingType.enter}
                label={trans('common.view')}
                className="category-card__wayfinding-wrapper"
                iconPathClassName="category-card__wayfinding-icon-path"
            >
                <Link to={productCategoryUrl}>
                    <div className="category-card__image-wrapper">
                        <Picture
                            {...image}
                            className="category-card__picture"
                            imageClassName="category-card__image"
                        />
                    </div>
                </Link>
            </WayfindingWrapper>

            <div className="category-card__text-wrapper">
                <h3 className="category-card__title">
                    {name}
                </h3>
            </div>
        </div>
    );
};
