import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { Page } from '../../../components';
import { NotFound } from '../../../containers';
import { MeilisearchAttribute } from '../../../entities/@api/Meilisearch';
import { generateMeilisearchFilterQuery } from '../../../entities/@search/Meilisearch/MeilisearchRequests';
import { useClientEffect } from '../../../hooks';
import { fetchBrand } from '../../../redux/@brands/brand/brandActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedProductCategoryChildProps {
    baseFilter: string;
}

interface ConnectedProductCategoryWrapperProps {
    children: (childProps: ConnectedProductCategoryChildProps) => ReactElement;
}

export const ConnectedBrandDetailWrapper: FC<ConnectedProductCategoryWrapperProps> = ({
    children,
}): ReactElement => {
    const { slug = '' } = useParams();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.brandSlice.hasFetched);
    const brand = useTypedSelector(state => state.brandSlice.brand);

    useClientEffect((): void => {
        if (slug) {
            dispatch(fetchBrand(slug));
        }
    }, [slug]);

    if (hasFetched && !brand) {
        return (
            <Page>
                <NotFound />
            </Page>
        );
    }

    const baseFilter = brand
        ? generateMeilisearchFilterQuery(MeilisearchAttribute.brand, brand.meilisearchProductListName)
        : '';

    return (
        <div>
            {children({ baseFilter })}
        </div>
    );
};
