import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '../../../../../compositions';
import { BrandNavigation as BrandNavigationInterface } from '../../../../../entities/Brand/Brand';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { scrollQuerySelectorIntoViewIncludingNavigation } from '../../../../../helpers/scroll';
import { alphabetWithNumbers } from '../../../../../helpers/string';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useTrans } from '../../../../../hooks';
import { BrandNavigationSkeletons } from './skeletons/BrandNavigationSkeletons/BrandNavigationSkeletons';

import './BrandNavigation.scss';

interface BrandNavigationProps {
    isLoading?: boolean;
    navigation: BrandNavigationInterface;
}

export const BrandNavigation: FC<BrandNavigationProps> = ({
    isLoading,
    navigation,
}): ReactElement => {
    const trans = useTrans();

    if (isLoading) {
        return (
            <BrandNavigationSkeletons />
        );
    }

    return (
        <nav
            aria-label={trans('containers.brandNavigation.navigation')}
            className="brand-navigation"
        >
            <ul className="brand-navigation__list">
                {alphabetWithNumbers.map(letter => {
                    const handleIndexClick = (): void => {
                        const selector = `[data-letter="${letter}"]`;

                        scrollQuerySelectorIntoViewIncludingNavigation(selector);
                    };

                    return (
                        <li key={letter} className="brand-navigation__navigation-item">
                            <Button
                                text={letter}
                                disabled={!navigation[letter]}
                                onClick={handleIndexClick}
                                className="brand-navigation__navigation-index-button"
                            />
                        </li>
                    );
                })}
            </ul>

            <div className="brand-navigation__section-wrapper">
                {Object.entries(navigation).map(([letter, allBrands]) => (
                    <div
                        key={letter}
                        data-letter={letter}
                        className="brand-navigation__section"
                    >
                        <p className="brand-navigation__section-heading">
                            {letter}
                        </p>

                        <ol className="brand-navigation__section-list">
                            {allBrands.map(brand => {
                                const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);
                                const brandDetailUrl = replaceUrlParamKeysWithValues(brandDetailPath, {
                                    slug: brand.slug,
                                });

                                return (
                                    <li key={brand.name} className="brand-navigation__section-list-item">
                                        <Link
                                            to={brandDetailUrl}
                                            className="brand-navigation__brand-link"
                                        >
                                            {brand.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ol>
                    </div>
                ))}
            </div>
        </nav>
    );
};
