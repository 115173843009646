import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import {
    ConnectedBlockRenderer,
    ConnectedBrandDetailRenderer,
    ConnectedBrandDetailWrapper,
    ConnectedProductList,
} from '../../../connectors';
import { MeilisearchAttribute } from '../../../entities/@api/Meilisearch';
import { MeilisearchStoreKey } from '../../../entities/@search/Meilisearch/Meilisearch';
import { AppRoute } from '../../../entities/Routing/Routing';
import { useTrans } from '../../../hooks';

import './BrandDetailPage.scss';

interface BrandDetailProps {
    className?: string;
}

export const BrandDetailPage: FC<BrandDetailProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <ConnectedBrandDetailWrapper>
            {({ baseFilter }) => (
                <Page className={`brand-detail-page ${className}`}>
                    <ConnectedBrandDetailRenderer />

                    {baseFilter && (
                        <ConnectedProductList
                            showFilterBar
                            storeKey={MeilisearchStoreKey.brandDetail}
                            title={trans('pages.brands.shopProducts')}
                            baseFilter={baseFilter}
                            hiddenFilterAttributes={[MeilisearchAttribute.brand]}
                            className="brand-detail-page__block"
                        />
                    )}

                    <ConnectedBlockRenderer
                        appRoute={AppRoute.brandDetail}
                        className="brand-detail-page__block"
                    />
                </Page>
            )}
        </ConnectedBrandDetailWrapper>
    );
};
