import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useSearchParams } from 'react-router-dom';

import { FormOption } from '../../../entities/@forms/Form/Form';
import { sortParameter } from '../../../entities/Sort/Sort';
import { SelectInput, SelectInputProps } from '../..';

import './SortInput.scss';

interface SortInputProps extends Omit<SelectInputProps, 'onChange'> {
    onChange?: (option: string) => void;
    className?: string;
}

export const SortInput: FC<SortInputProps> = ({
    name,
    options,
    hideLabel,
    label,
    placeholder,
    onChange,
    className = '',
}): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [value, setValue] = useState<FormOption>(options[0]);

    useEffect((): void => {
        const paramValue = searchParams.get(sortParameter);
        const foundValue = options.find(option => option.value === paramValue);

        if (foundValue) {
            setValue(foundValue);
        }
    }, [searchParams]);

    const handleChange = (option: string): void => {
        searchParams.set(sortParameter, option);

        setSearchParams(searchParams, { replace: true });

        if (onChange) {
            onChange(option);
        }
    };

    return (
        <div className={`sort-input ${className}`}>
            <SelectInput
                hideLabel={hideLabel}
                name={name}
                label={label}
                value={value}
                placeholder={placeholder}
                options={options}
                onChange={handleChange}
                inputLabelWrapperClassName="sort-input__input-label-wrapper"
                labelClassName="sort-input__input-label"
                className="sort-input__select"
            />
        </div>
    );
};
