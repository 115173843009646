import { FC, ReactElement, useRef } from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { Navigation } from '../../../../entities/@navigation/Navigation/Navigation';
import { useHandleClickOutside } from '../../../../hooks';
import { DesktopMainNavigationDrawer, DesktopMainNavigationToggle } from '..';

import './DesktopMainNavigation.scss';

interface DesktopMainNavigationProps {
    label?: string;
    navigation?: Navigation;
    className?: string;
}

export const DesktopMainNavigation: FC<DesktopMainNavigationProps> = ({
    label,
    navigation,
    className = '',
}): ReactElement => {
    const desktopMainNavigationRef = useRef<HTMLDivElement>(null);

    const [navigationIsOpen, toggleNavigationIsOpen] = useToggle(false);

    const closeNavigation = (): void => {
        toggleNavigationIsOpen(false);
    };

    useHandleClickOutside([desktopMainNavigationRef], closeNavigation);

    const drawerClassNames = classNames('desktop-main-navigation__drawer', {
        'desktop-main-navigation__drawer--is-open': navigationIsOpen,
    });

    return (
        <div ref={desktopMainNavigationRef} className={`desktop-main-navigation ${className}`}>
            <DesktopMainNavigationToggle
                isDisabled={!navigation}
                navigationIsOpen={navigationIsOpen}
                label={label}
                onClick={toggleNavigationIsOpen}
                className="desktop-main-navigation__toggle-button"
            />

            {navigation && (
                <DesktopMainNavigationDrawer
                    navigationIsOpen={navigationIsOpen}
                    navigationLabel={label}
                    navigation={navigation}
                    onLinkClick={closeNavigation}
                    className={drawerClassNames}
                />
            )}
        </div>
    );
};
