import { useEffect } from 'react';

import { setHasServerData } from '../redux/app/appSlice';
import { useTypedDispatch, useTypedSelector } from '../redux/store';

// Regular useEffect hooks will fire on initial render and when the dependencies change
// We created this hook to prevent fetches from being fired when there is already fetched data from SSR
export const useClientEffect = (callback: () => void, deps: unknown[] = []): void => {
    const dispatch = useTypedDispatch();
    const hasServerData = useTypedSelector(state => state.appSlice.hasServerData);

    useEffect((): void => {
        if (hasServerData) {
            dispatch(setHasServerData(false));
            return;
        }

        callback();
    }, deps);
};
