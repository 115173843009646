import { FC, ReactElement } from 'react';

import { AccountTabHeader } from '../../../../../compositions';
import { ConnectedAccountData } from '../../../../../connectors';
import { useTrans } from '../../../../../hooks';

import './AccountDataTab.scss';

export const AccountDataTab: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <div className="account-data-tab">
            <AccountTabHeader
                title={trans('pages.account.tabs.data.title')}
                description={trans('pages.account.tabs.data.description')}
            />

            <ConnectedAccountData className="account-data-tab__content" />
        </div>
    );
};
