import {
    GetProductResponse,
    GetProductVariantResponse,
    syliusFetch,
    SyliusImageType,
} from '../../../entities/@api/Sylius';
import { transformSlugToVariantId, transformToProduct } from '../../../entities/@products/Product/ProductTransformers';
import { transformToProductDefault } from '../../../entities/@products/ProductDefault/ProductDefaultTransformers';
import { transformToProductVariant } from '../../../entities/@products/ProductVariant/ProductVariantTransformers';
import { fetchTaxonWithParents } from '../../../entities/@shop/Taxon/TaxonService';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { Link } from '../../../entities/Link/Link';
import { transformTaxonToLink } from '../../../entities/Link/LinkTransformers';
import { ReduxFetchAction } from '../../defaults';
import {
    setBreadcrumbs,
    setError,
    setHasFetched,
    setIsLoading,
    setProduct,
} from './productSlice';

export const fetchProductFromVariantSlug: ReduxFetchAction<string> = variantSlug => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setError(''));
    dispatch(setProduct(undefined));

    try {
        const variantId = transformSlugToVariantId(variantSlug);
        const productVariantResponse = await syliusFetch<GetProductVariantResponse>(`/shop/product-variants/${variantId}`);

        if (!isFetchResultSuccessful(productVariantResponse)) {
            dispatch(setError(productVariantResponse.error));
            return;
        }

        const productVariantResource = productVariantResponse.data;
        const productVariant = transformToProductVariant(productVariantResource);
        const imageFilterQuery = `?imageFilter=${SyliusImageType.meilisearchThumbnail}`;

        const productDefaultResponse = await syliusFetch<GetProductResponse>(`/shop/products/${productVariant.productCode}${imageFilterQuery}`);

        if (!isFetchResultSuccessful(productDefaultResponse)) {
            dispatch(setError(productDefaultResponse.error));
            return;
        }

        const productDefaultResource = productDefaultResponse.data;

        const taxon = await fetchTaxonWithParents(productDefaultResource.mainTaxon || '');

        const taxonLink = taxon
            ? transformTaxonToLink(taxon)
            : undefined;

        const taxonParentLinks: Link[] = taxon?.parents
            ? taxon.parents.map(parent => ({
                href: parent.slug,
                label: parent.name,
            }))
            : [];

        const breadcrumbBases = taxonLink
            ? [...taxonParentLinks, taxonLink]
            : taxonParentLinks;

        const breadcrumbs: Link[] = breadcrumbBases.map((link, index) => {
            const parentHrefs = breadcrumbBases.map(base => base.href).slice(1, index);
            const fullHref = [...parentHrefs, link.href].join('/');

            return {
                ...link,
                href: `/${fullHref}`,
            };
        });

        const categories = taxonParentLinks.map(link => link.label);

        dispatch(setBreadcrumbs(breadcrumbs));

        const productDefault = transformToProductDefault(productDefaultResource);
        const product = transformToProduct(productDefault, productVariant, categories);

        dispatch(setProduct(product));
    } catch (error) {
        console.error('[fetchProductFromVariantSlug]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};
