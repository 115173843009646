import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { Page } from '../../components';
import {
    ConnectedBlockRenderer,
    ConnectedPageSearchResults,
    ConnectedProductList,
    ConnectedSearchCount,
    ConnectedSearchPageWrapper,
} from '../../connectors';
import { MeilisearchStoreKey } from '../../entities/@search/Meilisearch/Meilisearch';
import { AppRoute } from '../../entities/Routing/Routing';

import './SearchPage.scss';

export const SearchPage: FC = (): ReactElement => {
    const [queryParams] = useSearchParams();
    const query = queryParams.get('q') || '';

    const storeKey = MeilisearchStoreKey.search;

    return (
        <ConnectedSearchPageWrapper>
            {({ showNullState }) => {
                const blocksClassNames = classNames('search-page__block-renderer', {
                    'search-page__block-renderer--is-hidden': !showNullState,
                });

                const resultsClassNames = classNames('search-page__results-wrapper', {
                    'search-page__results-wrapper--is-hidden': showNullState,
                });

                return (
                    <Page className="search-page">
                        <ConnectedSearchCount
                            storeKey={storeKey}
                            query={query}
                        />

                        <ConnectedBlockRenderer
                            appRoute={AppRoute.search}
                            className={blocksClassNames}
                        />

                        <div className={resultsClassNames}>
                            <ConnectedProductList
                                showFilterBar
                                storeKey={storeKey}
                                query={query}
                            />

                            <ConnectedPageSearchResults
                                storeKey={storeKey}
                                query={query}
                                className="search-page__pages-list"
                            />
                        </div>
                    </Page>
                );
            }}
        </ConnectedSearchPageWrapper>
    );
};
