import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Menu } from '../../containers';
import { Navigation } from '../../entities/@navigation/Navigation/Navigation';
import { getParentNavigation } from '../../helpers/navigation';
import { useClientEffect } from '../../hooks';
import { fetchNavigation } from '../../redux/navigations/navigationsActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedMenuProps {
    className?: string;
}

export const ConnectedMenu: FC<ConnectedMenuProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.navigationsSlice.isLoading);
    const navigations = useTypedSelector(state => state.navigationsSlice.navigations);

    const isAuthenticated = useTypedSelector(state => state.authenticationSlice.isAuthenticated);
    const customer = useTypedSelector(state => state.customerSlice.customer);

    const topNavbarItems = useTypedSelector(state => state.topNavbarSlice.topNavbarItems);

    const [activeNavigation, setActiveNavigation] = useState<Navigation>();
    const [hasActiveSubNavigation, setHasActiveSubNavigation] = useState<boolean>(false);

    const navigationKey = 'main';

    const navigation = useMemo((): Navigation | undefined => (
        navigations[navigationKey]
    ), [navigations, navigationKey]);

    useClientEffect((): void => {
        dispatch(fetchNavigation({
            key: navigationKey,
            slug: navigationKey,
        }));
    });

    useEffect((): void => {
        setActiveNavigation(navigation);
    }, [navigation]);

    useEffect((): void => {
        if (navigation && activeNavigation) {
            setHasActiveSubNavigation(navigation.id !== activeNavigation.id);
        }
    }, [activeNavigation]);

    const handleBackButtonClick = (): void => {
        if (!navigation || !activeNavigation) {
            return;
        }

        const parentNavigation = getParentNavigation(navigation, activeNavigation);

        if (parentNavigation) {
            setActiveNavigation(parentNavigation);
        }
    };

    return (
        <Menu
            isLoading={isLoading}
            isAuthenticated={isAuthenticated}
            customer={customer}
            hasActiveSubNavigation={hasActiveSubNavigation}
            navigation={navigation}
            activeNavigation={activeNavigation}
            topNavbarItems={topNavbarItems?.links}
            onNavigationChange={setActiveNavigation}
            onBackButtonClick={handleBackButtonClick}
            className={className}
        />
    );
};
