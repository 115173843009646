import trans from '../../helpers/trans';
import { FormOption } from '../@forms/Form/Form';

export enum Country {
    nl = 'nl',
    be = 'be',
    de = 'de',
}

export const countryOptions: FormOption[] = Object.values(Country)
    .map(value => ({
        label: trans(`entities.country.${value}`),
        value,
    }));
