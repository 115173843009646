import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { transformToImage } from '../../Media/MediaTransformers';
import { CarouselInterface, CarouselResource } from './Carousel';

export const transformToCarousel = (resource: CarouselResource): CarouselInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const images = resource.images.map(transformToImage);

    return {
        ...defaultBlock,
        type: BlockType.carousel,
        images,
        title: resource.title || undefined,
    };
};
