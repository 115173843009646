import {
    FormErrors,
    FormValidation,
    validateExactStringLength,
    validateForm,
} from '../../../../entities/@forms/FormValidation/FormValidationService';
import { GiftcardBalanceFormData } from '../../../../entities/@forms/GiftcardBalanceForm/GiftcardBalanceForm';

export type GiftcardBalanceFormErrors = FormErrors<GiftcardBalanceFormData>;

export const validateGiftcardBalanceFormData = (formData: GiftcardBalanceFormData): FormValidation<GiftcardBalanceFormErrors> => {
    const cardNumberLength = 19;
    const pinLength = 6;

    const errors: GiftcardBalanceFormErrors = {
        cardNumber: validateExactStringLength('cardNumber', formData.cardNumber, cardNumberLength),
        pin: validateExactStringLength('pin', formData.pin, pinLength),
    };

    return validateForm<GiftcardBalanceFormErrors>(errors);
};
