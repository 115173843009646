import { FC, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './ProductDetailRendererSkeletons.scss';

interface ProductDetailRendererSkeletonsProps {
    numberOfSkeletons?: number;
    className?: string;
}

export const ProductDetailRendererSkeletons: FC<ProductDetailRendererSkeletonsProps> = ({
    numberOfSkeletons = 6,
    className = '',
}): ReactElement => {
    const imageSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <div className={`product-detail-renderer-skeletons ${className}`}>
            <Wrapper className="product-detail-renderer-skeletons__breadcrumbs-wrapper">
                <div className="product-detail-renderer-skeletons__breadcrumbs-inner-wrapper">
                    <Skeleton className="product-detail-renderer-skeletons__breadcrumbs" />
                </div>
            </Wrapper>

            <Wrapper className="product-detail-renderer-skeletons__details-wrapper">
                <div className="product-detail-renderer-skeletons__details-image-list">
                    {imageSkeletons.map(skeleton => (
                        <div key={skeleton} className="product-detail-renderer-skeletons__details-image-item">
                            <Skeleton className="product-detail-renderer-skeletons__details-image" />
                        </div>
                    ))}
                </div>

                <Skeleton className="product-detail-renderer-skeletons__details-sidebar" />
            </Wrapper>

            <Wrapper className="product-detail-renderer-skeletons__information-wrapper">
                <Skeleton className="product-detail-renderer-skeletons__information-description" />
            </Wrapper>

            <Wrapper className="product-detail-renderer-skeletons__brand-wrapper">
                <Skeleton className="product-detail-renderer-skeletons__brand-description" />
            </Wrapper>
        </div>
    );
};
