import {
    FC,
    lazy,
    ReactElement,
    Suspense,
    useEffect,
    useState,
} from 'react';

import { ErrorLabel } from '../../components';
import { IconButton, SearchableValueInput } from '../../compositions';
import { SearchableOption } from '../../entities/@forms/Form/Form';
import { MapLocation, StoreLocation } from '../../entities/Map/Map';
import { transformSearchableOptionToMapLocation } from '../../entities/Map/MapTransformer';
import { isSSR } from '../../helpers';
import { useGeolocation, useTrans } from '../../hooks';

import './StoreLocator.scss';

interface StoreLocatorProps {
    searchQuery: string;
    storeLocations: StoreLocation[];
    searchResults: SearchableOption[];
    onSearchChange: (query: string) => void;
    onResultClick: () => void;
    className?: string;
}

const Map = lazy(() => import('../../compositions/Map/Map')
    .then(module => ({ default: module.Map })));

export const StoreLocator: FC<StoreLocatorProps> = ({
    searchQuery,
    storeLocations,
    searchResults,
    onSearchChange,
    onResultClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { geolocation, error, requestGeolocation } = useGeolocation();

    const [selectedLocation, setSelectedLocation] = useState<MapLocation>();

    const handleMarkerClick = (location: MapLocation): void => {
        if (!isSSR) {
            window.open(`https://www.google.com/maps/search/${encodeURIComponent(location.label)}`);
        }
    };

    const handleSearchChange = (value: SearchableOption): void => {
        onSearchChange(value.label);
    };

    const handleResultClick = (value: SearchableOption): void => {
        const transformedLocation = transformSearchableOptionToMapLocation(value);

        setSelectedLocation(transformedLocation);

        onResultClick();
    };

    useEffect((): void => {
        if (geolocation) {
            setSelectedLocation({
                label: 'Current Location',
                location: {
                    latitude: geolocation.latitude,
                    longitude: geolocation.longitude,
                },
            });
        }
    }, [geolocation]);

    return (
        <div className={`store-locator ${className}`}>
            <Suspense>
                <Map
                    center={selectedLocation?.location}
                    zoom={selectedLocation ? 10 : 7}
                    markers={storeLocations}
                    onMarkerClick={handleMarkerClick}
                    className="store-locator__map"
                />
            </Suspense>

            <h2 className="store-locator__title">
                {trans('containers.storeLocator.visitOurStores')}
            </h2>

            <div className="store-locator__search-form">
                <div className="store-locator__search-input-wrapper">
                    <SearchableValueInput
                        isSearchable
                        hasUnderline
                        hideLabel
                        label={trans('containers.storeLocator.searchLabel')}
                        placeholder={trans('containers.storeLocator.inputPlaceholder')}
                        value={searchQuery}
                        options={searchResults}
                        onChange={handleSearchChange}
                        onOptionClick={handleResultClick}
                        className="store-locator__search-input"
                        fieldClassName="store-locator__search-input-field"
                        inputWrapperClassName="store-locator__search-input-wrapper"
                        iconClassName="store-locator__search-input-icon"
                        iconWrapperClassName="store-locator__search-input-icon-wrapper"
                    />
                </div>

                <IconButton
                    icon="location"
                    text={trans('containers.storeLocator.useCurrentLocation')}
                    onClick={requestGeolocation}
                    className="store-locator__current-location-button"
                />

                {error && (
                    <ErrorLabel
                        text={error}
                        className="store-locator__current-location-error"
                    />
                )}
            </div>
        </div>
    );
};
