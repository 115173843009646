import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { transformButtonToLink } from '../../Link/LinkTransformers';
import { defaultMediaItem, transformToMediaItem } from '../../Media/MediaTransformers';
import { HighlightedMediaInterface, HighlightedMediaResource } from './HighlightedMedia';

export const transformToHighlightedMedia = (resource: HighlightedMediaResource): HighlightedMediaInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const media = resource.autoplayMedia
        ? transformToMediaItem(resource.autoplayMedia)
        : defaultMediaItem();

    const link = resource.button
        ? transformButtonToLink(resource.button)
        : undefined;

    return {
        ...defaultBlock,
        type: BlockType.highlightedMedia,
        titleHtml: resource.title || undefined,
        paragraphHtml: resource.paragraph || undefined,
        media,
        titleColor: resource.color,
        imageAlignment: resource.imageAlignment,
        link,
    };
};
