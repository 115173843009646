import { convertNumberToCurrency } from '../../../helpers/number';
import trans from '../../../helpers/trans';
import {
    Locales,
    ProductAttributeChoices,
    ProductAttributeResource,
    ProductAttributeType,
    ProductDimensionAttributeKey,
} from '../../@api/Sylius';
import { centimeterUnit, kilogramUnit, ProductAttribute } from './ProductAttribute';

const locale = Locales.nl;

const sizeAttributes = [
    ProductDimensionAttributeKey.width,
    ProductDimensionAttributeKey.height,
    ProductDimensionAttributeKey.depth,
    ProductDimensionAttributeKey.diameter,
];

export const transformToKilograms = (grams: number): string => `${grams / 1000} ${kilogramUnit}`;
export const transformToCentimeters = (millimeters: number): string => `${millimeters / 10} ${centimeterUnit}`;
export const transformToCurrency = (cents: number): string => convertNumberToCurrency(cents / 100, {
    includeCurrencySymbol: true,
});

const getLocalizedAttributeValues = (choices: ProductAttributeChoices, values: string[]): string[] => (
    values
        .filter(id => id in choices)
        .map(id => choices[id][locale])
);

export const transformToProductAttribute = (resource: ProductAttributeResource): ProductAttribute => {
    const { code, type, value } = resource;
    const { choices } = resource.attribute.configuration;

    const attributeDefaults = {
        key: resource.code,
        label: resource.name,
    };

    if (type === ProductAttributeType.boolean) {
        const booleanLabel = value
            ? trans('common.yes')
            : trans('common.no');

        return {
            ...attributeDefaults,
            value: booleanLabel,
        };
    }

    if (type === ProductAttributeType.option) {
        const localizedValues = getLocalizedAttributeValues(choices, value as string[]);
        const valueLabel = localizedValues.join(', ');

        return {
            ...attributeDefaults,
            value: valueLabel,
        };
    }

    let valueLabel = value.toString();

    if (sizeAttributes.includes(code as ProductDimensionAttributeKey)) {
        valueLabel = value
            ? transformToCentimeters(value as number)
            : '';
    }

    if (code === ProductDimensionAttributeKey.weight) {
        valueLabel = value
            ? transformToKilograms(value as number)
            : '';
    }

    return {
        ...attributeDefaults,
        value: valueLabel,
    };
};
