import {
    FC,
    FormEvent,
    ReactElement,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { ErrorLabel } from '../../../components';
import { Button, GiftcardBalanceInputs, RecaptchaDisclaimer } from '../../../compositions';
import { FormProps } from '../../../entities/@forms/Form/Form';
import { GiftcardBalanceFormData } from '../../../entities/@forms/GiftcardBalanceForm/GiftcardBalanceForm';
import { convertNumberToCurrency } from '../../../helpers/number';
import { scrollIntoViewIncludingNavigation } from '../../../helpers/scroll';
import { useTrans } from '../../../hooks';
import { GiftcardBalanceFormErrors, validateGiftcardBalanceFormData } from './validations';

import './GiftcardBalanceForm.scss';

interface GiftcardBalanceFormProps extends FormProps<GiftcardBalanceFormData> {
    isLoading?: boolean;
    hasFetched?: boolean;
    balance?: number;
    className?: string;
}

export const GiftcardBalanceForm: FC<GiftcardBalanceFormProps> = ({
    isLoading,
    hasFetched,
    balance,
    error,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const formRef = useRef<HTMLFormElement>(null);

    const [formErrors, setFormErrors] = useState<GiftcardBalanceFormErrors>({});

    const [cardNumber, setCardNumber] = useState<string>('');
    const [pin, setPin] = useState<string>('');

    const [botDetected, setBotDetected] = useState<boolean>(false);

    const giftcardBalance = useMemo((): number => {
        if (balance === undefined) {
            return -1;
        }

        return balance;
    }, [balance]);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (!executeRecaptcha) {
            setBotDetected(true);
            return;
        }

        const formData: GiftcardBalanceFormData = {
            cardNumber,
            pin,
        };

        const [errors, hasErrors] = validateGiftcardBalanceFormData(formData);
        const token = await executeRecaptcha();

        setBotDetected(!token);
        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoViewIncludingNavigation<HTMLFormElement>(formRef);
        }
    };

    return (
        <div className={`giftcard-balance-form ${className}`}>
            <div className="giftcard-balance-form__header">
                <h6 className="giftcard-balance-form__title">
                    {trans('containers.giftcardBalanceForm.title')}
                </h6>
            </div>

            <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="giftcard-balance-form__form"
            >
                <div className="giftcard-balance-form__card-wrapper">
                    <GiftcardBalanceInputs
                        isLoading={isLoading}
                        pin={pin}
                        setPin={setPin}
                        setCardNumber={setCardNumber}
                    />

                    {formErrors.cardNumber && (
                        <ErrorLabel
                            text={formErrors.cardNumber}
                            className="giftcard-balance-form__error-label"
                        />
                    )}

                    <RecaptchaDisclaimer className="giftcard-balance-form__recaptcha-disclaimer" />

                    {giftcardBalance >= 0 && (
                        <p className="giftcard-balance-form__giftcard-balance">
                            {trans('containers.giftcardBalanceForm.balance', {
                                balance: convertNumberToCurrency(giftcardBalance, { includeCurrencySymbol: true }),
                            })}
                        </p>
                    )}
                </div>

                {hasFetched && (error || botDetected || giftcardBalance < 0) && (
                    <ErrorLabel
                        text={trans('containers.giftcardBalanceForm.genericError')}
                        className="giftcard-balance-form__error-label"
                    />
                )}

                <Button
                    isSmall
                    type="submit"
                    text={trans('containers.giftcardBalanceForm.submit')}
                    className="giftcard-balance-form__submit-button"
                />
            </form>
        </div>
    );
};
