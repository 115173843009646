import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type ChangePasswordState = AsyncReduxState<{
    isChangedSuccessfully: boolean;
}>;

const initialState: ChangePasswordState = {
    ...initialAsyncReduxState,
    isChangedSuccessfully: false,
};

const changePasswordSlice = createSlice({
    name: 'changePasswordSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ChangePasswordState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ChangePasswordState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ChangePasswordState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsChangedSuccessfully(state, action: PayloadAction<boolean>): ChangePasswordState {
            return {
                ...state,
                isChangedSuccessfully: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setIsChangedSuccessfully,
} = changePasswordSlice.actions;

export default changePasswordSlice;
