import { Address } from '../Address/Address';

export interface LatLng {
    latitude: number;
    longitude: number;
}

export interface MapLocation {
    label: string;
    location: LatLng;
}

export interface StoreLocation extends MapLocation {
    address: Address;
}

export const defaultMapLatLng: LatLng = {
    latitude: 52.3531943,
    longitude: 5.2903855,
};
