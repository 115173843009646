import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../../components';
import { ConnectedBlockRenderer, ConnectedResetPasswordForm } from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';

import './ResetPasswordPage.scss';

interface ResetPasswordPageProps {
    className?: string;
}

export const ResetPasswordPage: FC<ResetPasswordPageProps> = ({
    className = '',
}): ReactElement => (
    <Page className={`reset-password-page ${className}`}>
        <Wrapper className="reset-password-page__wrapper">
            <ConnectedResetPasswordForm
                className="reset-password-page__password-form"
            />
        </Wrapper>

        <ConnectedBlockRenderer appRoute={AppRoute.resetPassword} className="reset-password-page__section" />
    </Page>
);
