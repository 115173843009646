import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Address } from '../../components';
import { AddressType, CustomerAddress } from '../../entities/Address/Address';
import { useTrans } from '../../hooks';
import { IconButton } from '../@buttons/IconButton/IconButton';

import './AddressCard.scss';

interface AddressesCardProps {
    hideTypeButtons?: boolean;
    address: CustomerAddress;
    onClickEditButton: () => void;
    onChangeShippingAddress?: (addressId: string, type: AddressType) => void;
    className?: string;
}

export const AddressCard: FC<AddressesCardProps> = ({
    hideTypeButtons,
    address,
    onClickEditButton,
    onChangeShippingAddress,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const handleBillingAddressButtonClick = (): void => {
        onChangeShippingAddress?.(address.id, AddressType.billing);
    };

    const handleShippingAddressButtonClick = (): void => {
        onChangeShippingAddress?.(address.id, AddressType.shipping);
    };

    const shippingButtonIconPathClassNames = classNames('address-card__button-icon', {
        'address-card__button-icon--filled': address.type === AddressType.shipping,
    });

    const billingButtonIconPathClassNames = classNames('address-card__button-icon', {
        'address-card__button-icon--filled': address.type === AddressType.billing,
    });

    return (
        <div className={`address-card ${className}`}>
            <Address
                address={address}
                className="address-card__info"
            />

            <div className="address-card__button-wrapper">
                {!hideTypeButtons && (
                    <>
                        <IconButton
                            isSmall
                            icon="star"
                            disabled={address.type === AddressType.shipping}
                            text={trans(`entities.address.type.${AddressType.shipping}`)}
                            onClick={handleShippingAddressButtonClick}
                            className="address-card__button"
                            labelClassName="address-card__button-label"
                            iconPathClassName={shippingButtonIconPathClassNames}
                        />
                        <IconButton
                            isSmall
                            icon="star"
                            disabled={address.type === AddressType.billing}
                            text={trans(`entities.address.type.${AddressType.billing}`)}
                            onClick={handleBillingAddressButtonClick}
                            className="address-card__button"
                            labelClassName="address-card__button-label"
                            iconPathClassName={billingButtonIconPathClassNames}
                        />
                    </>
                )}

                <IconButton
                    isSmall
                    icon="edit"
                    text={trans('common.edit')}
                    onClick={onClickEditButton}
                    className="address-card__button"
                    labelClassName="address-card__button-label"
                    iconClassName="address-card__button-icon"
                />
            </div>
        </div>
    );
};
