import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Page } from '../../../components';
import { NotFound } from '../../../containers';
import { MeilisearchAttribute } from '../../../entities/@api/Meilisearch';
import { ProductCategoryParams } from '../../../entities/@products/ProductCategory/ProductCategory';
import { ProductListCallToActionInterface } from '../../../entities/@products/ProductListCallToAction/ProductListCallToAction';
import { generateMeilisearchFilterQuery } from '../../../entities/@search/Meilisearch/MeilisearchRequests';
import { useClientEffect } from '../../../hooks';
import { fetchProductCategoryPageData } from '../../../redux/@products/productCategory/productCategoryActions';
import { setProductCategoryReplacementUrl } from '../../../redux/@products/productCategory/productCategorySlice';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedProductCategoryChildProps {
    baseFilter: string;
    callToActions: ProductListCallToActionInterface[];
}

interface ConnectedProductCategoryWrapperProps {
    children: (childProps: ConnectedProductCategoryChildProps) => ReactElement;
}

export const ConnectedProductCategoryWrapper: FC<ConnectedProductCategoryWrapperProps> = ({
    children,
}): ReactElement => {
    const { slug, subCategory, subSubCategory } = useParams<ProductCategoryParams>();
    const navigate = useNavigate();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.productCategorySlice.hasFetched);
    const activeProductCategory = useTypedSelector(state => state.productCategorySlice.activeProductCategory);
    const productCategoryReplacementUrl = useTypedSelector(state => state.productCategorySlice.productCategoryReplacementUrl);
    const pageData = useTypedSelector(state => state.productCategorySlice.pageData);

    useClientEffect((): void => {
        if (slug) {
            dispatch(fetchProductCategoryPageData({ slug, subCategory, subSubCategory }));
        }
    }, [slug]);

    useEffect((): void => {
        if (productCategoryReplacementUrl) {
            dispatch(setProductCategoryReplacementUrl(undefined));

            navigate(productCategoryReplacementUrl, { replace: true });
        }
    }, [productCategoryReplacementUrl]);

    if (hasFetched && !activeProductCategory) {
        return (
            <Page>
                <NotFound />
            </Page>
        );
    }

    const baseFilter = activeProductCategory
        ? generateMeilisearchFilterQuery(MeilisearchAttribute.categories, activeProductCategory.name)
        : '';

    return (
        <div>
            {children({
                baseFilter,
                callToActions: pageData?.callToActions || [],
            })}
        </div>
    );
};
