import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../../../components';
import { AccountTabHeader } from '../../../../../compositions';
import { ConnectedWishlistItems } from '../../../../../connectors';
import { useTrans } from '../../../../../hooks';

import './AccountWishlistTab.scss';

export const AccountWishlistTab: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <div className="account-wishlist-tab">
            <AccountTabHeader
                title={trans('pages.account.tabs.wishlist.title')}
                description={trans('pages.account.tabs.wishlist.description')}
            />

            <div className="account-wishlist-tab__wishlist-section">
                <Wrapper>
                    <h2 className="account-wishlist-tab__wishlist-title">
                        {trans('pages.account.tabs.wishlist.wishlist.title')}
                    </h2>
                </Wrapper>

                <ConnectedWishlistItems className="account-wishlist-tab__wishlist" />
            </div>
        </div>
    );
};
