import { FC, ReactElement, useRef } from 'react';

import { Wrapper } from '../../../components';
import { BlogPostCardList, Pagination } from '../../../compositions';
import { BlogOverviewBlockInterface } from '../../../entities/@blocks/BlogOverview/BlogOverview';
import { BlogOverviewInterface } from '../../../entities/@blog/BlogOverview/BlogOverview';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { scrollIntoViewIncludingNavigation } from '../../../helpers/scroll';
import { useDeviceWidth, useTrans } from '../../../hooks';
import { HighlightedBlogPost } from '../HighlightedBlogPost/HighlightedBlogPost';

import './BlogOverview.scss';

interface BlogOverviewProps extends Omit<BlogOverviewBlockInterface, 'categories' | 'amount'>, BlogOverviewInterface {
    isLoading?: boolean;
    onPaginationChange?: (page: number) => void;
    className?: string;
}

export const BlogOverview: FC<BlogOverviewProps> = ({
    isLoading,
    showPagination,
    title,
    blogPosts,
    pagination,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isTabletPortrait } = useDeviceWidth();

    const blogOverviewRef = useRef<HTMLDivElement>(null);

    /*
     * Amount of columns before the highlighted blog post on tablet is not divisible by the same amount as the rest
     * Therefore, the index of the highlighted blog post is offset by 1, to prevent whitespace in the layout.
     */
    const highlightIndex = isTabletPortrait ? 9 : 10;

    const startSet = blogPosts.slice(0, highlightIndex - 1);
    const highlightedBlogPost = blogPosts[highlightIndex - 1];
    const endSet = blogPosts.slice(highlightIndex);

    const handlePaginationChange = (page: number): void => {
        scrollIntoViewIncludingNavigation(blogOverviewRef);

        onPaginationChange?.(page);
    };

    return (
        <section ref={blogOverviewRef} className={`blog-overview ${className}`}>
            <Wrapper className="blog-overview__wrapper">
                {title && (
                    <h2 className="blog-overview__title">
                        {title}
                    </h2>
                )}

                {isLoading && (
                    <BlogPostCardList
                        isLoading
                        numberOfSkeletons={9}
                        className="blog-overview__skeletons"
                    />
                )}

                {!isLoading && blogPosts.length === 0 && (
                    <div className="blog-overview__no-results-wrapper">
                        <p className="blog-overview__no-results">
                            {trans('containers.blogOverview.noResults')}
                        </p>
                    </div>
                )}

                {!isLoading && startSet.length > 0 && (
                    <BlogPostCardList
                        blogPosts={startSet}
                        className="blog-overview__start-set"
                    />
                )}

                {!isLoading && highlightedBlogPost && (
                    <HighlightedBlogPost
                        imagePosition={HorizontalAlignment.right}
                        blogPost={highlightedBlogPost}
                        className="blog-overview__highlighted-blog-post"
                    />
                )}

                {!isLoading && endSet.length > 0 && (
                    <BlogPostCardList
                        blogPosts={endSet}
                        className="blog-overview__end-set"
                    />
                )}

                {showPagination && pagination && pagination.totalPages > 1 && (
                    <Pagination
                        numberOfPages={pagination.totalPages}
                        currentPage={pagination.page}
                        onChange={handlePaginationChange}
                        className="blog-overview__pagination"
                    />
                )}
            </Wrapper>
        </section>
    );
};
