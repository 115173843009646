import { Address } from '../../Address/Address';
import { Link } from '../../Link/Link';
import { Image } from '../../Media/Media';
import { PriceBaseInterface, ProductPriceInterface } from '../../Price/Price';

export const cartToken = 'cartToken';

export interface CartItem {
    id: string;
    variantId: string;
    productCode?: string;
    slug: string;
    name: string;
    quantity: number;
    image?: Image;
    brand?: Link;
    categories?: string[];
    price: ProductPriceInterface;
}

export interface CartInterface extends PriceBaseInterface {
    id: string
    items: CartItem[];
    addresses?: Address[];
}
