import { FC, ReactElement } from 'react';

import { Picture, SafeHtml, Wrapper } from '../../../../../components';
import { LinkIconButton } from '../../../../../compositions';
import { ConnectedProductCard } from '../../../../../connectors';
import { Product } from '../../../../../entities/@products/Product/Product';
import { HorizontalAlignment } from '../../../../../entities/Alignment/Alignment';
import { Brand } from '../../../../../entities/Brand/Brand';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useTrans } from '../../../../../hooks';
import { SliderSection } from '../../../../SliderSection/SliderSection';

import './ProductBrand.scss';

interface ProductBrandProps {
    brand: Brand;
    products: Product[];
    className?: string;
}

export const ProductBrand: FC<ProductBrandProps> = ({
    brand,
    products,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);
    const brandDetailUrl = replaceUrlParamKeysWithValues(brandDetailPath, {
        slug: brand.slug,
    });

    const brandLink = {
        href: brandDetailUrl,
        label: trans('containers.productDetailRenderer.viewAllFromBrand', {
            brand: brand.name,
        }),
    };

    return (
        <section className={`product-brand ${className}`}>
            <Wrapper className="product-brand__wrapper">
                <div className="product-brand__card">
                    {brand.logo && (
                        <div className="product-brand__logo-wrapper">
                            <Picture
                                {...brand.logo}
                                className="brand-header__logo"
                            />
                        </div>
                    )}

                    <div className="product-brand__information">
                        <h2 className="product-brand__name">
                            {brand.name}
                        </h2>

                        {brand.description && (
                            <SafeHtml
                                html={brand.description}
                                className="product-brand__description"
                            />
                        )}

                        <LinkIconButton
                            hasAnimation
                            icon="arrow-right"
                            iconPos={HorizontalAlignment.right}
                            to={brandDetailUrl}
                            text={trans('containers.productDetailRenderer.viewAllFromBrand', { brand: brand.name })}
                            className="product-brand__link-button"
                        />
                    </div>
                </div>
            </Wrapper>

            {products.length > 0 && (
                <SliderSection
                    titleHasThemeColor
                    id="more-from-brand"
                    title={trans('containers.productDetailRenderer.moreFromBrand', { brand: brand.name })}
                    link={brandLink}
                    items={products.map(product => (
                        <ConnectedProductCard
                            product={product}
                            className="product-brand__slider-product"
                            pictureWrapperClassName="product-brand__product-picture-wrapper"
                        />
                    ))}
                    className="product-brand__slider"
                />
            )}
        </section>
    );
};
