import { generateUuid } from '../../../helpers/string';
import { TaxonParentBaseResource, TaxonResource } from '../../@api/Sylius';
import { Taxon } from './Taxon';

export const transformToTaxon = (resource: TaxonResource): Taxon => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    return {
        id,
        code: resource.code,
        slug: resource.slug || '',
        name: resource.name || '',
    };
};

export const transformToTaxonParent = (resource: TaxonParentBaseResource): Taxon => ({
    id: resource.code,
    code: resource.code,
    slug: resource.slug || '',
    name: resource.name || '',
});
