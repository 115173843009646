import { CSSProperties, FC, ReactElement } from 'react';

import { ProductVariantColor } from '../../entities/@products/ProductVariantColor/ProductVariantColor';
import { transformToProductVariantColorVariable } from '../../entities/@products/ProductVariantColor/ProductVariantColorTransformers';

import './ColorSwatches.scss';

export interface ColorSwatchesProps {
    max?: number;
    colors: ProductVariantColor[];
    className?: string;
}

export const ColorSwatches: FC<ColorSwatchesProps> = ({
    max,
    colors,
    className = '',
}): ReactElement => {
    // -1 check to prevent '+1' from showing, since this label can be replaced with a swatch
    const excessColors = max
        ? colors.length - max - 1
        : 0;

    const truncatedColors = max && excessColors > 0
        ? colors.slice(0, max)
        : colors;

    // TODO: Implement tooltips + links to variants
    return (
        <div className={`color-swatches ${className}`}>
            <ul className="color-swatches__list">
                {truncatedColors.map(color => {
                    const backgroundVariable = transformToProductVariantColorVariable(color);

                    const cssVariables = {
                        '--c-swatch': `var(${backgroundVariable})`,
                    } as CSSProperties;

                    return (
                        <li
                            key={color}
                            title={color}
                            style={cssVariables}
                            className="color-swatches__item"
                        />
                    );
                })}

                {excessColors > 0 && (
                    <li className="color-swatches__excess-label">
                        {`+${excessColors + 1}`}
                    </li>
                )}
            </ul>
        </div>
    );
};
