import { BlogPostResource } from '../../../@blog/BlogPost/BlogPost';
import { DepartmentDetailResource } from '../../../@departments/DepartmentDetail/DepartmentDetail';
import { ProductCategoryResource } from '../../../@products/ProductCategory/ProductCategory';
import { BrandItemResource } from '../../../Brand/Brand';
import { PageResource, PageType } from '../../../Page/Page';
import { StoreResource } from '../../../Store/Store';

export enum MeilisearchPageTypeResource {
    blog = 'blog',
    brand = 'brand',
    category = 'category',
    department = 'department',
    page = 'page',
    store = 'shop',
}

export enum MeilisearchPageType {
    blog = 'blog',
    brand = 'brand',
    category = 'category',
    department = 'department',
    page = 'page',
    store = 'store',
}

export type MeilisearchPageId = `${MeilisearchPageTypeResource}-${number}`;

export interface MeilisearchPageResource extends PageResource {
    _meilisearch_id: MeilisearchPageId;
}

export interface MeilisearchPageHitResource extends MeilisearchPageResource {
    fixedType: PageType | null;
}

export type MeilisearchBlogPostHitResource = MeilisearchPageResource & BlogPostResource;
export type MeilisearchBrandHitResource = MeilisearchPageResource & BrandItemResource;
export type MeilisearchDepartmentHitResource = MeilisearchPageResource & DepartmentDetailResource;
export type MeilisearchCategoryHitResource = MeilisearchPageResource & ProductCategoryResource;
export type MeilisearchStoreHitResource = MeilisearchPageResource & StoreResource;

export type MeilisearchPage = MeilisearchPageHitResource
| MeilisearchBlogPostHitResource
| MeilisearchBrandHitResource
| MeilisearchDepartmentHitResource
| MeilisearchCategoryHitResource
| MeilisearchStoreHitResource
