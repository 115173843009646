import { RouteParams } from '../../../LocalizedRoutes';

export enum CheckoutTab {
    details = 'checkoutDetails',
    order = 'checkoutOrder',
    payment = 'checkoutPayment',
}

export interface CheckoutParams extends RouteParams {
    tab?: CheckoutTab;
}

export enum CheckoutDetailsTab {
    existingCustomer = 'existingCustomer',
    guest = 'guest',
}
