import {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import { useDebounce, useLocalStorage } from 'react-use';

import { SearchDrawer } from '../../containers';
import { MeilisearchIndex } from '../../entities/@api/Meilisearch';
import { Product } from '../../entities/@products/Product/Product';
import { MeilisearchStoreKey } from '../../entities/@search/Meilisearch/Meilisearch';
import { PageSearchResult } from '../../entities/@search/PageSearchResult/PageSearchResult';
import { recentSearchesKey } from '../../entities/@search/Search/Search';
import { GTMEvent } from '../../entities/GTM/GTM';
import { gtmEvent } from '../../entities/GTM/GTMService';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useClientEffect, useTrans } from '../../hooks';
import { fetchStores } from '../../redux/@stores/stores/storesActions';
import { fetchMeilisearchPages, fetchMeilisearchVariants } from '../../redux/meilisearch/meilisearchActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export const ConnectedSearchDrawer: FC = (): ReactElement => {
    const trans = useTrans();
    const dispatch = useTypedDispatch();

    const stores = useTypedSelector(state => state.storesSlice.stores);

    const isLoading = useTypedSelector(state => state.meilisearchSlice.isLoading);
    const variantResponses = useTypedSelector(state => state.meilisearchSlice.variantResponses);
    const pageResponses = useTypedSelector(state => state.meilisearchSlice.pageResponses);

    const [recentSearches, storeRecentSearches] = useLocalStorage<string[]>(recentSearchesKey, []);

    const [query, setQuery] = useState<string>('');

    const storeKey = MeilisearchStoreKey.searchDrawer;
    const debounceDelay = 200;
    const searchLimit = 5;

    const productResults = useMemo((): Product[] => {
        const variantResponse = variantResponses[storeKey];

        return variantResponse
            ? variantResponse.results
            : [];
    }, [variantResponses]);

    const pageResults = useMemo((): PageSearchResult[] => {
        const pageResponse = pageResponses[storeKey];

        return pageResponse
            ? pageResponse.results
            : [];
    }, [pageResponses]);

    useClientEffect((): void => {
        if (stores.length === 0) {
            dispatch(fetchStores());
        }
    }, []);

    useDebounce((): void => {
        if (query.length === 0) {
            return;
        }

        dispatch(fetchMeilisearchVariants({
            key: storeKey,
            index: MeilisearchIndex.variantsNl,
            query,
            pageSize: searchLimit,
        }));

        dispatch(fetchMeilisearchPages({
            key: storeKey,
            query,
            pageSize: searchLimit,
        }));
    }, debounceDelay, [query]);

    const storeTermInRecentSearches = (term: string): void => {
        if (term.length < 1) {
            return;
        }

        gtmEvent({
            event: GTMEvent.search,
            search_term: query,
        });

        const otherSearches = recentSearches?.filter(item => item !== term) || [];
        const cappedSearches = [term, ...otherSearches].slice(0, 5);

        storeRecentSearches(cappedSearches);
    };

    const pageSuggestions: PageSearchResult[] = useMemo((): PageSearchResult[] => ([
        {
            id: AppRoute.storeOverview,
            title: trans(appRoutes[AppRoute.storeOverview].label),
            link: {
                href: trans(appRoutes[AppRoute.storeOverview].path),
                label: trans(appRoutes[AppRoute.storeOverview].label),
            },
        },
        {
            id: AppRoute.customerService,
            title: trans(appRoutes[AppRoute.customerService].label),
            link: {
                href: trans(appRoutes[AppRoute.customerService].path),
                label: trans(appRoutes[AppRoute.customerService].label),
            },
        },
        {
            id: AppRoute.customerServiceFaq,
            title: trans(appRoutes[AppRoute.customerServiceFaq].label),
            link: {
                href: trans(appRoutes[AppRoute.customerServiceFaq].path),
                label: trans(appRoutes[AppRoute.customerServiceFaq].label),
            },
        },
    ]), []);

    return (
        <SearchDrawer
            isLoading={isLoading}
            query={query}
            productResults={productResults}
            pageResults={pageResults}
            stores={stores}
            pageSuggestions={pageSuggestions}
            recentSearches={recentSearches || []}
            onSetStoreTermInRecentSearches={storeTermInRecentSearches}
            onInput={setQuery}
        />
    );
};
