import qs from 'qs';

import { BlockTypeResource } from '../../Block/Block';
import { PageDraftConfig } from '../../Page/Page';

// TODO: Find more permanent name/fix for this populate solution
export const strapiBlockPopulates = {
    on: {
        [BlockTypeResource.blogOverview]: {
            populate: ['blogCategory', 'scheduling'],
        },
        [BlockTypeResource.blogsSelection]: {
            populate: ['blogs', 'blogs.image'],
        },
        [BlockTypeResource.brandCarousel]: {
            populate: [
                'brands',
                'brands.logo',
                'button',
                'button.link',
                'button.icon',
            ],
        },
        [BlockTypeResource.carousel]: {
            populate: ['images', 'scheduling'],
        },
        [BlockTypeResource.contentColumns]: {
            populate: [
                'columns',
                'columns.media',
                'scheduling',
                'scheduling',
            ],
        },
        [BlockTypeResource.faq]: {
            populate: ['faqs', 'scheduling'],
        },
        [BlockTypeResource.featuredProducts]: {
            populate: [
                'product_selection',
                'button',
                'button.link',
                'button.icon',
            ],
        },
        [BlockTypeResource.form]: {
            populate: '*',
        },
        [BlockTypeResource.header]: {
            populate: ['autoplayMedia', 'autoplayMedia.media', 'scheduling'],
        },
        [BlockTypeResource.highlightedBlogPost]: {
            populate: ['blog', 'blog.image', 'scheduling'],
        },
        [BlockTypeResource.highlightedMedia]: {
            populate: [
                'autoplayMedia.media',
                'button',
                'button.link',
                'button.icon',
            ],
        },
        [BlockTypeResource.iconColumns]: {
            populate: ['columns', 'columns.icon', 'scheduling'],
        },
        [BlockTypeResource.iframe]: {
            populate: '*',
        },
        [BlockTypeResource.mediaBanner]: {
            populate: '*',
        },
        [BlockTypeResource.mediaGrid]: {
            populate: ['mediaItems', 'mediaItems.media', 'scheduling'],
        },
        [BlockTypeResource.newsletterCallToAction]: {
            populate: ['image', 'scheduling'],
        },
        [BlockTypeResource.productCollection]: {
            populate: [
                'productSelection',
                'button',
                'button.link',
                'button.icon',
                'media',
                'media.image',
                'media.button',
                'media.button.link',
            ],
        },
        [BlockTypeResource.service]: {
            populate: '*',
        },
        [BlockTypeResource.uspSection]: {
            populate: ['uniqueSellingPoint', 'uniqueSellingPoint.icon', 'scheduling'],
        },
    },
};

export const generatePaginatedDataQuery = (page: number, draftParams?: PageDraftConfig): string => {
    const pagination = {
        page,
        pageSize: 100,
    };

    return qs.stringify({
        ...draftParams,
        pagination,
    }, {
        encodeValuesOnly: true,
    });
};
