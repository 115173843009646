import { FC, ReactElement } from 'react';

import { CartSidebar } from '../../containers';
import { GTMEvent } from '../../entities/GTM/GTM';
import { generateCartItemToGTMProduct } from '../../entities/GTM/GTMRequests';
import { gtmEvent } from '../../entities/GTM/GTMService';
import { MOCK_USPS } from '../../mock/mock-data/usp';
import { removeProductVariantFromCart, updateProductVariantInCart } from '../../redux/@shop/cart/cartActions';
import { addProductVariantToWishlist, removeProductVariantFromWishlist } from '../../redux/@shop/wishlist/wishlistActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export const ConnectedCartSideBar: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const cart = useTypedSelector(state => state.cartSlice.cart);
    const isLoading = useTypedSelector(state => state.cartSlice.isLoading);
    const productIsAddedToCart = useTypedSelector(state => state.cartSlice.isSuccessfulAdded);

    const wishlist = useTypedSelector(state => state.wishlistSlice.wishlist);

    const handleUpdateCartItemQuantity = (id: string, quantity: number): void => {
        dispatch(updateProductVariantInCart(id, quantity));
    };

    const handleRemoveItemFromCart = (id: string): void => {
        dispatch(removeProductVariantFromCart(id));

        const cartItem = cart.items.find(item => item.id === id);

        if (!cartItem) {
            return;
        }

        const GTMProduct = generateCartItemToGTMProduct(cartItem);

        gtmEvent({
            event: GTMEvent.removeItemFromCart,
            ...GTMProduct,
        });
    };

    const handleWishlistToggle = async (productVariantId: string, isInWishlist: boolean): Promise<void> => {
        if (isInWishlist) {
            await dispatch(removeProductVariantFromWishlist(productVariantId));
            return;
        }

        await dispatch(addProductVariantToWishlist(productVariantId));

        const cartItem = cart.items.find(item => item.variantId === productVariantId);

        if (!cartItem) {
            return;
        }

        const GTMProduct = generateCartItemToGTMProduct(cartItem);

        gtmEvent({
            event: GTMEvent.addToWishlist,
            ...GTMProduct,
        });
    };

    return (
        <CartSidebar
            {...cart}
            isLoading={isLoading}
            wishlistItems={wishlist.productVariantIds}
            uspItems={MOCK_USPS} // TODO: Replace with data from Strapi
            productIsAddedToCart={productIsAddedToCart}
            onRemoveItemFromCart={handleRemoveItemFromCart}
            onUpdateCartItem={handleUpdateCartItemQuantity}
            onUpdateCartItemIsInWishlist={handleWishlistToggle}
        />
    );
};
