import { strapiFetch } from '../../../entities/@api/Strapi';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { PageResponse } from '../../../entities/Page/Page';
import { generateCustomPagesQuery } from '../../../entities/Page/PageRequests';
import { generateIdArray } from '../../../helpers/array';
import { ReduxFetchAction } from '../../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setSlugs,
} from './customPagesSlice';

export const fetchCustomPageSlugs: ReduxFetchAction = () => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));
    dispatch(setSlugs([]));

    try {
        const query = generateCustomPagesQuery(1);
        const customPagesResponse = await strapiFetch<PageResponse>(`/custom-pages?${query}`);

        if (!isFetchResultSuccessful(customPagesResponse)) {
            dispatch(setError(customPagesResponse.error));
            return;
        }

        const { data: customPagesData, meta } = customPagesResponse.data;
        const { pagination } = meta;

        const extraPages = customPagesData
            ? Math.ceil(pagination.total / customPagesData.length) - 1
            : 0;

        const pagesToFetch = generateIdArray(extraPages).map(page => page + 2);

        let slugs = customPagesData
            ? customPagesData.map(customPage => customPage.slug)
            : [];

        const pageFetchPromises = pagesToFetch.map(async page => {
            const pageQuery = generateCustomPagesQuery(page);
            const pageResponse = await strapiFetch<PageResponse>(`/custom-pages?${pageQuery}`);

            if (!isFetchResultSuccessful(pageResponse)) {
                dispatch(setError(pageResponse.error));
                return;
            }

            const { data: pageData } = pageResponse.data;

            const pageSlugs = pageData
                ? pageData.map(customPage => customPage.slug)
                : [];

            slugs = [...slugs, ...pageSlugs];
        });

        await Promise.allSettled(pageFetchPromises);

        dispatch(setSlugs(slugs));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchCustomPageSlugs]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};
