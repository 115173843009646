import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { Page } from '../../../components';
import {
    ConnectedBlockRenderer,
    ConnectedOwnCollectionPageWrapper,
    ConnectedPageHelmet,
    ConnectedProductList,
} from '../../../connectors';
import { MeilisearchAttribute } from '../../../entities/@api/Meilisearch';
import { MeilisearchStoreKey } from '../../../entities/@search/Meilisearch/Meilisearch';
import { AppRoute } from '../../../entities/Routing/Routing';

import './OwnCollectionPage.scss';

interface OwnCollectionPageProps {
    className?: string;
}

export const OwnCollectionPage: FC<OwnCollectionPageProps> = ({
    className = '',
}): ReactElement => {
    const { categorySlug = '' } = useParams();

    const hiddenFilterAttributes = categorySlug
        ? [MeilisearchAttribute.brand, MeilisearchAttribute.categories]
        : [MeilisearchAttribute.brand];

    return (
        <ConnectedOwnCollectionPageWrapper>
            {({ baseFilter }) => (
                <Page className={`own-collection-page ${className}`}>
                    <ConnectedPageHelmet appRoute={AppRoute.ownCollection} />

                    <ConnectedBlockRenderer
                        appRoute={AppRoute.ownCollection}
                        className="own-collection-page__block-renderer"
                    />

                    <ConnectedProductList
                        showFilterBar
                        storeKey={MeilisearchStoreKey.ownCollection}
                        baseFilter={baseFilter}
                        hiddenFilterAttributes={hiddenFilterAttributes}
                        className="own-collection-page__product-list"
                    />
                </Page>
            )}
        </ConnectedOwnCollectionPageWrapper>
    );
};
