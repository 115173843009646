import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';

import './PageSearchResultsSkeletons.scss';

interface PageSearchResultsSkeletonsProps {
    numberOfSkeletons?: number;
    className?: string;
}

export const PageSearchResultsSkeletons: FC<PageSearchResultsSkeletonsProps> = ({
    numberOfSkeletons = randomInBetweenValue(4, 6),
    className = '',
}): ReactElement => {
    const pageResultsSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <div className={`page-search-results-skeletons ${className}`}>
            {pageResultsSkeletons.map(skeleton => (
                <div key={skeleton} className="page-search-results-skeletons__list-item">
                    <div className="page-search-results-skeletons__tag-wrapper">
                        <Skeleton className="page-search-results-skeletons__tag" />
                    </div>

                    <div className="page-search-results-skeletons__title-wrapper ">
                        <Skeleton className="page-search-results-skeletons__title" />
                    </div>

                    <Skeleton className="page-search-results-skeletons__description" />
                </div>
            ))}
        </div>
    );
};
