import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useSearchParams } from 'react-router-dom';

import { ProductList } from '../../containers';
import { MeilisearchAttribute, MeilisearchIndex } from '../../entities/@api/Meilisearch';
import { Product } from '../../entities/@products/Product/Product';
import { ProductListCallToActionInterface } from '../../entities/@products/ProductListCallToAction/ProductListCallToAction';
import { productSortingOptions } from '../../entities/@products/ProductSorting/ProductSorting';
import {
    defaultMeilisearchSearchResponse,
    MeilisearchFilter,
    MeilisearchRangeFilter,
    MeilisearchSearchResponse,
    MeilisearchStoreKey,
    staticFilterAttributes,
} from '../../entities/@search/Meilisearch/Meilisearch';
import { GTMEvent } from '../../entities/GTM/GTM';
import { generateGTMProductList } from '../../entities/GTM/GTMRequests';
import { gtmEvent } from '../../entities/GTM/GTMService';
import { paginationPageParameter } from '../../entities/Pagination/Pagination';
import { sortParameter } from '../../entities/Sort/Sort';
import {
    combineBaseFilterWithFilterQuery,
    retrieveFilterEntriesFromSearchParams,
    transformFilterEntriesToFilterQuery,
} from '../../helpers/meilisearch';
import { scrollIntoViewIncludingNavigation } from '../../helpers/scroll';
import { stringIsNumber } from '../../helpers/string';
import { useClientEffect, useEffectAfterInitialRender, useTimeout } from '../../hooks';
import { fetchMeilisearchVariants } from '../../redux/meilisearch/meilisearchActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { replaceDynamicWithStaticFilters } from './helpers';

interface ConnectedProductListProps {
    showFilterBar?: boolean;
    storeKey: MeilisearchStoreKey;
    title?: string;
    baseFilter?: string;
    hiddenFilterAttributes?: MeilisearchAttribute[];
    query?: string;
    callToActions?: ProductListCallToActionInterface[];
    className?: string;
}

export const ConnectedProductList: FC<ConnectedProductListProps> = ({
    showFilterBar,
    storeKey,
    title,
    baseFilter = '',
    hiddenFilterAttributes,
    query = '',
    callToActions = [],
    className = '',
}): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();

    const productListRef = useRef<HTMLDivElement>(null);

    const dispatch = useTypedDispatch();

    const isLoadingVariants = useTypedSelector(state => state.meilisearchSlice.isLoadingVariants);
    const variantsResponses = useTypedSelector(state => state.meilisearchSlice.variantResponses);

    // To keep track whether the 'Reset filter' button needs to be shown
    const [activeFilterQuery, setActiveFilterQuery] = useState<string>('');

    // To rerender the filter inputs and reset their initial values
    const [filtersAreResetting, setFiltersAreResetting] = useState<boolean>(false);

    // Reset filter reset boolean so filters are rerendered
    useTimeout((): void => {
        if (filtersAreResetting) {
            setFiltersAreResetting(false);
        }
    }, 10, [filtersAreResetting]);

    // To prevent the search param filters to set the initial filters on refresh,
    // set the initial filters based on just the given query
    useClientEffect((): void => {
        dispatch(fetchMeilisearchVariants({
            retrieveInitialFilters: true,
            key: storeKey,
            index: MeilisearchIndex.variantsNl,
            facets: ['*'],
            query,
            filter: baseFilter,
        }));
    }, [query, baseFilter]);

    // Fetch variants from Meilisearch when either the query the filters, sorting or pagination changes
    useClientEffect((): void => {
        const activeFilters = retrieveFilterEntriesFromSearchParams(searchParams);
        const filterQuery = transformFilterEntriesToFilterQuery(activeFilters);
        const filter = combineBaseFilterWithFilterQuery(baseFilter, filterQuery);

        const sort = searchParams.get(sortParameter) || undefined;

        const pageParam = searchParams.get(paginationPageParameter);
        const page = pageParam && stringIsNumber(pageParam)
            ? Number(pageParam)
            : 1;

        setActiveFilterQuery(filterQuery);

        dispatch(fetchMeilisearchVariants({
            key: storeKey,
            index: MeilisearchIndex.variantsNl,
            facets: ['*'],
            query,
            filter,
            sort,
            page,
        }));
    }, [query, baseFilter, searchParams]);

    useEffectAfterInitialRender((): void => {
        scrollIntoViewIncludingNavigation<HTMLDivElement>(productListRef);
    }, [searchParams]);

    useEffect((): void => {
        const variantResponseData = variantsResponses[storeKey];

        if (!variantResponseData?.results) {
            return;
        }

        const gtmProductList = generateGTMProductList(variantResponseData?.results);

        gtmEvent({
            event: GTMEvent.viewItemList,
            ...gtmProductList,
        });
    }, [variantsResponses]);

    // Retrieve variant response from store, or return default value
    const variantResponse = useMemo((): MeilisearchSearchResponse<Product> => {
        const variantResponseData = variantsResponses[storeKey];

        return variantResponseData || defaultMeilisearchSearchResponse<Product>();
    }, [variantsResponses, storeKey]);

    // To prevent updating filter values, replace 'dynamic' filters with 'static' ones where needed
    const filters = useMemo((): MeilisearchFilter[] => replaceDynamicWithStaticFilters<MeilisearchFilter>({
        dynamicFilters: variantResponse.filters,
        staticFilters: variantResponse.initialFilters,
        staticFilterAttributes,
    }), [variantResponse]);

    // To prevent updating filter values, replace 'dynamic' filters with 'static' ones where needed
    const rangeFilters = useMemo((): MeilisearchRangeFilter[] => replaceDynamicWithStaticFilters<MeilisearchRangeFilter>({
        dynamicFilters: variantResponse.rangeFilters,
        staticFilters: variantResponse.initialRangeFilters,
        staticFilterAttributes,
    }), [variantResponse]);

    // To prevent updating filter values, replace 'dynamic' filters with 'static' ones where needed
    const highlightedFilters = useMemo((): MeilisearchFilter[] => replaceDynamicWithStaticFilters<MeilisearchFilter>({
        dynamicFilters: variantResponse.highlightedFilters,
        staticFilters: variantResponse.initialHighlightedFilters,
        staticFilterAttributes,
    }), [variantResponse]);

    const callToActionsOnPage = useMemo((): ProductListCallToActionInterface[] => {
        const amountPerPage = 2;
        const currentPage = variantResponse?.pagination?.page || 1;
        const sliceIndex = (currentPage - 1) * amountPerPage;

        return callToActions.slice(sliceIndex, sliceIndex + amountPerPage);
    }, [callToActions, variantResponse]);

    const handleFiltersReset = (): void => {
        const activeSearchParamFilters = retrieveFilterEntriesFromSearchParams(searchParams);

        for (let i = 0; i < activeSearchParamFilters.length; i += 1) {
            const [filterAttribute] = activeSearchParamFilters[i];

            searchParams.delete(filterAttribute);
        }

        searchParams.set(paginationPageParameter, '1');
        setFiltersAreResetting(true);
        setSearchParams(searchParams, { replace: true });
    };

    return (
        <ProductList
            ref={productListRef}
            isLoading={isLoadingVariants}
            showFilterBar={showFilterBar && !filtersAreResetting}
            mayShowResetButton={!!activeFilterQuery}
            title={title}
            query={query}
            products={variantResponse?.results || []}
            callToActions={callToActionsOnPage}
            filters={filters}
            rangeFilters={rangeFilters}
            highlightedFilters={highlightedFilters}
            hiddenFilterAttributes={hiddenFilterAttributes}
            sortOptions={productSortingOptions}
            pagination={variantResponse?.pagination}
            onFiltersReset={handleFiltersReset}
            className={className}
        />
    );
};
