import { FC, PropsWithChildren, ReactElement } from 'react';

import { DefaultHelmet } from '../../../../../compositions';
import { ProductAttributeKey, ProductDimensionAttributeKey } from '../../../../../entities/@api/Sylius';
import {
    Product,
    ProductSeoAvailability,
    ProductSeoCondition,
    productVariantSeparator,
} from '../../../../../entities/@products/Product/Product';
import { Image } from '../../../../../entities/Media/Media';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useTrans } from '../../../../../hooks';

interface ProductDetailHelmetProps {
    product: Product;
}

export const ProductDetailHelmet: FC<PropsWithChildren<ProductDetailHelmetProps>> = ({
    product,
    children,
}): ReactElement => {
    const trans = useTrans();

    const siteUrl = trans('company.url');

    const fallbackImage = {
        src: `${siteUrl}/images/metadata-preview.jpg`,
        alt: trans('company.name'),
    };

    const metaTitle = product.name;
    const metaDescription = product.shortDescription || metaTitle;

    const productImages = product.mediaItems
        .filter(mediaItem => !!mediaItem.image)
        .map(mediaItem => mediaItem.image as Image);

    const metaImage = productImages.length > 0
        ? productImages[0]
        : fallbackImage;

    const productPathTemplate = trans(appRoutes[AppRoute.productDetail].path);
    const productPath = replaceUrlParamKeysWithValues(productPathTemplate, {
        slug: product.slug + productVariantSeparator + product.variantCode,
    });

    const productUrl = siteUrl + productPath;

    const structuredImages = productImages.map(image => image.src);

    const structuredBrand = product.brand
        ? {
            '@type': 'Brand',
            name: product.brand.label,
        }
        : undefined;

    // TODO: ProductSeoAvailability.inStoreOnly check
    // TODO: Implement getProductStructuredDataAvailability
    const structuredAvailability = product.stock
        ? ProductSeoAvailability.inStock
        : ProductSeoAvailability.outOfStock;

    const structuredSku = product.attributes.find(attribute => attribute.key === ProductAttributeKey.sku);
    const structuredMaterial = product.attributes.find(attribute => attribute.key === ProductAttributeKey.materials);
    const structuredColor = product.attributes.find(attribute => attribute.key === ProductAttributeKey.colors);

    const structuredWidth = product.dimensionAttributes.find(attribute => attribute.key === ProductDimensionAttributeKey.width);
    const structuredHeight = product.dimensionAttributes.find(attribute => attribute.key === ProductDimensionAttributeKey.height);
    const structuredDepth = product.dimensionAttributes.find(attribute => attribute.key === ProductDimensionAttributeKey.depth);
    const structuredWeight = product.dimensionAttributes.find(attribute => attribute.key === ProductDimensionAttributeKey.weight);

    return (
        <DefaultHelmet
            title={metaTitle}
            description={metaDescription}
            image={metaImage}
            canonical={productUrl}
        >
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'Product',
                    name: product.name,
                    description: product.description,
                    image: structuredImages,
                    brand: structuredBrand,
                    sku: structuredSku?.value,
                    offers: {
                        '@type': 'Offer',
                        url: productUrl,
                        priceCurrency: 'EUR',
                        price: product.price,
                        availability: structuredAvailability,
                        itemCondition: ProductSeoCondition.new,
                        seller: {
                            '@type': 'Organization',
                            name: trans('company.name'),
                        },
                    },
                    category: product.categories?.at(-1),
                    material: structuredMaterial?.value,
                    color: structuredColor?.value,
                    weight: structuredWeight?.value,
                    width: structuredWidth?.value,
                    height: structuredHeight?.value,
                    depth: structuredDepth?.value,
                })}
            </script>

            {children}
        </DefaultHelmet>
    );
};
