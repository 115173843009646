import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet, ConnectedProductList } from '../../../connectors';
import { MeilisearchAttribute } from '../../../entities/@api/Meilisearch';
import { MeilisearchStoreKey } from '../../../entities/@search/Meilisearch/Meilisearch';
import { generateMeilisearchFilterQuery } from '../../../entities/@search/Meilisearch/MeilisearchRequests';
import { AppRoute } from '../../../entities/Routing/Routing';

import './ProductsPage.scss';

interface ProductsPageProps {
    className?: string;
}

export const ProductsPage: FC<ProductsPageProps> = ({
    className = '',
}): ReactElement => {
    const filterQuery = generateMeilisearchFilterQuery(MeilisearchAttribute.type, 'variants');

    return (
        <Page className={`products-page ${className}`}>
            <ConnectedPageHelmet appRoute={AppRoute.products} />

            <ConnectedBlockRenderer
                appRoute={AppRoute.products}
                className="products-page__block-renderer"
            />

            <ConnectedProductList
                showFilterBar
                storeKey={MeilisearchStoreKey.products}
                baseFilter={filterQuery}
                className="products-page__product-list"
            />
        </Page>
    );
};
