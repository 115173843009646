import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { FeaturedProducts } from '../../containers';
import { MeilisearchAttribute, MeilisearchIndex } from '../../entities/@api/Meilisearch';
import { FeaturedProductsInterface } from '../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { getProductsCountForLayout } from '../../entities/@blocks/FeaturedProducts/FeaturedProductsRequests';
import { Product } from '../../entities/@products/Product/Product';
import { MeilisearchStoreKey } from '../../entities/@search/Meilisearch/Meilisearch';
import { generateMeilisearchFilterQuery } from '../../entities/@search/Meilisearch/MeilisearchRequests';
import { fetchMeilisearchVariants } from '../../redux/meilisearch/meilisearchActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export interface ConnectedFeaturedProductsProps extends FeaturedProductsInterface {
    className?: string;
}

export const ConnectedFeaturedProducts: FC<ConnectedFeaturedProductsProps> = ({
    className = '',
    ...featuredProductsProps
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const storeKey = MeilisearchStoreKey.featuredProducts + featuredProductsProps.id as MeilisearchStoreKey;

    const variantResponses = useTypedSelector(state => state.meilisearchSlice.variantResponses);

    const [pageSize, setPageSize] = useState<number>(0);

    useEffect((): void => {
        const limitOfProductsInLayout = getProductsCountForLayout(featuredProductsProps.layoutType);

        setPageSize(limitOfProductsInLayout);
    }, [featuredProductsProps.layoutType]);

    useEffect((): void => {
        if (!pageSize && featuredProductsProps.meilisearchProductListName) {
            return;
        }

        const filter = generateMeilisearchFilterQuery(MeilisearchAttribute.lists, featuredProductsProps.meilisearchProductListName);

        dispatch(fetchMeilisearchVariants({
            key: storeKey,
            index: MeilisearchIndex.variantsNl,
            pageSize,
            filter,
        }));
    }, [pageSize]);

    const products = useMemo((): Product[] => {
        const variantResponse = variantResponses[storeKey];

        return variantResponse
            ? variantResponse.results.slice(0, pageSize)
            : [];
    }, [variantResponses]);

    return (
        <FeaturedProducts
            {...featuredProductsProps}
            products={products}
            className={className}
        />
    );
};
