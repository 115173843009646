import { FC, ReactElement } from 'react';

import { Logo, Page, Wrapper } from '../../components';
import { LinkIconButton } from '../../compositions';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';

import './NotFound.scss';

export const NotFound: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="not-found">
            <Wrapper className="not-found__wrapper">
                <Logo name="broken" className="not-found__logo" />

                <h1 className="not-found__heading">
                    {trans('containers.notFound.somethingWentWrong')}
                </h1>

                <p className="not-found__description">
                    {trans('containers.notFound.pageDoesNotExist')}
                </p>

                <div className="not-found__button-wrapper">
                    <LinkIconButton
                        hasAnimation
                        to={trans(appRoutes[AppRoute.products].path)}
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        text={trans('containers.notFound.shopping')}
                    />

                    <LinkIconButton
                        hasAnimation
                        to={trans(appRoutes[AppRoute.customerService].path)}
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        text={trans('containers.notFound.askAQuestion')}
                        className="not-found__question-button"
                    />
                </div>
            </Wrapper>
        </Page>
    );
};
