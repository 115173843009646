import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { BlogPostCard } from '../../../compositions';
import { RelatedBlogPostsInterface } from '../../../entities/@blocks/RelatedBlogPosts/RelatedBlogPosts';

import './RelatedBlogPosts.scss';

export interface RelatedBlogPostsProps extends RelatedBlogPostsInterface {
    className?: string;
}

// TODO: Connect with data from CMS (L5W-416, Should Have)
export const RelatedBlogPosts: FC<RelatedBlogPostsProps> = ({
    blogPosts,
    title,
    className = '',
}): ReactElement => (
    <div className={`related-blog-posts ${className}`}>
        <Wrapper className="related-blog-posts__wrapper">
            {title && (
                <h2 className="related-blog-posts__title">
                    {title}
                </h2>
            )}

            <div className="related-blog-posts__blog-posts-wrapper">
                {blogPosts.map(blogPost => (
                    <BlogPostCard
                        key={blogPost.id}
                        blogPost={blogPost}
                    />
                ))}
            </div>
        </Wrapper>
    </div>
);
