import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet, ConnectedProductList } from '../../../connectors';
import { MeilisearchAttribute } from '../../../entities/@api/Meilisearch';
import { MeilisearchStoreKey } from '../../../entities/@search/Meilisearch/Meilisearch';
import { generateMeilisearchFilterQuery } from '../../../entities/@search/Meilisearch/MeilisearchRequests';
import { AppRoute } from '../../../entities/Routing/Routing';

import './SalePage.scss';

interface SalePageProps {
    className?: string;
}

export const SalePage: FC<SalePageProps> = ({
    className = '',
}): ReactElement => {
    const productsFilterQuery = generateMeilisearchFilterQuery(MeilisearchAttribute.isSale, 'true');

    return (
        <Page className={`sale-page ${className}`}>
            <ConnectedPageHelmet appRoute={AppRoute.sale} />

            <ConnectedBlockRenderer
                appRoute={AppRoute.sale}
                className="sale-page__block"
            />

            <ConnectedProductList
                showFilterBar
                storeKey={MeilisearchStoreKey.sale}
                baseFilter={productsFilterQuery}
                className="sale-page__block"
            />
        </Page>
    );
};
