import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { generateOwnCollectionMeilisearchFilterQuery } from '../../../entities/OwnCollection/OwnCollection';
import { useClientEffect } from '../../../hooks';
import { fetchProductCategoryPageData } from '../../../redux/@products/productCategory/productCategoryActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedOwnCollectionPageChildProps {
    baseFilter: string;
}

interface ConnectedOwnCollectionPageWrapperProps {
    children: (childProps: ConnectedOwnCollectionPageChildProps) => ReactElement;
}

export const ConnectedOwnCollectionPageWrapper: FC<ConnectedOwnCollectionPageWrapperProps> = ({
    children,
}): ReactElement => {
    const { categorySlug = '' } = useParams();

    const dispatch = useTypedDispatch();

    const activeProductCategory = useTypedSelector(state => state.productCategorySlice.activeProductCategory);

    useClientEffect((): void => {
        if (categorySlug) {
            dispatch(fetchProductCategoryPageData({
                slug: categorySlug,
                subCategory: undefined,
                subSubCategory: undefined,
            }));
        }
    }, [categorySlug]);

    const categoryName = categorySlug && activeProductCategory
        ? activeProductCategory.name
        : '';

    const baseFilter = generateOwnCollectionMeilisearchFilterQuery(categoryName);

    return (
        <div>
            {children({ baseFilter })}
        </div>
    );
};
