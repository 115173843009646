import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedDepartmentDetailRenderer } from '../../../connectors';

import './DepartmentDetailPage.scss';

interface DepartmentDetailPageProps {
    className?: string;
}

export const DepartmentDetailPage: FC<DepartmentDetailPageProps> = ({
    className = '',
}): ReactElement => (
    <Page className={`department-detail-page ${className}`}>
        <ConnectedDepartmentDetailRenderer />
    </Page>
);
