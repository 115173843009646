import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Icon, ProductPrice } from '../../components';
import {
    Button,
    CartItem,
    Dialog,
    DialogContent,
    LinkIconButton,
    ShoppingCartCount,
} from '../../compositions';
import { ExtendedOrderInterface } from '../../entities/@shop/Order/Order';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useDeviceWidth, useTrans } from '../../hooks';
import { useTypedDispatch } from '../../redux/store';
import { addPositiveToast } from '../../redux/toast/toastActions';

import './CartSidebar.scss';

interface CartSidebarProps extends ExtendedOrderInterface {
    isLoading: boolean;
    wishlistItems: string[];
    onUpdateCartItem: (productId: string, quantity: number) => void;
    onUpdateCartItemIsInWishlist: (productId: string, isInWishlist: boolean) => void;
    onRemoveItemFromCart: (productId: string) => void;
    productIsAddedToCart: boolean;
    className?: string;
}

export const CartSidebar: FC<CartSidebarProps> = ({
    isLoading,
    items,
    totalPrice,
    subTotalPrice,
    uspItems,
    wishlistItems,
    onUpdateCartItem,
    onUpdateCartItemIsInWishlist,
    onRemoveItemFromCart,
    productIsAddedToCart,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const dispatch = useTypedDispatch();
    const { isMobile } = useDeviceWidth();

    const dialogRef = useRef<HTMLDialogElement>(null);

    const [shouldHide, setShouldHide] = useState<boolean>(false);
    const [layoutIsMini, setLayoutIsMini] = useState<boolean>(false);

    const totalItemsInCart = items.reduce((acc, item) => acc + item.quantity, 0);

    const handleOpenDialog = (): void => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.showModal();
    };

    const handleOpenMiniSideBar = (): void => {
        setLayoutIsMini(true);
        handleOpenDialog();
    };

    useEffect((): void => {
        if (productIsAddedToCart && isMobile) {
            dispatch(addPositiveToast({
                title: trans('containers.cartSidebar.productIsAddedToCartTitle'),
                description: trans('containers.cartSidebar.productIsAddedToCartDescription'),
            }));

            return;
        }

        if (productIsAddedToCart) {
            handleOpenMiniSideBar();
        }
    }, [productIsAddedToCart]);

    const handleCloseDialog = (): void => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.close();
        setShouldHide(false);

        if (layoutIsMini) {
            setLayoutIsMini(false);
        }
    };

    const handleCloseDialogByButton = (): void => {
        setShouldHide(true);
    };

    const dialogClassNames = classNames('cart-sidebar__dialog', {
        'cart-sidebar__dialog--layout-mini': layoutIsMini,
    });

    return (
        <div className={`cart-sidebar ${className}`}>
            <ShoppingCartCount
                count={totalItemsInCart}
                onClick={handleOpenDialog}
            />

            <Dialog
                ref={dialogRef}
                enableBackdropClose
                hasDarkBackdrop={!layoutIsMini}
                onClose={handleCloseDialog}
                shouldHide={shouldHide}
                className={dialogClassNames}
            >
                <DialogContent>
                    <h6 className="cart-sidebar__heading">
                        {trans('common.cart')}
                    </h6>

                    <div className="cart-sidebar__items-wrapper">
                        {items.map(item => (
                            <CartItem
                                {...item}
                                showWishlistButton
                                isEditMode
                                isLoading={isLoading}
                                wishlistItems={wishlistItems}
                                key={item.id}
                                onUpdateCartItem={onUpdateCartItem}
                                onUpdateCartItemIsInWishlist={onUpdateCartItemIsInWishlist}
                                onRemoveItemFromCart={onRemoveItemFromCart}
                                className="cart-sidebar__cart-item"
                            />
                        ))}

                        {items.length === 0 && (
                            <div className="cart-sidebar__no-products-wrapper">
                                <Icon name="cart" className="cart-sidebar__no-products-icon" />

                                <p className="cart-sidebar__no-products-text">
                                    {trans('containers.cartSidebar.noProductsInCart')}
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="cart-sidebar__price-wrapper cart-sidebar__price-wrapper--has-border">
                        <p className="cart-sidebar__subTotal">
                            {trans('containers.cartSidebar.subTotal')}
                        </p>

                        <ProductPrice
                            isCents
                            includeCurrencySymbol
                            current={subTotalPrice}
                            original={subTotalPrice}
                        />
                    </div>

                    <div className="cart-sidebar__price-wrapper">
                        <p className="cart-sidebar__shippingCosts">
                            {trans('containers.cartSidebar.shippingCosts')}
                        </p>

                        {/* @TODO: Add real shipping costs (when available) */}
                        <ProductPrice
                            current={0}
                            original={0}
                        />
                    </div>

                    <div className="cart-sidebar__price-wrapper cart-sidebar__total-price-wrapper">
                        <p className="cart-sidebar__total">
                            {trans('containers.cartSidebar.total')}
                        </p>

                        <ProductPrice
                            isCents
                            includeCurrencySymbol
                            current={totalPrice}
                            original={totalPrice}
                            className="cart-sidebar__total-price"
                        />
                    </div>

                    <div className="cart-sidebar__buttons-wrapper">
                        <LinkIconButton
                            isSmall
                            hasAnimation
                            icon="arrow-right"
                            iconPos={HorizontalAlignment.right}
                            text={trans('common.toCart')}
                            to={trans(appRoutes[AppRoute.checkoutOrder].path)}
                            onClick={handleCloseDialogByButton}
                            className="cart-sidebar__button"
                        />

                        {!layoutIsMini && (
                            <Button
                                isSmall
                                hasAnimation
                                text={trans('common.continueShopping')}
                                onClick={handleCloseDialogByButton}
                                className="cart-sidebar__button cart-sidebar__button--is-outline"
                            />
                        )}
                    </div>

                    {!layoutIsMini && (
                        <ul className="cart-sidebar__usp-list">
                            {uspItems.map(item => (
                                <li
                                    key={item.id}
                                    className="cart-sidebar__usp-item"
                                >
                                    <Icon
                                        name={item.icon}
                                        className="cart-sidebar__usp-item-icon"
                                    />

                                    {item.text}
                                </li>
                            ))}
                        </ul>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};
