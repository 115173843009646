import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
} from '../../../../entities/@forms/FormValidation/FormValidationService';
import { ResetPasswordFormData } from '../../../../entities/@forms/ResetPasswordForm/ResetPasswordForm';

export type ResetPasswordFormErrors = FormErrors<ResetPasswordFormData>;

export const validateResetPasswordFormData = (formData: ResetPasswordFormData): FormValidation<ResetPasswordFormErrors> => {
    const errors: ResetPasswordFormErrors = {
        username: validateEmailAddress(formData.username),
    };

    return validateForm<ResetPasswordFormErrors>(errors);
};
