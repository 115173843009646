import { generateUuid } from '../../../helpers/string';
import { MOCK_IMAGE_7 } from '../../../mock/mock-data';
import { StrapiEntity, StrapiEntityData } from '../../@api/Strapi';
import { Block, BlockResource } from '../../Block/Block';
import { DateResource } from '../../Date/Date';
import { Image, MediaResource } from '../../Media/Media';
import { Seo, SeoResource } from '../../Seo/Seo';
import { ProductListCallToActionInterface, ProductListCallToActionResource } from '../ProductListCallToAction/ProductListCallToAction';

export interface ProductCategoryResource extends StrapiEntityData {
    title: string;
    slug: string;
    description: string;
    sylius_id: number;
    image?: MediaResource;
    callToAction?: ProductListCallToActionResource[];
    elements: BlockResource[];
    seo?: SeoResource;
    createdAt: DateResource;
    publishedAt: DateResource;
    updatedAt: DateResource;
}

export type ProductCategoryResponse = StrapiEntity<ProductCategoryResource[]>;

export interface ProductCategory {
    id: string;
    slug: string;
    name: string;
    children?: ProductCategory[];
    image?: Image;
}

export interface ProductCategoryPageData {
    id: string;
    slug: string;
    title: string;
    image: Image;
    meilisearchProductListName: string;
    callToActions: ProductListCallToActionInterface[];
    blocks: Block[];
    seo?: Seo;
}

enum ProductCategoryHierarchy {
    slug = 'slug',
    subCategory = 'subCategory',
    subSubCategory = 'subSubCategory',
}

export type ProductCategoryParams = Record<ProductCategoryHierarchy, string | undefined>;

export const defaultCategoryPageData = (): ProductCategoryPageData => ({
    id: generateUuid(),
    slug: '',
    title: '',
    meilisearchProductListName: '',
    image: MOCK_IMAGE_7, // TODO: Use different image
    callToActions: [],
    blocks: [],
});
