import {
    MeilisearchAttribute,
    MeilisearchFacetDistribution,
    MeilisearchFacetStats,
    MeilisearchFilterEntry,
    MeilisearchRangeFilterOptions,
    SearchResource,
} from '../../@api/Meilisearch';
import { FormOption } from '../../@forms/Form/Form';
import { transformToCentimeters, transformToCurrency, transformToKilograms } from '../../@products/ProductAttribute/ProductAttributeTransformers';
import { PaginationInterface } from '../../Pagination/Pagination';
import {
    defaultMeilisearchPageSize,
    filterAttributePrefix,
    MeilisearchFilter,
    MeilisearchRangeFilter,
    MeilisearchSearchResponseBase,
    staticFilterAttributes,
} from './Meilisearch';

// Remove attribute prefix from Meilisearch attributes
export const getFilterAttributeKey = (filterAttribute: MeilisearchAttribute): string => filterAttribute.replace(filterAttributePrefix, '');

// Define filter options sorting per attribute
const filterOptionSortFunctions: Partial<Record<MeilisearchAttribute, (a: FormOption, b: FormOption) => number>> = {
    [MeilisearchAttribute.sizing]: (option, comparisonOption) => {
        const sizingOrder = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'];

        const optionIndex = sizingOrder.indexOf(option.label);
        const comparisonOptionIndex = sizingOrder.indexOf(comparisonOption.label);

        return optionIndex - comparisonOptionIndex;
    },
};

export const transformToMeilisearchFilter = (resource: MeilisearchFilterEntry): MeilisearchFilter => {
    const [attribute, options] = resource;

    const typedAttribute = attribute as MeilisearchAttribute;
    const isStaticFilter = staticFilterAttributes.includes(typedAttribute);

    // Show amount of results only on the dynamic filters
    const optionList: FormOption[] = Object.entries(options).map(([value, numberOfResults]) => {
        const label = !isStaticFilter
            ? `${value} (${numberOfResults})`
            : value;

        return { label, value };
    });

    const sortFunction = filterOptionSortFunctions[typedAttribute];
    const sortedOptions = sortFunction
        ? optionList.sort(sortFunction)
        : optionList;

    return {
        attribute: typedAttribute,
        options: sortedOptions,
    };
};

export const transformToProductRangeFilter = (resource: MeilisearchFilterEntry): MeilisearchRangeFilter => {
    const [attribute, options] = resource;

    const typedAttribute = attribute as MeilisearchAttribute;
    const typedOptions = options as MeilisearchRangeFilterOptions;

    // Define steps of range input slider
    const steps: Partial<Record<MeilisearchAttribute, number>> = {
        [MeilisearchAttribute.width]: 100,
        [MeilisearchAttribute.height]: 100,
        [MeilisearchAttribute.depth]: 100,
        [MeilisearchAttribute.price]: 100,
    };

    // Convert values of attribute to human-readable formats
    const valueFormats: Partial<Record<MeilisearchAttribute, (value: number) => string>> = {
        [MeilisearchAttribute.width]: transformToCentimeters,
        [MeilisearchAttribute.height]: transformToCentimeters,
        [MeilisearchAttribute.depth]: transformToCentimeters,
        [MeilisearchAttribute.weight]: transformToKilograms,
        [MeilisearchAttribute.price]: transformToCurrency,
    };

    return {
        attribute: typedAttribute,
        min: typedOptions.min,
        max: typedOptions.max,
        step: steps[typedAttribute],
        valueFormat: valueFormats[typedAttribute],
    };
};

export const accumulateMeilisearchFilters = (accumulator: MeilisearchFacetDistribution | MeilisearchFacetStats, entry: MeilisearchFilterEntry) => {
    const [attribute, options] = entry;

    accumulator[attribute] = {
        ...accumulator[attribute],
        ...options,
    };

    return accumulator;
};

export const transformMeilisearchResourceToPagination = (resource: SearchResource): PaginationInterface => ({
    totalResults: resource.totalHits || 0,
    page: resource.page || 1,
    pageSize: resource.hitsPerPage || defaultMeilisearchPageSize,
    totalPages: resource.totalPages || 0,
});

export const transformToMeilisearchResponseBase = (resource: SearchResource): MeilisearchSearchResponseBase => {
    const pagination = transformMeilisearchResourceToPagination(resource);

    return {
        query: resource.query,
        pagination,
    };
};
