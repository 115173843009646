import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedProductList, ConnectedSpaceDetailRenderer, ConnectedSpaceDetailWrapper } from '../../../connectors';
import { MeilisearchStoreKey } from '../../../entities/@search/Meilisearch/Meilisearch';

import './SpaceDetailPage.scss';

interface SpaceDetailPageProps {
    className?: string;
}

export const SpaceDetailPage: FC<SpaceDetailPageProps> = ({
    className = '',
}): ReactElement => (
    <ConnectedSpaceDetailWrapper>
        {({ name, baseFilter }) => (
            <Page className={`space-detail-page ${className}`}>
                <ConnectedSpaceDetailRenderer title={name} />

                {baseFilter && (
                    <ConnectedProductList
                        showFilterBar
                        storeKey={MeilisearchStoreKey.spaceDetail}
                        baseFilter={baseFilter}
                        className="space-detail-page__block"
                    />
                )}
            </Page>
        )}
    </ConnectedSpaceDetailWrapper>
);
