import { AdjustedOpeningHours, OpeningHours } from '../entities/Store/Store';
import trans from './trans';
import { getTodayIsoDate } from './validation';

const formatTime = (time?: string): string => {
    if (!time) return '';
    return time.split(':').slice(0, 2).join(':');
};

export const getStoreOpeningStatus = (
    openingHours: OpeningHours[],
    adjustedOpeningHours: AdjustedOpeningHours[] = [],
): string => {
    const todayIso = getTodayIsoDate();
    const now = new Date();
    const currentTime = now.toTimeString().split(' ')[0];
    const today = now.getDay() || 7;

    const adjustedHour = adjustedOpeningHours.find(item => item.day === todayIso);

    if (adjustedHour) {
        const todayName = trans(`date.days.${adjustedHour.day}`);

        if (adjustedHour.closed || !adjustedHour.from || !adjustedHour.to) {
            return trans('entities.store.isClosed.day', {
                day: todayName,
            });
        }

        if (currentTime >= adjustedHour.from && currentTime <= adjustedHour.to) {
            return trans('entities.store.isOpen.hourRange', {
                day: todayName,
                from: formatTime(adjustedHour.from),
                to: formatTime(adjustedHour.to),
            });
        }

        return trans('entities.store.isClosed.hourRange', {
            day: todayName,
            from: formatTime(adjustedHour.from),
            to: formatTime(adjustedHour.to),
        });
    }

    const todayHours = openingHours.find(hour => hour.day === today);
    const todayName = trans(`date.days.${today}`);

    if (!todayHours || todayHours.closed || !todayHours.from || !todayHours.to) {
        return trans('entities.store.isClosed.day', {
            day: todayName,
        });
    }

    if (currentTime >= todayHours.from && currentTime <= todayHours.to) {
        return trans('entities.store.isOpen.hourRange', {
            day: todayName,
            from: formatTime(todayHours.from),
            to: formatTime(todayHours.to),
        });
    }

    return trans('entities.store.isClosed.hourRange', {
        day: todayName,
        from: formatTime(todayHours.from),
        to: formatTime(todayHours.to),
    });
};
