import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DepartmentDetailInterface } from '../../../entities/@departments/DepartmentDetail/DepartmentDetail';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type DepartmentDetailState = AsyncReduxState<{
    departmentDetail?: DepartmentDetailInterface;
}>;

const initialState: DepartmentDetailState = {
    ...initialAsyncReduxState,
};

const departmentDetailSlice = createSlice({
    name: 'departmentDetailSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DepartmentDetailState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): DepartmentDetailState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): DepartmentDetailState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DepartmentDetailState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDepartmentDetail(state, action: PayloadAction<DepartmentDetailInterface | undefined>): DepartmentDetailState {
            return {
                ...state,
                departmentDetail: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setDepartmentDetail,
} = departmentDetailSlice.actions;

export default departmentDetailSlice;
