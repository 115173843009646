import trans from '../../../helpers/trans';
import { FetchResult, FetchResultType } from '../../FetchResult/FetchResult';

export const meilisearchFetch = async <ResponseData>(
    endpoint: string,
    options?: RequestInit,
): Promise<FetchResult<ResponseData, string>> => {
    let data: ResponseData | null;

    try {
        const apiUrl = process.env.REACT_APP_MEILISEARCH_HOST_URL || '';
        const apiSecret = process.env.REACT_APP_MEILISEARCH_API_KEY || '';

        const response = await fetch(apiUrl + endpoint, {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Accept-Language': 'nl',
                Authorization: `Bearer ${apiSecret}`,
                ...options?.headers,
            },
        });

        data = await response.json();

        return {
            status: 200,
            type: FetchResultType.Success,
            data: data as ResponseData,
        };
    } catch (error: unknown) {
        console.error('[meilisearchFetch]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    } finally {
        data = null;
    }
};

