import { FC, ReactElement } from 'react';

import { GiftcardBalanceInputs, RadioList } from '../../../../compositions';
import { PaymentMethod } from '../../../../entities/@shop/PaymentMethod/PaymentMethod';
import { useTrans } from '../../../../hooks';

import './PaymentMethodsList.scss';

interface PaymentMethodsListProps {
    paymentMethod: PaymentMethod;
    paymentMethods: PaymentMethod[];
    giftcardPin: string;
    setPaymentMethod: (data: PaymentMethod) => void;
    setGiftcardNumber: (data: string) => void;
    setGiftcardPin: (data: string) => void;
    className?: string;
}

export const PaymentMethodsList: FC<PaymentMethodsListProps> = ({
    paymentMethod,
    paymentMethods,
    giftcardPin,
    setPaymentMethod,
    setGiftcardNumber,
    setGiftcardPin,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const handleChange = (value: string): void => {
        const chosenPaymentMethod = paymentMethods.find(method => method.value === value);

        if (chosenPaymentMethod) {
            setPaymentMethod(chosenPaymentMethod);
        }
    };

    return (
        <div className={`payment-methods-list ${className}`}>
            <RadioList
                hideLabel
                label={trans('containers.checkout.paymentStep.choosePaymentMethod')}
                name="payment-methods-list"
                value={paymentMethod.value}
                options={paymentMethods}
                onChange={handleChange}
                className="payment-methods-list__list"
                radioClassName="payment-methods-list__payment-method-item"
            />

            {paymentMethod.isGiftCard && (
                <div className="payment-methods-list__giftcard-input-wrapper">
                    <GiftcardBalanceInputs
                        pin={giftcardPin}
                        setPin={setGiftcardPin}
                        setCardNumber={setGiftcardNumber}
                        className="payment-methods-list__giftcard-inputs"
                    />

                    <p className="payment-methods-list__giftcard-disclaimer">
                        {trans('containers.checkout.paymentStep.giftcardDisclaimer')}
                    </p>
                </div>
            )}
        </div>
    );
};
