import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Logo, Wrapper } from '../../../../components';
import { LinkIconButton } from '../../../../compositions';
import { ConnectedCartSideBar, ConnectedSearchDrawer } from '../../../../connectors';
import { Customer } from '../../../../entities/@account/Customer/Customer';
import { Navigation } from '../../../../entities/@navigation/Navigation/Navigation';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { useTrans } from '../../../../hooks';
import { excludeMainNavigation, retrieveMainNavigationItem } from '../../helpers';
import { DesktopMainNavigation, DesktopSubNavigation } from '..';

import './DesktopMenu.scss';

interface DesktopMenuProps {
    isAuthenticated: boolean;
    navigation?: Navigation;
    customer?: Customer;
    className?: string;
}

export const DesktopMenu: FC<DesktopMenuProps> = ({
    isAuthenticated,
    navigation,
    customer,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const firstLetterOfCustomerName = customer?.firstName?.charAt(0).toUpperCase() || '';

    const mainNavigationItem = retrieveMainNavigationItem(navigation);

    const mainNavigationLabel = mainNavigationItem?.label;
    const mainNavigation = mainNavigationItem?.subNavigation;

    const subNavigation = excludeMainNavigation(navigation);

    return (
        <div className={`desktop-menu ${className}`}>
            <Wrapper className="desktop-menu__wrapper">
                <DesktopMainNavigation
                    label={mainNavigationLabel}
                    navigation={mainNavigation}
                    className="desktop-menu__main-navigation"
                />

                <Link
                    title={trans('containers.menu.homeLink')}
                    aria-label={trans('containers.menu.homeLink')}
                    to={trans(appRoutes[AppRoute.home].path)}
                >
                    <Logo className="desktop-menu__logo" />
                </Link>

                <div className="desktop-menu__controls">
                    <ConnectedSearchDrawer />

                    {!isAuthenticated && (
                        <>
                            <LinkIconButton
                                hideLabel
                                to={trans(appRoutes[AppRoute.login].path)}
                                icon="user"
                                text={trans('containers.menu.accountDashboard')}
                                className="desktop-menu__link"
                                iconClassName="desktop-menu__link-icon"
                            />

                            <LinkIconButton
                                hideLabel
                                to={trans(appRoutes[AppRoute.wishlist].path)}
                                icon="heart"
                                text={trans('containers.menu.wishlist')}
                                className="desktop-menu__link"
                                iconClassName="desktop-menu__link-icon"
                            />
                        </>
                    )}

                    {isAuthenticated && (
                        <>
                            <Link
                                to={trans(appRoutes[AppRoute.accountDashboard].path)}
                                className="desktop-menu__link"
                            >
                                <span className="desktop-menu__link-icon desktop-menu__account-avatar">
                                    {firstLetterOfCustomerName}
                                </span>
                            </Link>

                            <LinkIconButton
                                hideLabel
                                to={trans(appRoutes[AppRoute.accountWishlist].path)}
                                icon="heart"
                                text={trans('containers.menu.wishlist')}
                                className="desktop-menu__link"
                                iconClassName="desktop-menu__link-icon"
                            />
                        </>
                    )}

                    <ConnectedCartSideBar />
                </div>
            </Wrapper>

            {subNavigation && (
                <DesktopSubNavigation
                    subNavigation={subNavigation}
                    className="desktop-menu__sub-navigation"
                />
            )}
        </div>
    );
};
