import {
    ChangeEvent,
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { ErrorLabel, Input } from '../../../components';
import { Button, InputLabelWrapper, RecaptchaDisclaimer } from '../../../compositions';
import { AddCouponCodeToOrderFormData } from '../../../entities/@forms/CouponCodeForm/CouponCodeForm';
import { FormProps } from '../../../entities/@forms/Form/Form';
import { scrollIntoViewIncludingNavigation } from '../../../helpers/scroll';
import { useTrans } from '../../../hooks';
import { CouponFormErrors, validateCouponCodeFormData } from './validations';

import './CouponCodeForm.scss';

interface CouponCodeFormProps extends FormProps<AddCouponCodeToOrderFormData> {
    className?: string;
}

export const CouponCodeForm: FC<CouponCodeFormProps> = ({
    error,
    onSubmit,
    className = '',
    ...inputProps
}): ReactElement => {
    const trans = useTrans();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<CouponFormErrors>({});
    const [botDetected, setBotDetected] = useState<boolean>(false);

    const [couponCode, setCouponCode] = useState<string>('');
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (hasSubmitted) {
            return;
        }

        if (!executeRecaptcha) {
            setBotDetected(true);
            return;
        }

        const formData: AddCouponCodeToOrderFormData = { discountCode: couponCode };

        const [errors, hasErrors] = validateCouponCodeFormData(formData);
        const token = await executeRecaptcha();

        setFormErrors(errors);
        setBotDetected(!token);

        if (!hasErrors) {
            onSubmit(formData);
            setHasSubmitted(true);
        } else {
            scrollIntoViewIncludingNavigation<HTMLFormElement>(formRef);
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setCouponCode(event.currentTarget.value);
        setHasSubmitted(false);
    };

    const inputLabel = trans('containers.checkout.pricing.discountCode');

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={`coupon-code-form ${className}`}
        >
            <label aria-label={inputLabel} className="coupon-code-form__label">
                <InputLabelWrapper
                    label={inputLabel}
                    tooltip={trans('containers.checkout.pricing.couponCodeTooltip')}
                    error={formErrors.discountCode}
                >
                    <div className="coupon-code-form__field-wrapper">
                        <Input
                            {...inputProps}
                            error={formErrors.discountCode}
                            onChange={handleChange}
                            fieldClassName="coupon-code-form__field"
                        />

                        <Button
                            disabled={!couponCode.length || hasSubmitted}
                            type="submit"
                            text={trans('common.apply')}
                            className="coupon-code-form__visibility-button"
                        />
                    </div>
                </InputLabelWrapper>
            </label>

            <RecaptchaDisclaimer className="coupon-code-form__recaptcha-disclaimer" />

            {botDetected && (
                <ErrorLabel
                    text={trans('errors.unknownError')}
                    className="coupon-code-form__error-label"
                />
            )}

            {error && (
                <ErrorLabel
                    text={error}
                    className="coupon-code-form__error-label"
                />
            )}
        </form>
    );
};
