export * from './Filters';
export * from './Page';
export * from './Product';
export * from './Search';
export * from './Sorting';

export enum MeilisearchIndex {
    pages = 'pages',
    variantsNl = 'variants_nl',
    variantsEn = 'variants_en',
}

export enum MeilisearchAttribute {
    type = 'type',
    id = 'id',
    code = 'code',
    categories = 'categories',
    isNew = 'is_new',
    isSale = 'is_sale',
    ranking = 'ranking',
    stock = 'stock',
    width = 'attributes.Breedte',
    height = 'attributes.Hoogte',
    depth = 'attributes.Diepte',
    weight = 'attributes.Gewicht',
    brand = 'attributes.Merk',
    color = 'attributes.Kleur',
    deliveryMin = 'attributes.DeliveryMin',
    deliveryMax = 'attributes.DeliveryMax',
    material = 'attributes.Materiaal',
    sizing = 'attributes.XS-XXL',
    price = 'price.price',
    priceDiscount = 'price.discount',
    lists = 'lists.name',
}
