import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedProductCategoryRenderer, ConnectedProductCategoryWrapper, ConnectedProductList } from '../../../connectors';
import { MeilisearchAttribute } from '../../../entities/@api/Meilisearch';
import { MeilisearchStoreKey } from '../../../entities/@search/Meilisearch/Meilisearch';

import './ProductCategoryPage.scss';

export const ProductCategoryPage: FC = (): ReactElement => (
    <ConnectedProductCategoryWrapper>
        {({ baseFilter, callToActions }) => (
            <Page className="product-category-page">
                <ConnectedProductCategoryRenderer />

                {baseFilter && (
                    <ConnectedProductList
                        showFilterBar
                        storeKey={MeilisearchStoreKey.productCategory}
                        baseFilter={baseFilter}
                        hiddenFilterAttributes={[MeilisearchAttribute.categories]}
                        callToActions={callToActions}
                    />
                )}
            </Page>
        )}
    </ConnectedProductCategoryWrapper>
);
