import { FC, ReactElement, useRef } from 'react';

import { Button, Dialog, DialogContent } from '../../compositions';
import { useTrans } from '../../hooks';

import './DialogWithButton.scss';

interface DialogWithButton {
    title: string;
    text: string;
    buttonText?: string;
    className?: string;
}

export const DialogWithButton: FC<DialogWithButton> = ({
    title,
    text,
    buttonText,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const dialogRef = useRef<HTMLDialogElement>(null);

    const handleCloseDialog = (): void => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.close();
    };

    const handleOpenDialog = (): void => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.showModal();
    };

    return (
        <div className={`dialog-with-button ${className}`}>
            <Button
                isSmall
                text={buttonText || trans('containers.dialogWithButton.buttonText')}
                onClick={handleOpenDialog}
                className="dialog-with-button__button"
            />

            <Dialog
                ref={dialogRef}
                enableBackdropClose
                hasDarkBackdrop
                onClose={handleCloseDialog}
                className="dialog-with-button__dialog"
            >
                <DialogContent title={title || trans('containers.dialogWithButton.title')}>
                    <p>{text}</p>
                </DialogContent>
            </Dialog>
        </div>
    );
};
