import { FC, ReactElement, useState } from 'react';

import { SwipeDetector, Wrapper } from '../../../components';
import { CarouselInterface } from '../../../entities/@blocks/Carousel/Carousel';
import { Direction } from '../../../entities/@navigation/NavigationControls/NavigationControls';
import { range } from '../../../helpers/array';
import { useTrans } from '../../../hooks';
import { CarouselColumn, CarouselNavigationButton } from './subcomponents';

import './Carousel.scss';

interface CarouselProps extends CarouselInterface {
    className?: string;
}

export const Carousel: FC<CarouselProps> = ({
    id,
    title,
    images,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [direction, setDirection] = useState<Direction>(Direction.next);
    const [isTransitioning, setIsTransitioning] = useState<boolean>(false);

    const columns = range(-2, 3);

    const handleTransitionEnd = (): void => {
        if (!isTransitioning) {
            return;
        }

        setIsTransitioning(false);

        if (direction === Direction.prev) {
            setCurrentIndex((currentIndex - 1 + images.length) % images.length);

            return;
        }

        setCurrentIndex((currentIndex + 1) % images.length);
    };

    const handlePrev = (): void => {
        setIsTransitioning(true);
        setDirection(Direction.prev);
    };

    const handleNext = (): void => {
        setIsTransitioning(true);
        setDirection(Direction.next);
    };

    return (
        <section className={`carousel ${className}`}>
            {title && (
                <Wrapper>
                    <h2 className="carousel__title">
                        {title}
                    </h2>
                </Wrapper>
            )}

            <SwipeDetector
                onSwipeLeft={handleNext}
                onSwipeRight={handlePrev}
                className="carousel__navigation-wrapper"
            >
                <CarouselNavigationButton
                    id={id}
                    direction={Direction.prev}
                    text={trans('common.prev')}
                    disabled={isTransitioning}
                    onClick={handlePrev}
                    className="carousel__navigation-button"
                />

                <CarouselNavigationButton
                    id={id}
                    direction={Direction.next}
                    text={trans('common.next')}
                    disabled={isTransitioning}
                    onClick={handleNext}
                    className="carousel__navigation-button"
                />
            </SwipeDetector>

            <ul className="carousel__column-list">
                {columns.map(column => {
                    const currentImageIndex = (currentIndex + column + images.length) % images.length;
                    const prevImageIndex = (currentImageIndex - 1 + images.length) % images.length;
                    const nextImageIndex = (currentImageIndex + 1) % images.length;

                    const newImageIndex = direction === Direction.prev
                        ? prevImageIndex
                        : nextImageIndex;

                    return (
                        <CarouselColumn
                            key={column}
                            hasRequestedSlide={isTransitioning}
                            direction={direction}
                            currentImage={images[currentImageIndex]}
                            newImage={images[newImageIndex]}
                            onTransitionEnd={column === 0 ? handleTransitionEnd : undefined}
                            className="carousel__column"
                        />
                    );
                })}
            </ul>
        </section>
    );
};
