import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useSearchParams } from 'react-router-dom';

import { Accordion, CheckboxList } from '../../../../compositions';
import { MeilisearchAttribute } from '../../../../entities/@api/Meilisearch';
import { customFilterAttributes, filterAttributePrefix, MeilisearchFilter } from '../../../../entities/@search/Meilisearch/Meilisearch';
import { paginationPageParameter } from '../../../../entities/Pagination/Pagination';
import { useTrans } from '../../../../hooks';

import './FilterAccordion.scss';

interface FilterAccordionProps extends MeilisearchFilter {
    className?: string;
}

export const FilterAccordion: FC<FilterAccordionProps> = ({
    attribute,
    options,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

    const label = useMemo((): string => {
        if (customFilterAttributes.includes(attribute)) {
            return trans(`entities.meilisearch.customAttributes.${attribute}`);
        }

        return attribute.replace(filterAttributePrefix, '');
    }, [trans, attribute]);

    useEffect((): void => {
        const attributeParams = searchParams.get(attribute);

        if (attributeParams) {
            setSelectedCheckboxes(attributeParams.toString().split(','));
        }
    }, []);

    const handleChangeSelectedCheckboxes = (selectedValues: string[]): void => {
        if (selectedValues.length > 0) {
            searchParams.set(attribute, selectedValues.join(','));
        } else {
            searchParams.delete(attribute);
        }

        searchParams.set(paginationPageParameter, '1');
        setSearchParams(searchParams, { replace: true });
        setSelectedCheckboxes(selectedValues);
    };

    return (
        <Accordion
            hasBorder
            key={attribute}
            header={label}
            className={`filter-accordion ${className}`}
            headingClassName="filter-accordion__heading"
        >
            <CheckboxList
                hideLabel
                showColorIndicator={attribute === MeilisearchAttribute.color}
                name={attribute}
                label={label}
                value={selectedCheckboxes}
                options={options}
                onChange={handleChangeSelectedCheckboxes}
            />
        </Accordion>
    );
};
