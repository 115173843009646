import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { ErrorLabel, Picture } from '../../../../../components';
import {
    AccountInfoBlock,
    Button,
    Checkbox,
    PasswordInput,
    RecaptchaDisclaimer,
    SelectInput,
    TextInput,
} from '../../../../../compositions';
import { FormOption } from '../../../../../entities/@forms/Form/Form';
import { RegistrationFormData } from '../../../../../entities/@forms/RegistrationForm/RegistrationForm';
import { countryOptions } from '../../../../../entities/Country/Country';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { useTrans } from '../../../../../hooks';
import { RegistrationFormErrors } from '../../validations';

import './RegistrationFormStepAccountDetails.scss';

interface RegistrationFormStepAccountDetailsProps {
    formData: RegistrationFormData;
    setFormData: (formData: RegistrationFormData) => void;
    formErrors: RegistrationFormErrors;
    botDetected: boolean;
    isLoading?: boolean;
    error?: string;
    className?: string;
}

export const RegistrationFormStepAccountDetails: FC<RegistrationFormStepAccountDetailsProps> = ({
    formData,
    setFormData,
    formErrors,
    botDetected,
    isLoading,
    error,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const setFirstName = (firstName: string): void => setFormData({ ...formData, firstName });
    const setLastName = (lastName: string): void => setFormData({ ...formData, lastName });
    const setEmail = (email: string): void => setFormData({ ...formData, email });

    const currentCountry = countryOptions.find(option => option.value === formData.countryCode) || countryOptions[0];

    const setStreet = (street: string): void => setFormData({ ...formData, street });
    const setNumber = (number: string): void => setFormData({ ...formData, number });
    const setAddition = (addition: string): void => setFormData({ ...formData, addition });
    const setPostalCode = (postalCode: string): void => setFormData({ ...formData, postcode: postalCode });
    const setCity = (city: string): void => setFormData({ ...formData, city });
    const setCountry = (country: FormOption): void => setFormData({ ...formData, countryCode: country.value.toUpperCase() });

    const setPassword = (password: string): void => setFormData({ ...formData, password });
    const setConfirmPassword = (confirmPassword: string): void => setFormData({ ...formData, confirmPassword });
    const setAcceptTermsAndConditions = (acceptTermsAndConditions: boolean): void => setFormData({ ...formData, acceptTermsAndConditions });
    const setSubscribedToNewsletter = (subscribedToNewsletter: boolean): void => setFormData({ ...formData, subscribedToNewsletter });

    const handleCountryDropDown = (value: string): void => {
        const correctCountry = countryOptions.find(item => item.value === value) as FormOption;
        setCountry(correctCountry);
    };

    return (
        <div className={`registration-form-step-account-details ${className}`}>
            <div className="registration-form-step-account-details__column">
                <div className="registration-form-step-account-details__content registration-form-step-account-details__content--personal-data">
                    <h2 className="registration-form-step-account-details__title">
                        {trans('containers.registrationForm.personalDetailsStepHeading')}
                    </h2>

                    <p className="registration-form-step-account-details__description">
                        {trans('containers.registrationForm.personalDetailsStepDescription')}
                    </p>

                    <AccountInfoBlock
                        title={trans('containers.registrationForm.personalDetailsSectionLabel')}
                        className="registration-form-step-account-details__info-block"
                    >
                        <div className="registration-form-step-account-details__form-section">
                            <div className="registration-form-step-account-details__input-row">
                                <TextInput
                                    type="text"
                                    autoComplete="given-name"
                                    autoCapitalize="words"
                                    label={trans('containers.registrationForm.firstNameLabel')}
                                    placeholder={trans('containers.registrationForm.firstNamePlaceholder')}
                                    value={formData.firstName}
                                    error={formErrors.firstName}
                                    disabled={isLoading}
                                    onChange={setFirstName}
                                    className="registration-form-step-account-details__text-input"
                                />

                                <TextInput
                                    type="text"
                                    autoComplete="family-name"
                                    autoCapitalize="words"
                                    label={trans('containers.registrationForm.lastNameLabel')}
                                    placeholder={trans('containers.registrationForm.lastNamePlaceholder')}
                                    value={formData.lastName}
                                    error={formErrors.lastName}
                                    disabled={isLoading}
                                    onChange={setLastName}
                                    className="registration-form-step-account-details__text-input"
                                />
                            </div>

                            <TextInput
                                type="email"
                                inputMode="email"
                                autoComplete="email"
                                label={trans('containers.registrationForm.emailLabel')}
                                placeholder={trans('containers.registrationForm.emailPlaceholder')}
                                value={formData.email}
                                error={formErrors.email}
                                disabled={isLoading}
                                onChange={setEmail}
                                className="registration-form-step-account-details__text-input"
                            />
                        </div>
                    </AccountInfoBlock>

                    <AccountInfoBlock
                        title={trans('containers.registrationForm.addressDetailsSectionLabel')}
                        className="registration-form-step-account-details__info-block"
                    >
                        <div className="registration-form-step-account-details__form-section">
                            <div className="registration-form-step-account-details__input-row">
                                <TextInput
                                    autoCapitalize="words"
                                    label={trans('form.street')}
                                    placeholder={trans('form.street')}
                                    value={formData.street}
                                    error={formErrors.street}
                                    disabled={isLoading}
                                    onChange={setStreet}
                                    className="registration-form-step-account-details__text-input"
                                />

                                <TextInput
                                    type="number"
                                    inputMode="numeric"
                                    label={trans('form.houseNumber')}
                                    placeholder={trans('form.houseNumber')}
                                    value={formData.number}
                                    error={formErrors.number}
                                    disabled={isLoading}
                                    onChange={setNumber}
                                    className="registration-form-step-account-details__text-input"
                                />

                                <TextInput
                                    autoCapitalize="words"
                                    label={trans('form.addition')}
                                    placeholder={trans('form.addition')}
                                    value={formData.addition || ''}
                                    error={formErrors.addition}
                                    disabled={isLoading}
                                    onChange={setAddition}
                                    className="registration-form-step-account-details__text-input"
                                />
                            </div>

                            <div className="registration-form-step-account-details__input-row">
                                <TextInput
                                    autoCapitalize="words"
                                    label={trans('form.postalCode')}
                                    placeholder={trans('form.postalCode')}
                                    maxLength={7}
                                    value={formData.postcode}
                                    error={formErrors.postcode}
                                    disabled={isLoading}
                                    onChange={setPostalCode}
                                    className="registration-form-step-account-details__text-input"
                                />

                                <TextInput
                                    autoCapitalize="words"
                                    label={trans('form.city')}
                                    placeholder={trans('form.city')}
                                    value={formData.city}
                                    error={formErrors.city}
                                    disabled={isLoading}
                                    onChange={setCity}
                                    className="registration-form-step-account-details__text-input"
                                />
                            </div>

                            <SelectInput
                                name="countrySelect"
                                options={countryOptions}
                                label={trans('form.country')}
                                error={formErrors.countryCode}
                                placeholder={formData.countryCode}
                                value={currentCountry}
                                onChange={handleCountryDropDown}
                            />
                        </div>
                    </AccountInfoBlock>
                </div>
            </div>

            <div className="registration-form-step-account-details__column">
                <div className="registration-form-step-account-details__content registration-form-step-account-details__content--account">
                    <AccountInfoBlock
                        title={trans('containers.registrationForm.accountSectionLabel')}
                        className="registration-form-step-account-details__info-block"
                    >
                        <div className="registration-form-step-account-details__form-section">
                            <PasswordInput
                                autoComplete="new-password"
                                label={trans('containers.registrationForm.passwordLabel')}
                                placeholder={trans('containers.registrationForm.passwordPlaceholder')}
                                value={formData.password}
                                error={formErrors.password}
                                disabled={isLoading}
                                onChange={setPassword}
                                className="registration-form-step-account-details__text-input"
                                fieldClassName="registration-form-step-account-details__password-input-field"
                            />

                            <PasswordInput
                                autoComplete="new-password"
                                label={trans('containers.registrationForm.confirmPasswordLabel')}
                                placeholder={trans('containers.registrationForm.confirmPasswordPlaceholder')}
                                value={formData.confirmPassword}
                                error={formErrors.confirmPassword}
                                disabled={isLoading}
                                onChange={setConfirmPassword}
                                className="registration-form-step-account-details__text-input"
                                fieldClassName="registration-form-step-account-details__password-input-field"
                            />

                            <Checkbox
                                htmlLabel={trans('containers.registrationForm.acceptTermsAndConditions')}
                                disabled={isLoading}
                                checked={formData.acceptTermsAndConditions}
                                error={formErrors.acceptTermsAndConditions}
                                onChange={setAcceptTermsAndConditions}
                                className="registration-form-step-account-details__terms-input"
                            />

                            <Checkbox
                                label={trans('form.newsLetterSubscription')}
                                disabled={isLoading}
                                checked={formData.subscribedToNewsletter}
                                onChange={setSubscribedToNewsletter}
                                className="registration-form-step-account-details__newsletter-subscription-input"
                            />
                        </div>
                    </AccountInfoBlock>

                    <Button
                        hasAnimation
                        type="submit"
                        text={trans('containers.registrationForm.submitButton')}
                        className="registration-form-step-account-details__submit-button"
                    />

                    <RecaptchaDisclaimer className="registration-form-step-account-details__recaptcha-disclaimer" />

                    {botDetected && (
                        <ErrorLabel text={trans('errors.unknownError')} />
                    )}

                    {error && (
                        <div className="registration-form-step-account-details__error-wrapper">
                            <ErrorLabel
                                text={error}
                                className="registration-form-step-account-details__error-label"
                            />

                            {error.includes(trans('containers.registrationForm.emailExistsErrorMessagePattern')) && (
                                <Link
                                    to={trans(appRoutes[AppRoute.login].path)}
                                    className="registration-form-step-account-details__login-button"
                                >
                                    {trans('containers.registrationForm.loginButton')}
                                </Link>
                            )}
                        </div>
                    )}
                </div>

                <div className="registration-form-step-account-details__header-image">
                    <Picture
                        src="/mock/image/registration-step-2.webp"
                        className="registration-form-step-account-details__header-picture"
                    />
                </div>
            </div>
        </div>
    );
};
