import {
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
} from 'react';

import { useLocation } from 'react-router-dom';

import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { scrollToTop } from '../../../helpers/scroll';
import { useClientEffect, useTimeout, useTrans } from '../../../hooks';
import { fetchCustomer } from '../../../redux/@account/customer/customerActions';
import { fetchCustomPageSlugs } from '../../../redux/@pages/customPages/customPagesActions';
import { fetchProductCategorySlugs } from '../../../redux/@products/productCategories/productCategoriesActions';
import { setAllowScrollReset } from '../../../redux/app/appSlice';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

export const ConnectedAppWrapper: FC<PropsWithChildren> = ({
    children,
}): ReactElement => {
    const trans = useTrans();
    const { pathname, hash } = useLocation();

    const dispatch = useTypedDispatch();

    const allowScrollReset = useTypedSelector(state => state.appSlice.allowScrollReset);

    useClientEffect((): void => {
        dispatch(fetchCustomPageSlugs());
        dispatch(fetchProductCategorySlugs());
    }, []);

    useEffect((): void => {
        const isCheckoutThankYouPage = pathname === trans(appRoutes[AppRoute.checkoutThankYou].path);

        dispatch(fetchCustomer({
            fetchCartAndWishlist: true,
            isCheckoutThankYouPage,
        }));
    }, [pathname]);

    useEffect((): void => {
        if (allowScrollReset && !hash) {
            scrollToTop('auto');
        }
    }, [pathname]);

    // Reset scroll lock boolean
    useTimeout((): void => {
        if (!allowScrollReset) {
            dispatch(setAllowScrollReset(true));
        }
    }, 10, [allowScrollReset]);

    return (
        <div>
            {children}
        </div>
    );
};
