import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../../../../components';
import { LinkIconButton } from '../../../../../compositions';
import { ConnectedOrderDetailRenderer } from '../../../../../connectors';
import { HorizontalAlignment } from '../../../../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { useTrans } from '../../../../../hooks';

import './AccountOrderDetailTab.scss';

export const AccountOrderDetailTab: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="account-order-detail-tab">
            <Wrapper className="account-order-detail-tab__wrapper">
                <LinkIconButton
                    isSmall
                    to={trans(appRoutes[AppRoute.accountOrderOverview].path)}
                    icon="chevron-left"
                    iconPos={HorizontalAlignment.left}
                    text={trans('pages.account.tabs.orderDetail.backButtonText')}
                    className="account-order-detail-tab__back-button"
                    iconClassName="account-order-detail-tab__back-button-icon"
                />

                <ConnectedOrderDetailRenderer className="account-order-detail-tab__renderer" />
            </Wrapper>
        </Page>
    );
};
