import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchableOption } from '../../../entities/@forms/Form/Form';
import { StoreLocation } from '../../../entities/Map/Map';
import { Store } from '../../../entities/Store/Store';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type StoresState = AsyncReduxState<{
    searchResults: SearchableOption[];
    storeLocations: StoreLocation[];
    stores: Store[],
}>;

const initialState: StoresState = {
    ...initialAsyncReduxState,
    searchResults: [],
    storeLocations: [],
    stores: [],
};

const storesSlice = createSlice({
    name: 'storesSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): StoresState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): StoresState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): StoresState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): StoresState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setStoreLocations(state, action: PayloadAction<StoreLocation[]>): StoresState {
            return {
                ...state,
                storeLocations: action.payload,
            };
        },
        setSearchResults(state, action: PayloadAction<SearchableOption[]>): StoresState {
            return {
                ...state,
                searchResults: action.payload,
            };
        },
        setStores(state, action: PayloadAction<Store[]>): StoresState {
            return {
                ...state,
                stores: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setStoreLocations,
    setSearchResults,
    setStores,
} = storesSlice.actions;

export default storesSlice;
