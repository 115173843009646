import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../../../../components';
import { IconButton, TextInput } from '../../../../../compositions';
import { RegistrationFormData } from '../../../../../entities/@forms/RegistrationForm/RegistrationForm';
import { HorizontalAlignment } from '../../../../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { useTrans } from '../../../../../hooks';
import { RegistrationFormErrors } from '../../validations';

import './RegistrationFormStepEmail.scss';

interface RegistrationFormStepEmailProps {
    formData: RegistrationFormData;
    setFormData: (formData: RegistrationFormData) => void;
    formErrors: RegistrationFormErrors;
    isLoading?: boolean;
    className?: string;
}

export const RegistrationFormStepEmail: FC<RegistrationFormStepEmailProps> = ({
    formData,
    setFormData,
    formErrors,
    isLoading,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const setEmail = (email: string): void => setFormData({ ...formData, email });

    return (
        <div className={`registration-form-step-email ${className}`}>
            <div className="registration-form-step-email__content">
                <h2 className="registration-form-step-email__title">
                    {trans('containers.registrationForm.emailStepHeading')}
                </h2>

                <p className="registration-form-step-email__description">
                    {trans('containers.registrationForm.emailStepDescription')}
                </p>

                <TextInput
                    type="email"
                    inputMode="email"
                    autoComplete="email"
                    label={trans('containers.registrationForm.emailLabel')}
                    placeholder={trans('containers.registrationForm.emailPlaceholder')}
                    value={formData.email}
                    error={formErrors.email}
                    disabled={isLoading}
                    onChange={setEmail}
                    className="registration-form-step-email__email-input"
                />

                <IconButton
                    hasAnimation
                    type="submit"
                    icon="arrow-right"
                    iconPos={HorizontalAlignment.right}
                    text={trans('containers.registrationForm.nextStepButton')}
                    className="registration-form-step-email__next-step-button"
                />

                <Link
                    to={trans(appRoutes[AppRoute.login].path)}
                    className="registration-form-step-email__login-button"
                >
                    {trans('containers.registrationForm.loginButton')}
                </Link>
            </div>

            <div className="registration-form-step-email__content registration-form-step-email__content--image">
                <div className="registration-form-step-email__image">
                    <Picture
                        src="/mock/image/registration-step-1.webp"
                        className="registration-form-step-email__picture"
                    />
                </div>
            </div>
        </div>
    );
};
