import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { CheckboxList, IconButton, TextInput } from '../../../../compositions';
import { MeilisearchAttribute } from '../../../../entities/@api/Meilisearch';
import { FormOptionChunk } from '../../../../entities/@forms/Form/Form';
import { customFilterAttributes, filterAttributePrefix, MeilisearchFilter } from '../../../../entities/@search/Meilisearch/Meilisearch';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';
import { paginationPageParameter } from '../../../../entities/Pagination/Pagination';
import { divideArrayInEqualChunks } from '../../../../helpers/array';
import { useHandleClickOutside, useTrans } from '../../../../hooks';

import './HighlightedFilter.scss';

interface HighlightedFilterProps extends MeilisearchFilter {
    isExpanded: boolean;
    showSearchInput?: boolean;
    searchInputLabel: string;
    onToggle: () => void;
    className?: string;
}

export const HighlightedFilter: FC<HighlightedFilterProps> = ({
    isExpanded,
    showSearchInput,
    searchInputLabel,
    attribute,
    options,
    onToggle,
    className = '',
}): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();
    const trans = useTrans();

    const toggleButtonRef = useRef<HTMLButtonElement>(null);
    const filterDrawerRef = useRef<HTMLDivElement>(null);

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

    useHandleClickOutside([toggleButtonRef, filterDrawerRef], (): void => {
        if (isExpanded) {
            onToggle();
        }
    });

    useEffect((): void => {
        const attributeParams = searchParams.get(attribute);

        if (attributeParams) {
            setSelectedCheckboxes(attributeParams.toString().split(','));
        }
    }, []);

    const label = useMemo((): string => {
        if (customFilterAttributes.includes(attribute)) {
            return trans(`entities.meilisearch.customAttributes.${attribute}`);
        }

        return attribute.replace(filterAttributePrefix, '');
    }, [trans, attribute]);

    const optionListChunks = useMemo((): FormOptionChunk[] => {
        const filteredOptions = searchQuery.length > 0
            ? options.filter(option => option.label.toLowerCase().includes(searchQuery.toLowerCase()))
            : options;

        const minChunkSize = 8;
        const equalChunkDivision = Math.ceil(filteredOptions.length / 4);
        const chunkSize = Math.max(minChunkSize, equalChunkDivision);

        const dividedOptions = divideArrayInEqualChunks(filteredOptions, chunkSize);

        return dividedOptions.map((chunkOptions, index) => ({
            id: index.toString(),
            options: chunkOptions,
        }));
    }, [searchQuery, options]);

    const handleChangeSelectedCheckboxes = (selectedValues: string[]): void => {
        if (selectedValues.length > 0) {
            searchParams.set(attribute, selectedValues.join(','));
        } else {
            searchParams.delete(attribute);
        }

        searchParams.set(paginationPageParameter, '1');
        setSearchParams(searchParams, { replace: true });
        setSelectedCheckboxes(selectedValues);
    };

    const filterButtonClassNames = classNames('highlighted-filter__filter-button', {
        'highlighted-filter__filter-button--is-active': isExpanded,
    });

    const filterDrawerClassNames = classNames('highlighted-filter__filter-drawer', {
        'highlighted-filter__filter-drawer--is-open': isExpanded,
    });

    return (
        <div className={`highlighted-filter ${className}`}>
            <IconButton
                ref={toggleButtonRef}
                isSmall
                icon={isExpanded ? 'chevron-up' : 'chevron-down'}
                iconPos={HorizontalAlignment.right}
                text={label}
                onClick={onToggle}
                className={filterButtonClassNames}
                labelClassName="highlighted-filter__filter-button-label"
            />

            <div ref={filterDrawerRef} className={filterDrawerClassNames}>
                <div className="highlighted-filter__label-and-search-input-wrapper">
                    <h6 className="highlighted-filter__label">
                        {label}
                    </h6>

                    {showSearchInput && (
                        <TextInput
                            hideLabel
                            hasUnderline
                            type="search"
                            inputMode="search"
                            icon="search"
                            label={searchInputLabel}
                            placeholder={searchInputLabel}
                            value={searchQuery}
                            onChange={setSearchQuery}
                            className="highlighted-filter__search-input"
                        />
                    )}
                </div>

                {optionListChunks.length === 0 && (
                    <p className="highlighted-filter__no-results">
                        {trans('containers.productListFilters.highlightedFilters.noResults')}
                    </p>
                )}

                {optionListChunks.map(optionsChunk => (
                    <CheckboxList
                        key={optionsChunk.id}
                        hideLabel
                        showColorIndicator={attribute === MeilisearchAttribute.color}
                        name={attribute}
                        label={label}
                        value={selectedCheckboxes}
                        options={optionsChunk.options}
                        onChange={handleChangeSelectedCheckboxes}
                    />
                ))}
            </div>
        </div>
    );
};
