import { FC, ReactElement, useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Page, Wrapper } from '../../../components';
import { ConnectedBlockRenderer } from '../../../connectors';
import { AccountNavigationSidebar } from '../../../containers/AccountNavigationSidebar/AccountNavigationSidebar';
import { AccountPages, AccountParams } from '../../../entities/@account/Account/Account';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { useRouteByTranslatedPath, useTrans } from '../../../hooks';
import { useTypedSelector } from '../../../redux/store';
import {
    AccountChangePasswordTab,
    AccountDashboardTab,
    AccountDataTab,
    AccountOrderDetailTab,
    AccountOrderOverviewTab,
    AccountWishlistTab,
} from './subcomponents';

import './AccountPage.scss';

interface AccountPageProps {
    page?: string;
}

export const AccountPage: FC<AccountPageProps> = (): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { id } = useParams<AccountParams>();
    const routeByTranslatedPath = useRouteByTranslatedPath();

    const translatedTab = routeByTranslatedPath(pathname, { id });

    const hasFetched = useTypedSelector(state => state.customerSlice.hasFetched);
    const isAuthenticated = useTypedSelector(state => state.authenticationSlice.isAuthenticated);

    useEffect((): void => {
        if (hasFetched && !isAuthenticated) {
            navigate(trans(appRoutes[AppRoute.login].path));
        }
    }, [hasFetched, isAuthenticated]);

    return (
        <Page className="account-page">
            <Wrapper className="account-page__wrapper">
                <AccountNavigationSidebar className="account-page__sidebar" />

                <div className="account-page__contents">
                    {translatedTab === AccountPages.dashboard && (
                        <AccountDashboardTab />
                    )}

                    {translatedTab === AccountPages.data && (
                        <AccountDataTab />
                    )}

                    {translatedTab === AccountPages.orderOverview && (
                        <AccountOrderOverviewTab />
                    )}

                    {translatedTab === AccountPages.orderDetail && (
                        <AccountOrderDetailTab />
                    )}

                    {translatedTab === AccountPages.wishlist && (
                        <AccountWishlistTab />
                    )}

                    {translatedTab === AccountPages.changePassword && (
                        <AccountChangePasswordTab />
                    )}
                </div>
            </Wrapper>

            {translatedTab === AccountPages.orderDetail && (
                <ConnectedBlockRenderer appRoute={AppRoute.accountOrderDetail} className="account-page__block" />
            )}

            {translatedTab !== AccountPages.orderDetail && (
                <ConnectedBlockRenderer appRoute={AppRoute.account} className="account-page__block" />
            )}
        </Page>
    );
};
