import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import {
    ConnectedBlockRenderer,
    ConnectedGiftcardBalanceForm,
    ConnectedPageHelmet,
    ConnectedProductList,
} from '../../connectors';
import { MeilisearchAttribute } from '../../entities/@api/Meilisearch';
import { MeilisearchStoreKey } from '../../entities/@search/Meilisearch/Meilisearch';
import { generateMeilisearchFilterQuery } from '../../entities/@search/Meilisearch/MeilisearchRequests';
import { AppRoute } from '../../entities/Routing/Routing';

import './GiftcardBalancePage.scss';

interface GiftcardBalancePageProps {
    className?: string;
}

export const GiftcardBalancePage: FC<GiftcardBalancePageProps> = ({
    className = '',
}): ReactElement => {
    const baseFilter = generateMeilisearchFilterQuery(MeilisearchAttribute.categories, 'Cadeaukaarten');

    return (
        <Page className={`giftcard-balance-page ${className}`}>
            <ConnectedPageHelmet appRoute={AppRoute.giftcardBalance} />

            <div className="giftcard-balance-page__form-section">
                <Wrapper className="giftcard-balance-page__form-wrapper">
                    <ConnectedGiftcardBalanceForm className="giftcard-balance-page__form" />
                </Wrapper>
            </div>

            <ConnectedProductList
                storeKey={MeilisearchStoreKey.giftcard}
                baseFilter={baseFilter}
                className="giftcard-balance-page__product-list"
            />

            <ConnectedBlockRenderer
                appRoute={AppRoute.giftcardBalance}
                className="giftcard-balance-page__block-renderer"
            />
        </Page>
    );
};
