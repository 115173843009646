import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type GiftcardBalanceFormState = AsyncReduxState<{
    balance?: number;
}>;

const initialState: GiftcardBalanceFormState = {
    ...initialAsyncReduxState,
};

const giftcardBalanceFormSlice = createSlice({
    name: 'giftcardBalanceFormSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): GiftcardBalanceFormState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): GiftcardBalanceFormState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): GiftcardBalanceFormState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): GiftcardBalanceFormState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBalance(state, action: PayloadAction<number | undefined>): GiftcardBalanceFormState {
            return {
                ...state,
                balance: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setBalance,
} = giftcardBalanceFormSlice.actions;

export default giftcardBalanceFormSlice;
