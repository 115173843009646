import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { ConnectedBlockRenderer, ConnectedProductCard } from '../../../connectors';
import { Product } from '../../../entities/@products/Product/Product';
import { Brand } from '../../../entities/Brand/Brand';
import { Link } from '../../../entities/Link/Link';
import { AppRoute } from '../../../entities/Routing/Routing';
import { useTrans } from '../../../hooks';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import { SliderSection } from '../../SliderSection/SliderSection';
import { ProductDetailRendererSkeletons } from './skeletons';
import {
    ProductBrand,
    ProductDetail,
    ProductDetailHelmet,
    ProductInformation,
} from './subcomponents';

import './ProductDetailRenderer.scss';

interface ProductDetailRendererProps {
    productIsLoading: boolean;
    cartIsLoading: boolean;
    wishlistIsLoading: boolean;
    breadcrumbs: Link[];
    product?: Product;
    brand?: Brand;
    brandProducts: Product[];
    relatedProducts: Product[];
    className?: string;
}

export const ProductDetailRenderer: FC<ProductDetailRendererProps> = ({
    productIsLoading,
    cartIsLoading,
    wishlistIsLoading,
    breadcrumbs,
    product,
    brand,
    brandProducts,
    relatedProducts,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    if (productIsLoading || !product) {
        return (
            <ProductDetailRendererSkeletons className={`product-detail-renderer ${className}`} />
        );
    }

    return (
        <div className={`product-detail-renderer ${className}`}>
            <ProductDetailHelmet product={product} />

            <Wrapper>
                <Breadcrumbs
                    isLoading={productIsLoading}
                    breadcrumbs={breadcrumbs}
                    className="product-detail-renderer__breadcrumbs"
                />
            </Wrapper>

            <Wrapper className="product-detail-renderer__detail-wrapper">
                <ProductDetail
                    cartIsLoading={cartIsLoading}
                    wishlistIsLoading={wishlistIsLoading}
                    product={product}
                />
            </Wrapper>

            <Wrapper className="product-detail-renderer__information-wrapper">
                <ProductInformation product={product} />
            </Wrapper>

            {brand && (
                <ProductBrand
                    brand={brand}
                    products={brandProducts}
                    className="product-detail-renderer__brand-section"
                />
            )}

            {relatedProducts.length > 0 && (
                <SliderSection
                    titleHasThemeColor
                    id="related-products"
                    title={trans('containers.productDetailRenderer.relatedProducts')}
                    items={relatedProducts.map(relatedProduct => (
                        <ConnectedProductCard
                            product={relatedProduct}
                            className="product-detail-renderer__related-products-product"
                            pictureWrapperClassName="product-detail-renderer__related-products-picture-wrapper"
                        />
                    ))}
                    className="product-detail-renderer__related-products-slider"
                />
            )}

            <ConnectedBlockRenderer appRoute={AppRoute.productDetail} />
        </div>
    );
};
