import { FormErrors } from '../FormValidation/FormValidationService';

export interface GuestFormData {
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    shippingAddressStreet: string;
    shippingAddressNumber: string;
    shippingAddressAddition: string;
    shippingAddressCity: string;
    shippingAddressPostalCode: string;
    billingAddressStreet?: string;
    billingAddressNumber?: string;
    billingAddressAddition?: string;
    billingAddressCity?: string;
    billingAddressPostalCode?: string;
    subscribedToNewsletter: boolean;
    useShippingAddressForBilling: boolean;
}

export interface GuestAddress {
    label?: string;
    firstName?: string;
    lastName?: string;
    country: string;
    street: string;
    number: string;
    addition: string;
    city: string;
    postalCode: string;
}

export interface UpdateCartFormData {
    email?: string;
    firstName?: string;
    shippingAddress: GuestAddress;
    billingAddress: GuestAddress;
    subscribedToNewsletter?: boolean;
}

export interface CreateAccountDuringCheckoutFormData {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    shippingAddress: GuestAddress;
    billingAddress: GuestAddress;
    subscribedToNewsletter: boolean;
}

export type PayAsGuestFormErrors = FormErrors<GuestFormData>;

export const defaultGuestFormData = (): GuestFormData => ({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    shippingAddressCity: '',
    shippingAddressPostalCode: '',
    shippingAddressStreet: '',
    shippingAddressNumber: '',
    shippingAddressAddition: '',
    billingAddressCity: '',
    billingAddressPostalCode: '',
    billingAddressStreet: '',
    billingAddressNumber: '',
    billingAddressAddition: '',
    subscribedToNewsletter: false,
    useShippingAddressForBilling: true,
});
