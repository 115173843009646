import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { BlogPostRenderer, NotFound } from '../../../containers';
import { MeilisearchAttribute } from '../../../entities/@api/Meilisearch';
import { MeilisearchStoreKey } from '../../../entities/@search/Meilisearch/Meilisearch';
import { generateMeilisearchFilterQuery } from '../../../entities/@search/Meilisearch/MeilisearchRequests';
import { useClientEffect, useTrans } from '../../../hooks';
import { fetchBlogPost } from '../../../redux/@blog/blogPost/blogPostActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { ConnectedProductList } from '../../ConnectedProductList/ConnectedProductList';

interface ConnectedBlogPostRendererProps {
    className?: string;
}

export const ConnectedBlogPostRenderer: FC<ConnectedBlogPostRendererProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();
    const trans = useTrans();

    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.blogPostSlice.isLoading);
    const hasFetched = useTypedSelector(state => state.blogPostSlice.hasFetched);
    const blogPost = useTypedSelector(state => state.blogPostSlice.blogPost);

    useClientEffect((): void => {
        dispatch(fetchBlogPost(slug));
    }, [slug]);

    if (hasFetched && !blogPost) {
        return (
            <NotFound />
        );
    }

    const baseFilter = blogPost?.meilisearchProductListName
        ? generateMeilisearchFilterQuery(MeilisearchAttribute.lists, blogPost.meilisearchProductListName)
        : '';

    return (
        <div className={className}>
            <BlogPostRenderer
                isLoading={isLoading}
                blogPost={blogPost}
            />

            {baseFilter && (
                <ConnectedProductList
                    storeKey={MeilisearchStoreKey.blogPost}
                    title={trans('pages.blogPost.shopTheLook')}
                    baseFilter={baseFilter}
                    className="brand-detail-page__block"
                />
            )}
        </div>
    );
};
