import { FC, ReactElement } from 'react';

import { MediaGrid } from '../../../../../containers';
import {
    MOCK_IMAGE_1,
    MOCK_IMAGE_2,
    MOCK_IMAGE_3,
    MOCK_VIDEO,
} from '../../../../../mock/mock-data';

import './MediaGridExamples.scss';

interface MediaGridProps {
    className?: string;
}

export const MediaGridExamples: FC<MediaGridProps> = ({
    className = '',
}): ReactElement => (
    <div className={`media-grid-examples ${className}`}>
        <MediaGrid
            mediaItems={[
                { id: '1', image: MOCK_IMAGE_1 },
                { id: '2', image: MOCK_IMAGE_2 },
            ]}
            className="media-grid-examples__section"
        />

        <MediaGrid
            mediaItems={[
                { id: '1', image: MOCK_IMAGE_1 },
                { id: '2', image: MOCK_IMAGE_3 },
                { id: '3', video: MOCK_VIDEO, videoPlaysInline: true },
            ]}
            className="media-grid-examples__section"
        />

        <MediaGrid
            mediaItems={[
                { id: '1', image: MOCK_IMAGE_1 },
                { id: '2', image: MOCK_IMAGE_2, video: MOCK_VIDEO },
                { id: '3', image: MOCK_IMAGE_3 },
            ]}
            className="media-grid-examples__section"
        />
    </div>
);
