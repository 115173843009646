import { FC, ReactElement } from 'react';

import { SectionFooter } from '../../../../../compositions';
import { ConnectedProductCard } from '../../../../../connectors';
import { FeaturedProductsInterface } from '../../../../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { Product } from '../../../../../entities/@products/Product/Product';
import { useTrans } from '../../../../../hooks';

import './FeaturedProductsVerticalTitle.scss';

export interface FeaturedProductsVerticalTitleProps extends Omit<FeaturedProductsInterface, 'layoutType'> {
    products: Product[];
    className?: string;
}

export const FeaturedProductsVerticalTitle: FC<FeaturedProductsVerticalTitleProps> = ({
    title,
    link,
    products,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`featured-products-vertical-title ${className}`}>
            <div className="featured-products-vertical-title__products-wrapper">
                {products.map((product, index) => (
                    <div key={product.id} className="featured-products-vertical-title__product-wrapper">
                        <ConnectedProductCard
                            product={product}
                            className="featured-products-vertical-title__product"
                        />

                        {index === 0 && (
                            <h2 className="featured-products-vertical-title__title">
                                {title || trans('containers.featuredProducts.defaultTitle')}
                            </h2>
                        )}
                    </div>
                ))}
            </div>

            {link && (
                <SectionFooter
                    link={link}
                    className="featured-products-vertical-title__footer"
                />
            )}
        </div>
    );
};
