import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { RootPortal } from '../../components';
import { Button, Toggle } from '../../compositions';
import { useCookieSettings } from '../../context';
import { CookieType, CookieTypeConfiguration } from '../../entities/Cookie/Cookie';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';

import './CookieSettings.scss';

interface CookieSettingsProps {
    isRevealed: boolean;
    onUpdateCookieSettings: (cookieSettings: CookieTypeConfiguration) => void;
    className?: string;
}

export const CookieSettings: FC<CookieSettingsProps> = ({
    isRevealed,
    onUpdateCookieSettings,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const acceptButtonRef = useRef<HTMLButtonElement>(null);

    const cookieSettings = useCookieSettings();
    const { cookieSettingsConfigured } = cookieSettings;

    const defaultAnalyticalCookies = cookieSettingsConfigured ? cookieSettings[CookieType.analytical] : true;
    const defaultMarketingCookies = cookieSettingsConfigured ? cookieSettings[CookieType.marketing] : true;

    const [functionalCookiesEnabled, setFunctionalCookiesEnabled] = useState<boolean>(true);
    const [analyticalCookiesEnabled, setAnalyticalCookiesEnabled] = useState<boolean>(defaultAnalyticalCookies);
    const [marketingCookiesEnabled, setMarketingCookiesEnabled] = useState<boolean>(defaultMarketingCookies);

    useEffect((): void => {
        if (acceptButtonRef.current) {
            acceptButtonRef.current.focus();
        }
    }, [acceptButtonRef]);

    useEffect((): void => {
        if (cookieSettingsConfigured) {
            setAnalyticalCookiesEnabled(cookieSettings[CookieType.analytical]);
            setMarketingCookiesEnabled(cookieSettings[CookieType.marketing]);
        }
    }, [cookieSettings]);

    const handleSaveSettings = (): void => {
        onUpdateCookieSettings({
            [CookieType.functional]: functionalCookiesEnabled,
            [CookieType.analytical]: analyticalCookiesEnabled,
            [CookieType.marketing]: marketingCookiesEnabled,
        });
    };

    const handleRejectSettings = (): void => {
        onUpdateCookieSettings({
            [CookieType.functional]: true,
            [CookieType.analytical]: false,
            [CookieType.marketing]: false,
        });
    };

    const cookieSettingsCardClassNames = classNames('cookie-settings__card', {
        'cookie-settings__card--is-revealed': isRevealed,
    });

    return (
        <RootPortal>
            <div className={`cookie-settings ${className}`}>
                <div className={cookieSettingsCardClassNames}>
                    <h2 className="cookie-settings__title">
                        {trans('containers.cookieSettings.title')}
                    </h2>

                    <p className="cookie-settings__description">
                        {trans('containers.cookieSettings.description')}
                    </p>

                    <Link
                        to={trans(appRoutes[AppRoute.legalCookies].path)}
                        className="cookie-settings__link"
                    >
                        {trans('containers.cookieSettings.link')}
                    </Link>

                    <div className="cookie-settings__toggle-wrapper">
                        <Toggle
                            label={trans('containers.cookieSettings.functionalCookies')}
                            checked={functionalCookiesEnabled}
                            disabled
                            onChange={setFunctionalCookiesEnabled}
                            className="cookie-settings__toggle"
                        />

                        <Toggle
                            label={trans('containers.cookieSettings.analyticalCookies')}
                            checked={analyticalCookiesEnabled}
                            onChange={setAnalyticalCookiesEnabled}
                            className="cookie-settings__toggle"
                        />

                        <Toggle
                            label={trans('containers.cookieSettings.marketingCookies')}
                            checked={marketingCookiesEnabled}
                            onChange={setMarketingCookiesEnabled}
                            className="cookie-settings__toggle"
                        />
                    </div>

                    <div className="cookie-settings__button-wrapper">
                        <Button
                            ref={acceptButtonRef}
                            text={trans('containers.cookieSettings.save')}
                            onClick={handleSaveSettings}
                            className="cookie-settings__button"
                        />

                        <Button
                            text={trans('containers.cookieSettings.reject')}
                            onClick={handleRejectSettings}
                            className="cookie-settings__button cookie-settings__button--is-reject"
                        />
                    </div>
                </div>
            </div>
        </RootPortal>
    );
};
