import trans from '../../../helpers/trans';
import {
    MeilisearchBlogPostHitResource,
    MeilisearchBrandHitResource,
    MeilisearchCategoryHitResource,
    MeilisearchDepartmentHitResource,
    MeilisearchPage,
    MeilisearchPageHitResource,
    MeilisearchPageId,
    MeilisearchPageType,
    MeilisearchPageTypeResource,
    MeilisearchStoreHitResource,
} from '../../@api/Meilisearch';
import { transformMeilisearchPageLinkToLink } from '../../Link/LinkTransformers';
import { PageSearchResult } from './PageSearchResult';

const transformToPageSearchResultTypeLabel = (resource: MeilisearchPage, type: MeilisearchPageType): string => {
    if (type === MeilisearchPageType.page) {
        return (resource as MeilisearchPageHitResource).fixedType || '';
    }

    if (type === MeilisearchPageType.blog) {
        return (resource as MeilisearchBlogPostHitResource).blogCategory?.title || '';
    }

    if (type === MeilisearchPageType.brand) {
        return trans(`entities.pageType.${MeilisearchPageType.brand}`);
    }

    if (type === MeilisearchPageType.department) {
        return trans(`entities.pageType.${MeilisearchPageType.department}`);
    }

    if (type === MeilisearchPageType.category) {
        return trans(`entities.pageType.${MeilisearchPageType.category}`);
    }

    if (type === MeilisearchPageType.store) {
        return trans(`entities.pageType.${MeilisearchPageType.store}`);
    }

    return '';
};

const transformToPageSearchResultDescription = (resource: MeilisearchPage, type: MeilisearchPageType): string => {
    if (type === MeilisearchPageType.page) {
        return (resource as MeilisearchPageHitResource).description || '';
    }

    if (type === MeilisearchPageType.blog) {
        return (resource as MeilisearchBlogPostHitResource).intro || '';
    }

    if (type === MeilisearchPageType.brand) {
        return (resource as MeilisearchBrandHitResource).description || '';
    }

    if (type === MeilisearchPageType.department) {
        return (resource as MeilisearchDepartmentHitResource).intro || '';
    }

    if (type === MeilisearchPageType.store) {
        return (resource as MeilisearchStoreHitResource).description || '';
    }

    return '';
};

export const transformToPageSearchResultTitle = (resource: MeilisearchPage, type: MeilisearchPageType): string => {
    if (type === MeilisearchPageType.page) {
        return (resource as MeilisearchPage).title;
    }

    if (type === MeilisearchPageType.blog) {
        return (resource as MeilisearchBlogPostHitResource).title;
    }

    if (type === MeilisearchPageType.brand) {
        return (resource as MeilisearchBrandHitResource).name;
    }

    if (type === MeilisearchPageType.department) {
        return (resource as MeilisearchDepartmentHitResource).title;
    }

    if (type === MeilisearchPageType.category) {
        return (resource as MeilisearchCategoryHitResource).title;
    }

    if (type === MeilisearchPageType.store) {
        return (resource as MeilisearchStoreHitResource).title;
    }

    return '';
};

const getType = (resource: MeilisearchPageId): MeilisearchPageType => {
    if (resource.includes(MeilisearchPageTypeResource.blog)) {
        return MeilisearchPageType.blog;
    }

    if (resource.includes(MeilisearchPageTypeResource.brand)) {
        return MeilisearchPageType.brand;
    }

    if (resource.includes(MeilisearchPageTypeResource.department)) {
        return MeilisearchPageType.department;
    }

    if (resource.includes(MeilisearchPageTypeResource.category)) {
        return MeilisearchPageType.category;
    }

    if (resource.includes(MeilisearchPageTypeResource.store)) {
        return MeilisearchPageType.store;
    }

    return MeilisearchPageType.page;
};

export const transformPageHitToPageSearchResult = (resource: MeilisearchPage): PageSearchResult => {
    const type = getType(resource._meilisearch_id);
    const typeLabel = transformToPageSearchResultTypeLabel(resource, type);

    const title = transformToPageSearchResultTitle(resource, type);
    const link = transformMeilisearchPageLinkToLink(resource, type);
    const shortDescription = transformToPageSearchResultDescription(resource, type);

    return {
        id: resource.id.toString(),
        type: typeLabel,
        title,
        link,
        shortDescription,
    };
};
