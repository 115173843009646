import { BaseImageResource } from './BaseImageResource';

export type ProductImage = BaseImageResource

export enum SyliusImageType {
    large = 'sylius_large',
    medium = 'sylius_medium',
    meilisearchThumbnail = 'meilisearch_thumbnail',
    original = 'sylius_original',
    productOriginal = 'sylius_admin_product_original',
    productThumbnail = 'sylius_admin_product_thumbnail',
    productSmallThumbnail = 'sylius_admin_product_small_thumbnail',
    productLargeThumbnail = 'sylius_admin_product_large_thumbnail',
    small = 'sylius_small',
    tinyThumbnail = 'sylius_admin_product_tiny_thumbnail',
    userAvatarThumbnail = 'sylius_admin_admin_user_avatar_thumbnail',
}
