import { FC, ReactElement } from 'react';

import { ProductPrice } from '../../../../../components';
import { noDataLabel } from '../../../../../constants';
import { OrderDetailInterface, PaymentState } from '../../../../../entities/@account/OrderDetail/OrderDetail';
import { useTrans } from '../../../../../hooks';

import './OrderDetailPriceTable.scss';

interface OrderDetailPriceTableProps {
    order: OrderDetailInterface;
    className?: string;
}

export const OrderDetailPriceTable: FC<OrderDetailPriceTableProps> = ({
    order,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const partiallyRefundedAmount = order.paymentState === PaymentState.partiallyRefunded
        ? noDataLabel // TODO: Replace with real value
        : undefined;

    return (
        <table className={`order-detail-price-table ${className}`}>
            <tbody className="order-detail-price-table__body">
                <tr className="order-detail-price-table__row">
                    <td className="order-detail-price-table__cell">
                        {trans('containers.orderDetailRenderer.order.priceProductTotal', {
                            numberOfProducts: order.productVariants.length,
                        })}
                    </td>

                    <td className="order-detail-price-table__cell">
                        <ProductPrice
                            isCents
                            includeCurrencySymbol
                            current={order.priceItemsSubTotal}
                            original={order.priceItemsSubTotal}
                        />
                    </td>
                </tr>

                <tr className="order-detail-price-table__row">
                    <td className="order-detail-price-table__cell">
                        {trans('containers.orderDetailRenderer.order.priceInvoiceTotal')}
                    </td>

                    <td className="order-detail-price-table__cell">
                        <ProductPrice
                            isCents
                            includeCurrencySymbol
                            current={order.priceItemsTotal}
                            original={order.priceItemsTotal}
                        />
                    </td>
                </tr>

                {partiallyRefundedAmount && (
                    <tr className="order-detail-price-table__row">
                        <td className="order-detail-price-table__cell">
                            {trans('containers.orderDetailRenderer.order.priceRefundTotal')}
                        </td>

                        <td className="order-detail-price-table__cell">
                            {partiallyRefundedAmount}
                        </td>
                    </tr>
                )}
            </tbody>

            <tfoot className="order-detail-price-table__footer">
                <tr className="order-detail-price-table__row">
                    <td className="order-detail-price-table__cell">
                        {trans('containers.orderDetailRenderer.order.priceTotal')}
                    </td>

                    <td className="order-detail-price-table__cell">
                        <ProductPrice
                            isCents
                            includeCurrencySymbol
                            current={order.priceTotal}
                            original={order.priceTotal}
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};
