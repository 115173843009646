import { FC, ReactElement } from 'react';

import { Product } from '../../../../entities/@products/Product/Product';
import { PageSearchResult } from '../../../../entities/@search/PageSearchResult/PageSearchResult';
import { useTrans } from '../../../../hooks';
import { SearchResultsSkeletons } from '../../skeletons';
import { PageSuggestions, ProductSuggestions, Suggestions } from '..';

import './SearchResults.scss';

interface SearchResultsProps {
    isLoading?: boolean;
    recentSearches: string[];
    productResults: Product[];
    pageResults: PageSearchResult[];
    onRecentSearchClick: (query: string) => void;
    onLinkClick: () => void;
    className?: string;
}

export const SearchResults: FC<SearchResultsProps> = ({
    isLoading,
    productResults,
    pageResults,
    recentSearches,
    onRecentSearchClick,
    onLinkClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`search-results ${className}`}>
            {recentSearches.length > 0 && (
                <Suggestions
                    label={trans('containers.searchDrawer.recentSearches')}
                    suggestions={recentSearches}
                    onSuggestionClick={onRecentSearchClick}
                    className="search-results__recent-searches"
                />
            )}

            {isLoading && (
                <SearchResultsSkeletons className="search-results__skeletons" />
            )}

            {!isLoading && (
                <ProductSuggestions
                    products={productResults}
                    onProductClick={onLinkClick}
                    className="search-results__product-results"
                />
            )}

            {!isLoading && (
                <PageSuggestions
                    label={trans('containers.searchDrawer.pages')}
                    pages={pageResults}
                    onLinkClick={onLinkClick}
                    className="search-results__page-results"
                />
            )}
        </div>
    );
};
