import { FC, ReactElement } from 'react';

import { IFrame } from '../../../components';
import { BrandHeader } from '../../../compositions';
import {
    ConnectedBlogOverview,
    ConnectedFaq,
    ConnectedFeaturedProducts,
    ConnectedForm,
    ConnectedHeader,
    ConnectedNewsletterCallToAction,
    ConnectedProductCollection,
    ConnectedProductsSlider,
    ConnectedServiceSection,
} from '../../../connectors';
import { BlogOverviewBlockInterface } from '../../../entities/@blocks/BlogOverview/BlogOverview';
import { BlogsSelectionBlockInterface } from '../../../entities/@blocks/BlogsSelection/BlogsSelection';
import { BrandCarouselInterface } from '../../../entities/@blocks/BrandCarousel/BrandCarousel';
import { BrandHeaderInterface } from '../../../entities/@blocks/BrandHeader/BrandHeader';
import { CarouselInterface } from '../../../entities/@blocks/Carousel/Carousel';
import { ContentColumnsInterface } from '../../../entities/@blocks/ContentColumns/ContentColumns';
import { FaqDataInterface } from '../../../entities/@blocks/Faq/Faq';
import { FeaturedProductsInterface, FeaturedProductsType } from '../../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { FormInterface } from '../../../entities/@blocks/Form/Form';
import { HeaderInterface } from '../../../entities/@blocks/Header/Header';
import { HighlightedBlogPostInterface } from '../../../entities/@blocks/HighlightedBlogPost/HighlightedBlogPost';
import { HighlightedMediaInterface } from '../../../entities/@blocks/HighlightedMedia/HighlightedMedia';
import { IconColumnsInterface } from '../../../entities/@blocks/IconColumns/IconColumns';
import { IFrameInterface } from '../../../entities/@blocks/IFrame/IFrame';
import { MediaBannerInterface } from '../../../entities/@blocks/MediaBanner/MediaBanner';
import { MediaGridInterface } from '../../../entities/@blocks/MediaGrid/MediaGrid';
import { NewsletterCallToActionInterface } from '../../../entities/@blocks/NewsletterCallToAction/NewsletterCallToAction';
import { ProductCollectionInterface } from '../../../entities/@blocks/ProductCollection/ProductCollection';
import { ServiceSectionInterface } from '../../../entities/@blocks/ServiceSection/ServiceSection';
import { UspSectionInterface } from '../../../entities/@blocks/UspSection/UspSection';
import { Block, BlockType } from '../../../entities/Block/Block';
import { AppRoute } from '../../../entities/Routing/Routing';
import {
    BlogOverview,
    BrandCarousel,
    Carousel,
    ContentColumns,
    HighlightedBlogPost,
    HighlightedMedia,
    IconColumns,
    MediaBanner,
    MediaGrid,
    UspSection,
} from '../..';
import { BlockRendererSkeletons } from './skeletons';

import './BlockRenderer.scss';

interface BlockRendererProps {
    isLoading?: boolean;
    appRoute?: AppRoute;
    blocks?: Block[];
    className?: string;
}

export const BlockRenderer: FC<BlockRendererProps> = ({
    isLoading,
    appRoute,
    blocks = [],
    className = '',
}): ReactElement => {
    if (isLoading) {
        return (
            <BlockRendererSkeletons className={className} />
        );
    }

    return (
        <div className={`block-renderer ${className}`}>
            {blocks.map(block => {
                if (block.type === BlockType.blogOverview) {
                    return (
                        <ConnectedBlogOverview
                            {...block as BlogOverviewBlockInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.blogsSelection) {
                    return (
                        <BlogOverview
                            {...block as BlogsSelectionBlockInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.brandCarousel) {
                    return (
                        <BrandCarousel
                            {...block as BrandCarouselInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.brandHeader) {
                    return (
                        <BrandHeader
                            {...block as BrandHeaderInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.carousel) {
                    return (
                        <Carousel
                            {...block as CarouselInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.contentColumns) {
                    return (
                        <ContentColumns
                            {...block as ContentColumnsInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.faqs) {
                    const blockData = block as FaqDataInterface;

                    return (
                        <ConnectedFaq
                            {...blockData}
                            key={blockData.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.featuredProducts) {
                    if ((block as FeaturedProductsInterface).layoutType === FeaturedProductsType.slider) {
                        return (
                            <ConnectedProductsSlider
                                {...block as FeaturedProductsInterface}
                                key={block.id}
                                className="block-renderer__block"
                            />
                        );
                    }

                    return (
                        <ConnectedFeaturedProducts
                            {...block as FeaturedProductsInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.form) {
                    return (
                        <ConnectedForm
                            {...block as FormInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.header) {
                    return (
                        <ConnectedHeader
                            {...block as HeaderInterface}
                            key={block.id}
                            appRoute={appRoute}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.highlightedBlogPost) {
                    return (
                        <HighlightedBlogPost
                            {...block as HighlightedBlogPostInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.highlightedMedia) {
                    return (
                        <HighlightedMedia
                            {...block as HighlightedMediaInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.iconColumns) {
                    return (
                        <IconColumns
                            {...block as IconColumnsInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.iframe) {
                    return (
                        <IFrame
                            {...block as IFrameInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.mediaBanner) {
                    return (
                        <MediaBanner
                            {...block as MediaBannerInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.mediaGrid) {
                    return (
                        <MediaGrid
                            {...block as MediaGridInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.newsletterCallToAction) {
                    return (
                        <ConnectedNewsletterCallToAction
                            {...block as NewsletterCallToActionInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.productCollection) {
                    return (
                        <ConnectedProductCollection
                            {...block as ProductCollectionInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.serviceSection) {
                    return (
                        <ConnectedServiceSection
                            {...block as ServiceSectionInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                if (block.type === BlockType.uspSection) {
                    return (
                        <UspSection
                            {...block as UspSectionInterface}
                            key={block.id}
                            className="block-renderer__block"
                        />
                    );
                }

                return null;
            })}
        </div>
    );
};
