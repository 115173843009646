import { CSSProperties, FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Picture } from '../../../../components';
import { LinkIconButton } from '../../../../compositions';
import { ProductListCallToActionInterface } from '../../../../entities/@products/ProductListCallToAction/ProductListCallToAction';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';

import './ProductListCallToAction.scss';

interface ProductListCallToActionProps extends ProductListCallToActionInterface {
    className?: string;
}

export const ProductListCallToAction: FC<ProductListCallToActionProps> = ({
    title,
    description,
    link,
    image,
    className = '',
}): ReactElement => {
    const isImageType = image && !title && !description;

    const productListCtaClassNames = classNames('product-list-call-to-action', {
        'product-list-call-to-action--is-image-type': isImageType,
        'product-list-call-to-action--has-background-image': !isImageType && image,
    }, className);

    if (isImageType) {
        return (
            <div className={productListCtaClassNames}>
                {link && (
                    <Link
                        to={link.href}
                        target={link.target}
                        className="product-list-call-to-action__image-link"
                    >
                        {link.label}
                    </Link>
                )}

                <Picture
                    {...image}
                    className="product-list-call-to-action__picture"
                    imageClassName="product-list-call-to-action__image"
                />
            </div>
        );
    }

    const cssVariables = {
        '--product-list-cta-image': `url(${image?.src})`,
    } as CSSProperties;

    return (
        <div style={cssVariables} className={productListCtaClassNames}>
            {title && (
                <h3 className="product-list-call-to-action__title">
                    {title}
                </h3>
            )}

            {description && (
                <p className="product-list-call-to-action__description">
                    {description}
                </p>
            )}

            {link && (
                <LinkIconButton
                    hasAnimation
                    icon="arrow-right"
                    iconPos={HorizontalAlignment.right}
                    to={link.href}
                    text={link.label}
                    target={link.target}
                    className="product-list-call-to-action__link-button"
                />
            )}
        </div>
    );
};
