import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type AuthenticationState = AsyncReduxState<{
    isAuthenticated: boolean; // TODO: Create a property in LocalStorage to check if user is authenticated
}>;

const initialState: AuthenticationState = {
    ...initialAsyncReduxState,
    isAuthenticated: false,
};

const authenticationSlice = createSlice({
    name: 'authenticationSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AuthenticationState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): AuthenticationState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): AuthenticationState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): AuthenticationState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsAuthenticated(state, action: PayloadAction<boolean>): AuthenticationState {
            return {
                ...state,
                isAuthenticated: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setIsAuthenticated,
} = authenticationSlice.actions;

export default authenticationSlice;
