import qs from 'qs';

import { strapiBlockPopulates } from '../../@api/Strapi';

export const generateBlogPostQuery = (slug: string): string => {
    const filters = {
        slug: {
            $eq: slug,
        },
    };

    const populate = {
        blogCategory: {
            fields: ['title'],
        },
        productSelections: {
            fields: ['name'],
        },
        tags: {
            fields: ['tag'],
        },
        image: {
            fields: ['*'],
        },
        elements: strapiBlockPopulates,
        seo: {
            populate: ['metaImage'],
        },
    };

    return qs.stringify({
        filters,
        populate,
    }, {
        encodeValuesOnly: true,
    });
};
