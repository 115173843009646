import { calculateDifferenceInDays } from '../../helpers/date';
import { OrderOverviewOrder } from '../@account/OrderOverview/OrderOverview';
import {
    defaultGTMDaysBetweenTodayAndLastPurchase,
    GTMCompletedOrder,
    GTMProduct,
    GTMProductItem,
    GTMProductList,
} from '../@api/Sylius/Resource/GTM';
import { Product } from '../@products/Product/Product';
import { CartItem } from '../@shop/Cart/Cart';
import { OrderInterface } from '../@shop/Order/Order';

export const generateGTMProductInfo = (product: Product, index: number = 0): GTMProductItem => ({
    index,
    item_id: product.id,
    item_name: product.name,
    item_variant: product.variantId,
    item_brand: product.brand?.label || '',
    discount: product.price.original - product.price.current,
    price: product.price.current,
    quantity: 1,
    item_category: product.categories && product.categories[0],
    item_category2: product.categories && product.categories[1],
    item_category3: product.categories && product.categories[2],
    item_category4: product.categories && product.categories[3],
    item_category5: product.categories && product.categories[4],
});

export const generateCartItemToGTMProduct = (cartItem: CartItem, index: number = 0): GTMProductItem => ({
    index,
    item_id: cartItem.productCode || '',
    item_name: cartItem.name,
    item_variant: cartItem.variantId,
    item_brand: cartItem.brand?.label || '',
    discount: cartItem.price.original - cartItem.price.current,
    price: cartItem.price.current,
    quantity: cartItem.quantity,
    item_category: cartItem.categories && cartItem.categories[0],
    item_category2: cartItem.categories && cartItem.categories[1],
    item_category3: cartItem.categories && cartItem.categories[2],
    item_category4: cartItem.categories && cartItem.categories[3],
    item_category5: cartItem.categories && cartItem.categories[4],
});

export const generateGTMProduct = (product: Product): GTMProduct => ({
    currency: 'EUR',
    value: product.price.current,
    items: [generateGTMProductInfo(product)],
});

export const generateGTMCart = (cart: OrderInterface): GTMProduct => {
    const items = cart.items.map((item, index) => generateCartItemToGTMProduct(item, index));

    return {
        currency: 'EUR',
        value: cart.totalPrice,
        items,
    };
};

export const generateGTMProductList = (products: Product[]): GTMProductList => {
    const items = products.map(generateGTMProductInfo);

    return {
        currency: 'EUR',
        items,
    };
};

export const generateGTMCompletedOrder = (order: OrderInterface, isNewCustomer?: boolean): GTMCompletedOrder => {
    const items = order.items.map((item, index) => generateCartItemToGTMProduct(item, index));

    const newCustomer = isNewCustomer && { new_customer: isNewCustomer };

    return {
        ...newCustomer,
        currency: 'EUR',
        value: order.totalPrice,
        transaction_id: order.id, // TODO: Replace with transaction ID (when it's possible in the API)
        shipping: 0,
        items,
    };
};

// Source: https://support.google.com/google-ads/answer/12077475#zippy=%2Cinstall-with-google-tag-manager
export const checkIfCustomerIsNew = (orders: OrderOverviewOrder[]): boolean => {
    const { orderDate } = orders[0];

    const daysBetweenLastOrderAndToday = orderDate
        ? calculateDifferenceInDays(orderDate)
        : 0;

    return daysBetweenLastOrderAndToday >= defaultGTMDaysBetweenTodayAndLastPurchase;
};
