import { MeilisearchAttribute } from '../../../entities/@api/Meilisearch';

interface ReplaceDynamicFiltersType {
    attribute: MeilisearchAttribute;
}

interface ReplaceDynamicFiltersConfig <T>{
    dynamicFilters?: T[];
    staticFilters?: T[];
    staticFilterAttributes?: MeilisearchAttribute[];
}

export const replaceDynamicWithStaticFilters = <T extends ReplaceDynamicFiltersType>(config: ReplaceDynamicFiltersConfig<T>): T[] => {
    const { dynamicFilters = [], staticFilters = [], staticFilterAttributes = [] } = config;

    return dynamicFilters.map(dynamicFilter => {
        if (staticFilterAttributes.includes(dynamicFilter.attribute)) {
            const foundStaticFilter = staticFilters.find(staticFilter => staticFilter.attribute === dynamicFilter.attribute);

            return foundStaticFilter || dynamicFilter;
        }

        return dynamicFilter;
    });
};
