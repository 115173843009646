import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { ProductCategory, ProductCategoryPageData } from '../../../entities/@products/ProductCategory/ProductCategory';
import { Link } from '../../../entities/Link/Link';
import { BlockRenderer } from '../..';
import { CategoryHeader } from '../../@blocks/CategoryHeader/CategoryHeader';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import { ProductCategoryHelmet } from './subcomponents';

import './ProductCategoryRenderer.scss';

interface ProductCategoryRendererProps {
    isLoading: boolean;
    breadcrumbs: Link[];
    activeProductCategory?: ProductCategory;
    pageData?: ProductCategoryPageData;
    className?: string;
}

export const ProductCategoryRenderer: FC<ProductCategoryRendererProps> = ({
    isLoading,
    breadcrumbs,
    activeProductCategory,
    pageData,
    className = '',
}): ReactElement => {
    const title = activeProductCategory?.name || pageData?.title || '';
    const image = activeProductCategory?.image || pageData?.image;

    return (
        <div className={`product-category-renderer ${className}`}>
            {pageData && (
                <ProductCategoryHelmet pageData={pageData} />
            )}

            <Wrapper>
                <Breadcrumbs
                    isLoading={isLoading}
                    breadcrumbs={breadcrumbs}
                    className="product-category-renderer__breadcrumbs"
                />
            </Wrapper>

            <CategoryHeader
                isLoading={isLoading}
                title={title}
                image={image}
                className="product-category-renderer__header"
            />

            <BlockRenderer
                isLoading={isLoading}
                blocks={pageData?.blocks || []}
                className="product-category-renderer__block-renderer"
            />
        </div>
    );
};
