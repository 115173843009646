import { ProductAttributeKey, ProductDimensionAttributeKey } from '../../@api/Sylius';

export interface ProductDimensionAttribute {
    key: ProductDimensionAttributeKey;
    label: string;
    value: string;
}

export interface ProductAttribute {
    key: ProductAttributeKey | ProductDimensionAttributeKey;
    label: string;
    value: string;
}

export const kilogramUnit = 'kg';
export const centimeterUnit = 'cm';
