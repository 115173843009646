import { alphabetNumbers, stringIsNumber, transformToSlug } from '../../helpers/string';
import { MeilisearchBrand } from '../@api/Meilisearch';
import { transformToBlock } from '../Block/BlockTransformers';
import { Link } from '../Link/Link';
import { transformToImage } from '../Media/MediaTransformers';
import { transformToSeo } from '../Seo/SeoTransformers';
import { Brand, BrandItemResource, BrandNavigation } from './Brand';

export const transformMeilisearchBrandNameToLink = (resource: MeilisearchBrand): Link => ({
    label: resource.name,
    href: resource.slug,
});

export const transformBrandNameToLink = (value: string): Link => ({
    label: value,
    href: transformToSlug(value),
});

export const transformToBrand = (resource: BrandItemResource): Brand => {
    const image = resource.logo
        ? transformToImage(resource.logo)
        : undefined;

    const blocks = resource.elements
        ? resource.elements.map(transformToBlock)
        : [];

    const publishedBlocks = blocks.filter(block => block.isPublished);

    const seo = resource.seo
        ? transformToSeo(resource.seo)
        : undefined;

    return {
        id: resource.documentId,
        name: resource.name,
        slug: resource.slug,
        meilisearchProductListName: resource.name,
        description: resource.description || '',
        logo: image,
        blocks: publishedBlocks,
        seo,
    };
};

export const transformToBrandNavigation = (brands: Brand[]): BrandNavigation => {
    const brandNavigation = brands.reduce<Record<string, Brand[]>>((acc, brand) => {
        let firstLetter = brand.name.charAt(0).toUpperCase();
        if (stringIsNumber(firstLetter)) firstLetter = alphabetNumbers;

        (acc[firstLetter] ||= []).push(brand);
        return acc;
    }, {});

    Object.values(brandNavigation)
        .forEach(navItem => navItem
            .sort((a, b) => a.name.localeCompare(b.name)));

    return Object.fromEntries(Object.entries(brandNavigation)
        .sort(([a], [b]) => a.localeCompare(b)));
};
