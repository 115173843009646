import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DepartmentDetailInterface } from '../../../entities/@departments/DepartmentDetail/DepartmentDetail';
import { PaginationInterface } from '../../../entities/Pagination/Pagination';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type DepartmentOverviewState = AsyncReduxState<{
    departments: DepartmentDetailInterface[];
    pagination?: PaginationInterface;
}>;

const initialState: DepartmentOverviewState = {
    ...initialAsyncReduxState,
    departments: [],
};

const departmentOverviewSlice = createSlice({
    name: 'departmentOverviewSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): DepartmentOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): DepartmentOverviewState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): DepartmentOverviewState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): DepartmentOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setDepartments(state, action: PayloadAction<DepartmentDetailInterface[]>): DepartmentOverviewState {
            return {
                ...state,
                departments: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<PaginationInterface | undefined>): DepartmentOverviewState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setDepartments,
    setPagination,
} = departmentOverviewSlice.actions;

export default departmentOverviewSlice;
