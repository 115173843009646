import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { transformButtonToLink } from '../../Link/LinkTransformers';
import { defaultImage, transformToImage } from '../../Media/MediaTransformers';
import {
    ProductCollectionInterface,
    ProductCollectionMediaItem,
    ProductCollectionMediaResource,
    ProductCollectionResource,
} from './ProductCollection';

export const transformToProductCollectionMediaItem = (resource: ProductCollectionMediaResource): ProductCollectionMediaItem => {
    const image = resource.image && resource.image.mime.startsWith('image')
        ? transformToImage(resource.image)
        : defaultImage();

    const backgroundColor = resource.buttonBackgroundColor || undefined;
    const textColor = resource.buttonTextColor || undefined;

    const link = resource.button
        ? transformButtonToLink(resource.button)
        : undefined;

    return {
        id: resource.id.toString(),
        backgroundColor,
        textColor,
        image,
        link,
    };
};

export const transformToProductCollection = (resource: ProductCollectionResource): ProductCollectionInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const productCollection = resource.media
        ? resource.media.map(transformToProductCollectionMediaItem)
        : [];

    const meilisearchProductListName = resource.productSelection
        ? resource.productSelection.name
        : '';

    const link = resource.button
        ? transformButtonToLink(resource.button)
        : undefined;

    return {
        ...defaultBlock,
        type: BlockType.productCollection,
        title: resource.title,
        titleColor: resource.titleColor,
        media: productCollection,
        meilisearchProductListName,
        link,
    };
};
