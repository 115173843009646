import { FC, ReactElement, useMemo } from 'react';

import { SearchCount } from '../../containers';
import { MeilisearchStoreKey } from '../../entities/@search/Meilisearch/Meilisearch';
import { useTypedSelector } from '../../redux/store';

interface ConnectedSearchCountProps {
    storeKey: MeilisearchStoreKey;
    query: string;
    className?: string;
}

export const ConnectedSearchCount: FC<ConnectedSearchCountProps> = ({
    storeKey,
    query,
    className = '',
}): ReactElement => {
    const isLoading = useTypedSelector(state => state.meilisearchSlice.isLoading);
    const variantResponses = useTypedSelector(state => state.meilisearchSlice.variantResponses);
    const pageResponses = useTypedSelector(state => state.meilisearchSlice.pageResponses);

    const totalResults = useMemo((): number => {
        const maxAmountOfPageResults = 10;

        const totalVariants = variantResponses[storeKey]?.pagination?.totalResults || 0;
        const totalPages = pageResponses[storeKey]?.pagination?.totalResults
            ? Math.max(pageResponses[storeKey]?.pagination.totalResults || 0, maxAmountOfPageResults)
            : 0;

        return totalVariants + totalPages;
    }, [variantResponses, pageResponses]);

    return (
        <SearchCount
            isLoading={isLoading}
            query={query}
            totalResults={totalResults}
            className={className}
        />
    );
};
