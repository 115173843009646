import {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import classNames from 'classnames';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { Wrapper } from '../../components';
import { IconButton } from '../../compositions';
import { Product } from '../../entities/@products/Product/Product';
import { PageSearchResult } from '../../entities/@search/PageSearchResult/PageSearchResult';
import { searchTermQueryKey } from '../../entities/@search/Search/Search';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { Store } from '../../entities/Store/Store';
import { useDeviceWidth, useHandleClickOutside, useTrans } from '../../hooks';
import { GlobalSearch, SearchResults, SearchSuggestions } from './subcomponents';

import './SearchDrawer.scss';

interface SearchDrawerProps {
    isLoading?: boolean;
    query: string;
    pageSuggestions: PageSearchResult[];
    stores: Store[];
    recentSearches: string[];
    productResults: Product[];
    pageResults: PageSearchResult[];
    onSetStoreTermInRecentSearches: (query: string) => void;
    onInput: (query: string) => void;
    className?: string;
}

export const SearchDrawer: FC<SearchDrawerProps> = ({
    isLoading,
    query,
    pageResults,
    productResults,
    stores,
    pageSuggestions,
    recentSearches,
    onSetStoreTermInRecentSearches,
    onInput,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();
    const { isMobile } = useDeviceWidth();

    const [isOpen, toggleIsOpen] = useToggle(false);

    const itemRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const closeDrawer = (): void => {
        toggleIsOpen(false);
    };

    useHandleClickOutside([itemRef], closeDrawer);

    useEffect((): void => {
        if (isOpen && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isOpen]);

    const navigateToSearchResults = (term: string): void => {
        navigate({
            pathname: trans(appRoutes[AppRoute.search].path),
            search: createSearchParams({ [searchTermQueryKey]: term }).toString(),
        });
    };

    const handleRecentSearchClick = (suggestion: string): void => {
        closeDrawer();
        onInput(suggestion);
        navigateToSearchResults(suggestion);
    };

    const handleShowAllResults = (): void => {
        closeDrawer();
        onSetStoreTermInRecentSearches(query);
        navigateToSearchResults(query);
    };

    const drawerWrapperClassNames = classNames('search-drawer__wrapper', {
        'search-drawer__wrapper--is-open': isOpen,
    });

    return (
        <section ref={itemRef} className={`search-drawer ${className}`}>
            <IconButton
                hideLabel
                icon={(isOpen && isMobile) ? 'cross' : 'search'}
                text={trans('common.search')}
                onClick={toggleIsOpen}
                className="search-drawer__link"
                iconClassName="search-drawer__link-icon"
            />

            <div
                inert={!isOpen}
                className={drawerWrapperClassNames}
            >
                <Wrapper>
                    <GlobalSearch
                        ref={searchInputRef}
                        query={query}
                        onSubmit={handleShowAllResults}
                        onInput={onInput}
                    />

                    <div className="search-drawer__results-wrapper">
                        {query.length === 0 ? (
                            <SearchSuggestions
                                pageSuggestions={pageSuggestions}
                                stores={stores}
                                onLinkClick={closeDrawer}
                            />
                        ) : (
                            <SearchResults
                                isLoading={isLoading}
                                recentSearches={recentSearches}
                                productResults={productResults}
                                pageResults={pageResults}
                                onRecentSearchClick={handleRecentSearchClick}
                                onLinkClick={closeDrawer}
                            />
                        )}
                    </div>

                    {query.length > 0 && (
                        <IconButton
                            hasAnimation
                            isSmall
                            icon="arrow-right"
                            text={trans('containers.searchDrawer.showAllResults')}
                            iconPos={HorizontalAlignment.right}
                            onClick={handleShowAllResults}
                            className="search-drawer__show-all-results-button"
                        />
                    )}
                </Wrapper>
            </div>
        </section>
    );
};
