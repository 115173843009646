import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedBlockRenderer, ConnectedDepartmentOverview, ConnectedPageHelmet } from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';

import './DepartmentOverviewPage.scss';

export const DepartmentOverviewPage: FC = (): ReactElement => (
    <Page className="department-overview-page">
        <ConnectedPageHelmet appRoute={AppRoute.departmentOverview} />

        <ConnectedDepartmentOverview />

        <ConnectedBlockRenderer
            appRoute={AppRoute.departmentOverview}
            className="department-overview-page__block-renderer"
        />
    </Page>
);
