import { FC, ReactElement } from 'react';

import './ColourExamples.scss';

interface ColourExamplesProps {
    className?: string;
}

export const ColourExamples: FC<ColourExamplesProps> = ({
    className = '',
}): ReactElement => (
    <div className={`colour-examples ${className}`}>
        <div className="colour-examples__section">
            <h3 className="colour-examples__section-title">
                Base colours
            </h3>

            <div className="colour-examples__grid">
                <div title="--c-black" className="colour-examples__color colour-examples__color--black" />
                <div title="--c-white" className="colour-examples__color colour-examples__color--white" />
                <div title="--c-green-dark" className="colour-examples__color colour-examples__color--green-dark" />
                <div title="--c-green-medium" className="colour-examples__color colour-examples__color--green-medium" />
                <div title="--c-green" className="colour-examples__color colour-examples__color--green" />
                <div title="--c-green-75" className="colour-examples__color colour-examples__color--green-75" />
                <div title="--c-green-50" className="colour-examples__color colour-examples__color--green-50" />
                <div title="--c-green-25" className="colour-examples__color colour-examples__color--green-25" />
                <div title="--c-grey-light" className="colour-examples__color colour-examples__color--grey-light" />
            </div>
        </div>

        <div className="colour-examples__section">
            <h3 className="colour-examples__section-title">
                Background
            </h3>

            <div className="colour-examples__grid">
                <div title="--c-background" className="colour-examples__color colour-examples__color--background" />
            </div>
        </div>

        <div className="colour-examples__section">
            <h3 className="colour-examples__section-title">
                Support colours
            </h3>

            <div className="colour-examples__grid">
                <div title="--c-sand-soft" className="colour-examples__color colour-examples__color--sand-soft" />
                <div title="--c-sand-warm" className="colour-examples__color colour-examples__color--sand-warm" />
                <div title="--c-ocher-soft" className="colour-examples__color colour-examples__color--ocher-soft" />
                <div title="--c-caramel" className="colour-examples__color colour-examples__color--caramel" />
                <div title="--c-chocolate" className="colour-examples__color colour-examples__color--chocolate" />
                <div title="--c-khaki" className="colour-examples__color colour-examples__color--khaki" />
                <div title="--c-cement" className="colour-examples__color colour-examples__color--cement" />
                <div title="--c-turquoise" className="colour-examples__color colour-examples__color--turquoise" />
                <div title="--c-charcoal" className="colour-examples__color colour-examples__color--charcoal" />
                <div title="--c-green-light" className="colour-examples__color colour-examples__color--green-light" />
                <div title="--c-green-garage" className="colour-examples__color colour-examples__color--green-garage" />
                <div title="--c-rust" className="colour-examples__color colour-examples__color--rust" />
            </div>
        </div>

        <div className="colour-examples__section">
            <h3 className="colour-examples__section-title">
                Category colours
            </h3>

            <div className="colour-examples__grid">
                <div title="--c-outdoor-1" className="colour-examples__color colour-examples__color--outdoor-1" />
                <div title="--c-outdoor-2" className="colour-examples__color colour-examples__color--outdoor-2" />
                <div title="--c-favorites-brown" className="colour-examples__color colour-examples__color--favorites-brown" />
                <div title="--c-favorites-blue" className="colour-examples__color colour-examples__color--favorites-blue" />
                <div title="--c-favorites-pink" className="colour-examples__color colour-examples__color--favorites-pink" />
                <div title="--c-goods-1" className="colour-examples__color colour-examples__color--goods-1" />
                <div title="--c-goods-2" className="colour-examples__color colour-examples__color--goods-2" />
                <div title="--c-deals" className="colour-examples__color colour-examples__color--deals" />
                <div title="--c-sale" className="colour-examples__color colour-examples__color--sale" />
            </div>
        </div>

        <div className="colour-examples__section">
            <h3 className="colour-examples__section-title">
                UI colours
            </h3>

            <div className="colour-examples__grid">
                <div title="--c-success" className="colour-examples__color colour-examples__color--success" />
                <div title="--c-warning" className="colour-examples__color colour-examples__color--warning" />
                <div title="--c-error" className="colour-examples__color colour-examples__color--error" />
            </div>
        </div>
    </div>
);
