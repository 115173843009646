import { GetGiftcardBalanceResponse, syliusFetch } from '../../../entities/@api/Sylius';
import { GiftcardBalanceFormData } from '../../../entities/@forms/GiftcardBalanceForm/GiftcardBalanceForm';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { ReduxFetchAction } from '../../defaults';
import {
    setBalance,
    setError,
    setHasFetched,
    setIsLoading,
} from './giftcardBalanceFormSlice';

export const checkGiftcardBalance: ReduxFetchAction<GiftcardBalanceFormData> = formData => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setError(''));
    dispatch(setBalance(undefined));

    try {
        const balanceResponse = await syliusFetch<GetGiftcardBalanceResponse>('/shop/giftcards/balance', {
            method: 'POST',
            body: JSON.stringify(formData),
        });

        if (!isFetchResultSuccessful(balanceResponse)) {
            dispatch(setError(balanceResponse.error));
            return;
        }

        dispatch(setBalance(balanceResponse.data.balance));
    } catch (error) {
        console.error('[checkGiftcardBalance]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};
