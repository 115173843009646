import { FC, ReactElement } from 'react';

import { Address, Icon } from '../../../components';
import { AccountInfoBlock, AccountLabelledValue, TooltipWrapper } from '../../../compositions';
import { noDataLabel } from '../../../constants';
import {
    EnrichedOrderDetailInterface,
    OrderPaymentMethod,
    OrderState,
    PaymentState,
} from '../../../entities/@account/OrderDetail/OrderDetail';
import { AddressType } from '../../../entities/Address/Address';
import { useFormatDate, useTrans } from '../../../hooks';
import { OrderDetailRendererSkeletons } from './skeletons';
import { OrderDetailPriceTable, OrderDetailProductVariant } from './subcomponents';

import './OrderDetailRenderer.scss';

interface OrderDetailRendererProps {
    isLoading?: boolean;
    order?: EnrichedOrderDetailInterface;
    className?: string;
}

export const OrderDetailRenderer: FC<OrderDetailRendererProps> = ({
    isLoading,
    order,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    if (isLoading || !order) {
        return (
            <OrderDetailRendererSkeletons className={`order-detail-renderer ${className}`} />
        );
    }

    const orderDate = formatDate(order.orderDate, {
        weekday: 'long',
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    });

    const notFullyPaidStates = [PaymentState.partiallyPaid, PaymentState.partiallyRefunded];
    const notFullyPaidAmount = notFullyPaidStates.includes(order.paymentState)
        ? { amount: noDataLabel } // TODO: Replace with real value
        : undefined;

    const paymentStateDescription = trans(`entities.paymentState.${order.paymentState}.description`, notFullyPaidAmount);

    const paymentMethodLabel = trans(`entities.paymentMethod.${order.paymentMethod}`);
    const paymentMethod = order.paymentMethod !== OrderPaymentMethod.unknown
        ? trans('containers.orderDetailRenderer.paymentMethod.paidWith', { method: paymentMethodLabel })
        : paymentMethodLabel;

    return (
        <div className={`order-detail-renderer ${className}`}>
            <h1 className="order-detail-renderer__title">
                {trans('containers.orderDetailRenderer.title', {
                    orderNumber: order.orderNumber,
                })}
            </h1>

            <div className="order-detail-renderer__wrapper">
                <div className="order-detail-renderer__product-variant-wrapper">
                    <ul className="order-detail-renderer__product-variant-list">
                        {order.productVariants.map(productVariant => (
                            <OrderDetailProductVariant
                                key={productVariant.id}
                                productVariant={productVariant}
                                className="order-detail-renderer__product-variant"
                            />
                        ))}
                    </ul>
                </div>

                <div className="order-detail-renderer__detail-wrapper">
                    <AccountInfoBlock
                        title={trans('containers.orderDetailRenderer.order.title')}
                        className="order-detail-renderer__info-block"
                    >
                        <AccountLabelledValue
                            label={trans('containers.orderDetailRenderer.order.orderNumber')}
                            value={order.orderNumber}
                            className="order-detail-renderer__labelled-value"
                        />

                        {orderDate && (
                            <AccountLabelledValue
                                label={trans('containers.orderDetailRenderer.order.date')}
                                value={orderDate}
                                className="order-detail-renderer__labelled-value"
                            />
                        )}

                        <OrderDetailPriceTable
                            order={order}
                            className="order-detail-renderer__price-table"
                        />
                    </AccountInfoBlock>

                    <AccountInfoBlock
                        hasBorder
                        title={trans('containers.orderDetailRenderer.status.paymentState')}
                        className="order-detail-renderer__info-block"
                    >
                        <div className="order-detail-renderer__info-block--with-tooltip">
                            <p>
                                {trans(`entities.paymentState.${order.paymentState}.label`)}
                            </p>

                            <TooltipWrapper
                                id="payment-tooltip"
                                text={paymentStateDescription}
                                className="order-detail-renderer__tooltip-wrapper"
                                tooltipClassName="order-detail-renderer__tooltip"
                            >
                                <Icon name="box-help" className="order-detail-renderer__tooltip-icon" />
                            </TooltipWrapper>
                        </div>

                    </AccountInfoBlock>

                    <AccountInfoBlock
                        hasBorder
                        title={trans('containers.orderDetailRenderer.paymentMethod.title')}
                        className="order-detail-renderer__info-block"
                    >
                        {paymentMethod}
                    </AccountInfoBlock>

                    {order.state === OrderState.cancelled && (
                        <AccountInfoBlock
                            title={trans('containers.orderDetailRenderer.cancelled.title')}
                            className="order-detail-renderer__info-block"
                        >
                            <p>{trans('containers.orderDetailRenderer.cancelled.description')}</p>
                        </AccountInfoBlock>
                    )}

                    {order.shippingAddress && (
                        <AccountInfoBlock
                            title={trans(`entities.address.type.${AddressType.shipping}`)}
                            className="order-detail-renderer__info-block"
                        >
                            <Address
                                address={order.shippingAddress}
                                className="order-detail-renderer__address"
                            />
                        </AccountInfoBlock>
                    )}

                    {order.billingAddress && (
                        <AccountInfoBlock
                            title={trans(`entities.address.type.${AddressType.billing}`)}
                            className="order-detail-renderer__info-block"
                        >
                            <Address
                                address={order.billingAddress}
                                className="order-detail-renderer__address"
                            />
                        </AccountInfoBlock>
                    )}
                </div>
            </div>
        </div>
    );
};
