import { FC, ReactElement } from 'react';

import { Button, CartItem } from '../../../../compositions';
import { AddCouponCodeToOrderFormData } from '../../../../entities/@forms/CouponCodeForm/CouponCodeForm';
import { OrderInterface } from '../../../../entities/@shop/Order/Order';
import { useTrans } from '../../../../hooks';
import { PaymentMethods } from '../PaymentMethods/PaymentMethods';
import { Pricing } from '../Pricing/Pricing';

import './CartSection.scss';

interface CartSectionProps extends OrderInterface {
    isLoading: boolean;
    showPayButton: boolean;
    onSetCouponCode: (data: AddCouponCodeToOrderFormData) => void;
    onRemoveCouponCode: () => void;
    onPayOrder: () => void;
    className?: string;
}

export const CartSection: FC<CartSectionProps> = ({
    isLoading,
    showPayButton,
    items,
    subTotalPrice,
    totalPrice,
    discounts,
    onSetCouponCode,
    onRemoveCouponCode,
    onPayOrder,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`cart-section ${className}`}>
            <h2 className="cart-section__heading">
                {trans('containers.checkout.cartSection.yourOrder')}
            </h2>

            <div className="cart-section__products-wrapper">
                {items.map(item => (
                    <CartItem
                        {...item}
                        key={item.id}
                        showBigRemoveButton
                        isLoading={isLoading}
                        imageClassName="cart-section__product-image"
                    />
                ))}
            </div>

            <Pricing
                subTotalPrice={subTotalPrice}
                totalPrice={totalPrice}
                discounts={discounts}
                onSetCouponCode={onSetCouponCode}
                onRemoveCouponCode={onRemoveCouponCode}
                className="cart-section__prices-wrapper"
            />

            {showPayButton && (
                <Button
                    isSmall
                    hasAnimation
                    text={trans('containers.checkout.cartSection.pay')}
                    onClick={onPayOrder}
                    className="cart-section__purchase-button"
                />
            )}

            <PaymentMethods />
        </div>
    );
};
