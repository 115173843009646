import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { Checkout } from '../../containers';
import { StartPaymentFormData } from '../../entities/@api/Sylius';
import { GTMProduct as GTMProductInterface } from '../../entities/@api/Sylius/Resource/GTM';
import { AddCouponCodeToOrderFormData } from '../../entities/@forms/CouponCodeForm/CouponCodeForm';
import { CreateAccountDuringCheckoutFormData, UpdateCartFormData } from '../../entities/@forms/GuestForm/GuestForm';
import { NewsletterFormData } from '../../entities/@forms/NewsletterForm/NewsletterForm';
import { PaymentStepFormData } from '../../entities/@forms/PaymentForm/PaymentForm';
import { CheckoutTab } from '../../entities/@shop/Checkout/Checkout';
import { Address } from '../../entities/Address/Address';
import { GTMEvent } from '../../entities/GTM/GTM';
import { generateCartItemToGTMProduct, generateGTMCart } from '../../entities/GTM/GTMRequests';
import { gtmEvent } from '../../entities/GTM/GTMService';
import { LinkTarget } from '../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { isSSR } from '../../helpers';
import { useTrans } from '../../hooks';
import { MOCK_USPS } from '../../mock/mock-data/usp';
import { fetchCustomer } from '../../redux/@account/customer/customerActions';
import { registerUserDuringCheckout } from '../../redux/@account/registration/registrationActions';
import { signUpForNewsletter } from '../../redux/@forms/newsletterForm/newsletterFormActions';
import { clearCart, removeProductVariantFromCart, updateProductVariantInCart } from '../../redux/@shop/cart/cartActions';
import {
    addCouponCodeToOrder,
    connectOrderToCustomer,
    getPaymentMethods,
    payOrder,
    removeCouponCodeFromOrder,
    updateOrderInfo,
} from '../../redux/@shop/order/orderActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedCheckoutProps {
    tab?: CheckoutTab;
}

export const ConnectedCheckout: FC<ConnectedCheckoutProps> = ({
    tab,
}): ReactElement => {
    const trans = useTrans();
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();

    const isAuthenticated = useTypedSelector(state => state.authenticationSlice.isAuthenticated);

    const customer = useTypedSelector(state => state.customerSlice.customer);

    const cartIsLoading = useTypedSelector(state => state.cartSlice.isLoading);

    const orderIsLoading = useTypedSelector(state => state.orderSlice.isLoading);
    const order = useTypedSelector(state => state.orderSlice.order);

    const isLoading = cartIsLoading || orderIsLoading;

    const [addresses, setAddresses] = useState<Address[]>([]);
    const [paymentStepData, setPaymentStepData] = useState<PaymentStepFormData>();

    const userIsAuthenticated = isAuthenticated || addresses.length > 0;

    const cart = useMemo((): GTMProductInterface => generateGTMCart(order), [order]);

    useDebounce((): void => {
        if (tab === CheckoutTab.order) {
            if (cart.items.length === 0) {
                return;
            }

            gtmEvent({
                event: GTMEvent.viewCart,
                ...cart,
            });
        }
    }, 100, [cart]);

    useEffect((): void => {
        if (order.addresses?.length) {
            setAddresses(order.addresses);
            return;
        }

        if (customer?.addresses) {
            setAddresses(customer.addresses);
        }
    }, [customer, order]);

    useEffect((): void => {
        if (tab === CheckoutTab.payment && order.id && order.items.length && !order.paymentMethods?.length) {
            dispatch(getPaymentMethods());
        }
    }, [order, tab]);

    useEffect((): void => {
        if (order.items.length === 0) {
            navigate(trans(appRoutes[AppRoute.checkoutOrder].path));
        }
    }, [tab, order]);

    useEffect((): void => {
        if (order.paymentEnvironmentUrl) {
            if (!isSSR) {
                window.open(order.paymentEnvironmentUrl, LinkTarget.self);
            }
        }
    }, [order.paymentEnvironmentUrl]);

    useEffect((): void => {
        if (order.items.length === 0) {
            navigate(trans(appRoutes[AppRoute.checkoutOrder].path));
        }
    }, [tab, order]);

    const handleAddCouponCode = async (data: AddCouponCodeToOrderFormData): Promise<void> => {
        await dispatch(addCouponCodeToOrder(data));
    };

    const handleRemoveCouponCode = (): void => {
        dispatch(removeCouponCodeFromOrder());
    };

    const handleAfterLogin = async (): Promise<void> => {
        await dispatch(fetchCustomer());

        await dispatch(connectOrderToCustomer());

        navigate(trans(appRoutes[AppRoute.checkoutPayment].path));
    };

    const handleToCheckout = async (): Promise<void> => {
        gtmEvent({
            event: GTMEvent.checkoutHasBegun,
            ...cart,
        });

        if (userIsAuthenticated) {
            navigate(trans(appRoutes[AppRoute.checkoutPayment].path));

            return;
        }

        navigate(trans(appRoutes[AppRoute.checkoutDetails].path));
    };

    const handleContinueAsGuest = async (formData: UpdateCartFormData): Promise<void> => {
        await dispatch(updateOrderInfo(formData));

        if (formData.subscribedToNewsletter) {
            const body: NewsletterFormData = {
                email: formData.email || '',
                firstName: formData.firstName || '',
            };

            await dispatch(signUpForNewsletter(body));

            gtmEvent({
                event: GTMEvent.signUp,
                method: 'newsletter',
            });
        }

        navigate(trans(appRoutes[AppRoute.checkoutPayment].path));
    };

    const handleCreateAccount = async (formData: CreateAccountDuringCheckoutFormData): Promise<void> => {
        await dispatch(registerUserDuringCheckout(formData));

        gtmEvent({
            event: GTMEvent.signUp,
            method: 'account',
        });

        if (customer) {
            const body: UpdateCartFormData = {
                email: formData.email,
                shippingAddress: formData.shippingAddress,
                billingAddress: formData.billingAddress,
            };

            await dispatch(updateOrderInfo(body));
        }
    };

    const handlePayment = async (data: StartPaymentFormData): Promise<void> => {
        const body: StartPaymentFormData = {
            expectedPriceInCents: order.totalPrice,
            paymentMethodId: data.paymentMethodId,
            paymentBankId: data.paymentMethodId,
            giftCardNumber: data.giftCardNumber,
            giftCardPin: data.giftCardPin,
            notes: data.notes,
        };

        dispatch(payOrder(body));
    };

    const handlePaymentStep = async (): Promise<void> => {
        if (!paymentStepData) {
            console.error('No payment data');
            return;
        }

        const body: UpdateCartFormData = {
            email: customer?.email,
            shippingAddress: paymentStepData.shippingAddress,
            billingAddress: paymentStepData.billingAddress,
        };

        await dispatch(updateOrderInfo(body));

        await handlePayment(paymentStepData);
    };

    const handleUpdateCartItemQuantity = async (id: string, quantity: number): Promise<void> => {
        await dispatch(updateProductVariantInCart(id, quantity));
    };

    const handleRemoveItemFromCart = async (id: string): Promise<void> => {
        await dispatch(removeProductVariantFromCart(id));

        const cartItem = order.items.find(item => item.id === id);

        if (!cartItem) {
            return;
        }

        const GTMProduct = generateCartItemToGTMProduct(cartItem);

        gtmEvent({
            event: GTMEvent.removeItemFromCart,
            ...GTMProduct,
        });
    };

    const handleClearCart = async (): Promise<void> => {
        order.items.forEach(product => {
            const GTMProduct = generateCartItemToGTMProduct(product);

            gtmEvent({
                event: GTMEvent.removeItemFromCart,
                ...GTMProduct,
            });
        });

        await dispatch(clearCart());
    };

    return (
        <Checkout
            {...order}
            isLoading={isLoading}
            tab={tab}
            addresses={addresses}
            paymentMethods={order?.paymentMethods}
            uspItems={MOCK_USPS} // TODO: Replace with data from Strapi
            onAddCouponCode={handleAddCouponCode}
            onRemoveCouponCode={handleRemoveCouponCode}
            onLoggedIn={handleAfterLogin}
            onGoToCheckout={handleToCheckout}
            onContinueAsGuest={handleContinueAsGuest}
            onCreateAccount={handleCreateAccount}
            onPayOrder={handlePaymentStep}
            setPaymentData={setPaymentStepData}
            onUpdateCartItem={handleUpdateCartItemQuantity}
            onRemoveItemFromCart={handleRemoveItemFromCart}
            onClearCart={handleClearCart}
        />
    );
};
