import { convertPriceInCentsToEuros } from '../../../helpers/number';
import { generateUuid, stringIsNumber } from '../../../helpers/string';
import trans from '../../../helpers/trans';
import {
    Locales,
    ProductAttributeKey,
    ProductDimensionAttributeKey,
    ProductVariantResource,
} from '../../@api/Sylius';
import { transformBrandNameToLink } from '../../Brand/BrandTransformers';
import { ProductPriceInterface } from '../../Price/Price';
import { transformToProductCode } from '../Product/ProductTransformers';
import { ProductAttribute, ProductDimensionAttribute } from '../ProductAttribute/ProductAttribute';
import { transformToProductAttribute } from '../ProductAttribute/ProductAttributeTransformers';
import { ProductVariantColor } from '../ProductVariantColor/ProductVariantColor';
import { dimensionAttributeKeys, ProductVariant } from './ProductVariant';

export const locale = Locales.nl;

const transformToAvailabilityTime = (days: number): string => {
    const weekDays = 7;
    const monthDays = 30;

    if (days === 1) {
        return trans('entities.product.day');
    }

    if (days <= weekDays) {
        return trans('entities.product.days', { days });
    }

    if (days < monthDays) {
        const weeks = Math.ceil(days / weekDays);
        return weeks === 1
            ? trans('entities.product.week')
            : trans('entities.product.weeks', { weeks });
    }

    const months = Math.ceil(days / monthDays);
    return months === 1
        ? trans('entities.product.month')
        : trans('entities.product.months', { months });
};

export const transformToProductVariant = (resource: ProductVariantResource): ProductVariant => {
    const productCode = transformToProductCode(resource.product);

    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const translatedProductAttributes = resource.attributes
        ? resource.attributes.filter(attribute => attribute.localeCode === locale)
        : [];

    const productVariantAttributes = translatedProductAttributes
        ? translatedProductAttributes.map(transformToProductAttribute)
        : [];

    const productVariantAttributesWithData = productVariantAttributes.filter(
        productVariantAttribute => productVariantAttribute.value
            && !dimensionAttributeKeys.includes(productVariantAttribute.key as ProductAttributeKey),
    );

    const skuAttribute = {
        key: ProductAttributeKey.sku,
        label: trans('entities.product.sku'),
        value: productCode,
    };

    const attributes: ProductAttribute[] = [
        skuAttribute,
        ...productVariantAttributesWithData,
    ];

    const dimensionAttributeValues = Object.values(ProductDimensionAttributeKey);
    const dimensionAttributes: ProductDimensionAttribute[] = dimensionAttributeValues.map(attributeKey => {
        const foundAttribute = productVariantAttributes.find(productAttribute => productAttribute.key === attributeKey);

        return {
            key: attributeKey,
            label: trans(`entities.productFilters.${attributeKey}`),
            value: foundAttribute?.value || '',
        };
    });

    const dimensionAttributesWithData = dimensionAttributes.filter(dimensionAttribute => dimensionAttribute.value);

    const attributesToExclude = [
        ...dimensionAttributeValues,
        ProductAttributeKey.brand,
    ];

    const filteredAttributes = attributes.filter(productAttribute => (
        !attributesToExclude.includes(productAttribute.key)
    ));

    attributes.length = 0;
    attributes.push(...filteredAttributes);

    const brandAttribute = productVariantAttributesWithData.find(productAttribute => productAttribute.key === ProductAttributeKey.brand);
    const brand = brandAttribute
        ? transformBrandNameToLink(brandAttribute.value)
        : undefined;

    const colorsAttribute = productVariantAttributesWithData.find(productAttribute => productAttribute.key === ProductAttributeKey.colors);
    const colors = colorsAttribute
        ? colorsAttribute.value.split(', ') as ProductVariantColor[]
        : [];

    const deliveryMin = productVariantAttributes.find(attribute => attribute.key === ProductAttributeKey.deliveryMin);
    const deliveryNumber = stringIsNumber(deliveryMin?.value || '')
        ? Number(deliveryMin?.value)
        : 0;

    const availableInTime = deliveryMin && deliveryNumber > 0
        ? transformToAvailabilityTime(deliveryNumber)
        : undefined;


    const defaultPrice: ProductPriceInterface = {
        original: convertPriceInCentsToEuros(resource.originalPrice || 0),
        current: convertPriceInCentsToEuros(resource.originalPrice || 0),
        discount: false,
    };

    const discountInPercentage = resource.discount?.isPercentage
        ? convertPriceInCentsToEuros(resource.discount.value)
        : undefined;

    const price: ProductPriceInterface = resource.discount
        ? {
            ...defaultPrice,
            current: convertPriceInCentsToEuros(resource.price),
            discount: true,
            discountInPercentage,
        }
        : defaultPrice;

    return {
        id,
        variantId: resource.id?.toString() || '',
        variantCode: resource.code,
        name: resource.name || '',
        price,
        attributes,
        dimensionAttributes: dimensionAttributesWithData,
        brand,
        colors,
        stock: resource.inStock,
        productCode,
        availableInTime,
    };
};
