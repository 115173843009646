import { strapiFetch } from '../../../entities/@api/Strapi';
import { TopNavbarResponse } from '../../../entities/@blocks/TopNavbar/TopNavbar';
import { generateTopNavbarQuery } from '../../../entities/@blocks/TopNavbar/TopNavbarRequest';
import { transformToTopNavbar } from '../../../entities/@blocks/TopNavbar/TopNavbarTransformers';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { ReduxFetchAction } from '../../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setTopNavbarItems,
} from './topNavbarSlice';

export const fetchTopNavbar: ReduxFetchAction = () => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));
    dispatch(setTopNavbarItems(undefined));

    try {
        const query = generateTopNavbarQuery();

        const topNavbarResponse = await strapiFetch<TopNavbarResponse>(`/top-navbar?${query}`);

        if (!isFetchResultSuccessful(topNavbarResponse)) {
            dispatch(setError(topNavbarResponse.error));
            return;
        }

        const { data } = topNavbarResponse.data;

        if (data) {
            const transformedTopNavbar = transformToTopNavbar(data);
            dispatch(setTopNavbarItems(transformedTopNavbar));
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchTopNavbar]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};
