import { AddCouponCodeToOrderFormData } from '../../../../entities/@forms/CouponCodeForm/CouponCodeForm';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../entities/@forms/FormValidation/FormValidationService';

export type CouponFormErrors = FormErrors<AddCouponCodeToOrderFormData>;

export const validateCouponCodeFormData = (formData: AddCouponCodeToOrderFormData): FormValidation<CouponFormErrors> => {
    const errors: CouponFormErrors = {
        discountCode: validateRequiredString('couponCode', formData.discountCode),
    };

    return validateForm<CouponFormErrors>(errors);
};
