import {
    FC,
    ReactElement,
    ReactEventHandler,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useSearchParams } from 'react-router-dom';

import { Accordion, RangeInput } from '../../../../compositions';
import { customFilterAttributes, filterAttributePrefix, MeilisearchRangeFilter } from '../../../../entities/@search/Meilisearch/Meilisearch';
import { paginationPageParameter } from '../../../../entities/Pagination/Pagination';
import { transformMinMaxStringToRangeFilterValue, transformRangeFilterValueToMinMaxString } from '../../../../helpers/meilisearch';
import { useDebounceAfterInitialRender, useTrans } from '../../../../hooks';
import { MinMaxValue } from '../../../../types';

import './RangeFilterAccordion.scss';

interface RangeFilterAccordionProps extends MeilisearchRangeFilter {
    className?: string;
}

export const RangeFilterAccordion: FC<RangeFilterAccordionProps> = ({
    attribute,
    min,
    max,
    step,
    valueFormat,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const [searchParams, setSearchParams] = useSearchParams();

    const [value, setValue] = useState<MinMaxValue>({ min, max });
    const [shouldForceUpdate, setShouldForceUpdate] = useState<boolean>(false);

    const label = useMemo((): string => {
        if (customFilterAttributes.includes(attribute)) {
            return trans(`entities.meilisearch.customAttributes.${attribute}`);
        }

        return attribute.replace(filterAttributePrefix, '');
    }, [trans, attribute]);

    useEffect((): void => {
        const attributeParams = searchParams.get(attribute);

        if (attributeParams) {
            const paramValues = transformMinMaxStringToRangeFilterValue(attributeParams);

            setValue({
                min: paramValues.min,
                max: paramValues.max,
            });
        }
    }, []);

    useDebounceAfterInitialRender((): void => {
        const hasChanges = value.min !== min || value.max !== max;

        if (hasChanges) {
            searchParams.set(attribute, transformRangeFilterValueToMinMaxString(value));
        } else {
            searchParams.delete(attribute);
        }

        searchParams.set(paginationPageParameter, '1');
        setSearchParams(searchParams, { replace: true });
    }, 250, [value]);

    const handleToggle: ReactEventHandler<HTMLDetailsElement> = event => {
        const isExpanded = event?.currentTarget.hasAttribute('open');

        if (!shouldForceUpdate) {
            setShouldForceUpdate(isExpanded);
        }
    };

    return (
        <Accordion
            hasBorder
            header={label}
            className={`range-filter-accordion ${className}`}
            headingClassName="range-filter-accordion__heading"
            contentClassName="range-filter-accordion__content"
            onToggle={handleToggle}
        >
            <RangeInput
                hideLabel
                forceUpdate={shouldForceUpdate}
                id={attribute}
                label={label}
                minHandleLabel={trans('common.min')}
                maxHandleLabel={trans('common.max')}
                value={value}
                min={min}
                max={max}
                step={step}
                valueFormat={valueFormat}
                hiddenMarginPercentage={15}
                onChange={setValue}
            />
        </Accordion>
    );
};
