import { generatePaginatedDataQuery, strapiFetch } from '../../../entities/@api/Strapi';
import { Brand, BrandItemResponse } from '../../../entities/Brand/Brand';
import { transformToBrand, transformToBrandNavigation } from '../../../entities/Brand/BrandTransformers';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { generateIdArray } from '../../../helpers/array';
import { ReduxFetchAction } from '../../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setNavigation,
} from './brandOverviewSlice';

export const fetchBrandNavigation: ReduxFetchAction = () => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));
    dispatch(setNavigation(undefined));

    try {
        const query = generatePaginatedDataQuery(1);

        const brandsResponse = await strapiFetch<BrandItemResponse>(`/brands?${query}`);

        if (!isFetchResultSuccessful(brandsResponse)) {
            dispatch(setError(brandsResponse.error));
            return;
        }

        const { data: brandsData, meta } = brandsResponse.data;
        const { pagination } = meta;

        const extraPages = brandsData
            ? Math.ceil(pagination.total / brandsData.length) - 1
            : 0;

        const pagesToFetch = generateIdArray(extraPages).map(page => page + 2);

        let allBrands: Brand[] = brandsData
            ? brandsData.map(transformToBrand)
            : [];

        const pageFetchPromises = pagesToFetch.map(async page => {
            const pageQuery = generatePaginatedDataQuery(page);
            const pageResponse = await strapiFetch<BrandItemResponse>(`/brands?${pageQuery}`);

            if (!isFetchResultSuccessful(pageResponse)) {
                dispatch(setError(pageResponse.error));
                return;
            }

            const { data: pageData } = pageResponse.data;

            const brands = pageData
                ? pageData.map(transformToBrand)
                : [];

            allBrands = [...allBrands, ...brands];
        });

        await Promise.allSettled(pageFetchPromises);

        const filteredBrands = allBrands.filter(brand => brand.name && brand.slug);

        const navigation = filteredBrands
            ? transformToBrandNavigation(filteredBrands)
            : undefined;

        dispatch(setNavigation(navigation));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchBrandNavigation]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};
