import { FC, ReactElement } from 'react';

import { ResetPasswordForm } from '../../../containers';
import { ResetPasswordFormData } from '../../../entities/@forms/ResetPasswordForm/ResetPasswordForm';
import { resetPassword } from '../../../redux/@account/resetPassword/resetPasswordActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedResetPasswordFormProps {
    className?: string;
}

export const ConnectedResetPasswordForm: FC<ConnectedResetPasswordFormProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.resetPasswordSlice.isLoading);
    const error = useTypedSelector(state => state.resetPasswordSlice.error);

    const handleSubmit = (formData: ResetPasswordFormData): void => {
        dispatch(resetPassword(formData));
    };

    return (
        <ResetPasswordForm
            isLoading={isLoading}
            error={error}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};
