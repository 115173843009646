import {
    FC,
    FocusEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useTrans } from '../../../hooks';
import { TextInput } from '../../index';

import './GiftcardBalanceInputs.scss';

interface GiftcardBalanceInputsProps {
    isLoading?: boolean;
    pin: string;
    setCardNumber: (value: string) => void;
    setPin: (value: string) => void;
    className?: string;
}

export const GiftcardBalanceInputs: FC<GiftcardBalanceInputsProps> = ({
    isLoading,
    pin,
    setCardNumber,
    setPin,
    className,
}): ReactElement => {
    const trans = useTrans();

    const cardNumberBRef = useRef<HTMLInputElement>(null);
    const cardNumberCRef = useRef<HTMLInputElement>(null);
    const cardNumberDRef = useRef<HTMLInputElement>(null);
    const cardNumberERef = useRef<HTMLInputElement>(null);
    const pinRef = useRef<HTMLInputElement>(null);

    const [cardNumberA, setCardNumberA] = useState<string>('');
    const [cardNumberB, setCardNumberB] = useState<string>('');
    const [cardNumberC, setCardNumberC] = useState<string>('');
    const [cardNumberD, setCardNumberD] = useState<string>('');
    const [cardNumberE, setCardNumberE] = useState<string>('');

    useEffect((): void => {
        const combinedCardNumber = [cardNumberA, cardNumberB, cardNumberC, cardNumberD, cardNumberE].join('');

        setCardNumber(combinedCardNumber);
    }, [cardNumberA, cardNumberB, cardNumberC, cardNumberD, cardNumberE]);

    const handleChangeCardNumberA = (value: string): void => {
        setCardNumberA(value);

        if (value.length === 4 && cardNumberBRef.current) {
            cardNumberBRef.current.focus();
        }
    };

    const handleChangeCardNumberB = (value: string): void => {
        setCardNumberB(value);

        if (value.length === 4 && cardNumberCRef.current) {
            cardNumberCRef.current.focus();
        }
    };

    const handleChangeCardNumberC = (value: string): void => {
        setCardNumberC(value);

        if (value.length === 4 && cardNumberDRef.current) {
            cardNumberDRef.current.focus();
        }
    };

    const handleChangeCardNumberD = (value: string): void => {
        setCardNumberD(value);

        if (value.length === 4 && cardNumberERef.current) {
            cardNumberERef.current.focus();
        }
    };

    const handleChangeCardNumberE = (value: string): void => {
        setCardNumberE(value);

        if (value.length === 3 && pinRef.current) {
            pinRef.current.focus();
        }
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
        event.currentTarget.select();
    };

    return (
        <div className={`giftcard-balance-inputs ${className}`}>
            <p className="giftcard-balance-inputs__legend">
                {trans('containers.giftcardBalanceForm.cardNumber')}
            </p>

            <div className="giftcard-balance-inputs__list">
                <TextInput
                    inputMode="numeric"
                    hideLabel
                    label={trans('containers.giftcardBalanceForm.cardNumberStart')}
                    value={cardNumberA}
                    placeholder="0000"
                    maxLength={4}
                    disabled={isLoading}
                    onChange={handleChangeCardNumberA}
                    onFocus={handleFocus}
                    className="giftcard-balance-inputs__card-number-input"
                    fieldClassName="giftcard-balance-inputs__card-number-field"
                />

                <TextInput
                    ref={cardNumberBRef}
                    inputMode="numeric"
                    hideLabel
                    label={trans('containers.giftcardBalanceForm.cardNumberProgress')}
                    value={cardNumberB}
                    placeholder="0000"
                    maxLength={4}
                    disabled={isLoading}
                    onChange={handleChangeCardNumberB}
                    onFocus={handleFocus}
                    className="giftcard-balance-inputs__card-number-input"
                    fieldClassName="giftcard-balance-inputs__card-number-field"
                />

                <TextInput
                    ref={cardNumberCRef}
                    inputMode="numeric"
                    hideLabel
                    label={trans('containers.giftcardBalanceForm.cardNumberProgress')}
                    value={cardNumberC}
                    placeholder="0000"
                    maxLength={4}
                    disabled={isLoading}
                    onChange={handleChangeCardNumberC}
                    onFocus={handleFocus}
                    className="giftcard-balance-inputs__card-number-input"
                    fieldClassName="giftcard-balance-inputs__card-number-field"
                />

                <TextInput
                    ref={cardNumberDRef}
                    inputMode="numeric"
                    hideLabel
                    label={trans('containers.giftcardBalanceForm.cardNumberProgress')}
                    value={cardNumberD}
                    placeholder="0000"
                    maxLength={4}
                    disabled={isLoading}
                    onChange={handleChangeCardNumberD}
                    onFocus={handleFocus}
                    className="giftcard-balance-inputs__card-number-input"
                    fieldClassName="giftcard-balance-inputs__card-number-field"
                />

                <TextInput
                    ref={cardNumberERef}
                    inputMode="numeric"
                    hideLabel
                    label={trans('containers.giftcardBalanceForm.cardNumberEnd')}
                    value={cardNumberE}
                    placeholder="000"
                    maxLength={3}
                    disabled={isLoading}
                    onChange={handleChangeCardNumberE}
                    onFocus={handleFocus}
                    className="giftcard-balance-inputs__card-number-input"
                    fieldClassName="giftcard-balance-inputs__card-number-field"
                />
            </div>

            <TextInput
                ref={pinRef}
                inputMode="numeric"
                label={trans('containers.giftcardBalanceForm.pinCode')}
                value={pin}
                placeholder={trans('containers.giftcardBalanceForm.pinCodePlaceholder')}
                maxLength={6}
                disabled={isLoading}
                onChange={setPin}
                onFocus={handleFocus}
                className="giftcard-balance-inputs__pin-code-input"
            />
        </div>
    );
};
