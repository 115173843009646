import { Navigation, NavigationItem } from '../../../entities/@navigation/Navigation/Navigation';

export const retrieveMainNavigationItem = (navigation?: Navigation): NavigationItem | undefined => {
    if (!navigation || navigation.groups.length === 0) {
        return undefined;
    }

    const { items } = navigation.groups[0];

    if (items.length === 0) {
        return undefined;
    }

    return items[0];
};

export const excludeMainNavigation = (navigation?: Navigation): Navigation | undefined => {
    const mainNavigationItem = retrieveMainNavigationItem(navigation);

    if (!navigation || !mainNavigationItem) {
        return navigation;
    }

    return {
        ...navigation,
        groups: navigation.groups.map(group => ({
            ...group,
            items: group.items.filter(item => item.id !== mainNavigationItem.id),
        })),
    };
};

export const findMatchingNavigationItem = (pathname: string, navigation: Navigation): NavigationItem | undefined => {
    const matchingGroup = navigation.groups.find(group => (
        group.items.find(item => pathname.startsWith(item.to.toString()))
    ));

    return matchingGroup?.items.find(item => (
        pathname.startsWith(item.to.toString())
    ));
};
