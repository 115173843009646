import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { FormOption, FormProps } from '../../../entities/@forms/Form/Form';
import { Address, AddressType, CustomerAddress } from '../../../entities/Address/Address';
import { countryOptions } from '../../../entities/Country/Country';
import { scrollIntoViewIncludingNavigation } from '../../../helpers/scroll';
import { useTrans } from '../../../hooks';
import {
    AddressCard,
    Button,
    SelectInput,
    TextInput,
} from '../../index';
import { AddressFormErrors, validateAddressFormData } from './validations';

import './AddressForm.scss';

interface AddressFormProps extends FormProps<CustomerAddress> {
    hideTypeButtons?: boolean;
    isEditModeOn?: boolean;
    address: CustomerAddress;
    onChangeShippingAddress?: (addressId: string, type: AddressType) => void;
    className?: string;
}

export const AddressForm: FC<AddressFormProps> = ({
    hideTypeButtons,
    isEditModeOn,
    address,
    onSubmit,
    onChangeShippingAddress,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formRef = useRef<HTMLFormElement>(null);

    const [isEditMode, setIsEditMode] = useState<boolean>(!!isEditModeOn);

    const [formErrors, setFormErrors] = useState<AddressFormErrors>({});

    const defaultCountry = countryOptions.find(item => item.value === address.country.toLowerCase()) || countryOptions[0];

    const [label, setLabel] = useState<string>(address.label);
    const [street, setStreet] = useState<string>(address.street);
    const [postalCode, setPostalCode] = useState<string>(address.postalCode);
    const [houseNumber, setHouseNumber] = useState<string>(address.houseNumber);
    const [addition, setAddition] = useState<string>(address.addition || '');
    const [city, setCity] = useState<string>(address.city);
    const [country, setCountry] = useState<FormOption>(defaultCountry);

    const handleEditButtonClick = (): void => {
        setIsEditMode(true);
    };

    const handleCloseButtonClick = (): void => {
        setIsEditMode(false);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const formData: Address = {
            ...address,
            label,
            street,
            postalCode,
            houseNumber,
            addition,
            city,
            country: country.value.toUpperCase(),
        };

        const [errors, hasErrors] = validateAddressFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            setIsEditMode(false);
            onSubmit(formData);
        } else {
            scrollIntoViewIncludingNavigation<HTMLFormElement>(formRef);
        }
    };

    const handleCountryDropDown = (value: string): void => {
        const correctCountry = countryOptions.find(item => item.value === value) as FormOption;
        setCountry(correctCountry);
    };

    if (isEditMode) {
        return (
            <form
                ref={formRef}
                onSubmit={handleSubmit}
                className={`address-form ${className}`}
            >
                <div className="address-form__item">
                    <div className="address-form__input-wrapper">
                        <TextInput
                            required
                            label={trans('form.addressLabel')}
                            error={formErrors.label}
                            value={label}
                            onChange={setLabel}
                        />
                    </div>

                    <div className="address-form__input-wrapper">
                        <TextInput
                            required
                            label={trans('form.street')}
                            error={formErrors.street}
                            value={street}
                            onChange={setStreet}
                        />

                        <TextInput
                            required
                            label={trans('form.houseNumber')}
                            error={formErrors.houseNumber}
                            value={houseNumber}
                            onChange={setHouseNumber}
                        />

                        <TextInput
                            label={trans('form.addition')}
                            error={formErrors.addition}
                            value={addition}
                            onChange={setAddition}
                        />
                    </div>

                    <div className="address-form__input-wrapper">
                        <TextInput
                            required
                            label={trans('form.city')}
                            error={formErrors.city}
                            value={city}
                            onChange={setCity}
                        />

                        <TextInput
                            required
                            autoCapitalize="characters"
                            label={trans('form.postalCode')}
                            error={formErrors.postalCode}
                            value={postalCode}
                            onChange={setPostalCode}
                        />
                    </div>

                    <div className="address-form__input-wrapper">
                        <SelectInput
                            name="countrySelect"
                            options={countryOptions}
                            label={trans('form.country')}
                            error={formErrors.country}
                            placeholder={country.label}
                            value={country}
                            onChange={handleCountryDropDown}
                            className="address-form__country-select"
                        />
                    </div>
                </div>

                <div className="address-form__buttons-wrapper">
                    <Button
                        isSmall
                        type="button"
                        text={trans('common.close')}
                        onClick={handleCloseButtonClick}
                        className="address-form__close-button"
                    />

                    <Button
                        isSmall
                        type="submit"
                        text={trans('common.save')}
                    />
                </div>
            </form>
        );
    }

    return (
        <AddressCard
            hideTypeButtons={hideTypeButtons}
            address={address}
            onClickEditButton={handleEditButtonClick}
            onChangeShippingAddress={onChangeShippingAddress}
            className={className}
        />
    );
};
