import { CSSProperties, FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture, SafeHtml, Wrapper } from '../../../components';
import { LinkIconButton, Video } from '../../../compositions';
import { HighlightedMediaInterface } from '../../../entities/@blocks/HighlightedMedia/HighlightedMedia';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { isSilentLoop } from '../../../entities/Media/Media';

import './HighlightedMedia.scss';

interface HighlightedMediaProps extends HighlightedMediaInterface {
    className?: string;
}

export const HighlightedMedia: FC<HighlightedMediaProps> = ({
    media,
    titleHtml,
    paragraphHtml,
    imageAlignment,
    link,
    titleColor,
    className = '',
}): ReactElement => {
    const isReversed = imageAlignment === HorizontalAlignment.right;

    const wrapperClassNames = classNames('highlighted-media__wrapper', {
        'highlighted-media__wrapper--is-reversed': isReversed,
    });

    const cssVariables = {
        '--c-highlighted-media-title': titleColor,
    } as CSSProperties;

    return (
        <div
            style={cssVariables}
            className={`highlighted-media ${className}`}
        >
            <Wrapper className={wrapperClassNames}>
                <div className="highlighted-media__media-wrapper">
                    {media.image && (
                        <Picture
                            {...media.image}
                            className="highlighted-media__picture"
                            imageClassName="highlighted-media__image"
                        />
                    )}

                    {media.video && (
                        <Video
                            {...media.video}
                            {...isSilentLoop}
                            className="highlighted-media__video"
                        />
                    )}
                </div>

                <div className="highlighted-media__text-wrapper">
                    {titleHtml && (
                        <SafeHtml
                            html={titleHtml}
                            className="highlighted-media__title"
                        />
                    )}

                    {paragraphHtml && (
                        <SafeHtml
                            html={paragraphHtml}
                            className="highlighted-media__paragraph-html"
                        />
                    )}

                    {link && (
                        <LinkIconButton
                            hasAnimation
                            iconPos={HorizontalAlignment.right}
                            icon="arrow-right"
                            to={link.href}
                            text={link.label}
                            className="highlighted-media__button"
                        />
                    )}
                </div>
            </Wrapper>
        </div>
    );
};
