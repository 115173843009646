interface DateRange {
    start?: Date;
    end?: Date;
}

export const dateIsInRange = (range: DateRange, date = new Date()): boolean => {
    const { start, end } = range;

    if (!start && !end) {
        return true;
    }

    const isPastStart = !start || date > start;
    const isPastEnd = end ? date > end : false;

    return isPastStart && !isPastEnd;
};

export const transformIsoStringToDate = (isoString: string): string => new Date(`${isoString}Z`).toISOString().split('T')[0];

export const calculateDifferenceInDays = (date: Date | string): number => {
    const dateObject = date instanceof Date
        ? date
        : new Date(date);

    const today = new Date();

    const differenceInMilliSeconds = today.getTime() - dateObject.getTime();

    return Math.floor(differenceInMilliSeconds / (1000 * 60 * 60 * 24));
};
