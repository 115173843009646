import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderOverviewInterface } from '../../../entities/@account/OrderOverview/OrderOverview';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type OrderOverviewState = AsyncReduxState<{
    onlineOrderOverview?: OrderOverviewInterface;
    inStoreOrderOverview?: OrderOverviewInterface;
}>;

const initialState: OrderOverviewState = {
    ...initialAsyncReduxState,
};

const orderOverviewSlice = createSlice({
    name: 'orderOverviewSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OrderOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): OrderOverviewState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): OrderOverviewState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): OrderOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setOnlineOrderOverview(state, action: PayloadAction<OrderOverviewInterface | undefined>): OrderOverviewState {
            return {
                ...state,
                onlineOrderOverview: action.payload,
            };
        },
        setInStoreOrderOverview(state, action: PayloadAction<OrderOverviewInterface | undefined>): OrderOverviewState {
            return {
                ...state,
                inStoreOrderOverview: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setOnlineOrderOverview,
    setInStoreOrderOverview,
} = orderOverviewSlice.actions;

export default orderOverviewSlice;
