import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderInterface } from '../../../entities/@shop/Order/Order';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type OrderState = AsyncReduxState<{
    order: OrderInterface;
}>;

export const defaultOrder: OrderInterface = {
    id: '',
    items: [],
    totalPrice: 0,
    subTotalPrice: 0,
    discounts: [],
};

const initialState: OrderState = {
    ...initialAsyncReduxState,
    order: defaultOrder,
};

const orderSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OrderState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): OrderState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): OrderState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setOrder(state, action: PayloadAction<OrderInterface>): OrderState {
            return {
                ...state,
                order: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setOrder,
} = orderSlice.actions;

export default orderSlice;
