import { FC, ReactElement } from 'react';

import { GiftcardBalanceForm } from '../../../containers';
import { GiftcardBalanceFormData } from '../../../entities/@forms/GiftcardBalanceForm/GiftcardBalanceForm';
import { checkGiftcardBalance } from '../../../redux/@forms/giftcardBalanceForm/giftcardBalanceFormActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedGiftcardBalanceFormProps {
    className?: string;
}

export const ConnectedGiftcardBalanceForm: FC<ConnectedGiftcardBalanceFormProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.giftcardBalanceFormSlice.isLoading);
    const hasFetched = useTypedSelector(state => state.giftcardBalanceFormSlice.hasFetched);
    const balance = useTypedSelector(state => state.giftcardBalanceFormSlice.balance);
    const error = useTypedSelector(state => state.giftcardBalanceFormSlice.error);

    const handleSubmit = async (formData: GiftcardBalanceFormData): Promise<void> => {
        await dispatch(checkGiftcardBalance(formData));
    };

    return (
        <GiftcardBalanceForm
            isLoading={isLoading}
            hasFetched={hasFetched}
            balance={balance}
            error={error}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};
