import { FC, ReactElement } from 'react';

import { NavLink } from 'react-router-dom';

import { NavigationItem } from '../../../../entities/@navigation/Navigation/Navigation';

interface SsrNavigationItemProps {
    item: NavigationItem;
}

export const SsrNavigationItem: FC<SsrNavigationItemProps> = ({
    item,
}): ReactElement => (
    <li key={item.id}>
        <NavLink to={item.to}>
            {item.label}
        </NavLink>

        {item.subNavigation && (
            <nav aria-label={item.label}>
                <ul>
                    {item.subNavigation.groups.map(subGroup => (
                        subGroup.items.map(subItem => (
                            <SsrNavigationItem key={subItem.id} item={subItem} />
                        ))
                    ))}
                </ul>
            </nav>
        )}
    </li>
);
