import {
    MeilisearchAttribute,
    MeilisearchIndex,
    MeilisearchPage,
    MeilisearchProductResource,
    SearchResource,
} from '../../entities/@api/Meilisearch';
import { transformProductHitToProduct } from '../../entities/@products/Product/ProductTransformers';
import {
    filterAttributePrefix,
    filterAttributes,
    forbiddenMeilisearchAttributeKeys,
    highlightedFilterAttributes,
    MeilisearchPagesConfig,
    MeilisearchVariantsConfig,
    rangedFilterAttributes,
} from '../../entities/@search/Meilisearch/Meilisearch';
import { generateMeilisearchConfigResource } from '../../entities/@search/Meilisearch/MeilisearchRequests';
import { meilisearchFetch } from '../../entities/@search/Meilisearch/MeilisearchService';
import { sortOnMeilisearchAttribute } from '../../entities/@search/Meilisearch/MeilisearchSorters';
import {
    accumulateMeilisearchFilters,
    transformMeilisearchResourceToPagination,
    transformToMeilisearchFilter,
    transformToMeilisearchResponseBase,
    transformToProductRangeFilter,
} from '../../entities/@search/Meilisearch/MeilisearchTransformers';
import { transformPageHitToPageSearchResult } from '../../entities/@search/PageSearchResult/PageSearchResultTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { isEmptyObject } from '../../helpers/object';
import { ReduxFetchAction } from '../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsLoadingPages,
    setIsLoadingVariants,
    setIsSuccessful,
    setPageResponses,
    setVariantResponses,
} from './meilisearchSlice';

export const fetchMeilisearchVariants: ReduxFetchAction<MeilisearchVariantsConfig> = config => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setIsLoadingVariants(true));
    dispatch(setHasFetched(false));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const {
            retrieveInitialFilters,
            index,
            key,
            query = '',
        } = config;
        const searchQuery = generateMeilisearchConfigResource(config);

        const meilisearchResponse = await meilisearchFetch<SearchResource<MeilisearchProductResource>>(`/indexes/${index}/search`, {
            method: 'POST',
            body: JSON.stringify(searchQuery),
        });

        if (!isFetchResultSuccessful(meilisearchResponse)) {
            dispatch(setError(meilisearchResponse.error));
            return;
        }

        const meilisearchResource = meilisearchResponse.data;
        const { hits = [], facetDistribution = {}, facetStats = {} } = meilisearchResource;

        // Base transformations
        const searchResponseBase = transformToMeilisearchResponseBase(meilisearchResource);
        const results = hits.map(transformProductHitToProduct);
        const pagination = transformMeilisearchResourceToPagination(meilisearchResource);

        // Remove unnecessary filters and group all options based on their attribute
        const filtersResource = Object.entries(facetDistribution).filter(([attribute, value]) => {
            const hasAttributePrefix = attribute.startsWith(filterAttributePrefix);
            const isForbiddenAttribute = forbiddenMeilisearchAttributeKeys.includes(attribute as MeilisearchAttribute);
            const isAllowedFilter = hasAttributePrefix || filterAttributes.includes(attribute as MeilisearchAttribute);
            const isCheckboxAttribute = !rangedFilterAttributes.includes(attribute as MeilisearchAttribute);
            const hasValue = !isEmptyObject(value);

            return isAllowedFilter && isCheckboxAttribute && !isForbiddenAttribute && hasValue;
        }).reduce(accumulateMeilisearchFilters, {});

        // Remove unnecessary filters and group all options based on their attribute
        const rangeFiltersResource = Object.entries(facetStats).filter(([attribute, value]) => {
            const isRangeFilter = rangedFilterAttributes.includes(attribute as MeilisearchAttribute);
            const hasMaxValue = value.max > 0;
            const hasMinMaxDifference = value.min !== value.max;

            return isRangeFilter && hasMaxValue && hasMinMaxDifference;
        }).reduce(accumulateMeilisearchFilters, {});

        // Remove unnecessary filters and group all options based on their attribute
        const highlightedFiltersResource = Object.entries(facetDistribution).filter(([attribute]) => (
            highlightedFilterAttributes.includes(attribute as MeilisearchAttribute)
        )).reduce(accumulateMeilisearchFilters, {});

        // Transform resources to interfaces the front-end desires
        const filters = Object.entries(filtersResource).map(transformToMeilisearchFilter).sort(sortOnMeilisearchAttribute);
        const rangeFilters = Object.entries(rangeFiltersResource).map(transformToProductRangeFilter);
        const highlightedFilters = Object.entries(highlightedFiltersResource).map(transformToMeilisearchFilter);

        // Place response on key between existing responses
        const { meilisearchSlice } = getState();
        const { variantResponses } = meilisearchSlice;
        const storedResponse = variantResponses[key];

        // Only store the query and initial filters for the given query
        if (retrieveInitialFilters) {
            dispatch(setVariantResponses({
                ...variantResponses,
                [key]: {
                    ...storedResponse,
                    query,
                    initialFilters: filters,
                    initialRangeFilters: rangeFilters,
                    initialHighlightedFilters: highlightedFilters,
                },
            }));

            return;
        }

        // Initial/static filters should only update when a new query is used, or when they are not set yet
        const isNewQuery = query !== storedResponse?.query;
        const shouldDispatchStoredData = storedResponse && !isNewQuery;

        const initialFilters = shouldDispatchStoredData
            ? storedResponse?.initialFilters
            : filters;

        const initialRangeFilters = shouldDispatchStoredData
            ? storedResponse?.initialRangeFilters
            : rangeFilters;

        const initialHighlightedFilters = shouldDispatchStoredData
            ? storedResponse?.initialHighlightedFilters
            : highlightedFilters;

        dispatch(setVariantResponses({
            ...variantResponses,
            [key]: {
                ...searchResponseBase,
                results,
                pagination,
                filters,
                rangeFilters,
                initialFilters,
                initialRangeFilters,
                highlightedFilters,
                initialHighlightedFilters,
            },
        }));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchMeilisearchVariants]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setIsLoadingVariants(false));
        dispatch(setHasFetched(true));
    }
};

export const fetchMeilisearchPages: ReduxFetchAction<MeilisearchPagesConfig> = config => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setIsLoadingPages(true));
    dispatch(setHasFetched(false));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const { key } = config;
        const index = MeilisearchIndex.pages;
        const searchQuery = generateMeilisearchConfigResource(config);

        const meilisearchResponse = await meilisearchFetch<SearchResource<MeilisearchPage>>(`/indexes/${index}/search`, {
            method: 'POST',
            body: JSON.stringify(searchQuery),
        });

        if (!isFetchResultSuccessful(meilisearchResponse)) {
            dispatch(setError(meilisearchResponse.error));
            return;
        }

        const meilisearchResource = meilisearchResponse.data;
        const { hits } = meilisearchResource;

        // Place response on key between existing responses
        const { meilisearchSlice } = getState();
        const { pageResponses } = meilisearchSlice;

        const searchResponseBase = transformToMeilisearchResponseBase(meilisearchResource);
        const results = hits.map(transformPageHitToPageSearchResult);
        const pagination = transformMeilisearchResourceToPagination(meilisearchResource);

        dispatch(setPageResponses({
            ...pageResponses,
            [key]: {
                ...searchResponseBase,
                results,
                pagination,
            },
        }));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchMeilisearchPages]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setIsLoadingPages(false));
        dispatch(setHasFetched(true));
    }
};
