import { StrapiEntityData } from '../../@api/Strapi';
import { Product } from '../../@products/Product/Product';
import { ProductSelectionResource } from '../../@products/ProductSelection/ProductSelection';
import { DefaultBlock } from '../../Block/Block';
import { ButtonResource } from '../../Button/Button';
import { Link } from '../../Link/Link';

export enum FeaturedProductsType {
    alternatingSizes = 'alternating-sizes',
    verticalTitle = 'vertical-title',
    slider = 'slider',
}

export interface FeaturedProductsResource extends StrapiEntityData {
    title: string;
    product_selection: ProductSelectionResource | null;
    type: 'Slider' | 'Grid-3' | 'Grid-4';
    button: ButtonResource | null;
}

export interface FeaturedProductsInterface extends DefaultBlock {
    title: string;
    products: Product[];
    meilisearchProductListName: string;
    link?: Link;
    layoutType: FeaturedProductsType;
}
