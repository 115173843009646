import { GetProductResponse, GetProductVariantResponse, syliusFetch } from '../../@api/Sylius';
import { transformToProductDefault } from '../../@products/ProductDefault/ProductDefaultTransformers';
import { transformToProductVariant } from '../../@products/ProductVariant/ProductVariantTransformers';
import { isFetchResultSuccessful } from '../../FetchResult/FetchResult';
import { Image } from '../../Media/Media';
import { defaultImage } from '../../Media/MediaTransformers';
import { EnrichedOrderProductVariant, OrderProductVariant } from './OrderDetail';

export const fetchEnrichedOrderProductVariant = async (
    orderProductVariant: OrderProductVariant,
): Promise<EnrichedOrderProductVariant | undefined> => {
    const { variantId } = orderProductVariant;

    const productVariantResponse = await syliusFetch<GetProductVariantResponse>(`/shop/product-variants/${variantId}`);

    if (!isFetchResultSuccessful(productVariantResponse)) {
        return undefined;
    }

    const productVariantResource = productVariantResponse.data;
    const productVariant = transformToProductVariant(productVariantResource);

    const productDefaultResponse = await syliusFetch<GetProductResponse>(`/shop/products/${productVariant.productCode}`);

    if (!isFetchResultSuccessful(productDefaultResponse)) {
        return undefined;
    }

    const productDefaultResource = productDefaultResponse.data;
    const productDefault = transformToProductDefault(productDefaultResource);

    const images = productDefault.mediaItems
        .map(mediaItem => mediaItem.image)
        .filter(Boolean) as Image[];

    const image = images.length > 0
        ? images[0]
        : defaultImage();

    return {
        ...orderProductVariant,
        variantSlug: productDefault.variantSlug,
        image,
        brand: productVariant.brand,
    };
};
