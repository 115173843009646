import { FC, ReactElement } from 'react';

import { AccountTabHeader } from '../../../../../compositions';
import { ConnectedChangePasswordForm } from '../../../../../connectors';
import { useTrans } from '../../../../../hooks';

import './AccountChangePasswordTab.scss';

export const AccountChangePasswordTab: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <div className="account-change-password-tab">
            <AccountTabHeader
                title={trans('pages.account.tabs.changePassword.title')}
                description={trans('pages.account.tabs.changePassword.description')}
            />

            <ConnectedChangePasswordForm className="account-change-password-tab__form" />
        </div>
    );
};
