import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductCategory, ProductCategoryPageData } from '../../../entities/@products/ProductCategory/ProductCategory';
import { Link } from '../../../entities/Link/Link';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type ProductCategoryState = AsyncReduxState<{
    activeProductCategory?: ProductCategory;
    productCategoryReplacementUrl?: string;
    breadcrumbs: Link[];
    pageData?: ProductCategoryPageData;
}>;

const initialState: ProductCategoryState = {
    ...initialAsyncReduxState,
    breadcrumbs: [],
};

const productCategorySlice = createSlice({
    name: 'productCategorySlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ProductCategoryState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): ProductCategoryState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ProductCategoryState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ProductCategoryState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setActiveProductCategory(state, action: PayloadAction<ProductCategory | undefined>): ProductCategoryState {
            return {
                ...state,
                activeProductCategory: action.payload,
            };
        },
        setProductCategoryReplacementUrl(state, action: PayloadAction<string | undefined>): ProductCategoryState {
            return {
                ...state,
                productCategoryReplacementUrl: action.payload,
            };
        },
        setProductCategoryBreadcrumbs(state, action: PayloadAction<Link[]>): ProductCategoryState {
            return {
                ...state,
                breadcrumbs: action.payload,
            };
        },
        setProductCategoryPageData(state, action: PayloadAction<ProductCategoryPageData | undefined>): ProductCategoryState {
            return {
                ...state,
                pageData: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setHasFetched,
    setActiveProductCategory,
    setProductCategoryReplacementUrl,
    setProductCategoryBreadcrumbs,
    setProductCategoryPageData,
} = productCategorySlice.actions;

export default productCategorySlice;
