import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { ProductPrice, SafeHtml } from '../../../../../components';
import { ColorSwatches, ProductCallToAction, ProductLabels } from '../../../../../compositions';
import { Product } from '../../../../../entities/@products/Product/Product';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useTrans } from '../../../../../hooks';

import './ProductSidebar.scss';

interface ProductSidebarProps {
    cartIsLoading: boolean;
    wishlistIsLoading: boolean;
    product: Product;
    className?: string;
}

export const ProductSidebar: FC<ProductSidebarProps> = ({
    cartIsLoading,
    wishlistIsLoading,
    product,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);
    const brandDetailUrl = replaceUrlParamKeysWithValues(brandDetailPath, {
        slug: product.brand?.href,
    });

    return (
        <div className={`product-sidebar ${className}`}>
            <ProductLabels
                {...product}
                className="product-sidebar__labels"
            />

            <div className="product-sidebar__brand-wrapper">
                {product.brand && (
                    <Link to={brandDetailUrl} className="product-sidebar__brand-link">
                        <h3 className="product-sidebar__brand">
                            {product.brand.label}
                        </h3>
                    </Link>
                )}
            </div>

            <h1 className="product-sidebar__name">
                {product.name}
            </h1>

            <ProductPrice
                {...product.price}
                className="product-sidebar__price"
                oldPriceClassName="product-sidebar__price--is-old"
            />

            {product.shortDescription && (
                <SafeHtml
                    html={product.shortDescription}
                    className="product-sidebar__description"
                />
            )}

            <div className="product-sidebar__notices">
                {product.availableInTime && (
                    <p className="product-sidebar__notice">
                        {trans('containers.productDetailRenderer.availableIn', {
                            time: product.availableInTime,
                        })}
                    </p>
                )}
            </div>

            {product.colors.length > 1 && (
                <div className="product-sidebar__color-wrapper">
                    <p className="product-sidebar__subtitle">
                        {trans('containers.productDetailRenderer.availableColors', {
                            numberOfColors: product.colors.length,
                        })}
                    </p>

                    <ColorSwatches
                        colors={product.colors}
                        className="product-sidebar__color-swatches"
                    />
                </div>
            )}

            <ProductCallToAction
                cartIsLoading={cartIsLoading}
                wishlistIsLoading={wishlistIsLoading}
                product={product}
                className="product-sidebar__call-to-action"
            />
        </div>
    );
};
