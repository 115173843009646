import { ProductCollectionMediaItem } from '../../entities/@blocks/ProductCollection/ProductCollection';
import { MOCK_IMAGE_2, MOCK_IMAGE_5 } from './media';

export const MOCK_PRODUCT_COLLECTION_MEDIA_ITEMS: ProductCollectionMediaItem[] = [
    {
        id: '1',
        image: MOCK_IMAGE_5,
        backgroundColor: '#bbb660',
        textColor: '#fff',
        link: {
            label: 'Naar product',
            href: '/producten',
        },
    },
    {
        id: '2',
        image: MOCK_IMAGE_2,
        backgroundColor: '#bbb660',
        textColor: '#fff',
        link: {
            label: 'Bekijk producten',
            href: '/producten',
        },
    },
];
