import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
    useMemo,
} from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { Wrapper } from '../../components';
import { Button, Pagination } from '../../compositions';
import { ConnectedProductCard } from '../../connectors';
import { MeilisearchAttribute } from '../../entities/@api/Meilisearch';
import { FormOption } from '../../entities/@forms/Form/Form';
import { Product } from '../../entities/@products/Product/Product';
import { ProductListCallToActionInterface } from '../../entities/@products/ProductListCallToAction/ProductListCallToAction';
import { defaultMeilisearchPageSize, MeilisearchFilter, MeilisearchRangeFilter } from '../../entities/@search/Meilisearch/Meilisearch';
import { PaginationInterface } from '../../entities/Pagination/Pagination';
import { zipArray } from '../../helpers/array';
import { useDeviceWidth, useTrans } from '../../hooks';
import { ProductListFilters } from '..';
import { ProductListSkeletons } from './skeletons';
import { ProductListCallToAction } from './subcomponents';

import './ProductList.scss';

interface ProductListProps extends RefAttributes<HTMLDivElement> {
    isLoading?: boolean;
    showFilterBar?: boolean;
    mayShowResetButton?: boolean;
    title?: string;
    query?: string;
    products: Product[];
    callToActions: ProductListCallToActionInterface[];
    filters: MeilisearchFilter[];
    rangeFilters: MeilisearchRangeFilter[];
    highlightedFilters: MeilisearchFilter[];
    hiddenFilterAttributes?: MeilisearchAttribute[];
    sortOptions: FormOption[];
    pagination?: PaginationInterface;
    onFiltersReset: () => void;
    className?: string;
}

export const ProductList: ForwardRefExoticComponent<ProductListProps> = forwardRef(({
    isLoading,
    showFilterBar,
    mayShowResetButton,
    title,
    sortOptions,
    filters,
    rangeFilters,
    highlightedFilters,
    hiddenFilterAttributes,
    products,
    callToActions,
    pagination,
    onFiltersReset,
    className = '',
}, ref: Ref<HTMLDivElement>): ReactElement => {
    const trans = useTrans();

    const { isMobile } = useDeviceWidth();

    const [filterSidebarIsOpen, toggleFilterSidebarIsOpen] = useToggle(false);

    const productListContent = useMemo((): (Product[] | ProductListCallToActionInterface)[] => {
        const breakpoints = [0, 10, 18];
        const productLists = breakpoints.map((breakpoint, index) => {
            const nextBreakpoint = breakpoints[index + 1] || defaultMeilisearchPageSize;

            return [...products].slice(breakpoint, nextBreakpoint);
        });

        const filteredCallToActions = callToActions.filter((cta, index) => {
            const nextBreakpoint = breakpoints[index + 1] || defaultMeilisearchPageSize;

            return productLists[index].length === nextBreakpoint - breakpoints[index];
        });

        return zipArray<Product[], ProductListCallToActionInterface>(productLists, filteredCallToActions);
    }, [products, callToActions]);

    const productListClassNames = classNames('product-list', {
        'product-list--has-backdrop': filterSidebarIsOpen,
    }, className);

    return (
        <div ref={ref} className={productListClassNames}>
            <Wrapper className="product-list__heading-wrapper">
                {title && (
                    <h2 className="product-list__heading">
                        {title}
                    </h2>
                )}
            </Wrapper>

            <div className="product-list__filter-section">
                <Wrapper className="product-list__wrapper">
                    {showFilterBar && pagination && pagination.totalResults > 0 && (
                        <ProductListFilters
                            sidebarIsOpen={filterSidebarIsOpen}
                            mayShowResetButton={mayShowResetButton}
                            resultsCount={pagination.totalResults}
                            sortOptions={sortOptions}
                            filters={filters}
                            rangeFilters={rangeFilters}
                            highlightedFilters={highlightedFilters}
                            hiddenFilterAttributes={hiddenFilterAttributes}
                            onFilterSidebarToggle={toggleFilterSidebarIsOpen}
                            onFiltersReset={onFiltersReset}
                        />
                    )}
                </Wrapper>
            </div>

            <Wrapper className="product-list__wrapper">
                {isLoading && (
                    <ProductListSkeletons numberOfSkeletons={32} />
                )}

                <div className="product-list__grid">
                    {!isLoading && productListContent.map(item => {
                        if (!Array.isArray(item)) {
                            return (
                                <ProductListCallToAction
                                    {...item}
                                    key={item.id}
                                    className="product-list__call-to-action"
                                />
                            );
                        }

                        return item.map(product => (
                            <ConnectedProductCard
                                key={product.id}
                                isGridView
                                product={product}
                                className="product-list__product-card"
                            />
                        ));
                    })}
                </div>

                {!isLoading && products.length === 0 && (
                    <div className="product-list__no-results-wrapper">
                        <p className="product-list__no-results">
                            {trans('containers.productList.noProductsFound')}
                        </p>

                        {showFilterBar && mayShowResetButton && (
                            <Button
                                hasAnimation
                                text={trans('containers.productList.resetFilters')}
                                onClick={onFiltersReset}
                            />
                        )}
                    </div>
                )}
            </Wrapper>

            <div className="product-list__pagination-section">
                {pagination && pagination.totalPages > 1 && (
                    <Pagination
                        marginCenter={isMobile ? 0 : undefined}
                        marginInitial={isMobile ? 1 : undefined}
                        numberOfPages={pagination.totalPages}
                        currentPage={pagination.page}
                        className="product-list__pagination"
                    />
                )}
            </div>
        </div>
    );
});
