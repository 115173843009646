import { FC, ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { RegistrationForm } from '../../../containers';
import { RegistrationFormData } from '../../../entities/@forms/RegistrationForm/RegistrationForm';
import { GTMEvent } from '../../../entities/GTM/GTM';
import { gtmEvent } from '../../../entities/GTM/GTMService';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { useTrans } from '../../../hooks';
import { registerUser } from '../../../redux/@account/registration/registrationActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedRegistrationFormProps {
    className?: string;
}

export const ConnectedRegistrationForm: FC<ConnectedRegistrationFormProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.registrationSlice.isLoading);
    const error = useTypedSelector(state => state.registrationSlice.error);

    const hasFetched = useTypedSelector(state => state.authenticationSlice.hasFetched);
    const isAuthenticated = useTypedSelector(state => state.authenticationSlice.isAuthenticated);

    const handleSubmit = async (formData: RegistrationFormData): Promise<void> => {
        await dispatch(registerUser(formData));

        gtmEvent({
            event: GTMEvent.signUp,
            method: 'account',
        });
    };

    useEffect(() => {
        if (hasFetched && isAuthenticated) {
            navigate(trans(appRoutes[AppRoute.home].path));
        }
    }, [hasFetched, isAuthenticated]);

    return (
        <RegistrationForm
            isLoading={isLoading}
            error={error}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};
