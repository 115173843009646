import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type RegistrationState = AsyncReduxState<{
    isRegistered: boolean;
}>;

const initialState: RegistrationState = {
    ...initialAsyncReduxState,
    isRegistered: false,
};

const registrationSlice = createSlice({
    name: 'registrationSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): RegistrationState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): RegistrationState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): RegistrationState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsRegistered(state, action: PayloadAction<boolean>): RegistrationState {
            return {
                ...state,
                isRegistered: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setIsRegistered,
} = registrationSlice.actions;

export default registrationSlice;
