import { FC, ReactElement } from 'react';

import { LinkButton } from '../../../../compositions';
import { PageSearchResult } from '../../../../entities/@search/PageSearchResult/PageSearchResult';
import { useTrans } from '../../../../hooks';

import './PageSuggestions.scss';

interface PageSuggestionsProps {
    label: string;
    pages: PageSearchResult[];
    onLinkClick?: () => void;
    className?: string;
}

export const PageSuggestions: FC<PageSuggestionsProps> = ({
    label,
    pages,
    onLinkClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`page-suggestions ${className}`}>
            <p className="page-suggestions__label">
                {label}
            </p>

            {pages.length === 0 && (
                <p className="page-suggestions__no-results">
                    {trans('containers.searchDrawer.noPageResults')}
                </p>
            )}

            {pages.length > 0 && (
                <ul className="page-suggestions__list">
                    {pages.map(page => (
                        <li key={page.id}>
                            <LinkButton
                                to={page.link.href}
                                text={page.link.label}
                                onClick={onLinkClick}
                                className="page-suggestions__list-item"
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
