import { Product } from '../../@products/Product/Product';
import { Address } from '../../Address/Address';
import { DateIsoString } from '../../Date/Date';
import { Link } from '../../Link/Link';
import { Image } from '../../Media/Media';

export interface Order {
    id: string;
    totalPrice: number;
    orderNumber: string;
    orderDate: Date;
    deliveryDate: Date;
    products: Product[];
}

export enum OrderState {
    cancelled = 'cancelled',
    cart = 'cart',
    fulfilled = 'fulfilled',
    new = 'new',
}

export interface OrderBase {
    id: string;
    tokenValue: string;
    orderNumber: string;
    state: OrderState;
    priceTotal: number;
    priceSubTotal: number;
    channel: string;
    orderDate?: DateIsoString;
}

export interface OrderProductVariant {
    id: string;
    variantId: string;
    productName: string;
    quantity: number;
    priceUnit: number;
    priceUnitOriginal: number;
    priceUnitDiscounted: number;
    priceSubTotal: number;
    priceTotal: number;
}

export interface EnrichedOrderProductVariant extends OrderProductVariant {
    variantSlug: string;
    image: Image;
    brand?: Link;
}

export enum OrderPaymentProvider {
    paynl = 'paynl',
}

export interface OrderPayment {
    id: string;
    provider: OrderPaymentProvider;
}

export enum OrderShipmentMethod {
    ups = 'ups',
}

export interface OrderShipment {
    id: string;
    method: OrderShipmentMethod;
}

export enum CheckoutState {
    addressed = 'addressed',
    cart = 'cart',
    completed = 'completed',
    paymentSelected = 'paymentSelected',
    paymentSkipped = 'paymentSkipped',
    shippingSelected = 'shippingSelected',
    shippingSkipped = 'shippingSkipped',
}

export enum PaymentState {
    authorized = 'authorized',
    awaitingPayment = 'awaitingPayment',
    cancelled = 'cancelled',
    cart = 'cart',
    paid = 'paid',
    partiallyAuthorized = 'partiallyAuthorized',
    partiallyPaid = 'partiallyPaid',
    partiallyRefunded = 'partiallyRefunded',
    refunded = 'refunded',
}

export enum OrderPaymentMethod {
    bancontact = 'bancontact',
    bankTransfer = 'bankTransfer',
    creditCard = 'creditCard',
    giftCard = 'giftCard',
    ideal = 'ideal',
    in3 = 'in3',
    klarna = 'klarna',
    paypal = 'paypal',
    pin = 'pin',
    riverty = 'riverty',
    sofort = 'sofort',
    voucher = 'voucher',
    unknown = 'unknown',
}

export enum ShippingState {
    ready = 'ready',
}

export interface OrderDetailInterface {
    id: string;
    tokenValue: string;
    orderNumber: string;
    productVariants: OrderProductVariant[];
    currencyCode: string;
    localeCode: string;
    checkoutState: CheckoutState;
    paymentState: PaymentState;
    shippingState: ShippingState;
    state: OrderState;
    paymentMethod: OrderPaymentMethod;
    priceItemsSubTotal: number;
    priceItemsTotal: number;
    priceTaxTotal: number;
    priceShippingTotal: number;
    priceShippingTaxTotal: number;
    priceTaxExcludedTotal: number;
    priceTaxIncludedTotal: number;
    priceOrderPromotionTotal: number;
    priceShippingPromotionTotal: number;
    priceTotal: number;
    shippingAddress?: Address;
    billingAddress?: Address;
    payments: OrderPayment[];
    shipments: OrderShipment[];
    orderDate?: string;
}

export interface EnrichedOrderDetailInterface extends Omit<OrderDetailInterface, 'productVariants'> {
    productVariants: EnrichedOrderProductVariant[];
}
