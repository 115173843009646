import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { SliderSection } from '../../containers';
import { MeilisearchAttribute, MeilisearchIndex } from '../../entities/@api/Meilisearch';
import { FeaturedProductsInterface } from '../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { Product } from '../../entities/@products/Product/Product';
import { MeilisearchStoreKey } from '../../entities/@search/Meilisearch/Meilisearch';
import { generateMeilisearchFilterQuery } from '../../entities/@search/Meilisearch/MeilisearchRequests';
import { generateUuid } from '../../helpers/string';
import { fetchMeilisearchVariants } from '../../redux/meilisearch/meilisearchActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { ConnectedProductCard } from '../ConnectedProductCard/ConnectedProductCard';

export interface ConnectedProductsSliderProps extends FeaturedProductsInterface {
    className?: string;
}

export const ConnectedProductsSlider: FC<ConnectedProductsSliderProps> = ({
    className = '',
    ...productsSliderProps
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const storeKey = MeilisearchStoreKey.productsSlider + productsSliderProps.id as MeilisearchStoreKey;

    const variantResponses = useTypedSelector(state => state.meilisearchSlice.variantResponses);

    useEffect((): void => {
        const filter = generateMeilisearchFilterQuery(MeilisearchAttribute.lists, productsSliderProps.meilisearchProductListName);

        dispatch(fetchMeilisearchVariants({
            key: storeKey,
            index: MeilisearchIndex.variantsNl,
            filter,
        }));
    }, []);

    const products = useMemo((): Product[] => {
        const variantResponse = variantResponses[storeKey];

        return variantResponse
            ? variantResponse.results
            : [];
    }, [variantResponses]);

    // TODO: Fix this to don't need to please the type checker
    const blockId = productsSliderProps.id || generateUuid();

    return (
        <SliderSection
            {...productsSliderProps}
            titleHasThemeColor
            id={blockId}
            items={products.map(product => (
                <ConnectedProductCard product={product} />
            ))}
            className={className}
        />
    );
};
