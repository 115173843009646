import { Token, tokenKey } from '../../@api/Sylius';

export const getCustomerLoods5Id = (): string => {
    const storedToken = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);

    const tokenData = storedToken
        ? JSON.parse(storedToken) as Token
        : undefined;

    return tokenData?.customerLoods5Id || '';
};
