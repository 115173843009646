import { MeilisearchAttribute, MeilisearchFilterOperator, SearchConfigResource } from '../../@api/Meilisearch';
import { defaultMeilisearchPageSize, MeilisearchSearchConfig } from './Meilisearch';

// Convert attribute and value into a valid filter as input for Meilisearch fetches, for string or string array values
export const generateMeilisearchFilterQuery = (attribute: MeilisearchAttribute, value: string | string[]): string => {
    if (Array.isArray(value)) {
        return value.map(val => generateMeilisearchFilterQuery(attribute, val))
            .join(` ${MeilisearchFilterOperator.or} `);
    }

    const formattedValue = value.trim();

    return `${attribute} = '${formattedValue}'`;
};

export const generateMeilisearchExcludeFilterQuery = (attribute: MeilisearchAttribute, value: string): string => {
    const formattedValue = value.trim();

    return `(${MeilisearchFilterOperator.not} ${attribute} = '${formattedValue}')`;
};

export const generateMeilisearchConfigResource = (config: MeilisearchSearchConfig): SearchConfigResource => {
    const sort = config.sort
        ? [config.sort]
        : [];

    const pageSize = config.pageSize || defaultMeilisearchPageSize;

    return {
        q: config.query,
        filter: config.filter,
        sort,
        facets: config.facets,
        hitsPerPage: pageSize,
        page: config.page || 1,
    };
};
