import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { transformButtonToLink } from '../../Link/LinkTransformers';
import { FeaturedProductsInterface, FeaturedProductsResource, FeaturedProductsType } from './FeaturedProducts';

export const transformToFeaturedProducts = (resource: FeaturedProductsResource): FeaturedProductsInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    let layoutType = resource.type === 'Grid-3'
        ? FeaturedProductsType.verticalTitle
        : FeaturedProductsType.alternatingSizes;

    if (resource.type === 'Slider') {
        layoutType = FeaturedProductsType.slider;
    }

    const meilisearchProductListName = resource.product_selection
        ? resource.product_selection.name
        : '';

    const link = resource.button
        ? transformButtonToLink(resource.button)
        : undefined;

    return {
        ...defaultBlock,
        type: BlockType.featuredProducts,
        title: resource.title,
        products: [],
        meilisearchProductListName,
        link,
        layoutType,
    };
};
