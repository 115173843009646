import { FC, ReactElement } from 'react';

import { Skeleton } from '../../components';
import { useTrans } from '../../hooks';

import './SearchCount.scss';

interface SearchCountProps {
    isLoading?: boolean;
    query: string;
    totalResults: number;
    className?: string;
}

export const SearchCount: FC<SearchCountProps> = ({
    isLoading,
    query,
    totalResults,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`search-count ${className}`}>
            {isLoading && (
                <div className="search-count__skeleton-wrapper">
                    <Skeleton className="search-count__skeleton" />
                </div>
            )}

            {!isLoading && (
                <p className="search-count__label">
                    {query.length > 0 && totalResults === 1 && (
                        trans('containers.searchCount.singular', { query })
                    )}

                    {query.length > 0 && totalResults !== 1 && (
                        trans('containers.searchCount.plural', {
                            count: totalResults,
                            query,
                        })
                    )}
                </p>
            )}
        </div>
    );
};
