import { transformToOrderBase } from '../../../entities/@account/OrderDetail/OrderDetailTransformers';
import { OrderOverviewConfig, OrderOverviewOrder } from '../../../entities/@account/OrderOverview/OrderOverview';
import { fetchOrderOverviewOrder } from '../../../entities/@account/OrderOverview/OrderOverviewService';
import { authorizedSyliusFetch, GetOrdersResponse } from '../../../entities/@api/Sylius';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { transformSyliusPaginationToPagination } from '../../../entities/Pagination/PaginationTransformers';
import { ReduxFetchAction } from '../../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setOnlineOrderOverview,
} from './orderOverviewSlice';

export const fetchOnlineOrderOverview: ReduxFetchAction<OrderOverviewConfig> = config => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setHasFetched(false));
    dispatch(setError(''));
    dispatch(setOnlineOrderOverview(undefined));

    try {
        const query = `page=${config.page}&itemsPerPage=${config.itemsPerPage}`;
        const queryFilter = '&isWebshop=true&order[orderedAt]=desc';
        const onlineOrderOverviewResponse = await authorizedSyliusFetch<GetOrdersResponse>(`/shop/orders?${query}${queryFilter}`);

        if (!isFetchResultSuccessful(onlineOrderOverviewResponse)) {
            dispatch(setError(onlineOrderOverviewResponse.error));
            return;
        }

        const orderResources = onlineOrderOverviewResponse.data['hydra:member'];
        const totalItems = onlineOrderOverviewResponse.data['hydra:totalItems'];

        const orders = orderResources.map(transformToOrderBase);
        const orderPromises = orders.map(fetchOrderOverviewOrder);

        const resolvedOrderPromises = await Promise.all(orderPromises);
        const foundOrders = resolvedOrderPromises.filter(Boolean) as OrderOverviewOrder[];

        const pagination = transformSyliusPaginationToPagination(totalItems, config);

        const onlineOrderOverview = {
            orders: foundOrders,
            pagination,
        };

        dispatch(setOnlineOrderOverview(onlineOrderOverview));
    } catch (error) {
        console.error('[fetchOnlineOrderOverview]', error);
    } finally {
        dispatch(setIsLoading(false));
        dispatch(setHasFetched(true));
    }
};
